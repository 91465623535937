import { create } from 'zustand';

const useCultureStore = create((set) => ({
  searchKeyword: null,
  currentPage: 0,
  itemsPerPage: 5,
  showUsedRecords: false,
  sortConfig: { key: null, direction: null },
  selectedCultureName: "",
  cultureTypeId: null,
  geneticTypeId: null,
  selectedGeneticTypeName: "",
  curingTypeId: null,
  selectedCuringTypeName: "",
  cultureId: null,
  selectedCultureId :"",

  setSearchKeyword: (keyword) => set({ searchKeyword: keyword }),
  setCurrentPage: (page) => set({ currentPage: page }),
  setItemsPerPage: (size) => set({ itemsPerPage: size }),
  setShowUsedRecords: (show) => set({ showUsedRecords: show }),
  setSortConfig: (config) => set({ sortConfig: config }),
  setSelectedCultureName: (name) => set({ selectedCultureName: name }),
  setCultureTypeId: (id) => set({ cultureTypeId: id }),
  setGeneticTypeId: (id) => set({ geneticTypeId: id }),
  setSelectedGeneticTypeName: (name) => set({ selectedGeneticTypeName: name }),
  setCuringTypeId: (id) => set({ curingTypeId: id }),
  setSelectedCuringTypeName: (name) => set({ selectedCuringTypeName: name }),
  setCultureId: (id) => set({ cultureId: id }),
  setSelectedCultureId : (id) => set ({selectedCultureId:id})
}));

export default useCultureStore;
