import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import MenuIcon1 from "assets/MenuIcon1.svg";
import DragIcon from "assets/_DragabbleIcon_.svg";
import SubMenuArea from './SubMenuArea';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MSCModal from "./MSCModal";
import MSCService from "services/MSCService";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Tooltip from "@mui/material/Tooltip";

const defaultcountryid = null;

const tableContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const tableRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
  boxShadow: '-2px 1px 10px -2px #00000040',
  backgroundColor: 'white',
  borderRadius: '5px',
  marginRight: '2px',
  marginLeft: '2px',
};

const tableHeaderStyle = {
  color: '#3b454f',
  fontWeight: 600,
  fontSize: '1.125rem',
  borderBottom: '5px solid #102a62',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '12px',
  textAlign: 'left',
};

const tableCellStyle = {
  padding: '12px',
  textAlign: 'left',
  color: '#3b454f',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
};

const actionButtonStyle = {
  cursor: 'pointer',
  padding: '5px',
  margin: '0 5px',
};



const SubMenuTable = ({ onDelete, selectedRow, selectedCountryId }) => {
  console.log("Selected Row Details from Submenutable:",selectedRow);
  const navigate = useNavigate();

  // State variables
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


    // Fetch SubMenu Data
    useEffect(() => {
      const fetchSubMenus = async () => {
        try {
          // Use selectedRow.menuId as the parentMenuId
          const parentMenuId = selectedRow?.menuId;
          
          // Only fetch if parentMenuId exists
          if (parentMenuId) {
            const response = await MSCService.getSubMenuAPI(parentMenuId);
            
            if (response.data && response.data.response) {
              setData(response.data.response);
              setLoading(false);
            } else {
              throw new Error("No data received");
            }
          } else {
            // If no parentMenuId, use initial data or set to empty array
            setData([]);
            setLoading(false);
          }
        } catch (err) {
          console.error("Error fetching submenus:", err);
          setData([]);
          setError(err);
          setLoading(false);
          setMessage(t('notfound'));
          setSeverity("error");
          setOpenAlert(true);
        }
      };
  
      fetchSubMenus();
    }, [selectedRow]); // Add selectedRow as a dependency

  // // Static mock data with 5 records
  const initialData = [];
  // const initialData = [
  //   {
  //     "menuId": "14e102d9-f7e0-4679-9e53-bc5d7ad9877f",
  //     "menuName": "Menu Structure Registration",
  //     "menuOrder": 1,
  //     "menuType": "Functionaltiy",
  //     "parentMenuId": "3054eeb7-1a5c-454c-a9c1-c50722198f1c",
  //     "subMenus": []
  //   },
  //   {
  //     "menuId": "557ff7ce-a227-4ede-bcf2-b2119cb5dae7",
  //     "menuName": "Farmer Registration",
  //     "menuOrder": 2,
  //     "menuType": "Category",
  //     "parentMenuId": "3054eeb7-1a5c-454c-a9c1-c50722198f1c",
  //     "subMenus": [
  //       {
  //         "menuId": "badb0052-1daf-4bc3-aef4-0ef6dfdd3765",
  //         "menuName": "Farmer Dependent Registration",
  //         "menuOrder": 1,
  //         "menuType": "Functionaltiy",
  //         "parentMenuId": "557ff7ce-a227-4ede-bcf2-b2119cb5dae7",
  //         "subMenus": []
  //       },
  //       {
  //         "menuId": "0ba2b60e-ac9f-49c7-a637-e6b276601d91",
  //         "menuName": "Farmer Property Registration",
  //         "menuOrder": 2,
  //         "menuType": "Functionaltiy",
  //         "parentMenuId": "557ff7ce-a227-4ede-bcf2-b2119cb5dae7",
  //         "subMenus": []
  //       }
  //     ]
  //   },
  //   {
  //     "menuId": "3400a2cc-5568-495e-8640-dfaba5ccb33a",
  //     "menuName": "Crop Year",
  //     "menuOrder": 3,
  //     "menuType": "Functionaltiy",
  //     "parentMenuId": "3054eeb7-1a5c-454c-a9c1-c50722198f1c",
  //     "subMenus": []
  //   },
  //   {
  //     "menuId": "91684d5b-760c-418b-afe1-b8dfa4aae8a2",
  //     "menuName": "Test Sub Menu 1",
  //     "menuOrder": 4,
  //     "menuType": "Category",
  //     "parentMenuId": "3054eeb7-1a5c-454c-a9c1-c50722198f1c",
  //     "subMenus": [
  //       {
  //         "menuId": "bd54701e-3c22-4444-b176-785ec32ef395",
  //         "menuName": "Child Sub Menu 1",
  //         "menuOrder": 1,
  //         "menuType": "Functionaltiy",
  //         "parentMenuId": "91684d5b-760c-418b-afe1-b8dfa4aae8a2",
  //         "subMenus": []
  //       }
  //     ]
  //   }
  // ];

  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [parentMenuForChildSubmenu, setParentMenuForChildSubmenu] = useState(null);

  const handleDeleteClick = (menuId) => {
    setDeleteId(menuId);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Call delete API with the menuId
      await MSCService.deleteAPI(deleteId, 'ADMIN');

      // Refetch the submenu list to ensure consistency
      const refreshedSubMenus = await MSCService.getSubMenuAPI(selectedRow?.menuId);
      
      if (refreshedSubMenus.data && refreshedSubMenus.data.response) {
        setData(refreshedSubMenus.data.response);
      } else {
        // If API refresh fails, manually remove the deleted item
        const updatedData = data.filter(row => 
          row.menuId !== deleteId && 
          !row.subMenus?.some(subMenu => subMenu.menuId === deleteId)
        );
        setData(updatedData);
      }
      
      setMessage(t('deletedSuccessfully'));
      setSeverity("success");
      
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      console.error("Error deleting submenu:", error);
      setMessage(error?.response?.data?.message || t('errorDeletingSubmenu'));
      setSeverity("error");
    } finally {
      setOpenAlert(true);
      setOpen(false);
    }
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData(
      [...data].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

    const handleEditClick = async (row) => {
      try {
        // Fetch detailed menu information using the API
        const response = await MSCService.getSingleMenuDetailsAPI(row.menuId);
        
        if (response?.data?.response) {
          const menuDetails = response.data.response;
          
          // Prepare menu icon for modal
          let menuIcon = null;
          if (menuDetails.menuIcon) {
            if (menuDetails.menuIcon.startsWith('http')) {
              // Remove SAS token if present
              menuIcon = menuDetails.menuIcon.split('?')[0];
            } else if (menuDetails.menuIcon.startsWith('data:')) {
              menuIcon = menuDetails.menuIcon;
            } else {
              menuIcon = `data:image/png;base64,${menuDetails.menuIcon}`;
            }
          }

          // Open modal with fetched details
          setEditingRow({
            menuId: menuDetails.menuId,
            menuName: menuDetails.menuName,
            menuOrder: menuDetails.menuOrder?.toString() || '',
            menuType: menuDetails.menuType || '',
            menuIcon: menuIcon,
            link: menuDetails.link || '',
          });
          setIsAddModalOpen(true);
        }
      } catch (error) {
        // Handle error if API call fails
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      }
    };

  // Update table styles to be responsive to editing state
  const getTableStyle = () => ({
    ...tableStyle,
    tableLayout: 'fixed',
    // width: isEditing ? '40vw' : '100%', // Shrink width when editing
    width: '100%',
    // transition: 'width 0.3s ease-in-out',
    // marginLeft: isEditing ? '0' : 'auto', // Push to the left when editing
    // marginRight: isEditing ? 'auto' : '0'
  });

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  // Helper function to convert file to base64 (updated)
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // If file is already a base64 string, return it directly
      if (typeof file === 'string') {
        // If it's a full data URL, extract base64 content
        if (file.startsWith('data:')) {
          resolve(file.split(',')[1]);
        } 
        // If it's a base64 string without prefix, return as-is
        else if (/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/.test(file)) {
          resolve(file);
        }
        // If it's a URL, resolve with null
        else if (file.startsWith('http')) {
          resolve(null);
        }
        // If it doesn't match expected formats, reject
        else {
          reject(new Error('Invalid file input'));
        }
        return;
      }

      // If it's a File object
      if (file instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 content
        reader.onerror = (error) => reject(error);
      } else {
        reject(new Error('Invalid file type'));
      }
    });
  };

  const handleAddSubmenu = async (submenuData, parentRow = null) => {
    try {
      // Convert image file to base64 if present
      let base64FileContent = null;
      if (submenuData.imageFile) {
        base64FileContent = await getBase64(submenuData.imageFile);
      }

      // Prepare the submenu payload with parent menu details
      const submenuPayload = {
        menuName: submenuData.description,
        menuOrder: parseInt(submenuData.menuOrder, 10), // Ensure it's a number
        menuType: submenuData.menuType,
        menuIcon: submenuData.imageFile ? {
          fileName: submenuData.imageFile.name || 'menu_icon.png',
          base64FileContent: base64FileContent
        } : null,
        isMenuIconChanged: !!submenuData.imageFile,
        parentMenuId: selectedRow?.menuId, // Use selectedRow.menuId
        link: submenuData.url || null,
        countryId: selectedCountryId || defaultcountryid, // Assuming you have country ID in selectedRow
        languageId: null,
        createdByUser: "ADMIN", // You might want to dynamically set this
        modifiedByUser: "ADMIN"
      };

      console.log("Submenu Payload:", submenuPayload);

      // Call createMenuAPI to save the submenu
      const response = await MSCService.createMenuAPI(submenuPayload);

      // Assuming the API returns the created menu item
      const newSubmenu = {
        ...submenuPayload,
        menuId: response.data.menuId || `temp-${Math.random().toString(36).substr(2, 9)}`,
        subMenus: []
      };

      // Update the data state
      const updatedData = data.map(row => {
        if (row.menuId === submenuPayload.parentMenuId) {
          return {
            ...row,
            subMenus: [...(row.subMenus || []), newSubmenu]
          };
        }
        return row;
      });

      // Refetch the entire submenu list to ensure consistency
      const refreshedSubMenus = await MSCService.getSubMenuAPI(selectedRow?.menuId);
      
      if (refreshedSubMenus.data && refreshedSubMenus.data.response) {
        setData(refreshedSubMenus.data.response);
      } else {
        // Fallback to the manually updated data if API refresh fails
        setData(updatedData);
      }
      
      // Close the modal
      setIsAddModalOpen(false);
      
      // Show success message
      setMessage(t('soilAnalysisApplicationPhase.messages.updateSuccess'));
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      console.error("Error creating submenu:", error);
      
      // Show error message
      setMessage(t('errorAddingSubmenu'));
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleOpenChildSubmenuModal = (parentRow) => {
    // Set the parent menu for the child submenu
    setParentMenuForChildSubmenu(parentRow);
    
    // Open the modal with empty initial data
    setEditingRow(null);
    setIsAddModalOpen(true);
  };

  const handleAddChildSubmenu = async (submenuData) => {
    try {
      // Convert image file to base64 if present
      let base64FileContent = null;
      if (submenuData.imageFile) {
        base64FileContent = await getBase64(submenuData.imageFile);
      }

      // Prepare the submenu payload with the specific parent menu details
      const childsubmenuPayload = {
        menuName: submenuData.description,
        menuOrder: parseInt(submenuData.menuOrder, 10), // Ensure it's a number
        menuType: submenuData.menuType,
        menuIcon: submenuData.imageFile ? {
          fileName: submenuData.imageFile.name || 'menu_icon.png',
          base64FileContent: base64FileContent
        } : null,
        isMenuIconChanged: !!submenuData.imageFile,
        parentMenuId: parentMenuForChildSubmenu.menuId, // Use the specific parent menu's ID
        link: submenuData.url || null,
        countryId: selectedCountryId || defaultcountryid,
        languageId: null,
        createdByUser: "ADMIN",
        modifiedByUser: "ADMIN"
      };

      console.log("Child Submenu Payload:", childsubmenuPayload);

      // Call createMenuAPI to save the submenu
      const response = await MSCService.createMenuAPI(childsubmenuPayload);

      // Refetch the entire submenu list to ensure consistency
      const refreshedSubMenus = await MSCService.getSubMenuAPI(selectedRow?.menuId);
      
      if (refreshedSubMenus.data && refreshedSubMenus.data.response) {
        setData(refreshedSubMenus.data.response);
      }
      
      // Close the modal
      setIsAddModalOpen(false);
      
      // Reset parent menu for child submenu
      setParentMenuForChildSubmenu(null);
      
      // Show success message
      setMessage(t('soilAnalysisApplicationPhase.messages.updateSuccess'));
      setSeverity("success");
      setOpenAlert(true);
    } catch (error) {
      console.error("Error creating child submenu:", error);
      
      // Show error message
      setMessage(t('errorAddingSubmenu'));
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleUpdateSubmenu = async (updatedData) => {
    try {
      // Convert image file to base64 if present
      let base64FileContent = null;
      let isMenuIconChanged = false;

      // Handle image conversion
      if (updatedData.imageFile) {
        try {
          base64FileContent = await getBase64(updatedData.imageFile);
          isMenuIconChanged = !!base64FileContent;
        } catch (conversionError) {
          console.warn('Image conversion error:', conversionError);
          // If conversion fails, keep existing icon or set to null
          base64FileContent = null;
          isMenuIconChanged = false;
        }
      }

      // Prepare the submenu payload for update
      const updatesubmenuPayload = {
        menuId: editingRow.menuId,
        menuName: updatedData.description,
        menuOrder: parseInt(updatedData.menuOrder, 10),
        menuType: updatedData.menuType,
        menuIcon: base64FileContent ? {
          fileName: updatedData.imageFile instanceof File 
            ? updatedData.imageFile.name 
            : 'menu_icon.png',
          base64FileContent: base64FileContent
        } : null,
        isMenuIconChanged: isMenuIconChanged,
        parentMenuId: selectedRow?.menuId,
        link: updatedData.menuType === 'Link' ? updatedData.url : null,
        countryId: selectedCountryId || defaultcountryid,
        languageId: null,
        createdByUser: "ADMIN",
        modifiedByUser: "ADMIN"
      };

      // Call createMenuAPI to save the updated submenu
      const response = await MSCService.createMenuAPI(updatesubmenuPayload);

      // Refetch the entire submenu list to ensure consistency
      const refreshedSubMenus = await MSCService.getSubMenuAPI(selectedRow?.menuId);
      
      if (refreshedSubMenus.data && refreshedSubMenus.data.response) {
        setData(refreshedSubMenus.data.response);
      }
      
      // Close the modal
      setIsAddModalOpen(false);

      // Show success message
      setMessage(t('soilAnalysisApplicationPhase.messages.updateSuccess'));
      setSeverity("success");
      setOpenAlert(true);

      // Reset editing row
      setEditingRow(null);
    } catch (error) {
      console.error("Error updating submenu:", error);
      
      // Show error message
      setMessage(t('errorUpdatingSubmenu'));
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleUpdateChildSubmenu = async (updatedData) => {
    try {
      // Convert image file to base64 if present
      let base64FileContent = null;
      let isMenuIconChanged = false;

      // Handle image conversion
      if (updatedData.imageFile) {
        try {
          base64FileContent = await getBase64(updatedData.imageFile);
          isMenuIconChanged = !!base64FileContent;
        } catch (conversionError) {
          console.warn('Image conversion error:', conversionError);
          base64FileContent = null;
          isMenuIconChanged = false;
        }
      }

      // First, fetch the detailed menu information to get both menuId and parentMenuId
      const response = await MSCService.getSingleMenuDetailsAPI(editingRow.menuId);
      
      if (!response?.data?.response) {
        throw new Error('Could not retrieve menu details');
      }

      const menuDetails = response.data.response;

      // Prepare the submenu payload for update
      const updateChildSubmenuPayload = {
        menuId: menuDetails.menuId, // Use the menuId from getSingleMenuDetailsAPI
        menuName: updatedData.description,
        menuOrder: parseInt(updatedData.menuOrder, 10),
        menuType: updatedData.menuType,
        menuIcon: base64FileContent ? {
          fileName: updatedData.imageFile instanceof File 
            ? updatedData.imageFile.name 
            : 'menu_icon.png',
          base64FileContent: base64FileContent
        } : null,
        isMenuIconChanged: isMenuIconChanged,
        parentMenuId: menuDetails.parentMenuId, // Use the parentMenuId from getSingleMenuDetailsAPI
        link: updatedData.menuType === 'Link' ? updatedData.url : null,
        countryId: selectedCountryId || defaultcountryid,
        languageId: null,
        createdByUser: "ADMIN",
        modifiedByUser: "ADMIN"
      };

      console.log("Update Child Submenu Payload:", updateChildSubmenuPayload);

      // Call createMenuAPI to save the updated child submenu
      await MSCService.createMenuAPI(updateChildSubmenuPayload);

      // Refetch the entire submenu list to ensure consistency
      const refreshedSubMenus = await MSCService.getSubMenuAPI(selectedRow?.menuId);
      
      if (refreshedSubMenus.data && refreshedSubMenus.data.response) {
        setData(refreshedSubMenus.data.response);
      }
      
      // Close the modal
      setIsAddModalOpen(false);

      // Show success message
      setMessage(t('soilAnalysisApplicationPhase.messages.updateSuccess'));
      setSeverity("success");
      setOpenAlert(true);

      // Reset editing row
      setEditingRow(null);
    } catch (error) {
      console.error("Error updating child submenu:", error);
      
      // Show error message
      setMessage(t('errorUpdatingSubmenu'));
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  // Add a reorder function similar to PAFB-Details and MSCTable
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Modify renderRows to support drag and drop for all levels
  const renderRows = (menuData) => {
    const rows = [];

    const processMenu = (menu, depth = 0, parentIndex = 0) => {
      // Ensure menu is defined and has a valid menuId
      if (!menu || !menu.menuId) {
        return;
      }

      // Create a row for the main menu
      const mainRow = {
        ...menu,
        rowId: menu.menuId,
        depth,
        index: parentIndex
      };
      rows.push(mainRow);

      // Recursively add sub-menu rows
      if (menu.subMenus && menu.subMenus.length > 0) {
        menu.subMenus.forEach((subMenu, subIndex) => {
          processMenu(subMenu, depth + 1, subIndex);
        });
      }
    };

    // Process each menu item in the menuData array
    menuData.forEach((menu, index) => {
      processMenu(menu, 0, index);
    });

    return rows;
  };

  // Modify onDragEnd to handle multi-level dragging
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Function to find and update the correct menu level
    const updateMenuOrder = (menuList, sourceIndex, destIndex, depth = 0) => {
      // If this is the correct depth, reorder at this level
      if (depth === 0) {
        return reorder(menuList, sourceIndex, destIndex);
      }

      // Otherwise, recursively search through submenus
      return menuList.map(menu => {
        if (menu.subMenus && menu.subMenus.length > 0) {
          return {
            ...menu,
            subMenus: updateMenuOrder(menu.subMenus, sourceIndex, destIndex, depth - 1)
          };
        }
        return menu;
      });
    };

    // Determine the depth of the dragged item
    const flattenedRows = renderRows(data);
    const draggedItem = flattenedRows[result.source.index];
    
    // Update the data with reordered list
    const updatedData = updateMenuOrder(data, result.source.index, result.destination.index, draggedItem.depth);

    // Update the state
    setData(updatedData);

    // TODO: Implement API call to update menu order on backend
  };

  return (
    <div>
        {/* SubMenuDiv with Add New Button */}
      <div 
        style={{
          backgroundColor: 'white', 
          color: '#3B454F',
          padding: '10px 10px 10px 10px', 
          height: '52px',
          fontSize: '18px', 
          fontWeight: 600,
          border: '1px solid #E0E0E0',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Submenus
        <Button
          sx={{
            borderRadius: 1,
            minWidth: 95,
            minHeight: 44,
            background: "#004F9F",
            color: "white",
            "&:hover": { background: "#003A75" }
          }}
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={handleOpenAddModal}
        >
          ADD SUBMENU
        </Button>
      </div>


      {/* SubMenuTable */}
      <div 
        style={{
          ...tableContainerStyle,
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="menu-list">
            {(provided) => (
              <table 
                style={getTableStyle()} 
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <thead>
                  <tr style={tableRowStyle}>
                    <th style={{...tableHeaderStyle, width: '10vw'}}>No.</th>
                    <th 
                      style={{...tableHeaderStyle, width: '10vw'}}
                      onClick={() => handleSort('applicationPhase')}
                    >
                      Menu Icons {getSortIcon('applicationPhase')}
                    </th>
                    <th 
                      style={{...tableHeaderStyle, width: '40vw'}}
                      onClick={() => handleSort('description')}
                    >
                      Description {getSortIcon('description')}
                    </th>
                    <th 
                      style={{...tableHeaderStyle, width: '30vw'}}
                      onClick={() => handleSort('menuType')}
                    >
                      Menu Type {getSortIcon('menuType')}
                    </th>
                    <th style={{...tableHeaderStyle, width: '10vw'}}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderRows(data).map((row) => (
                    <Draggable 
                      key={row.rowId} 
                      draggableId={row.rowId} 
                      index={row.index}
                    >
                      {(provided) => (
                        <tr 
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...tableRowStyle,
                            marginLeft: `${row.depth * 30}px`,
                            width: `calc(100% - ${row.depth * 30}px)`,
                            ...provided.draggableProps.style
                          }}
                        >
                          <td style={{...tableCellStyle, width: '10vw', alignItems: 'flex-start'}}>
                            <div 
                              style={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                justifyContent: 'flex-start',
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <span 
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 300,
                                  textAlign: 'right',
                                  textDecorationSkipInk: 'none',
                                  marginBottom: '8px', 
                                  fontWeight: 'bold'
                                }}
                              >
                                Order
                              </span>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {row.depth > 0 && (
                                  <div 
                                    style={{ 
                                      width: '20px', 
                                      borderLeft: '2px solid #e0e0e0', 
                                      marginRight: '10px',
                                      height: '100%' 
                                    }} 
                                  />
                                )}
                                
                                {/* Drag handle for all levels */}
                                <div {...provided.dragHandleProps}>
                                  <img 
                                    src={DragIcon} 
                                    alt="Drag" 
                                    style={{ 
                                      marginRight: '8px', 
                                      width: '21px', 
                                      height: '28px',
                                      cursor: 'move',
                                      opacity: row.depth > 0 ? 0.5 : 1 // Slightly fade for nested levels
                                    }} 
                                  />
                                </div>
                                
                                {row.menuOrder}
                              </div>
                            </div>
                          </td>
                          <td style={{...tableCellStyle, width: '10vw', alignItems: 'flex-start'}}>
                            <div style={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'center', 
                              justifyContent: 'flex-start',
                              width: '100%',
                              height: '100%',
                            }}>
                              <span 
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 300,
                                  textAlign: 'right',
                                  textDecorationSkipInk: 'none',
                                  marginBottom: '8px', 
                                  fontWeight: 'bold'
                                }}
                              >
                                Menu Icon
                              </span>
                              <img 
                                src={row.menuIcon?.startsWith('http') 
                                ? `${row.menuIcon}${process.env.REACT_APP_SAS}`
                                : (row.menuIcon ? `data:image/png;base64,${row.menuIcon}` : MenuIcon1)
                              } 
                                alt="Menu Icon" 
                                style={{ width: '30px', height: '30px', objectFit: 'contain' }} 
                              />
                            </div>
                          </td>
                          <td style={{...tableCellStyle, width: '40vw', alignItems: 'flex-start'}}>
                            <div style={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'start', 
                              justifyContent: 'flex-start',
                              width: '100%',
                              height: '100%',
                            }}>
                              <span 
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 300,
                                  textAlign: 'right',
                                  textDecorationSkipInk: 'none',
                                  marginBottom: '8px', 
                                  fontWeight: 'bold'
                                }}
                              >
                                Description
                              </span>
                              <span 
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '26px',
                                  fontWeight: 400,
                                  textAlign: 'left',
                                  textDecorationSkipInk: 'none',
                                  color: '#3B454F',
                                }}
                              >
                                {row.menuName || 'N/A'}
                              </span>
                            </div>
                          </td>
                          <td style={{...tableCellStyle, width: '30vw', alignItems: 'flex-start'}}>
                            <div style={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'start', 
                              justifyContent: 'flex-start',
                              width: '100%',
                              height: '100%',
                            }}>
                              <span 
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 300,
                                  textAlign: 'right',
                                  textDecorationSkipInk: 'none',
                                  marginBottom: '8px', 
                                  fontWeight: 'bold'
                                }}
                              >
                                Menu Type
                              </span>
                              <span>{row.menuType || 'N/A'}</span>
                            </div>
                          </td>
                          <td style={{
                            ...tableCellStyle, 
                            width: '10vw', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <div style={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center', 
                              marginBottom: row.menuType === 'Category' ? '10px' : '0' 
                            }}>
                               <Tooltip title="EDIT" placement="top" arrow>
                              <img
                                style={{
                                  ...actionButtonStyle,
                                  marginLeft: row.menuType === 'Category' ? '65px' : '0'
                                }}
                                src={_ButtonEdit_}
                                alt="Edit Button"
                                onClick={() => handleEditClick(row)}
                              />
                              </Tooltip>
                               <Tooltip title="DELETE" placement="top" arrow>
                              <img
                                style={actionButtonStyle}
                                src={_ButtonDelete_}
                                alt="Delete"
                                onClick={() => handleDeleteClick(row.menuId)}
                              />
                              </Tooltip>
                            </div>
                            {/* Conditionally render Add Submenu button for Category menu type */}
                            {row.menuType === 'Category' && (
                              <Button
                                sx={{
                                  borderRadius: 1,
                                  width: '169px',
                                  height: '42px',
                                  minWidth: 'unset',
                                  background: "#004F9F",
                                  color: "white",
                                  fontSize: '0.75rem',
                                  "&:hover": { background: "#003A75" }
                                }}
                                variant="contained"
                                startIcon={<AddCircleIcon fontSize="small" />}
                                onClick={() => handleOpenChildSubmenuModal(row)}
                              >
                                ADD SUBMENU
                              </Button>
                            )} 
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>

        <Modal open={open} onClose={() => setOpen(false)}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "520px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                {t("deleteModal.heading")}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ mt: 2, fontWeight: 400 }}>
                Are you sure you want to delete this record? Deleted records
                cannot be recovered.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={() => setOpen(false)}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t('no')}
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t('yes')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <MSCModal 
          open={isAddModalOpen} 
          handleCloseModal={() => {
            setIsAddModalOpen(false);
            setEditingRow(null);
            setParentMenuForChildSubmenu(null);
          }}
          onConfirm={(submenuData) => {
            // Determine if it's a child submenu by checking the depth of the editing row
            const flattenedRows = renderRows(data);
            const editingRowInFlattened = flattenedRows.find(row => row.menuId === editingRow?.menuId);
            
            if (editingRow) {
              // If editing an existing row
              return editingRowInFlattened && editingRowInFlattened.depth > 0
                ? handleUpdateChildSubmenu(submenuData)  // Child submenu
                : handleUpdateSubmenu(submenuData);      // Top-level submenu
            } else {
              // If adding a new row
              return parentMenuForChildSubmenu
                ? handleAddChildSubmenu(submenuData)
                : handleAddSubmenu(submenuData);
            }
          }}
          title={editingRow 
                 ? (renderRows(data).find(row => row.menuId === editingRow.menuId)?.depth > 0
                     ? "Menu Item" 
                     : "Menu Item")
                 : parentMenuForChildSubmenu 
                 ? "Menu Item" 
                   : "Menu Item"}
          // {
          //   editingRow 
          //     ? (renderRows(data).find(row => row.menuId === editingRow.menuId)?.depth > 0
          //         ? "Edit Child Submenu" 
          //         : "Edit Submenu")
          //     : parentMenuForChildSubmenu 
          //       ? "Add Child Submenu" 
          //       : "Add New Submenu"
          // }
          confirmText={
            editingRow 
              ? "UPDATE" 
              : "SAVE"
          }
          initialData={editingRow ? {
            menuOrder: editingRow.menuOrder,
            description: editingRow.menuName,
            menuType: editingRow.menuType,
            url: editingRow.link,
            menuIcon: editingRow.menuIcon
          } : null}
          selectedCountryId={selectedCountryId}
        />


      </div>
    </div>
  );
};

export default SubMenuTable;
