import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Toolbar,
  Button,
  Typography,
  stepClasses,
} from "@mui/material";
import OptionsIcon from "../../assets/optionsIcon.svg";
import BellIcon from "../../assets/bellIcon.svg";
import LogoImg from "../../assets/bat.svg";
import MenuIcon from "../../assets/menuIcon.svg";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import BottomMenu from "./BottomMenu";
import "./Navbar.css";
import { UserContext } from "App";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from "react-i18next";
import mailunread from "assets/mailunread.svg";
import AlertsService from "services/AlertsService";
import infooutline from "assets/infooutline.svg";
import Sidebar from './Sidebar';  // Import the new Sidebar component

const Navbar = ({
  title,
  showExitWarningOverlayFeed,
  setShowExitWarningOverlayFeed,
  setPendingNavigation,
}) => {
  const [state, setState] = useState({
    left: false,
  });
  const [browserLanguage, setBrowserLanguage] = useState("");
  console.log("🚀 ~ Navbar ~ browserLanguage:", browserLanguage);
  const { user, logout } = AuthData(); // Access login function from AuthData context
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(browserLanguage || "en");
  console.log("🚀 ~ Navbar ~ language:", language);
  console.log("ii9", i18n);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const [unreadAlertData, setUnreadAlertData] = useState([]);
  const [alertAllData, setAlertAllData] = useState([]);
  const [optionsSidebarOpen, setOptionsSidebarOpen] = useState(false);

  useEffect(() => {
    const storedLanguage =
      localStorage.getItem("language") || navigator.language;
    setBrowserLanguage(storedLanguage);
    setLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  // useEffect(() => {
  //   setTimeout(()=>{
  //     fetchAllAlertData();
  //   })
  // }, [unreadAlertData.length]);

  // const fetchAllAlertData = async () => {
  //   try {
  //     let response = await AlertsService.getAllAlertData();
  //     console.log("allalert", response);
  //     setAlertAllData(response.data.response.alertsAndNotifications);
  //     console.log("alertAllDataInsideNavbar", alertAllData);
  //     const unreadAlerts = alertAllData.filter(
  //       (alert) => alert.status === "unread"
  //     );
  //     setUnreadAlertData(unreadAlerts); // Store unread alerts in state
  //     console.log("unreadAlertData", unreadAlertData);
  //   } catch (error) {
  //     console.error("Error fetching alert data:", error);
  //   }
  // };

  useEffect(() => {
    // Function to fetch alert data
    const fetchAllAlertData = async () => {
      try {
        let response = await AlertsService.getAllAlertData();
        console.log("allalert", response);
        setAlertAllData(response.data.response.alertsAndNotifications);

        // Filter unread alerts
        const unreadAlerts =
          response.data.response.alertsAndNotifications.filter(
            (alert) => alert.status === "unread"
          );
        setUnreadAlertData(unreadAlerts);
        console.log("unreadAlertData", unreadAlerts);
      } catch (error) {
        console.error("Error fetching alert data:", error);
      }
    };

    // Set interval to call fetchAllAlertData every 1 minute
    const intervalId = setInterval(() => {
      fetchAllAlertData();
    }, 60000); // 1 minute = 60000 milliseconds

    // Initial fetch on mount
    fetchAllAlertData();

    // Cleanup function to clear interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const LoggedUser = useContext(UserContext);
  const menuItems = [
    { id: 1, name: "Crop Year Registration", link: "/cropYearRegistration" },
    { id: 2, name: "Farmer Registration", link: "/link2" },
    { id: 3, name: "Manage Profiles", link: "/manageProfiles" },
    { id: 4, name: "Season Registration", link: "/seasonRegistration" },
    { id: 5, name: "Crop Stage Registration", link: "/cropStage" },
    { id: 6, name: "Modules Registration", link: "/getmoduleregistration" },
    { id: 7, name: "Crop Stage Calander", link: "/getCropStageCalander" },
  ];

  console.log("user nav", user);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const navigate = useNavigate();
  function handleLogout() {
    localStorage.setItem("isLoggedIn", false);
    navigate("/");
  }

  // Function to toggle options sidebar
  const toggleOptionsSidebar = () => {
    setOptionsSidebarOpen(!optionsSidebarOpen);
  };


  return (
    <>
      <AppBar elevation={0} sx={{ background: "#102A63"}}>
        <Toolbar className="toolBar">
          <NavbarContainer className="navBarContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <img 
                src={OptionsIcon} 
                alt="Options Icon" 
                onClick={toggleOptionsSidebar} 
                style={{ cursor: 'pointer' }} 
              />
              <Border />
              <Logo
                src={LogoImg}
                alt="Bat Logo"
                onClick={() => navigate("/")}
              />
            </div>

            {/* <BorderLeft />
            <BorderRight /> */}
            <MenuTitle className="menuTitle">{title}</MenuTitle>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Border />
              <Select
                value={language}
                onChange={handleLanguageChange}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  height: "2rem",
                  minWidth: "8rem",
                  fontSize: "0.9rem",
                }}
              >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="pt-BR">Portuguese</MenuItem>
              </Select>
              {/* <img src={BellIcon} alt="Bell Icon" onClick={() => {}} /> */}

              <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* Icon */}
                <img
                  src={BellIcon}
                  alt="Bell Icon"
                  onClick={() => navigate("/alerts")}
                />

                {/* Badge */}
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    // display: "flex",
                    display: unreadAlertData.length > 0 ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {unreadAlertData.length}
                </div>

                {isHovered && (
                  <Box
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      zIndex: 1300,
                      minWidth: "300px",
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        width: "100%",
                        marginTop: 0,
                        paddingTop: 0,
                        textAlign: "center",
                        backgroundColor: "#102A62",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        color: "white",
                        height: "30px",
                        fontSize: "16px", // Set font size to 12px
                        fontWeight: 500,
                      }}
                    >
                      Alerts
                    </Typography>

                    <Box sx={{ width: "97%", m: 1 }}>
                      {unreadAlertData.slice(0, 3).map((step) => (
                        <Box
                          key={step.altId}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            mb: 2,
                            borderColor: "divider",
                            border: 1,
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#0A2165",
                              p: 2,
                              minWidth: "45px",
                              height: "48px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "33px", height: "31px" }}
                              src={infooutline}
                            />
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                flexWrap: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                gap: 1,
                                mt: 1,
                                ml: 1,
                              }}
                            >
                              <Typography>
                                {formatDate(step.createdDateTimeUTC)}
                              </Typography>
                              <Typography>|</Typography>
                              <Typography sx={{ textAlign: "center" }}>
                                {step.moduleName}
                              </Typography>
                              <Typography>|</Typography>
                              <Typography>{step.header}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ mt: 1, ml: 1, color: "text.primary" }}
                              >
                                {step.seasonName}
                              </Typography>
                              <Box
                                sx={{
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{ width: "24px", height: "24px" }}
                                  src={mailunread}
                                  alt="mail status"
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Button
                      onClick={() => navigate("/alerts")}
                      style={{
                        maxHeight: 30,
                        width: "98%",
                        margin: "5px",
                        backgroundColor: "#102A62",
                        color: "white",
                        border: "none",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    >
                      VIEW ALL ALERTS
                    </Button>
                  </Box>
                )}
              </div>

              {user && (
                <div style={{ fontSize: "15px" }}>
                  <div> Hello, {user.userName}</div>
                  {/* <div>{user.profileName}</div> */}
                  <div> BAT {user.countryName}</div>
                </div>
              )}
            </div>
          </NavbarContainer>

          {/* <div style={{fontSize:"10px" ,width:"115px"}}>
            Hello, {LoggedUser.profileName} <br/>
            BAT {LoggedUser.countryName} {LoggedUser.countryCode} 
          </div> */}
          {/* {user && <LogoutButton onClick={logout}>Logout</LogoutButton>} */}
          {/* { <LogoutButton onClick={logout}>Logout</LogoutButton>} */}
        </Toolbar>
      </AppBar>

      {/* Sidebar positioned after AppBar and before BottomMenu */}
      <Sidebar 
        open={optionsSidebarOpen} 
        onClose={toggleOptionsSidebar} 
        sx={{
          position: 'fixed',
          top: '64px', // AppBar height
          left: 0,
          zIndex: 1100,
          height: 'calc(100vh - 64px - 20px)', // Add 1px to close potential gap
          overflowY: 'auto',
        }}
      />
      <BottomMenu
        showExitWarningOverlayFeed={showExitWarningOverlayFeed}
        setShowExitWarningOverlayFeed={setShowExitWarningOverlayFeed}
        setPendingNavigation={setPendingNavigation}
      />
    </>
  );
};
const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 1.5rem;
  // position: relative;
`;
const BorderLeft = styled.div`
  position: absolute;
  top: -0.92rem;
  left: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const BorderRight = styled.div`
  position: absolute;
  top: -0.92rem;
  right: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const Border = styled.div`
  // position: absolute;
  // top: -0.92rem;
  // right: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const DrawerContainer = styled.div`
  background: white;
  width: 14rem;
  color: black;
  padding: 6rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MenuLinks = styled.a`
  text-decoration: none;
  text-align: left;
  border-bottom: 1px solid grey;
  width: 100%;
  padding: 0.5rem;
`;
const Logo = styled.img`
  width: 6rem;
  height: auto;
`;
const MenuTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
`;
const LogoutButton = styled.button`
  width: 5rem;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;
const MenuBottom = styled.div`
  position: sticky;
  top: 64px;
  z-index: 1000;
  background-color: whitesmoke;
`;

export default Navbar;
