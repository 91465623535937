import { httpGrow } from "../http-common";

const getGeneticType = () => {
  return httpGrow.get(`/geneticType`);
};

const getSupplyType = () => {
  return httpGrow.get(`/supply-type-drop-down`);
};

const getUsageType = () => {
  return httpGrow.get(`/usage-type-drop-down`);
};

const getCropSeason = () => {
  return httpGrow.get(`/grCropSeason/crop-season-drop-down`, {
    params: {
      cultureName: "Tobacco",
    },
  });
};

const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get("/importHierarchyVersion/tree");
  return response;
};

const picklistAPI = (data) => {
  return httpGrow.post(`/picklist`, data);
};

const cloneData = (data) => {
  return httpGrow.post(`/central-agricultural-supply/clone-data`, data);
};

const lockUnlockData = (data) => {
  return httpGrow.post(`/central-agricultural-supply/bulk-lock-unlock`, data);
};

const getAgriculturalSupply = async (query) => {
  const response = await httpGrow.get(
    `/agricultural-supplies-with-usage-info?cropSeasonId=${query?.cropSeasonId}&isThirdPartySupply=${query?.isThirdPartySupply}`
  );
  return response;
};

const checkdata = async (query) => {
  const response = await httpGrow.get(
    `/central-agricultural-supply/data-exist?toCropSeasonId=${query?.cropSeasonId}&copyUsageInfo=${query?.copyUsageInfo}&isThirdPartSupply=${query?.isThirdPartySupply}`
  );
  return response;
};

const searchAgriculturalSupply = async (query) => {
  const {
    cropSeasonId,
    isThirdPartySupply,
    searchKeyword,
    supplyTypeId,
    geneticTypeIds = [],
    usageTypes = [],
  } = query;

  const response = await httpGrow.get(
    `/agricultural-supplies-with-usage-info`,
    {
      params: {
        cropSeasonId,
        isThirdPartySupply,
        searchKeyword: searchKeyword || undefined,
        supplyTypeId: supplyTypeId || undefined,
        geneticTypeIds: geneticTypeIds.length > 0 ? geneticTypeIds : undefined,
        usageTypes: usageTypes.length > 0 ? usageTypes : undefined,
      },
      paramsSerializer: {
        indexes: null,
      },
    }
  );
  return response;
};

const CentralAgriculturalSupplyManagementService = {
  getGeneticType,
  getHierarchyNodeAPI,
  getCropSeason,
  getUsageType,
  picklistAPI,
  getAgriculturalSupply,
  cloneData,
  searchAgriculturalSupply,
  getSupplyType,
  lockUnlockData,
  checkdata
};
export default CentralAgriculturalSupplyManagementService;
