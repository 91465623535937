// SearchTextInput.jsx
import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '../../assets/SearchIcon.svg';

export default function SearchTextInput({
  label = "Input",
  value,
  onChange,
  size = "medium",
  fullWidth = true,
  variant = "outlined",
  placeholder = "Enter text",
  type = "text", // Allow different input types
  ...otherProps // Spread any other TextField props
}) {
  const handleClearInput = () => {
    onChange({ target: { value: "" } });
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      size={size}
      fullWidth={fullWidth}
      variant={variant}
      placeholder={placeholder}
      type={type}
      sx={{
        '& .MuiInputLabel-root': {
          color: '#3B454F',
          fontFamily: 'Montserrat',
          // fontSize: '18px',
          fontWeight: 500,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#3B454F',
        },
        '& .MuiInputBase-root': {
          height: '46px',
          width: '100%'
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px' }} />
          </InputAdornment>
        ),
        endAdornment: (
          value && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={handleClearInput}
                edge="end"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        ),
        ...otherProps.InputProps,
      }}
      {...otherProps}
    />
  );
}