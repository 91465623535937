import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState, useRef, useEffect, useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import FarmerSegmentationService from "services/FarmerSegmentationService";
import FarmerContractTable from "./FarmerContractTable";

const FarmerContract = () => {
    const [groupName, setGroupName] = useState("");

    const [cropSeason, setCropSeason] = useState("");
    const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
    const [cropSeasonId, setCropSeasonId] = useState("");
    const [cultureType, setCultureType] = useState("");
    const [cultureTypeOptions, setCultureTypeOptions] = useState([]);
    const [cultureTypeId, setCultureTypeId] = useState(""); 
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const debounceTimeout = useRef(null); 

    const data=[{
      code:12345,
      cluster:1,
      name:'cluster 1',
      segmentation:'s1',
      variety:'CSC1',
      visits:1,
      plants:'15 k plants',
      ha:'0,6 ha',
      weight:'1500 kg',
      locality:'Morro Baixo',
    },
    {
      code:67890,
      cluster:0,
      name:'farmer 3',
      segmentation:'s2',
      variety:'CSC2',
      visits:2,
      plants:'5 k plants',
      ha:'1,6 ha',
      weight:'100 kg',
      locality:'Morro Alto',
    }]
     
   
    const handleSearchKeywordChange = (event) => {
        setGroupName(event.target.value);
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
         // fetchData({ groupName: event.target.value });
        }, 500); // Debounce to prevent too many API calls
      };

      useEffect(()=>{
        fetchCultureType();
        fetchCropSeason();
      },[])

      const fetchCultureType = async () => {
        try {
          let response = await FarmerSegmentationService.getCultureType();
    
          setCultureTypeOptions(
            response.data.response.map((item) => ({
              label: item.ctDescription,
              value: item.ctDescription,
              cultureTypeId: item.ctCultureTypeId, // Assuming there's an id field
            }))
          );
          console.log("setCultureTypeOptions", setCultureTypeOptions);
        } catch (error) {
          console.error("Error fetching cultureTypeOptions data:", error);
        }
      };
      const fetchCropSeason = async () => {
        try {
          let response = await FarmerSegmentationService.getCropSeason();
    
          setCropSeasonOptions(
            response.data.response.map((item) => ({
              label: item.cropYear,
              value: item.cropYear,
              cropSeasonId: item.cropSeasonId, // Assuming there's an id field
            }))
          );
          console.log("setCropSeasonOptions", setCropSeasonOptions);
        } catch (error) {
          console.error("Error fetching cropSeasonOptions data:", error);
        }
      };
      const paginate = (pageNumber, itemsPerPage) => {
        setCurrentPage(pageNumber - 1);
      
          //fetchData(pageNumber - 1, itemsPerPage);
       
      };

  return (
    <>
      <Layout
        title={t("Contracts")}
        background="#ffffff"
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        paginate={paginate}
        currentPage={currentPage}
        useNewPagination={true}
      >
        <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", p: 2 }}>
          <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography variant="h8" display="flex">
              <ArrowDropDownIcon />
              {t("Filters")}
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth size="medium">
                <TextField
                  label={t("Group Name")}
                  value={groupName}
                  onChange={handleSearchKeywordChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cultureTypeOptions.find(
                      (option) => option.value === cultureType
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCultureType(newValue ? newValue.value : "");
                  //  fetchData({ cultureType: newValue ? newValue.value : "" });
                  }}
                  options={cultureTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Culture Type")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "end"}}
            >
              <Button
                sx={{
                  borderRadius: 1,
                  minWidth: 95,
                  height: 50,
                  background: "#004F9F",
                  "&:hover": {
                    backgroundColor: "#102A62",
                  },
                }}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => navigate("/newcontract")}
              >
                {t("addNew")}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt:1}}>
          <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <FarmerContractTable data={data}/>
      </Layout>
    </>
  );
};
export default FarmerContract;
