import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Modal,
  InputAdornment,
  Autocomplete,
  FormControl,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FarmerSampleEstimateTable from "./FarmerSampleEstimateTable";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg";
import DefineFarmerSampleEstimateService from "services/DefineFarmerSampleEstimateService";
import uploadIcon from "../../../../../src/assets/ImportIcon.svg";

const FarmerSampleEstimate = () => {
  const [filEstimateData, setFilEstimateData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleted, setIsDeleted] = useState(0);
  const [open, setOpen] = useState(false);
  const [cropSeason, setCropSeason] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [geneticTypes, setGeneticTypes] = useState([]);
  const [farmerQuantity, setFarmerQuantity] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    cropSeasonId &&
      getAllFarmerSampleEstimateApi(cropSeasonId, hierarchicalNodeId);
  }, [cropSeasonId, hierarchicalNodeId, isDeleted]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
        DefineFarmerSampleEstimateService.getCropSeasonAPI(),
        DefineFarmerSampleEstimateService.getHierarchyNodeAPI(),
      ]);

      setCropSeasonOptions(
        cropSeasonResponse.data.response.cropSeason.map((item) => ({
          label: `${item.cropYear}`, // Display the cropYear as the label
          value: `${item.cropYear}-${item.cropSeasonId}`, // Use a combination of cropYear and cropSeasonId for uniqueness
          cropYear: item.cropYear,
          cropSeasonId: item.cropSeasonId,
        }))
      );

      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({ label: node.locationName, value: node.id, level });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const getAllFarmerSampleEstimateApi = (cropSeasonId, hierarchicalNodeId) => {
    DefineFarmerSampleEstimateService.getAllFarmerSampleEstimate({
      cropSeasonId,
      hierarchicalNodeId,
    })
      .then((res) => {
        const summaryResults =
          res?.data?.response?.summaryGeneticResponse || {};
        setSummaryData(summaryResults);
        const farmerQuantities = Array.from(
          new Set(Object.values(summaryResults).flatMap(Object.keys))
        ).sort();
        setFarmerQuantity(farmerQuantities);
        setGeneticTypes(Object.keys(summaryResults));
      })
      .catch((error) => {
        setFarmerQuantity([]);
        setGeneticTypes([]);
      });
  };

  const CallFarmerSampleEstimateApi = (
    pageNumber,
    size,
    cropSeasonId,
    hierarchicalNodeId,
    uploadingFile
  ) => {
    DefineFarmerSampleEstimateService.getFarmerSampleEstimate({
      pageNumber,
      size,
      cropSeasonId,
      hierarchicalNodeId,
    })
      .then((res) => {
        const estimateResults =
          res?.data?.response?.mapResponse?.defineFarmerResponse || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFilEstimateData(estimateResults);
        setTotalItems(res?.data?.response?.mapResponse?.totalItems || 0);
        if (!uploadingFile && estimateResults.length === 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        if (!uploadingFile) {
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
        }
        setFilEstimateData([]);
        setTotalItems(0);
      });
  };

  const downloadTemplate = () => {
    DefineFarmerSampleEstimateService.downloadTemplate()
      .then((response) => {
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: "text/csv",
        });

        // Create a link element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "farmer_sample_estimate_template.csv");
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setMessage(
          error?.response?.data?.message || "Error downloading template"
        );
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleCloseModal = () => {
    setUploadedFiles([]);
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      for (const file of files) {
        if (file.type === "text/csv") {
          await handleFileUpload(file);
        } else {
          setMessage("Please upload CSV files only");
          setOpenAlert(true);
          setSeverity("error");
        }
      }
    }
    // Clear the input value to allow selecting the same file again
    event.target.value = "";
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    try {
      await DefineFarmerSampleEstimateService.importFarmerSample(
        file,
        "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E", // countryId
        "47C8BD0D-AC5F-4786-A639-9C6FAD73AB1B", // languageId
        "Harsha", // createdByUser
        "Harsha" // modifiedByUser
      );
      setUploadedFiles((oldfiles) => [...oldfiles, file.name]);
      setMessage("Import successful. Farmer data has been saved.");
      setOpenAlert(true);
      setSeverity("success");
      if (cropSeasonId) {
        CallFarmerSampleEstimateApi(
          currentPage,
          itemsPerPage,
          cropSeasonId,
          hierarchicalNodeId,
          true
        );
        getAllFarmerSampleEstimateApi(cropSeasonId, hierarchicalNodeId);
      }
    } catch (error) {
      setMessage(
        error?.response?.data?.message ||
          "Invalid file format. Please use the correct CSV template."
      );
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  useEffect(() => {
    if (cropSeasonId) {
      CallFarmerSampleEstimateApi(
        currentPage,
        itemsPerPage,
        cropSeasonId,
        hierarchicalNodeId,
        false
      );
    }
  }, [currentPage, itemsPerPage, isDeleted, cropSeasonId, hierarchicalNodeId]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (cropSeasonId) {
      CallFarmerSampleEstimateApi(
        pageNumber - 1,
        itemsPerPage,
        cropSeasonId,
        hierarchicalNodeId,
        false
      );
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = Array.from(event.dataTransfer.files);
    for (const file of files) {
      if (file.type === "text/csv") {
        await handleFileUpload(file);
      } else {
        setMessage("Please upload CSV files only");
        setOpenAlert(true);
        setSeverity("error");
      }
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("defineFarmerSampleEstimatePositionPlant.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          padding: "1rem",
          paddingTop: "0.1rem",
          paddingBottom: "0.6rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 0.2, marginBottom: 1.1 }} />
          <Typography sx={{ fontWeight: "600", color: "#666666" }}>
            {t("filtersLable")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={3} style={{ paddingLeft: "20px" }}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeason && cropSeasonId
                    ? cropSeasonOptions.find(
                        (option) =>
                          option.value === `${cropSeason}-${cropSeasonId}`
                      ) || null
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.cropYear); // Update cropSeason with the selected cropYear
                    setCropSeasonId(newValue.cropSeasonId); // Update cropSeasonId with the selected cropSeasonId
                    setCurrentPage(0);
                  } else {
                    setCropSeason(""); // Clear cropSeason
                    setCropSeasonId(""); // Clear cropSeasonId
                    setTotalItems(0);
                    setCurrentPage(0);
                  }
                }}
                options={cropSeasonOptions}
                getOptionLabel={(option) => option.label} // Display cropYear in dropdown
                isClearable // Allows clearing the selection
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="number"
                    label={t(
                      "defineFarmerSampleEstimatePositionPlant.cropYear"
                    )}
                    placeholder="Select an option"
                    InputProps={{
                      ...params.InputProps,
                      style: { color: "black" },
                      startAdornment: <></>,
                    }}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "black",
                        opacity: 1,
                      },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "-" ||
                        e.key === "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  hierarchyOptions.find(
                    (option) => option.value === hierarchicalNode
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setHierarchicalNode(newValue.value);
                    setHierarchicalNodeId(newValue.value);
                    setCurrentPage(0);
                  } else {
                    setHierarchicalNode("");
                    setHierarchicalNodeId("");
                    setCurrentPage(0);
                  }
                }}
                options={hierarchyOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "defineFarmerSampleEstimatePositionPlant.hierarchicalNode"
                    )}
                    placeholder="Select an option"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={iconSearch}
                            alt="search"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "black",
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {cropSeasonId && (
            <Grid item xs={12} sm={6} md={5} textAlign={"right"}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: 1,
                    minWidth: 120,
                    minHeight: 44,
                    background: "#004F9F",
                    marginRight: "10px",
                    padding: "0px 95px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative", // For hover effects
                    "&:hover": {
                      background: "#002A5C", // Dark blue on hover
                    },
                    "&:hover .hover-image": {
                      opacity: 0, // Hide the image on hover
                    },
                    "&:hover .hover-icon": {
                      opacity: 1, // Show the icon on hover
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  {/* AddCircleIcon (hidden initially) */}
                  <AddCircleIcon
                    className="hover-icon"
                    sx={{
                      marginRight: "-20px",
                      opacity: 0, // Hidden initially
                      transition: "opacity 0.3s",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  />

                  {/* Image (visible initially) */}
                  <img
                    src={uploadIcon}
                    alt=""
                    className="hover-image"
                    style={{
                      marginRight: "10px",
                      transition: "opacity 0.3s",
                    }}
                  />

                  {/* Button Label */}
                  {t("defineFarmerSampleEstimatePositionPlant.import")}
                </Button>

                <Modal
                  open={open}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "7px",
                      width: "408px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      minHeight: "420px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#102A62",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "20px",
                          fontWeight: 500,
                        }}
                      >
                        {t(
                          "defineFarmerSampleEstimatePositionPlant.modalImport"
                        )}
                      </Typography>
                    </Box>
                    {uploadedFiles.length > 0 && (
                      <Box
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "10px !important",
                            fontWeight: 700,
                          }}
                        >
                          FILE LIST:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 1,
                            marginBottom: 1,
                          }}
                        >
                          {uploadedFiles.map((file) => (
                            <Box
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DescriptionOutlinedIcon
                                sx={{ fontSize: 20, color: "grey" }}
                              />
                              <Typography
                                sx={{
                                  textAlign: "left",
                                  fontSize: "13px !important",
                                  textUnderlineOffset: "3px",
                                  textDecoration: "underline",
                                }}
                              >
                                {file.toUpperCase()}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}

                    {/* Centering the Icon, Typography, and Button */}
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1, // Space between elements
                      }}
                    >
                      {/* Wrap the icon and text in a Box with a border */}
                      <Box
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        sx={{
                          border: "4px solid #ACB4BA",
                          borderRadius: "5px",
                          p: 2,
                          paddingLeft: 4,
                          paddingRight: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <DescriptionOutlinedIcon
                          sx={{ fontSize: 60, color: "grey" }}
                        />

                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 1, fontWeight: 400, textAlign: "center" }}
                        >
                          {t(
                            "defineFarmerSampleEstimatePositionPlant.dragDrop"
                          )}
                        </Typography>

                        {/* Add light gray background for "OR" and "BROWSE FILE" */}
                        <Box
                          sx={{
                            background: "#E9EBEE",
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p: 1.5,
                            mt: 1, // Margin top for spacing
                          }}
                        >
                          <Typography
                            id="modal-modal-description"
                            sx={{ fontWeight: 400, textAlign: "center" }}
                          >
                            {t("defineFarmerSampleEstimatePositionPlant.or")}
                          </Typography>

                          <Button
                            onClick={handleBrowseClick}
                            sx={{
                              background: "#0E2B63",
                              width: "150px",
                              color: "white",
                              mt: 1,
                              "&:hover": { background: "#0E2B63" },
                            }}
                          >
                            {t(
                              "defineFarmerSampleEstimatePositionPlant.browseFile"
                            )}
                          </Button>
                          <input
                            type="file"
                            hidden
                            ref={fileInputRef}
                            onChange={handleFileSelect}
                            accept=".csv"
                            multiple
                          />
                        </Box>
                      </Box>

                      <Button
                        onClick={downloadTemplate}
                        sx={{
                          background: "#004F9F",
                          width: "181px",
                          color: "#ffffff",
                          fontSize: "12px",
                          mt: 1,
                          "&:hover": { background: "#004F9F" },
                        }}
                      >
                        {t(
                          "defineFarmerSampleEstimatePositionPlant.downloadTemplate"
                        )}
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "30px",
                        mb: 1,
                      }}
                    >
                      <Button
                        onClick={handleCloseModal}
                        sx={{
                          background: "#737D86",
                          width: "100px",
                          color: "#ffffff",
                          "&:hover": { background: "#737D86" },
                        }}
                      >
                        {t("close")}
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {cropSeasonId && filEstimateData.length > 0 ? (
        <>
          <Box
            sx={{
              background: "#EFEFEF",
              borderRadius: "10px",
              padding: "1rem",
              paddingTop: "0.1rem",
              paddingBottom: "1rem",
              marginTop: "15px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 0.2, marginBottom: 1.1 }} />
              <Typography sx={{ fontWeight: "600", color: "#666666" }}>
                {t("defineFarmerSampleEstimatePositionPlant.summary")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gap: "6px",
                columnGap: "18px",
                gridTemplateRows: `repeat(${geneticTypes.length + 1}, 30px)`, // One row for headers and one for each genetic type
                gridTemplateColumns: `300px repeat(${
                  farmerQuantity.length + 1
                }, 140px)`, // First column + farmerQuantity columns + totals
                overflowX: "auto",
              }}
            >
              {/* Row 1: One large cell spanning all columns */}
              <Box
                sx={{
                  gridColumn: "span 1",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gridColumn: `span ${farmerQuantity.length + 1}`, // Spanning farmerQuantity columns + 1 for totals
                  background: "#acb4ba",
                  borderRadius: "5px",
                  width: `${
                    140 * (farmerQuantity.length + 1) +
                    18 * farmerQuantity.length
                  }px`,
                  color: "#666666",
                  fontWeight: "bold",
                }}
              >
                Farmer Quantity
              </Box>

              {Array.from({ length: geneticTypes.length + 1 }).map(
                (_, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    {Array.from({ length: farmerQuantity.length + 2 }).map(
                      (_, colIndex) => (
                        <Box
                          key={colIndex}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            background: rowIndex === 0 ? "#acb4ba" : "white",
                            fontWeight: rowIndex === 0 && "bold",
                            color: "#666666",
                            height: "30px",
                          }}
                        >
                          {colIndex === 0 && rowIndex === 0
                            ? "Genetic Types"
                            : colIndex === 0 && rowIndex !== 0
                            ? geneticTypes[rowIndex - 1] // Render geneticTypes values in the first column
                            : colIndex === 1 && rowIndex === 0
                            ? "Totals"
                            : colIndex >= 2 && rowIndex === 0
                            ? farmerQuantity[colIndex - 2] // Render farmerQuantity as column headers
                            : colIndex === 1 && rowIndex !== 0
                            ? Object.values(
                                summaryData[geneticTypes[rowIndex - 1]]
                              ).reduce((acc, curr) => acc + curr, 0) // Render totals for each genetic type
                            : colIndex >= 2 &&
                              rowIndex !== 0 &&
                              summaryData[geneticTypes[rowIndex - 1]][
                                farmerQuantity[colIndex - 2]
                              ]
                            ? summaryData[geneticTypes[rowIndex - 1]][
                                farmerQuantity[colIndex - 2]
                              ] // Render the specific S1 or S2 value
                            : 0}
                          {/* Default to 0 if no value exists */}
                        </Box>
                      )
                    )}
                  </React.Fragment>
                )
              )}
            </Box>
          </Box>

          <FarmerSampleEstimateTable
            estimateData={filEstimateData}
            setEstimateData={setFilEstimateData}
            setIsDeleted={setIsDeleted}
          />
        </>
      ) : (
        cropSeasonId && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontSize: "23px !important",
                fontWeight: "500",
                marginTop: "20px",
                color: "#666666",
              }}
            >
              No Data Available
            </Typography>
          </Box>
        )
      )}
    </Layout>
  );
};

export default FarmerSampleEstimate;
