import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import ErrorBoundary from "ErrorBoundary";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
const theme = createTheme({
  typography: {
    headerName: {
      fontSize: "1.5rem",
      color: "#F8F8F8",
    },
  },
});

console.log("Enviornment", process.env.REACT_APP_API_ENDPOINT);

// const msalInstance = new PublicClientApplication(msalConfig);
root.render(
  <ErrorBoundary>
    <BrowserRouter basename="gw_agri360">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {/* <MsalProvider instance={msalInstance}> */}
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          {/* </MsalProvider> */}
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </ErrorBoundary>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
