import React, { useState, useEffect, useContext } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { cultureGroupSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TrayService from "services/TrayService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";

export default function AddTrayRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    ttTrayTypeDescription,
    ttNoOfCells,
    ttLossPercentage,
    isActive,
    countryId,
    languageId,
    isDelete,
    ttTrayTypeId,
    details,
  } = location?.state?.row || {};
  const { edit } = location.state || {};

  console.log("dhoni", ttTrayTypeDescription, ttNoOfCells, ttLossPercentage);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    ttTrayTypeId: ttTrayTypeId,
    ttTrayTypeDescription: ttTrayTypeDescription || "", // Assuming this is the tray type description
    ttNoOfCells: ttNoOfCells, // Default to 0 if not provided
    ttLossPercentage: ttLossPercentage, // Default to 0 if not provided
    isActive: isActive === true ? true : false, // Default to false if not true
    countryId: countryId || "", // Set default empty string or any fallback value
    languageId: languageId || "", // Set default empty string or any fallback value
    isDelete: isDelete === true ? true : false, // Default to false if not true
  });

  console.log("values", values);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/trayTypeRegistration");
  };

  const handleChange = (event) => {
    // console.log("working");
    const { name, value, type, checked } = event.target;
    console.log("clicked", name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};

    // Tray Type Description Validation
    const trimmedDescription = values.ttTrayTypeDescription?.trim();
    const isValidDescription = /^[a-zA-Z0-9\s]+$/.test(trimmedDescription); // Allows alphanumeric and spaces

    // Check if it's empty after trimming
    if (!values.ttTrayTypeDescription) {
      schemaValidationErrors.ttTrayTypeDescription =
        "Tray Type Description is required.";
    } else if (values.ttTrayTypeDescription !== trimmedDescription) {
      schemaValidationErrors.ttTrayTypeDescription =
        "Tray Type Description must not contain leading or trailing spaces";
    } else if (!isValidDescription) {
      schemaValidationErrors.ttTrayTypeDescription =
        "Tray Type Description cannot contain special characters.";
    } else if (trimmedDescription.length > 40) {
      schemaValidationErrors.ttTrayTypeDescription =
        "Tray Type Description cannot exceed 40 characters.";
    }

    if (
      values.ttNoOfCells === undefined ||
      values.ttNoOfCells === null ||
      values.ttNoOfCells === ""
    ) {
      schemaValidationErrors.ttNoOfCells = "Number of cells is required.";
    } else if (!/^\d{1,10}$/.test(values.ttNoOfCells)) {
      schemaValidationErrors.ttNoOfCells =
        "Invalid input. Must be a whole number with at most 10 digits.";
    }
    // if (!values.ttNoOfCells || parseInt(values.ttNoOfCells, 10) < 0) {
    //   schemaValidationErrors.ttNoOfCells =
    //     "Number of Cells is required and cannot be negative.";
    // }

    // ttLossPercentage Validation: Percentage cannot be more than 100
    if (
      values.ttLossPercentage === undefined ||
      values.ttLossPercentage === null ||
      values.ttLossPercentage === ""
    ) {
      schemaValidationErrors.ttLossPercentage = "Loss percentage is required.";
    } else if (isNaN(values.ttLossPercentage)) {
      schemaValidationErrors.ttLossPercentage =
        "Invalid input. Must be a whole number or decimal number.";
    } else if (parseFloat(values.ttLossPercentage) > 100) {
      schemaValidationErrors.ttLossPercentage =
        "Percentage cannot be more than 100.";
    } else if (!/^\d{1,10}(\.\d{1,2})?$/.test(values.ttLossPercentage)) {
      schemaValidationErrors.ttLossPercentage =
        "Invalid format. Up to 10 digits before decimal and up to 2 digits after decimal are allowed.";
    }

    // If validation fails, set errors and return
    if (Object.keys(schemaValidationErrors).length > 0) {
      setValidationErrors(schemaValidationErrors);
      return;
    }

    // Clear errors before proceeding with submission
    setValidationErrors({});

    // Proceed with submission
    const payload = {
      ttTrayTypeId: values?.ttTrayTypeId,
      ttTrayTypeDescription: trimmedDescription, // Use trimmed value
      ttNoOfCells: values?.ttNoOfCells,
      ttLossPercentage: values?.ttLossPercentage,
      isActive: values?.isActive,
      countryId: values?.countryId,
      languageId: values?.languageId,
      isDelete: values?.isDelete ?? false,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string", // Change this to actual user data
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string", // Change this to actual user data
    };

    console.log("payload hai", payload);

    try {
      setLoading(true);
      const response = await TrayService.createTray({ ...payload });
      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        setMessage(
          values.ttTrayTypeId ? "Updated successfully" : "Added successfully"
        );
        setOpenAlert(true);
        navigate("/trayTypeRegistration");
      }
    } catch (error) {
      setLoading(false);

      // Alert message

      if (error.response && error.response.status === 409) {
        setSeverity("error");
        // setMessage(error.response.data.message);

        // Extract the message and remove the word "is"
        const originalMessage = error.response.data.message;
        const modifiedMessage = originalMessage.replace(/\bis\b/g, "").trim();
        setMessage(modifiedMessage);

        setOpenAlert(true);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Layout title={"Tray Type Registration "} background="#ffffff">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              bgcolor: "#EFEFEF",
              // height: "120px",
              padding: "1rem",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>{Lable.fromLable}</Typography>
            </Box>

            {/* Options Below Filters */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                height: "auto",
              }}
            >
              <FormControl>
                <TextField
                  name="ttTrayTypeDescription"
                  label="Tray Type Description"
                  value={values?.ttTrayTypeDescription}
                  onChange={handleChange}
                  disabled={edit == false}
                  fullWidth
                  required
                  error={!!validationErrors.ttTrayTypeDescription} // Show error state
                  helperText={validationErrors.ttTrayTypeDescription || ""} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                      minWidth: "25rem",
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                        "&:hover fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </FormControl>

              <FormControl
                sx={{ ml: "15px" }}
              //error={!!validationErrors.ttNoOfCells}
              >
                <TextField
                  name="ttNoOfCells"
                  label="Number of Cells"
                  type="text"
                  inputProps={{ min: 0 }}
                  value={values?.ttNoOfCells}
                  onChange={handleChange}
                  disabled={edit === false}
                  //required // Mark field as required
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                      width: "12rem",
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                        "&:hover fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    "& .MuiFormHelperText-root": {
                      maxWidth: "10rem",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                  }}
                  required
                  error={!!validationErrors.ttNoOfCells} // Display error state if validation fails
                  helperText={validationErrors.ttNoOfCells} // Show the validation error message
                />
              </FormControl>

              {/* <FormControl sx={{}}>
              <TextField
                name="ttLossPercentage"
                label="% Loss"
                value={values?.ttLossPercentage}
                onChange={handleChange}
                fullWidth
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                    width: "10rem",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                  endAdornment: focused ? (
                    <InputAdornment position="end">
                      <span style={{ color: "#131619" }}>%</span>
                    </InputAdornment>
                  ) : null, // Only show when focused
                }}
              />
            </FormControl> */}
              <FormControl sx={{}}>
                <TextField
                  type="text"
                  name="ttLossPercentage"
                  label="% Loss"
                  value={values?.ttLossPercentage}
                  onChange={(e) => {
                    // const value = e.target.value;
                    // if (/^\d*\.?\d*$/.test(value)) {
                    handleChange(e); // Only allow numeric input with optional decimal
                    // }
                  }}
                  fullWidth
                  disabled={edit == false}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  required
                  error={!!validationErrors.ttLossPercentage} // Show error if the field is invalid
                  helperText={validationErrors.ttLossPercentage} // Show the error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                      width: "12rem",
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                        "&:hover fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    "& .MuiFormHelperText-root": {
                      maxWidth: "10rem",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                    endAdornment: focused ? (
                      <InputAdornment position="end">
                        <span style={{ color: "#131619" }}>%</span>
                      </InputAdornment>
                    ) : null, // Only show when focused
                  }}
                  inputProps={{
                    inputMode: "decimal", // Makes sure the virtual keyboard shows numbers
                    pattern: "[0-9]*", // Restrict to numbers on some browsers
                  }}
                />
              </FormControl>

              {/* Status and Switch Part */}

              <Box
                sx={{
                  ml: 0.5,
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      color: "#5A646E",
                      mb: 1,
                      ml: 2.5,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                      fontWeight: "500",
                    }}
                  >
                    Status
                    <span style={{ color: "red" }}> *</span>
                  </FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 3, ml: 3, fontWeight: "500", color: "#737D86" }}>
                      {values?.isActive === true ? (
                        <span>Active</span>
                      ) : (
                        <span>Inactive</span>
                      )}
                    </Typography>
                    {console.log("status", values.isActive)}
                    <FormControlLabel
                      control={
                        <RedSwitch
                          name="isActive"
                          sx={{ marginRight: "11px" }}
                          checked={values.isActive}
                          onChange={handleChange}
                          color="primary"
                          disabled={details || edit === false}
                        />
                      }
                      // label={<Typography sx={{ ml: 0 }}>Active</Typography>}
                      labelPlacement="end" // Change label placement to "end" to display it after the switch
                    />
                  </Box>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {/* <hr style={{ marginTop: "200px" }} /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {/* {loading && <CircularProgress size={20} />} */}
              Cancel
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    Are you sure you want to cancel this operation? All your
                    data will be lost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "40px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={handleSubmit}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
