import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import './FarmerAdd.css'
import Button from "@mui/material/Button";
import { Icon, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import plusIcon from "assets/iconLeft.svg";
import Layout from "components/structure/Layout";
// import GeneralInformation from './GeneralInformation';
// import TaxInformation from './TaxInformation';
import { useDispatch, useSelector } from "react-redux";
// import { Farmer_Env_Info, Farmer_General_Info, Farmer_General_info, Farmer_Tax_Info, Minors } from 'slices/farmerslice';
// import MinorsComponent from './Minors';
// import Adults from './Adults';
// import EnvironmentalLicense from './EnvironmentalLicense';
// import Properties from './Properties';
import CustomButton from "utils/CustomButton";
// // import { clearGeneralInformation } from 'slices/generalInformation';
// import { clearTaxInformation } from 'slices/taxInformationSlice';
// import { clearAdults } from 'slices/adultsSlice';
// import { clearProperty } from 'slices/propertyAddSlice';
// import { clearDependant } from 'slices/dependantSlice';
// import { clearEnvironment } from 'slices/environmentalSlice';
import arrowRight from "assets/_IconButtonRightArrow_.svg";
import GeneralInformation from "./generalInformation";
import ControlData from "./controlData";
import Vehichles from "./vehicles";
import Areas from "./areas";
import { useTranslation } from 'react-i18next';

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30.5rem",
  bgcolor: "background.paper",
  height: "20.125rem",
  borderRadius: "0.625rem",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EditTransportor() {
  const [value, setValue] = React.useState(0);
  const [adultModalStatus, setAdultModalStatus] = React.useState(false);
  const dispatch = useDispatch();
  // const state = useSelector(state => state);
  const farmers = useSelector((state) => state.farmers);
  const [registerationModalStatus, setRegisterationModalStatus] =
    React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  //   const handleYes = () => {
  //     dispatch(clearGeneralInformation());
  //     dispatch(clearTaxInformation());
  //     dispatch(clearAdults());
  //     dispatch(clearProperty());
  //     dispatch(clearDependant());
  //     dispatch(clearEnvironment());
  //     navigate("/farmerRegistration")
  //   }

  const handleNo = () => setDeleteOpen(false);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    console.log("vaaaqaq", newValue);
    if (newValue >= 0 && newValue <= 3) setValue(newValue);
  };
  const navigate = useNavigate();
  const plusIconComp = (
    <Icon>
      <img
        alt="edit"
        style={{ display: "flex", justifyContent: "center" }}
        width="100%"
        height="100%"
        src={plusIcon}
      />
    </Icon>
  );

  const nextIconComp = (
    <Icon>
      <img
        alt="next"
        style={{ display: "flex", justifyContent: "center" }}
        width="100%"
        height="100%"
        src={arrowRight}
      />
    </Icon>
  );

  // onClick={() => navigate('/farmerRegistration')}

  const location = useLocation();
  const { edit, details } = location.state || {};

  return (
    <Layout background={"white"} title={t('TransportationRegistration.sections.title')}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#EF7D00" },
              "& .Mui-selected": { color: "#EF7D00 !important" },
            }}
          >
            <Tab label={t('TransportationRegistration.sections.generalInformation')} {...a11yProps(0)} />
            <Tab label={t('TransportationRegistration.sections.controlData')} {...a11yProps(1)} />
            <Tab label={t('TransportationRegistration.sections.vehicles')} {...a11yProps(2)} />
            <Tab label={t('TransportationRegistration.sections.areas')} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Button style={{ margin: '30px 0px 30px 10px', color: "#004F9F", borderColor: '#004F9F' }}
              required variant="outlined" startIcon={plusIconComp} onClick={() => setDeleteOpen(true)}> {edit ? "Back" : "Cancel"}</Button> */}
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 1)}
            >
              Next
            </Button> */}
          </Box>
          <GeneralInformation />
          {/* <GeneralInformation handleTabChange={handleTabChange} /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Button style={{ margin: '30px 0px 30px 10px', color: "#004F9F", borderColor: '#004F9F' }}
              required variant="outlined" startIcon={plusIconComp}
              onClick={(event) => handleTabChange(event, 2)}
            > Back</Button> */}
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 4)}
            >
              Next
            </Button> */}
          </Box>
          <ControlData />
          {/* <MinorsComponent registerationModalStatus={registerationModalStatus} setRegisterationModalStatus={(value) => setRegisterationModalStatus(value)} /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Button style={{ margin: '30px 0px 30px 10px', color: "#004F9F", borderColor: '#004F9F' }}
              required variant="outlined" startIcon={plusIconComp}
              onClick={(event) => handleTabChange(event, 1)}
            > Back</Button> */}
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 3)}
            >
              Next
            </Button> */}
          </Box>
          <Vehichles />
          {/* <Properties registerationModalStatus={registerationModalStatus} setRegisterationModalStatus={(value) => setRegisterationModalStatus(value)} /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Button
              style={{ color: "#004F9F", borderColor: '#004F9F' }}
              required
              variant="outlined"
              endIcon={nextIconComp}
              onClick={(event) => handleTabChange(event, 2)}
            >
              Next
            </Button> */}
          </Box>
          <Areas />
          {/* <TaxInformation registerationModalStatus={registerationModalStatus} setRegisterationModalStatus={(value) => setRegisterationModalStatus(value)} /> */}
        </CustomTabPanel>

        <Box
          sx={{
            position: "fixed",
            left: "50%",
            bottom: "1rem",
            transform: "translateX(-50%)",
            display: "flex",
            gap: "1rem",
            marginBottom: "13px",
            background: "white",
            zIndex:1000
          }}
        >
          <Button
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            variant="contained"
            onClick={(event) => handleTabChange(event, value - 1)}
          >
            PREVIOUS
          </Button>
          <Button
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            variant="contained"
            onClick={(event) => handleTabChange(event, value + 1)}
          >
            NEXT
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
