import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import EducationTypeService from "services/EducationTypeService";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

export default function AddEducationType() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    createdByUser,
    createdDateTimeUTC,
    educationTypeDesc,
    educationTypeId,
    isActive,
    isDelete,
    modifiedByUser,
    modifiedDateTimeUTC,
  } = location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [prevValues, setPrevValues] = useState({
    educationTypeDesc: educationTypeDesc,
    isActive: isActive === true ? true : isActive === undefined ? true : false,
  });

  const [values, setValues] = useState({
    createdDateTimeUTC: createdDateTimeUTC,
    educationTypeDesc: educationTypeDesc,
    educationTypeId: educationTypeId,
    isActive: isActive === true ? true : isActive === undefined ? true : false,
    modifiedByUser: modifiedByUser,
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    edit === false ||
    (values.educationTypeDesc === prevValues.educationTypeDesc &&
      values.isActive === prevValues.isActive)
      ? navigate("/educationType")
      : setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModalConfirm = () => {
    setOpen(false);
    setSeverity("error");
    setMessage("Changes discarded.");
    setOpenAlert(true);
    navigate("/educationType");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleValidation = (schemaValidationErrors, educationTypeTrimmed) => {
    if (!educationTypeTrimmed) {
      schemaValidationErrors.educationTypeDesc = t(
        "educationTypeRegistration.educationTypeBlank"
      );
    } else if (educationTypeTrimmed.length > 100) {
      schemaValidationErrors.educationTypeDesc =
        "The Character limit cannot exceed more than 100 in Education Type.";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const educationTypeTrimmed = values?.educationTypeDesc?.trim();

    handleValidation(schemaValidationErrors, educationTypeTrimmed);

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      createdByUser: values?.createdByUser || "UI",
      createdDateTimeUTC: new Date().toISOString(),
      educationTypeDesc: values?.educationTypeDesc,
      modifiedByUser: values?.modifiedByUser,
      isActive: values?.isActive,
      isDelete: false,
      modifiedDateTimeUTC: new Date().toISOString(),
    };

    try {
      const response = await EducationTypeService.createEducationType({
        ...payload,
      });

      if (response.status === 201) {
        setSeverity("success");
        setMessage("Education type saved successfully.");
        setOpenAlert(true);
        navigate("/educationType");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(
          `Education type description must be unique. Please enter a different description`
        );
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("Submission Failed");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const educationTypeTrimmed = values?.educationTypeDesc?.trim();

    handleValidation(schemaValidationErrors, educationTypeTrimmed);

    if (Object.keys(schemaValidationErrors, specialCharPattern).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    try {
      await schemaValidationErrors.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        createdByUser: values?.createdByUser || "UI",
        createdDateTimeUTC: values?.createdDateTimeUTC,
        educationTypeDesc: values?.educationTypeDesc,
        educationTypeId: values?.educationTypeId,
        modifiedByUser: values?.modifiedByUser,
        isActive: values?.isActive,
        isDelete: false,
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      try {
        const response = await EducationTypeService.updateEducationType({
          ...payload,
        });
        if (response.status === 200) {
          setSeverity("success");
          if (prevValues.isActive === true && values.isActive === false) {
            setMessage("Education type deactivated successfully.");
          } else {
            setMessage("Education type saved successfully.");
          }
          setOpenAlert(true);
          navigate("/educationType");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else if (error.response && error.response.status === 500) {
          setSeverity("error");
          setMessage(
            `The ${values.educationTypeDesc} already exists. Please provide a unique Education Type Name`
          );
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout
      title={t("educationTypeRegistration.pageTitle")}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography sx={{color:"#666666", fontWeight:"600"}}>{t("form")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "90px",
              marginTop: "-25px",
              borderRadius: "5px",
              paddingTop: "25px",
            }}
          >
            <TextField
              name="educationTypeDesc"
              label={t("educationTypeRegistration.educationType")}
              value={values?.educationTypeDesc}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              helperText={
                errors.educationTypeDesc ? errors.educationTypeDesc : " "
              }
              sx={{
                width: "90%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                  ...(edit === false && {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #0000003B",
                    },
                  }),
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                  marginLeft: "10px",
                },
              }}
              error={!!errors.educationTypeDesc}
              InputProps={{
                style: { height: "50px", marginLeft: "10px" },
              }}
            />

            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    mt: "-30px",
                    color: "#3B454F !important",
                    mb: 2,
                    ml: 0.5,
                    fontWeight: "600",
                  }}
                >
                  {t("status")}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 6 }}>{t("Inactive")}</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values.isActive}
                        onChange={handleChange}
                        name="isActive"
                        color="primary"
                        disabled={edit === false}
                      />
                    }
                    label={
                      <Typography sx={{ ml: 0, mr: 8 }}>
                        {t("Active")}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("back") : t("cancel")}
            </Button>

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleCloseModalConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
