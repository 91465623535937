import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./GrowingBaseTable.css";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";
import CompetitorConfigurationService from "services/CompetitorConfigurationService";
import { useTranslation } from "react-i18next";

const CompetitorConfigurationTable = ({ TableData: initialData }) => {
  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [confirmSwitchOpen, setConfirmSwitchOpen] = useState(false);
  const [currentSwitchId, setCurrentSwitchId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData((prevData) =>
      [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const handleSwitchChange = async (id, useThisRecord) => {
    if (useThisRecord === true) {
      setCurrentSwitchId(id);
      setConfirmSwitchOpen(true);
      return;
    } else {
      try {
        const itemToUpdate = data.find(
          (item) => item.ccCompetitorConfigId === id
        );

        if (itemToUpdate) {
          const updatedRecord = {
            ...itemToUpdate,
            ccUseThisRecord: !itemToUpdate.useThisRecord,
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: "85201405",
            batOneId: 85189949,
          };
          const updatedData = data.map((item) =>
            item.ccCompetitorConfigId === id ? updatedRecord : item
          );

          setData(updatedData);

          const response = await CompetitorConfigurationService.updateAPI(updatedRecord);
          if(response.status===201){
            setMessage("Record Updated Successfully");
            setSeverity("success");
            setOpenAlert(true);
          }
        }
      } catch (error) {
        console.error("Error updating record:", error);
        setMessage("Error updating record");
        setSeverity("error");
        setOpenAlert(true);
      } finally {
        setConfirmSwitchOpen(false);
        setCurrentSwitchId(null);
      }
    }
  };

  const handleConfirmSwitchChange = async (id) => {
    try {
      const itemToUpdate = data.find(
        (item) => item.ccCompetitorConfigId === id
      );

      if (itemToUpdate) {
        const updatedRecord = {
          ...itemToUpdate,
          ccUseThisRecord: itemToUpdate.useThisRecord ? true : false,
          modifiedDateTimeUTC: new Date().toISOString(),
          modifiedByUser: "85201405",
          batOneId: 85189949,
        };
        const updatedData = data.map((item) =>
          item.ccCompetitorConfigId === id ? updatedRecord : item
        );

        setData(updatedData);

        const response = await CompetitorConfigurationService.updateAPI(updatedRecord);
        if(response.status===201){
          setMessage("Record Updated Successfully");
          setSeverity("success");
          setOpenAlert(true);
        }      }
    } catch (error) {
      console.error("Error updating record:", error);
    } finally {
      setConfirmSwitchOpen(false);
      setCurrentSwitchId(null);
    }
  };

  return (
    <div className="table-container">
      <table className="gw-custom-table">
        <thead>
          <tr>
            <th style={{ maxWidth: "10vw" }}>
              {t("CompetitorConfiguration.useThisRecord")}
            </th>
            <th
              style={{ minWidth: "20vw", cursor: "pointer" }}
              onClick={() => handleSort("tobaccoCompanyName")}
            >
              {t("CompetitorConfiguration.companyName")}{" "}
              {getSortIcon("tobaccoCompanyName")}
            </th>
            <th
              style={{
                display:"flex",
                justifyContent:"space-between",
                minWidth: "15vw",
                cursor: "pointer",
              }}
              onClick={() => handleSort("region")}
            >
              {t("CompetitorConfiguration.region")}
              {getSortIcon("region")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.ccCompetitorConfigId}>
              <td style={{ maxWidth: "10vw" }}>
                <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                <RedSwitch
                  checked={row.ccUseThisRecord}
                  onChange={() =>
                    handleSwitchChange(
                      row.ccCompetitorConfigId,
                      row.ccUseThisRecord
                    )
                  }
                  color="primary"
                />
                <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
              </td>
              <td style={{ minWidth: "30vw" }}>{row.tobaccoCompanyName}</td>
              <td style={{ minWidth: "5vw", justifyContent: "flex-start" }}>
                {row.region}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={confirmSwitchOpen}
        onClose={() => setConfirmSwitchOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("CompetitorConfiguration.alert")}
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("CompetitorConfiguration.alertMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={() => setConfirmSwitchOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={() => handleConfirmSwitchChange(currentSwitchId)}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CompetitorConfigurationTable;
