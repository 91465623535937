import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import "./HierarchyTree.css";
import EditButton from "utils/EditButton";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useHistory, useNavigate } from "react-router-dom";
import moreActions from "assets/moreActions.svg";
import HierarchyService from "services/GrowingHierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import iconEdit from "assets/iconEdit.svg";

const HierarchyTreeTable = (props) => {
  const [hierarchyData, setHierarchyData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);
  console.log("🚀 ~ HierarchyTreeTable ~ hierarchyData:", hierarchyData);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const handleEdit = () => {
    handleClose();
  };

  const handleDelete = () => {
    // Add your delete logic here
    console.log("Delete action");
    handleClose();
  };

  const handleDuplicate = () => {
    // Add your duplicate logic here
    console.log("Duplicate action");
    handleClose();
  };

  useEffect(() => {
    setHierarchyData(props.hierarchyData);
  }, [props.hierarchyData]);

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr className="HierarchyHead">
            <th>Code</th>
            <th>Start Date</th>
            <th>End Date </th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hierarchyData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.id}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.startDate}>{row.startDate}</span>
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.endDate}
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {/* Status Fix */}
                {row.status}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    aria-label="more"
                    aria-controls={
                      menuIndex === index ? "long-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuIndex === index}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate(`/HierarchyTreeConfigurations/${row.ihvId}`);
                        handleClose();
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                      {/* <ListItemText primary="Edit" /> */}
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        try {
                          const response =
                            await HierarchyService.getDuplicateHierarchyList(
                              row?.ihvId
                            );
                          setSeverity("success");
                          setMessage("Duplicate Added successfully");
                          setOpenAlert(true);
                          props.fetchData();
                          handleClose();
                        } catch (error) {
                          console.error("Error calling API:", error);
                        }
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<FileCopyIcon />}
                      >
                        Duplicate
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HierarchyTreeTable;
