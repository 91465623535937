import * as React from "react";
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Typography } from "@mui/material";

export default function VarietyModal({ open, handleClose, handleConfirm }) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "7px",
          width: "585px",
          margin: "auto",
          mt: 25,
          height: "200px",
        }}
      >
        <Box
          sx={{
            background: "#102A62",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <Typography
            sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
          >
            {t('VarietyRegistration.modal.cancelOperation.title')}
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: 400 }}
          >
            {t('VarietyRegistration.modal.cancelOperation.message')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            mb: 1,
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              background: "#737D86",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#737D86" },
            }}
          >
            {t('VarietyRegistration.modal.cancelOperation.no')}
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              background: "#004F9F",
              width: "100px",
              color: "#ffffff",
              "&:hover": { background: "#004F9F" },
            }}
          >
            {t('VarietyRegistration.modal.cancelOperation.yes')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
} 