import { Autocomplete, Box, FormControl, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import Layout from 'components/structure/Layout';
import { SnackbarContext } from 'context/snackBarContext';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AgriculturalSuppliersRegService from 'services/AgriculturalSuppliesRegService';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import FarmersContractPreAssessmentService from 'services/FarmersContractPreAssessmentService';
import CloseIcon from "@mui/icons-material/Close";
import label from "../growingLabel.json";
import FarmersContractPreAssessmentTable from './FarmersContractPreAssessmentTable';
import CuringUnitService from 'services/CuringUnitService';




function FarmersContractPreAssessmentList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        return parseInt(localStorage.getItem("farmerContractPreAssess")) || 5;
    });
    const [totalItems, setTotalItems] = useState(0);
    const [cropSeason, setCropSeason] = useState("");
    const [cropSeasonId, setCropSeasonId] = useState("");
    const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
    const [status, setStatus] = useState("");

    console.log("data", data)
    useEffect(() => {
        fetchInitialData();
        // handleViewHistory();
        // fetchData()
    }, []);

    const fetchInitialData = async () => {
        try {
            const [cropSeasonResponse] = await Promise.all([
                FarmersContractPreAssessmentService.getCropSeasonAPI(),
            ]);

            setCropSeasonOptions(cropSeasonResponse.data.response.cropSeason.map(item => ({
                label: item.cropYear.toString(),
                value: item.cropYear.toString(),
                cropSeasonId: item.cropSeasonId,// Assuming there's an id field      
            })));

        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const fetchData = (
        pageNumber,
        size,
        cropSeasonId,
        status,
    ) => {
        setLoading(true);
        const query = {
            pageNumber,
            size,
            cropSeasonId,
            status: status === "active"
                ? true
                : status === "inactive"
                    ? false
                    : '',
        };
        console.log("usedRecordsquery", query)
        FarmersContractPreAssessmentService.getFarmerCPAList(query)
            .then((res) => {
                const results =
                    res?.data?.response?.farmerPreAssesment || [];
                setData(results);
                setTotalItems(res?.data?.response?.totalItems || 0);
                if (results.length === 0 && cropSeason.length > 0) {
                    setMessage("No records found.");
                    setSeverity("error");
                    setOpenAlert(true);
                }
            })
            .catch((error) => {
                setMessage(error?.response?.data?.message || error?.message);
                setSeverity("error");
                setOpenAlert(true);
                setData([]);
                setTotalItems(0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData(currentPage, itemsPerPage, cropSeasonId, status);
    }, [currentPage, itemsPerPage, cropSeasonId, status]);

    const paginate = (pageNumber, itemsPerPage) => {
        setCurrentPage(pageNumber - 1);
        setItemsPerPage(itemsPerPage);
        localStorage.setItem("farmerContractPreAssess", itemsPerPage)
        fetchData(pageNumber - 1, itemsPerPage, cropSeasonId, status);
    };

    return (
        <Layout
            title={t('farmersPreAssessment.pageTitle')}
            background="#ffffff"
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
            useNewPagination={true}
        >
            <Grid
                container
                id="mainfilter"
                spacing={2}
                sx={{
                    background: "#EFEFEF",
                    borderRadius: "5px",
                    p: 2,
                    maxWidth: "100%",
                    margin: "auto",
                    paddingBottom: "34px",
                    marginBottom: "12px",
                }}
            >
                <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
                    <Typography variant="h8" display="flex" fontWeight="600" color="#666666">
                        <ArrowDropDownIcon />
                        {t('Filters')}
                    </Typography>
                </Grid>

                <Grid item xs={4} style={{ paddingLeft: "0px" }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
                            disableClearable={false}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setCropSeason(newValue.value);
                                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                                } else {
                                    setCropSeason("");
                                    setCropSeasonId(""); // This will trigger useEffect
                                }
                            }}
                            options={cropSeasonOptions}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('investmentGroupLimits.csSearchField')}
                                    fullWidth
                                    placeholder='Select an Option'
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        '& .MuiInputBase-input::placeholder': {
                                            opacity: 1,
                                        },
                                    }}
                                // InputProps={{
                                //     style: { height: "50px" }, // Directly setting the height
                                //   }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ minWidth: "200px" }}>
                        <TextField
                            select
                            name="status"
                            label={t("status")}
                            value={status || "pl"}
                            onChange={(event) => setStatus(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    padding: "7px",
                                    minHeight: "56px",
                                },
                                "& .MuiInputLabel-root": {
                                    lineHeight: "1.3em",
                                },
                            }}
                            InputProps={{
                                style: { height: "56px" },
                                endAdornment: status && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => setStatus("")}
                                            style={{ marginRight: "15px" }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem value="pl" sx={{ display: "none" }}>{t("Select an option")}</MenuItem>
                            <MenuItem value="active">{t("active")}</MenuItem>
                            <MenuItem value="inactive">{t("inactive")}</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
            {data?.length > 0 ? (
                <FarmersContractPreAssessmentTable data={data} />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",                        
                    }}
                >
                    {label.noDataMessage}
                </Box>
            )}
        </Layout>
    )
}

export default FarmersContractPreAssessmentList