// src\services\ProductionTargetsService.jsx
import { httpGrow } from "../http-common";

const getAPI = async (query) => {
    const { cropYearUUID, tobaccoTypeUUID, ihId } = query;
    const response = await httpGrow.get(`/productionTarget/search`, {
        params: {
            cropYearUUID,
            tobaccoTypeUUID,
            ihId
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getTobaccoTypeAPI = async () => {
    const response = await httpGrow.get('/culture/culture-type/tobacco');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getPTHistoryAPI = async (productionTargetId) => {
    const response = await httpGrow.get('/productionTarget/history', {
        params: {
            productionTargetId
        }
    });
    return response;
};

const putPTAPI = (data) => {
    return httpGrow.put('productionTarget', data);
};

const getPTdistHistoryAPI = async (ptDistributionId) => {
    const response = await httpGrow.get('/productionTarget/distribution/history', {
        params: {
            ptDistributionId
        }
    });
    return response;
};

const getPTdistAPI = async (ptDistributionId) => {
    const response = await httpGrow.get('/productionTarget/distributions', {
        params: {
            ptDistributionId
        }
    });
    return response;
};

const putPTdistAPI = (data) => {
    return httpGrow.put('/productionTarget/distributions', data);
};

const ProductionTargetsService = {
    getAPI,
    getCropSeasonAPI,
    getTobaccoTypeAPI,
    getHierarchyNodeAPI,
    getPTHistoryAPI,
    putPTAPI,
    getPTdistHistoryAPI,
    getPTdistAPI,
    putPTdistAPI
};

export default ProductionTargetsService;
