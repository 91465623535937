import Ad from "../pages/AD/Ad";
import Home from "../pages/Home/Home";
import UserAdd from "../pages/User_Management/UserAdd/UserAdd";
import Users from "../pages/User_Management/Users/Users";
import CropYearRegistration from "components/pages/CropYearRegistration/CropYearRegistration";
import Profiles from "../pages/Profiles/Profiles";
import SaveCropYearRegistration from "components/pages/CropYearRegistration/SaveCropYearRegistration";
import AddProfiles from "components/pages/Profiles/AddProfiles";
import SeasonRegistration from "components/pages/SeasonRegistration/SeasonRegistration";
import CropStage from "components/pages/CropStage/CropStage";
import AddCropStage from "components/pages/CropStage/AddCropStage";
import Dashboard from "../pages/Dashboard/Dashboard";
import AddSeason from "components/pages/SeasonRegistration/AddSeason";
import AddModuleRegistration from "components/pages/ModulesRegistration/AddRegistration";
import EditModuleRegistration from "components/pages/ModulesRegistration/EditRegistration";
import GetModuleRegistration from "components/pages/ModulesRegistration/GetRegistration";
import GetCropStageRegistartion from "components/pages/CropStageCalander/GetCropStage";
import AddCropStageCalander from "components/pages/CropStageCalander/AddCropStage";
import EditCropStageCalander from "components/pages/CropStageCalander/EditCropStage";
import HierarchyContainer from "components/pages/Hierarchy/HierarchyContainer";
import AddCropSeasonRegistration from "components/pages/Growing/CropSeason/AddCropSeasonRegistration";
import GetCropSeasonRegistration from "components/pages/Growing/CropSeason/GetCropSeasonRegistration";
import AddCultureRegistration from "components/pages/Growing/CultureRegistration/AddCultureRegistration";
import GetCultureRegistration from "components/pages/Growing/CultureRegistration/GetCultureRegistration";
import IrregularityRegistration from "components/pages/Growing/IrregularityRegistration/IrregularityRegistration";
import AddIrregularity from "components/pages/Growing/IrregularityRegistration/AddIrregularity";
import Curing from "components/pages/Growing/SupplyType/Curing";
import CuringUnitForm from "components/pages/Growing/SupplyType/CuringUnitForm";
import FarmerRegisteration from "components/pages/Growing/Farmer_Management/Farmer_Management/FarmerAdd/FarmerAdd";
import AgriSupplyContainer from "components/pages/Growing/AgricultureSuppliesManagement/AgriSupplyContainer";
import Farmers from "components/pages/Growing/Farmer_Management/Farmer_Management/Farmers/Farmers";
import AgriSupplyForm from "components/pages/Growing/AgricultureSuppliesManagement/AgriSupplyRegistartion";
import HierarchyTreeConfiguration from "components/pages/Growing/Hierarchy/HierarchyTreeConfiguration";
import HierarchyTreeConfigurationHome from "components/pages/Growing/Hierarchy/HierarchyTreeConfigurationHome";
import GetCityConfiguration from "components/pages/Growing/CityConfiguration/GetCityConfiguration";
import GetCultureGroup from "components/pages/Growing/CultureGroup/GetCultureGroup";
import AddNewCultureGroup from "components/pages/Growing/CultureGroup/AddNewCultureGroup";
import GetSpacing from "components/pages/SpacingRegistration/GetSpacing";
import AddSpacing from "components/pages/SpacingRegistration/AddSpacing";
import VarietyList from "components/pages/Growing/VarietyRegistration/VarietyList";
import VarietyForm from "components/pages/Growing/VarietyRegistration/VarietyForm";
import GetEquipmentRegistration from "components/pages/Growing/EquipmentRegistration/GetEquipmentRegistration";
import AddEquipmentRegistration from "components/pages/Growing/EquipmentRegistration/AddEquipmentRegistration";
import SupplyTypeList from "components/pages/Growing/SupplyType/SupplyTypeList";
import AddSupplyType from "components/pages/Growing/SupplyType/AddSupplyType";
import FieldNotebookSupplyRegistration from "components/pages/Growing/FieldNotebookSupplyRegistration/FieldNotebookSupplyRegistration";
import AddFieldNotebookSupply from "components/pages/Growing/FieldNotebookSupplyRegistration/AddFieldNotebookSupply";
import GetCropPhaseRegistration from "components/pages/Growing/CropPhaseRegistration/GetCropPhaseRegistration";
import AddCropPhaseRegistration from "components/pages/Growing/CropPhaseRegistration/AddCropPhaseRegistration";
import GreenManure from "components/pages/Growing/GreenManureRegistration/GreenManure";
import AddGreenManure from "components/pages/Growing/GreenManureRegistration/AddGreenManure";
import InvestmentsGroupRegistration from "components/pages/Growing/InvestmentGroup/InvestmentsGroupRegistration";
import AddInvestment from "components/pages/Growing/InvestmentGroup/AddInvestment";
import FundingGroupRegistration from "components/pages/Growing/FundingGrop/FundingGroupRegistration";
import AddFunding from "components/pages/Growing/FundingGrop/AddFunding";
import FieldOccurenceList from "components/pages/Growing/FieldOccurrenceRegistration/FieldOccurenceList";
import AddFieldOccurence from "components/pages/Growing/FieldOccurrenceRegistration/AddFieldOccurence";
import ProductionTargets from "components/pages/Growing/ProductionTargets/ProductionTargets";
import GetPlantingRegistration from "components/pages/Growing/PlantingTypeRegistration/GetPlantingRegistraion";
import AddPlantRegistration from "components/pages/Growing/PlantingTypeRegistration/AddPlantingRegistraion";
import GetTrayRegistration from "components/pages/Growing/TrayTypeRegistration/GetTrayRegistration";
import AddTrayRegistration from "components/pages/Growing/TrayTypeRegistration/AddTrayRegistration";
import AddtionalFreight from "components/pages/Growing/AdditonalFreight/AdditionalFreight";
import AddFreight from "components/pages/Growing/AdditonalFreight/AddFreight";
import Expenses3rdPartyGroupList from "components/pages/Growing/Expenses3rdPartyGroupRegistration/Expenses3rdPartyGroupList";
import AddExpenses3rdPartyGroup from "components/pages/Growing/Expenses3rdPartyGroupRegistration/AddExpenses3rdPartyGroup";
import PaymentPolicies from "components/pages/Growing/PaymentPoliciesRegistration/PaymentPolicies";
import AddPaymentPolicies from "components/pages/Growing/PaymentPoliciesRegistration/AddPaymentPolicies";
import SoilAnalysisApplicationPhase from "components/pages/Growing/SoilAnalysisApplicationPhase/SoilAnalysisApplicationPhase";
import AddSoilAnalysisApplicationPhase from "components/pages/Growing/SoilAnalysisApplicationPhase/AddSoilAnalysisApplicationPhase";
import GetLocalityConfiguration from "components/pages/Growing/LocalityConfiguration/GetLocalityConfiguration";

import GetTobaccoCompetitorsRegistration from "components/pages/Growing/TobaccoCompaniesCompetitorsRegistration/GetTobaccoCompetitorsRegistration";
import AddTobaccoCompetitorsRegistration from "components/pages/Growing/TobaccoCompaniesCompetitorsRegistration/AddTobaccoCompetitorsRegistration";
import CuringDevice from "components/pages/Growing/CuringDeviceRegistration/CuringDevice";
import AddCuringDevice from "components/pages/Growing/CuringDeviceRegistration/AddCuringDevice";
import SoilAnalysisParameterList from "components/pages/Growing/SoilAnalysisParameterReg/SoilAnalysisParameterList";
import GetFurnaceTypeRegistration from "components/pages/Growing/FurnaceTypeRegistration/GetFurnace";
import AddFurnaceTypeRegistration from "components/pages/Growing/FurnaceTypeRegistration/AddFurnace";
import GetSoilParameter from "components/pages/Growing/SoilAnalysisParameter/GetSoilParameter";

import GetSoilAnalysis from "components/pages/Growing/Soil Analysis Registration/SoilAnalysis";
import AddSoilAnalysis from "components/pages/Growing/Soil Analysis Registration/AddSoilAnalysis";
import AddSoilParameter from "components/pages/Growing/SoilAnalysisParameter/AddSoilParameter";

export const nav = [
  {
    path: "/",
    name: "Dashboard",
    element: <Dashboard />,
    isPrivate: true,
  },
  {
    path: "/home",
    name: "Home",
    element: <Home />,
    isPrivate: true,
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   element: <Login />,
  //   isPrivate: false,
  // },
  {
    path: "/users",
    name: "Manage Users",
    element: <Users />,
    isPrivate: false,
  },
  {
    path: "/users/add",
    name: "Manage Users",
    element: <UserAdd />,
    isPrivate: false,
  },
  {
    path: "/users/:id",
    name: "Manage Users",
    element: <UserAdd />,
    isPrivate: false,
  },
  {
    path: "/ad",
    name: "AD",
    element: <Ad />,
  },
  {
    path: "/cropYearRegistration",
    name: "CropYearRegistration",
    element: <CropYearRegistration />,
    isPrivate: false,
  },
  {
    path: "/saveCropYearRegistration",
    name: "CropYearSaveRegistration",
    element: <SaveCropYearRegistration />,
    isPrivate: false,
  },
  {
    path: "/manageProfiles",
    name: "Manage Profiles",
    element: <Profiles />,
    isPrivate: false,
  },
  {
    path: "/addProfiles",
    name: "Manage Profiles",
    element: <AddProfiles />,
    isPrivate: false,
  },
  {
    path: "/editProfiles",
    name: "Manage Profiles",
    element: <AddProfiles />,
    isPrivate: false,
  },
  {
    path: "/seasonRegistration",
    name: "Season Registration",
    element: <SeasonRegistration />,
    isPrivate: false,
  },

  {
    path: "/addSeason",
    name: "Season Registration",
    element: <AddSeason />,
    isPrivate: false,
  },
  {
    path: "/editSeason",
    name: "Season Registration",
    element: <AddSeason />,
    isPrivate: false,
  },
  {
    path: "/cropStage",
    name: "Crop Stage Registration",
    element: <CropStage />,
    isPrivate: false,
  },

  {
    path: "/addCropStage",
    name: "Crop Stage Registration",
    element: <AddCropStage />,
    isPrivate: false,
  },
  {
    path: "/editCropStage/:id",
    name: "Crop Stage Registration",
    element: <AddCropStage />,
    isPrivate: false,
  },
  {
    path: "/getmoduleregistration",
    name: "Module Registration",
    element: <GetModuleRegistration />,
    isPrivate: false,
  },

  {
    path: "/editmoduleregistration",
    name: "Module Registration",
    element: <EditModuleRegistration />,
    isPrivate: false,
  },

  {
    path: "/addmoduleregistration",
    name: "Module Registration",
    element: <AddModuleRegistration />,
    isPrivate: false,
  },
  {
    path: "/getCropStageCalendar",
    name: "Crop Stage Calendar",
    element: <GetCropStageRegistartion />,
    isPrivate: false,
  },
  {
    path: "/addCropStageCalendar",
    name: "Crop Stage Calendar",
    element: <AddCropStageCalander />,
    isPrivate: false,
  },
  {
    path: "/editCropStageCalendar",
    name: "Crop Stage Calendar",
    element: <EditCropStageCalander />,
    isPrivate: false,
  },
  {
    path: "/hierarchyConfiguration",
    name: "Hierarchy Configuration",
    element: <HierarchyContainer />,
    isPrivate: false,
  },
  {
    path: "/spacingRegistration",
    name: "Space Lintels Plant",
    element: <GetSpacing />,
    isPrivate: false,
  },
  {
    path: "/addSpacing",
    name: "Add Space Lintels Plant",
    element: <AddSpacing />,
    isPrivate: false,
  },
  {
    path: "/cropSeasonRegistration",
    name: "Get Crop Season Registration",
    element: <GetCropSeasonRegistration />,
    isPrivate: false,
  },
  {
    path: "/addcropSeasonRegistration",
    name: "Add Crop Season Registration",
    element: <AddCropSeasonRegistration />,
    isPrivate: false,
  },
  {
    path: "/cultureRegistration",
    name: "Get Culture Registration",
    element: <GetCultureRegistration />,
    isPrivate: false,
  },
  {
    path: "/addcultureRegistration",
    name: "Add Culture Registration",
    element: <AddCultureRegistration />,
    isPrivate: false,
  },
  {
    path: "/editcultureRegistration/:id",
    name: "Add Culture Registration",
    element: <AddCultureRegistration />,
    isPrivate: false,
  },
  {
    path: "/addIrregularity",
    name: "Irregularity Registration",
    element: <AddIrregularity />,
    isPrivate: false,
  },
  {
    path: "/irregularityRegistration",
    name: "Irregularity Registration",
    element: <IrregularityRegistration />,
    isPrivate: false,
  },
  {
    path: "/curing",
    name: "Curing",
    element: <Curing />,
    isPrivate: false,
  },
  {
    path: "/addCuring",
    name: "Curing",
    element: <CuringUnitForm />,
    isPrivate: false,
  },
  {
    path: "/addFarmerRegistration",
    name: "Farmer Registration",
    element: <FarmerRegisteration />,
    isPrivate: false,
  },
  {
    path: "/editFarmerRegistration/:id",
    name: "Farmer Registration",
    element: <FarmerRegisteration />,
    isPrivate: false,
  },
  {
    path: "/farmerRegistration",
    name: "Farmer Registration",
    element: <Farmers />,
    isPrivate: false,
  },
  {
    path: "/AgriCultureSupplyManagement",
    name: "AgriCultureSupplyManagement",
    element: <AgriSupplyContainer />,
  },
  {
    path: "/AgriSupplyForm",
    name: "AgriCultureSupplyManagement",
    element: <AgriSupplyForm />,
  },
  {
    path: "/variety",
    name: "Variety List",
    element: <VarietyList />,
  },
  {
    path: "/varietyForm",
    name: "Variety List",
    element: <VarietyForm />,
  },
  {
    path: "/cultureGroup",
    name: "GetCultureGroup",
    element: <GetCultureGroup />,
  },
  {
    path: "/addnewcultureGroup",
    name: "Add New Culture Group",
    element: <AddNewCultureGroup />,
    isPrivate: false,
  },
  {
    path: "/editcultureGroup",
    name: "Edit Culture Group",
    element: <AddNewCultureGroup />,
    isPrivate: false,
  },
  {
    path: "/viewcultureGroup",
    name: "View Culture Group",
    element: <AddNewCultureGroup />,
    isPrivate: false,
  },
  {
    path: "/EquipmentRegistration",
    name: "GetEquipmentRegistration",
    element: <GetEquipmentRegistration />,
  },
  {
    path: "/addEquipmentRegistration",
    name: "Add Equipment Registration",
    element: <AddEquipmentRegistration />,
    isPrivate: false,
  },

  {
    path: "/editEquipmentRegistration",
    name: " Equipment Registration",
    element: <AddEquipmentRegistration />,
    isPrivate: false,
  },

  {
    path: "/cropPhaseRegistration",
    name: "GetCropPhaseRegistration",
    element: <GetCropPhaseRegistration />,
  },
  {
    path: "/addCropPhaseRegistration",
    name: "Add Crop Phase",
    element: <AddCropPhaseRegistration />,
    isPrivate: false,
  },

  {
    path: "/editCropPhaseRegistration/:id",
    name: "Add Crop Phase",
    element: <AddCropPhaseRegistration />,
    isPrivate: false,
  },
  {
    path: "/viewCropPhaseRegistration/:id",
    name: "View Crop Phase",
    element: <AddCropPhaseRegistration />,
    isPrivate: false,
  },
  {
    path: "/fieldNotebookSupplyRegistration",
    name: "Field Notebook Supply Registration",
    element: <FieldNotebookSupplyRegistration />,
    isPrivate: false,
  },
  {
    path: "/addAgriculturalSupplyRegistraion",
    name: "Add Field Notebook Supply",
    element: <AddFieldNotebookSupply />,
    isPrivate: false,
  },
  {
    path: "/editAgriculturalSupplyRegistraion",
    name: "Edit Field Notebook Supply",
    element: <AddFieldNotebookSupply />,
    isPrivate: false,
  },
  {
    path: "/viewAgriculturalSupplyRegistraion",
    name: "View Field Notebook Supply",
    element: <AddFieldNotebookSupply />,
    isPrivate: false,
  },

  {
    path: "/supplyTypeList",
    name: "Supply Type",
    element: <SupplyTypeList />,
    isPrivate: false,
  },

  {
    path: "/addSupplyType",
    name: "Add Supply Type",
    element: <AddSupplyType />,
    isPrivate: false,
  },

  {
    path: "/editSupplyType",
    name: "Edit Supply Type",
    element: <AddSupplyType />,
    isPrivate: false,
  },

  {
    path: "/viewSupplyType",
    name: "View Supply Type",
    element: <AddSupplyType />,
    isPrivate: false,
  },

  {
    path: "/HierarchyTreeConfigurations",
    name: "HierarchyTreeConfiguration",
    element: <HierarchyTreeConfiguration />,
  },
  {
    path: "/HierarchyTreeConfigurations/:id",
    name: "HierarchyTreeConfiguration",
    element: <HierarchyTreeConfiguration />,
  },
  {
    path: "/HierarchyTreeConfigurationHome",
    name: "HierarchyTreeConfigurationHome",
    element: <HierarchyTreeConfigurationHome />,
  },
  {
    path: "/greenManure",
    name: "Green Manure",
    element: <GreenManure />,
  },
  {
    path: "/addGreenManure",
    name: "Green Manure",
    element: <AddGreenManure />,
  },
  {
    path: "/editGreenManure",
    name: "Green Manure",
    element: <AddGreenManure />,
  },
  {
    path: "/viewGreenManure",
    name: "Green Manure",
    element: <AddGreenManure />,
  },
  {
    path: "/investmentsRegistration",
    name: "Investments Group Registration",
    element: <InvestmentsGroupRegistration />,
  },
  {
    path: "/addInvestment",
    name: "Investments Group Registration",
    element: <AddInvestment />,
  },
  {
    path: "/editInvestment",
    name: "Investments Group Registration",
    element: <AddInvestment />,
  },
  {
    path: "/viewInvestment",
    name: "Investments Group Registration",
    element: <AddInvestment />,
  },
  {
    path: "/funding",
    name: "Funding Group Registration",
    element: <FundingGroupRegistration />,
  },
  {
    path: "/addFunding",
    name: "Funding Group Registration",
    element: <AddFunding />,
  },
  {
    path: "/editFunding",
    name: "Funding Group Registration",
    element: <AddFunding />,
  },
  {
    path: "/viewFunding",
    name: "Funding Group Registration",
    element: <AddFunding />,
  },
  {
    path: "/occurrenceType",
    name: "Occurrence Type",
    element: <FieldOccurenceList />,
    isPrivate: false,
  },

  {
    path: "/addOccurrenceType",
    name: "Add Occurrence Type",
    element: <AddFieldOccurence />,
    isPrivate: false,
  },

  {
    path: "/editOccurrenceType",
    name: "Edit Occurrence Type",
    element: <AddFieldOccurence />,
    isPrivate: false,
  },
  {
    path: "/viewOccurrenceType",
    name: "Edit Occurrence Type",
    element: <AddFieldOccurence />,
    isPrivate: false,
  },

  {
    path: "/plantingTypeRegistration",
    name: "Get Planting Registration",
    element: <GetPlantingRegistration />,
    isPrivate: false,
  },
  {
    path: "/addplantingRegistration",
    name: "Add Planting Registration",
    element: <AddPlantRegistration />,
    isPrivate: false,
  },
  {
    path: "/editplantingRegistration",
    name: "Edit Planting Registration",
    element: <AddPlantRegistration />,
    isPrivate: false,
  },
  {
    path: "/viewplantingRegistration",
    name: "View Planting Registration",
    element: <AddPlantRegistration />,
    isPrivate: false,
  },
  // {
  //   path: "/editcultureRegistration/:id",
  //   name: "Add Culture Registration",
  //   element: <AddCultureRegistration />,
  //   isPrivate: false,
  // },
  {
    path: "/trayTypeRegistration",
    name: "Get Tray Registration",
    element: <GetTrayRegistration />,
    isPrivate: false,
  },
  {
    path: "/addTrayTypeRegistration",
    name: "Add Tray Registration",
    element: <AddTrayRegistration />,
    isPrivate: false,
  },
  {
    path: "/edittrayRegistration/",
    name: "Add Tray Registration",
    element: <AddTrayRegistration />,
    isPrivate: false,
  },
  {
    path: "/viewtrayRegistration/",
    name: "Add Tray Registration",
    element: <AddTrayRegistration />,
    isPrivate: false,
  },
  {
    path: "/additionalFreightGroupList",
    name: "Add Freight Registration",
    element: <AddtionalFreight />,
    isPrivate: false,
  },
  {
    path: "/freightGroupRegistration",
    name: "Add Freight Registration",
    element: <AddFreight />,
    isPrivate: false,
  },
  {
    path: "/editFreightGroupRegistration",
    name: "Add Freight Registration",
    element: <AddFreight />,
    isPrivate: false,
  },
  {
    path: "/viewFreightGroupRegistration",
    name: "Add Freight Registration",
    element: <AddFreight />,
    isPrivate: false,
  },
  {
    path: "/viewOccurrenceType",
    name: "Occurrence Type",
    element: <AddFieldOccurence />,
    isPrivate: false,
  },

  {
    path: "/expenses3rdPartyList",
    name: "Expenses with 3rd Parties",
    element: <Expenses3rdPartyGroupList />,
    isPrivate: false,
  },

  {
    path: "/addExpenses",
    name: "Expenses with 3rd Parties",
    element: <AddExpenses3rdPartyGroup />,
    isPrivate: false,
  },

  {
    path: "/editExpenses",
    name: "Expenses with 3rd Parties",
    element: <AddExpenses3rdPartyGroup />,
    isPrivate: false,
  },

  {
    path: "/viewExpenses",
    name: "Expenses with 3rd Parties",
    element: <AddExpenses3rdPartyGroup />,
    isPrivate: false,
  },
  {
    path: "/paymentPolicies",
    name: "Payment Policies",
    element: <PaymentPolicies />,
  },
  {
    path: "/addPaymentPolicies",
    name: "Payment Policies",
    element: <AddPaymentPolicies />,
  },
  {
    path: "/editPaymentPolicies",
    name: "Payment Policies",
    element: <AddPaymentPolicies />,
  },
  {
    path: "/viewPaymentPolicies",
    name: "Payment Policies",
    element: <AddPaymentPolicies />,
  },
  {
    path: "/curingDevice",
    name: "Curing Device",
    element: <CuringDevice />,
  },
  {
    path: "/addCuringDevice",
    name: "Curing Device",
    element: <AddCuringDevice />,
  },
  {
    path: "/tobaccoCompetitorsRegistration",
    name: "Get Tobacco Competitors Registration",
    element: <GetTobaccoCompetitorsRegistration />,
    isPrivate: false,
  },
  {
    path: "/addtobaccoCompetitorsRegistration",
    name: "Add Tobacco Competitors Registration",
    element: <AddTobaccoCompetitorsRegistration />,
    isPrivate: false,
  },
  {
    path: "/edittobaccoCompetitorsRegistration",
    name: "Add Tobacco Competitors Registration",
    element: <AddTobaccoCompetitorsRegistration />,
    isPrivate: false,
  },

  {
    path: "/viewtobaccoCompetitorsRegistration",
    name: "Add Tobacco Competitors Registration",
    element: <AddTobaccoCompetitorsRegistration />,
    isPrivate: false,
  },

  //Soil Analysis Application Phase
  {
    path: "/soilAnalysisApplicationPhase",
    name: "Soil Analysis Application Phase",
    element: <SoilAnalysisApplicationPhase />,
    isPrivate: false,
  },
  {
    path: "/addSoilAnalysisApplicationPhase",
    name: "Soil Analysis Application Phase",
    element: <AddSoilAnalysisApplicationPhase />,
    isPrivate: false,
  },
  {
    path: "/localityConfiguration",
    name: "Locality Configuration",
    element: <GetLocalityConfiguration />,
    isPrivate: false,
  },

  {
    path: "/soilAnalysisParameterList",
    name: "Soil Analysis Parameter List",
    element: <SoilAnalysisParameterList />,
  },

  {
    path: "/furnaceTypeRegistration",
    name: "Get Furnace Type Registration",
    element: <GetFurnaceTypeRegistration />,
    isPrivate: false,
  },
  {
    path: "/addfurnaceTypeRegistration",
    name: "Add Furnace Type Registration",
    element: <AddFurnaceTypeRegistration />,
    isPrivate: false,
  },
  {
    path: "/editfurnaceTypeRegistration",
    name: "Add Furnace Type Registration",
    element: <AddFurnaceTypeRegistration />,
    isPrivate: false,
  },

  {
    path: "/viewfurnaceTypeRegistration",
    name: "Add Furnace Type Registration",
    element: <AddFurnaceTypeRegistration />,
    isPrivate: false,
  },

  {
    path: "/soilParameter",
    name: "Get Soil Parameter",
    element: <GetSoilParameter />,
    isPrivate: false,
  },

  {
    path: "/addsoilParameter",
    name: "Add Soil Parameter",
    element: <AddSoilParameter />,
    isPrivate: false,
  },

  {
    path: "/editsoilParameter",
    name: "Add Soil Parameter",
    element: <AddSoilParameter />,
    isPrivate: false,
  },

  {
    path: "/viewsoilParameter",
    name: "Add Soil Parameter",
    element: <AddSoilParameter />,
    isPrivate: false,
  },
  {
    path: "/cityConfiguration",
    name: "City Configuration",
    element: <GetCityConfiguration />,
    isPrivate: false,
  },
  {
    path: "/soilAnalysis",
    name: "SoilAnalysis",
    element: <GetSoilAnalysis />,
  },

  {
    path: "/addSoilAnalysis",
    name: "SoilAnalysis",
    element: <AddSoilAnalysis />,
  },

  {
    path: "/productionTargets",
    name: "Production Targets",
    element: <ProductionTargets />,
    isPrivate: false,
  },
];
