import { httpGrow } from "../http-common";

const baseUrl="farmer-pre-assessment-esg"

const getAllFarmersData = async (query) => {
  const {
    pageNumber,
    size,
    cropSeasonId,
    searchKeyword,
    city,
    locality,
    commercialStatuses,
    esgStatuses,
  } = query;

  const response = await httpGrow.get(
    `/${baseUrl}/farmers-for-pre-assessment`,
    {
      params: {
        pageNumber,
        size,
        cropSeasonId,
        searchKeyword: searchKeyword || undefined,
        city: city || undefined,
        locality: locality || undefined,
        commercialStatuses: commercialStatuses || undefined,
        esgStatuses: esgStatuses || undefined,
      },
    }
  );
  return response;
};

const getPreAssessmentFormDetails = async (query) => {
  const { farmerId, cropSeasonId } = query;

  const response = await httpGrow.get(
    `/${baseUrl}/pre-assessment-form-details`,
    {
      params: {
        farmerId,
        cropSeasonId,
      },
    }
  );
  return response;
};

const getPreAssessmentFormDetailsAll = async (query) => {
  const { farmerId, cropSeasonId } = query;

  const response = await httpGrow.get(
    `/${baseUrl}/all-form-details`,
    {
      params: {
        farmerId,
        cropSeasonId,
      },
    }
  );
  return response;
};

const createPreAssessmentData = (data) => {
  return httpGrow.post(`/${baseUrl}`, data);
};

const PreAssessmentFormService = {
  getPreAssessmentFormDetails,
  createPreAssessmentData,
  getAllFarmersData,
  getPreAssessmentFormDetailsAll
};

export default PreAssessmentFormService;
