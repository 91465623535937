// MSCList.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import MSCTable from "./MSCTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MSCService from "services/MSCService";
import debounce from "lodash/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from 'react-i18next';
import SelectInput from "components/common/SelectInput";
import MSCModal from "./MSCModal";

const MSCList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const defaultcountryid = null;

  // using debounce so that it will not call api on every key stroke
  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(value);
    }, 300)
  );

  useEffect(() => {
    fetchData('');
    fetchCountries();
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, []);

  // Function to handle search input change
  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value) {
      debouncedSearch(value);
    } else {
      // Directly call fetchData when input is cleared
      fetchData(undefined);
    }
  };

  // Function to fetch data from API
  const fetchData = useCallback((countryId) => {
    setLoading(true);
    MSCService.getMenuAPI(countryId)
      .then((res) => {
        const menuList = res?.data?.response || [];
        setTableData(menuList);
        setLoading(false);
      })
      .catch((error) => {
        setTableData([]);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setLoading(false);
      });
  }, [setMessage, setSeverity, setOpenAlert]);

  // Add a method to refresh data after deletion
  const handleDeleteRefresh = () => {
    fetchData(searchKeyword);
  };

  // Add handler for modal confirmation
  const handleModalConfirm = (newData) => {
    // Add a helper function to convert file to base64
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    // Modify the payload creation to handle file conversion
    const processPayload = async () => {
      let base64Content = null;
      let fileName = null;

      // If imageFile is a File object (not a URL), convert to base64
      if (newData.imageFile && typeof newData.imageFile === 'object' && newData.imageFile instanceof File) {
        try {
          base64Content = await getBase64(newData.imageFile);
          fileName = `menu_icon_${Date.now()}.${newData.imageFile.name.split('.').pop()}`;
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      } 
      // If imageFile is a data URL, extract base64
      else if (newData.imageFile && newData.imageFile.startsWith('data:')) {
        base64Content = newData.imageFile;
        fileName = `menu_icon_${Date.now()}.png`;
      }

      const payload = {
        menuName: newData.description,
        menuOrder: parseInt(newData.menuOrder, 10),
        menuType: newData.menuType,
        menuIcon: base64Content ? {
          fileName: fileName,
          base64FileContent: base64Content.split(',')[1] // Extract base64 content
        } : null,
        isMenuIconChanged: !!base64Content,
        parentMenuId: null,
        link: newData.menuType === 'Link' ? newData.url : null,
        countryId: searchKeyword || defaultcountryid,
        languageId: null,
        createdByUser: "ADMIN",
        modifiedByUser: "ADMIN"
      };

      return payload;
    };

    // Use async function to handle file conversion
    const createMenu = async () => {
      try {
        const payload = await processPayload();
        
        const response = await MSCService.createMenuAPI(payload);
        
        // Refresh the data after successful creation
        fetchData(searchKeyword);

        // Close the modal
        setIsModalOpen(false);

        // Show success message
        setMessage(t('soilAnalysisApplicationPhase.messages.addSuccess'));
        setSeverity("success");
        setOpenAlert(true);
      } catch (error) {
        // Handle error
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      }
    };

    // Call the async function
    createMenu();
  };

  // Add a new function to fetch countries
  const fetchCountries = () => {
    MSCService.getCountriesAPI()
      .then((res) => {
        const countryList = res?.data?.response || [];
        setCountries(countryList);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      });
  };

  return (
    <Layout
      title={t('MSC.pageTitle')}
      background="#ffffff"
    >
      {/* // Search and Filter Section */}
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
        }}
      >
        {/* // DownArrowIcon and Filter Lable */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t('filtersLable')}
          </Typography>
        </Grid>

        {/* // Search Input Field */}
        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
          <SelectInput
            label={t('MSC.country')}
            options={countries.map(country => ({ 
              value: country.countryId, 
              label: country.countryName 
            }))}
            value={searchKeyword}
            onChange={(event) => handleSearchChange(event.target.value)}
            placeholder="Select an option"
            sx={{
              "& .MuiInputBase-input::placeholder": {
                opacity: 1,
              },
              "& .MuiInputLabel-root": {
                lineHeight: "1.2em", // Adjust label spacing if needed
                fontWeight: "600",
              },
              width: "100%",
            }}
            loading={loading}
          />
        </Grid>

        {/* // Empty Grid Item for Spacing */}
        <Grid item xs={7}></Grid>

        {/* // Add New Button */}
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <Button
            sx={{
              borderRadius: 1,
              minWidth: 95,
              minHeight: 44,
              background: "#004F9F",
              mb: 1,
              mr: 2,
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              // Open the modal for adding a new entry
              setIsModalOpen(true);
            }}
          >
            {t('addNew')}
          </Button>
        </Grid>
      </Grid>

      {/* // Table Section */}
      {tableData.length > 0 ? (
        <MSCTable
          MSCData={tableData}
          onDelete={handleDeleteRefresh}
          fetchData={fetchData}
          selectedCountryId={searchKeyword}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}
        >
          <Typography variant="h6" color="textSecondary">
            {t('noDataAvailable')}
          </Typography>
        </Box>
      )}

      {/* Empty Space To prevent table and pagination overlap */}
      <Box sx={{mt:"50px",color:"white"}}>
        Space
      </Box>

      <MSCModal
        open={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        title="Menu Item"
        confirmText="SAVE"
        onConfirm={handleModalConfirm}
        selectedCountryId={searchKeyword}
      />
    </Layout>
  );
};

export default MSCList;
