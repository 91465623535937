// GetLocalityConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  FormLabel,
  Modal,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useCultureStore from "./useCultureStore";
import Divider from "components/common/GrowingDivider";
import ListFilter from "./ListFilter";
import Save from "assets/SaveNew.svg";
import { set } from "date-fns";
import { useCultureData } from "./useCultureData";
import { useQueryClient } from '@tanstack/react-query';
import "./GrowingBaseTable.css";

const FormCTR = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { setMessage, setSeverity, setOpenAlert } = useContext(SnackbarContext);
  const selectedCultureName = useCultureStore(
    (state) => state.selectedCultureName
  );

  // Add state to track list filters
  const [filterList, setFilterList] = useState([]);

  const cultureTypeId = useCultureStore((state) => state.cultureTypeId);
  
  console.log('Component mounted, Culture Type ID:', cultureTypeId); // Initial load log

  // Get culture type data
  const { cultureTypeQuery } = useCultureData({
    cultureTypeId
  });

  // Debug logging for query status
  useEffect(() => {
    console.log('Query Status:', {
      isLoading: cultureTypeQuery.isLoading,
      isError: cultureTypeQuery.isError,
      error: cultureTypeQuery.error,
      data: cultureTypeQuery.data
    });
  }, [cultureTypeQuery.status]);

  // Update filter list when data is loaded
  useEffect(() => {
    console.log('Culture Type Query Data changed:', cultureTypeQuery.data);
    if (cultureTypeQuery.data?.response) {
      const cultureTypes = cultureTypeQuery.data.response;
      console.log('Setting filter list with culture types:', cultureTypes);
      setFilterList(Array.from({ length: cultureTypes.length }, (_, i) => i));
    }
  }, [cultureTypeQuery.data]);

  // Debug logging for filter list updates
  useEffect(() => {
    console.log('Filter List updated:', filterList);
  }, [filterList]);

  // Pass the initial data to ListFilter
  const getInitialDataForFilter = (index) => {
    if (!cultureTypeQuery.data?.response) return null;
    return cultureTypeQuery.data.response[index];
  };

  // Handler to add new filter
  const handleAddNew = () => {
    setFilterList(prev => [...prev, prev.length]);
  };

  // Update handleRemoveFilter to handle last filter case
  const handleRemoveFilter = async (indexToRemove) => {
    try {
      if (filterRefs.current[indexToRemove]) {
        const filterData = filterRefs.current[indexToRemove].getData();
        const initialData = getInitialDataForFilter(indexToRemove);
        
        if (initialData?.ctCultureTypeId) {
          // Prepare data for the API call
          const updatedData = [{
            gtGeneticTypeId: filterData?.geneticTypeId,
            cultureTypeCountryLinkDTOs: [],
            createdDateTimeUTC: new Date().toISOString(),
            createdByUser: "System_User",
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: "System_User",
            cuCultureId: useCultureStore.getState().cultureTypeId,
            ctCultureTypeId: initialData.ctCultureTypeId,
            ctAbbreviation: filterData?.abbreviation || "",
            ctDescription: filterData?.description || "",
            ctLSM: filterData?.lsm || "",
            ctrId: "8eaffec5-769b-ef11-8474-002248a01588",
            curingTypeName: filterData?.curingType || "",
            geneticTypeName: filterData?.geneticType || "",
            ctStatus: filterData?.status || false,
            isDeleted: true // Mark as deleted
          }];

          // Call the mutation
          await postCultureTypeMutation.mutateAsync(updatedData);
          
          // If this was the last filter, invalidate queries and clear cache
          if (filterList.length === 1) {
            queryClient.invalidateQueries(['cultureType', cultureTypeId]);
            queryClient.setQueryData(['cultureType', cultureTypeId], null);
            useCultureStore.getState().setCultureTypeId(null);
          }
          
          // Show success message using translation
          setMessage(t("deletedSuccessfully"));
          setSeverity('success');
          setOpenAlert(true);
          
          // Remove from UI
          setFilterList(prev => prev.filter((_, index) => index !== indexToRemove));
        } else {
          // For new records, just remove from the list
          setFilterList(prev => prev.filter((_, index) => index !== indexToRemove));
        }
      }
    } catch (error) {
      console.error('Error deleting filter:', error);
      setMessage(t("errorUpdatingRecord"));
      setSeverity('error');
      setOpenAlert(true);
    }
  };

  // Add this handler for cancel navigation
  const handleCancel = () => {
    setOpen(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/listCultureTypeRegistration");
  };

  // Add refs to store ListFilter data
  const filterRefs = useRef({});

  // Get the mutation from useCultureData
  const { postCultureTypeMutation } = useCultureData({});

  // Add this function to register refs
  const registerFilterRef = (index, ref) => {
    filterRefs.current[index] = ref;
  };

  // Add save handler
  const handleSave = async () => {
    try {
        const filtersData = filterList.map((index) => {
            const filterData = filterRefs.current[index]?.getData();
            const initialData = getInitialDataForFilter(index);
            
            return {
                gtGeneticTypeId: filterData?.geneticTypeId,
                cultureTypeCountryLinkDTOs: [],
                createdDateTimeUTC: new Date().toISOString(),
                createdByUser: "System_User",
                modifiedDateTimeUTC: new Date().toISOString(),
                modifiedByUser: "System_User",
                cuCultureId: useCultureStore.getState().cultureTypeId,
                ctCultureTypeId: initialData?.ctCultureTypeId || "",
                ctAbbreviation: filterData?.abbreviation || "",
                ctDescription: filterData?.description || "",
                ctLSM: filterData?.lsm || "",
                ctrId: filterData?.ctrId || "",
                curingTypeName: filterData?.curingType || "",
                geneticTypeName: filterData?.geneticType || "",
                ctStatus: filterData?.status || false,
                isDeleted: filterData?.isDeleted || false
            };
        });

        // Call the mutation
        await postCultureTypeMutation.mutateAsync(filtersData);
        
        // Show success message
        setMessage(t("recordUpdatedSuccessfully"));
        setSeverity('success');
        setOpenAlert(true);
        
        // Navigate on success
        navigate("/listCultureTypeRegistration");
        
    } catch (error) {
        console.error('Error saving culture types:', error);
        setMessage('Error saving changes');
        setSeverity('error');
        setOpenAlert(true);
    }
  };

  const queryClient = useQueryClient();

  return (
    <Layout
      title={t("curingTypeRegistration.pageTitle2")}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* Filter Section */}
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        {/* Filter and arrow */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("curingTypeRegistration.cultureinfo")}
          </Typography>
        </Grid>

        {/* input field */}
        <Grid item xs={12} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="culture-type-input"
              variant="outlined"
              size="small"
              disabled
              value={selectedCultureName}
              InputProps={{
                sx: {
                  backgroundColor: "#F5F5F5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* Filter Section Ends */}

      {/* Divider Section */}
      <Box
        display=""
        alignItems="center"
        flexDirection={"column"}
        mt={3}
        mb={3}
      >
        <Box display="flex" alignItems="center" flexDirection={"row"}>
          <FormLabel
            sx={{
              ml: 1,
              color: "#000000",
              display: "flex",
            }}
          >
            {t("curingTypeRegistration.formtitle")}
          </FormLabel>
          <Button
            style={{
              position: "absolute",
              right: 0,
              float: "right",
              marginLeft: "100px",
              marginRight: "25px",
              marginBottom: "8px",
              background: "#004F9F",
              alignSelf: "center",
            }}
            variant="contained"
            onClick={handleAddNew}
          >
            {t("addNew")}
          </Button>
        </Box>

        <hr
          style={{
            color: "#004F9F",
            backgroundColor: "#004F9F",
            height: 2,
            borderColor: "#004F9F",
          }}
        />
      </Box>

      {/* Render ListFilter components */}
      {filterList.map((_, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <ListFilter 
            ref={(ref) => registerFilterRef(index, ref)}
            onDelete={() => handleRemoveFilter(index)}
            showDelete={filterList.length > 0}
            initialData={getInitialDataForFilter(index)}
          />
        </Box>
      ))}

      {/* Replace the static margin Box with a dynamic one */}
      <Box 
        sx={{ 
          mb: filterList.length > 0 ? 20 : 10,
          minHeight: '100px' // Ensures minimum space even with no filters
        }}
      />

      {/* Cancel and Save Buttons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={3}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "10px",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
          zIndex: 1000 // Ensure buttons stay on top
        }}
      >
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
          }}
          variant="contained"
          startIcon={<img src={arrowLeft} alt="Arrow" />}
          onClick={handleCancel}
        >
          {t("cancel")}
        </Button>

        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Right Arrow" />}
          variant="contained"
          onClick={handleSave}
        >
          {t("save")}
        </Button>
      </Box>

      {/* Modal confirmation */}
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Cancel Operation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("cancelMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleCloseModal}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default FormCTR;
