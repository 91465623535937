import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SpacingTable from "./SpacingTable";
import { debounce } from "lodash";
import label from "utils/lables.json";
import { useTranslation } from "react-i18next";
import SpacingServices from "services/SpacingServices";
import ContractTypeService from "services/ContractTypeService";
import SearchIcon from "assets/SearchIcon.svg";

const RegistrationOptions = styled.div`
  background: #efefef;
  height: 130px;
  padding: 10px 10px;
  border-radius: 5px;
`;

const ContractType = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [geneticType, setGeneticType] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const statusData = [t("active"), t("inactive")];
  const [recordsError, setRecordsError] = useState(false);
  const [searchData, setSearchData] = useState({
    geneticType: "",
    isActive: "",
  });

  const handleButtonClick = async () => {
    navigate("/addContract");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchData);

      // If no search parameters are provided, fetch all data
      if (Object.keys(cleanedParams).length === 0) {
        fetchData(currentPage, itemsPerPage);
        return;
      }

      // Convert the status to boolean for the API
      let isActive;
      if (cleanedParams.isActive) {
        isActive = cleanedParams.isActive === t("active");
      }

      // Find the genetic description based on the selected geneticType ID
      // let geneticDescription;
      // if (cleanedParams.geneticType) {
      //   const selectedGenetic = geneticType.find(
      //     (item) => item.value === cleanedParams.geneticType
      //   );
      //   geneticDescription = selectedGenetic?.label;
      // }

      ContractTypeService.filter({
        geneticDescription: searchData.geneticType, // Using description instead of ID
        isActive: isActive,
        pageNumber: currentPage,
        size: itemsPerPage,
      })
        .then((response) => {
          setData(response?.data?.response);
          setCurrentPage(response?.data?.response?.currentPage || 0);
          setItemsPerPage(itemsPerPage);
          setTotalItems(response?.data?.response?.totalItems || 0);
          setRecordsError(false);
        })
        .catch((error) => {
          setRecordsError(true);
          if (error?.response?.status === 404) {
            setCurrentPage(0);
          }
        });
    }, 500),
    [
      searchData.geneticType,
      searchData.isActive,
      currentPage,
      itemsPerPage,
      t,
      geneticType,
    ]
  );

  useEffect(() => {
    if (searchData.geneticType || searchData.isActive) {
      handleSearch();
    } else {
      fetchData(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchData, currentPage, itemsPerPage, handleSearch]);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "" || cleanedParams[key] === "PL") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const paginate = (pageNumber, itemsPerPage) => {
    fetchData(pageNumber - 1, itemsPerPage);
  };

  const fetchData = async (pNumber = 0, size = itemsPerPage) => {
    try {
      setLoading(true);
      const response = await ContractTypeService.get({
        pageNumber: pNumber,
        size: size,
      });
      setData(response?.data?.response);
      setCurrentPage(pNumber);
      setItemsPerPage(size);
      setTotalItems(response?.data?.response?.totalItems);
      setRecordsError(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setRecordsError(true);
      if (error?.response?.status === 404) {
        setData(null);
      }
    }
  };
  useEffect(() => {
    SpacingServices.geneticType()
      .then((response) => {
        const geneticData = response.data.response
          .filter((item) => !item.isDelete)
          .map((item) => ({
            value: item.gtGeneticTypeId,
            label: item.gtGeneticDescription,
            abbreviation: item.gtGeneticAbbreviation,
          }));
        setGeneticType(geneticData);
      })
      .catch((error) => console.error("Error fetching genetic types:", error));
  }, []);

  const handleClear = async (name) => {
    const newData = {
      ...searchData,
      [name]: "",
    };
    setSearchData(newData);

    if (!newData.geneticType && !newData.isActive) {
      setRecordsError(false);
      await fetchData(0, itemsPerPage);
    }
  };

  useEffect(() => {
    // Only trigger search if at least one filter has a value
    if (searchData.geneticType || searchData.isActive) {
      handleSearch();
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchData, currentPage, itemsPerPage, handleSearch]);

  const refreshData = () => {
    // If there are no items left on the current page, go back one page
    if (data && data.ContractTypes && data.ContractTypes.length === 1) {
      fetchData(Math.max(0, currentPage - 1), itemsPerPage);
    } else {
      // Otherwise, just refresh the current page
      fetchData(currentPage, itemsPerPage);
    }
  };

  return (
    <Layout
      title={"Contract Type Registration - List"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      useNewPagination={true}
      currentPage={currentPage}
      background={"#ffffff"}
    >
      <div>
        <RegistrationOptions>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "20px",
            }}
          >
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  display: { md: "flex", xs: "block" },
                  alignItems: "center",
                  gap: "1rem",
                  marginLeft: 2,
                }}
              >
                <FormControl
                  sx={{
                    width: { xs: "100%", md: 400 },
                    minHeight: 50,
                  }}
                >
                  <TextField
                    id="geneticType"
                    labelId="cy-select-label"
                    label={"Contract Type"}
                    value={searchData.geneticType}
                    name="geneticType"
                    onChange={handleChange}
                    error={!!errors.geneticType}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true,
                      endAdornment: searchData.geneticType && (
                        <CloseIcon
                          onClick={() => handleClear("geneticType")}
                          style={{
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <FormHelperText error={true}>
                    {errors.geneticType}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  sx={{
                    width: { xs: "100%", md: 400 },
                    minHeight: 50,
                  }}
                >
                  <TextField
                    select
                    id="status"
                    labelId="cy-select-label-status"
                    label={t("spacingRegistration.status")}
                    value={searchData.isActive || "PL"}
                    name="isActive"
                    onChange={handleChange}
                    error={!!errors.geneticType}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true,
                      endAdornment: searchData.isActive && (
                        <CloseIcon
                          onClick={() => handleClear("isActive")}
                          style={{
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="PL" disabled sx={{ display: "none" }}>
                      {t("spacingRegistration.menuTitle")}
                    </MenuItem>
                    {statusData.map((s) => (
                      <MenuItem key={s} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error={true}>
                    {errors.geneticType}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              style={{ justifyContent: "flex-end" }}
              item
              xs={12}
              md={12}
              xl={12}
            >
              <div>
                <Button
                  sx={{
                    width: { xs: "100%", md: 150 },
                    minHeight: 55,
                    borderRadius: 1,
                    background: "#004F9F",
                    marginRight: 1,
                    fontSize: "1rem",
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={handleButtonClick}
                >
                  {t("addNew")}
                </Button>
              </div>
            </Grid>
          </Box>
        </RegistrationOptions>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!loading &&
            data &&
            data.ContractTypes &&
            data.ContractTypes.length > 0 &&
            !recordsError ? (
              <SpacingTable
                data={data.ContractTypes}
                refreshData={refreshData}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="body1">{label.noDataMessage}</Typography>
              </Box>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ContractType;
