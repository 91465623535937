import { httpGrow } from "../http-common";

const get = (query) => {
  return httpGrow.get(
    `spacing-registration?countryCode=BRA&pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};

const getUnitMeasurment = () => {
  return httpGrow.get(
    `/unit-of-measurements`
  );
};

const create = (data) => {
  return httpGrow.post("/spacing-registration", data);
};

const update = (data) => {
  return httpGrow.put(`/spacing-registration`, data);
};

const remove = (data) => {
  return httpGrow.delete(`/spacing-registration/${data}`);
};

const geneticType = (data) => {
  return httpGrow.get(`/geneticType`, data);
};

const filter = (query) => {
  let url = `/spacing-registration/filter-by-genetic-description?countryCode=BRA`;
  
  if (query.geneticDescription) {
    url += `&gtGeneticDescription=${query.geneticDescription}`;
  }
  
  if (query.isActive !== undefined) {
    url += `&isActive=${query.isActive}`;
  }
  url += `&pageNumber=${query.pageNumber}&pageSize=${query.size}`;
  
  return httpGrow.get(url);
};

const SpacingServices = {
  get,
  create,
  update,
  remove,
  getUnitMeasurment,
  geneticType,
  filter,
};

export default SpacingServices;
