import { httpGrow } from "../http-common";

const getFarmerSampleEstimate = async (query) => {
  const { pageNumber, size, cropSeasonId, hierarchicalNodeId } = query;

  const response = await httpGrow.get(`/define-farmers/list`, {
    params: {
      pageNumber,
      size,
      cropSeasonId: cropSeasonId || undefined,
      ihId: hierarchicalNodeId || undefined,
    },
  });
  return response;
};

const getAllFarmerSampleEstimate = async (query) => {
  const { cropSeasonId, hierarchicalNodeId } = query;

  const response = await httpGrow.get(`/define-farmers/list`, {
    params: {
      cropSeasonId: cropSeasonId || undefined,
      ihId: hierarchicalNodeId || undefined,
    },
  });
  return response;
};

const deleteFarmerSampleEstimate = (farmerUId) => {
  return httpGrow.delete(`/define-farmers/delete-farmer/${farmerUId}`);
};

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get(
    "/grCropSeason/search?status=true&cultureName=Tobacco"
  );
  return response;
};

const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get("/importHierarchyVersion/tree");
  return response;
};

const importFarmerSample = (
  file,
  countryId,
  languageId,
  createdByUser,
  modifiedByUser
) => {
  const formData = new FormData();
  formData.append("file", file);

  return httpGrow.post("/define-farmers/import-define-farmers", formData, {
    params: {
      countryId,
      languageId,
      createdByUser,
      modifiedByUser,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    },
  });
};

const downloadTemplate = () => {
  return httpGrow.get("/define-farmers/template/farmerestimate", {
    responseType: "blob",
  });
};

const DefineFarmerSampleEstimateService = {
  getFarmerSampleEstimate,
  getAllFarmerSampleEstimate,
  deleteFarmerSampleEstimate,
  importFarmerSample,
  downloadTemplate,
  getCropSeasonAPI,
  getHierarchyNodeAPI,
};

export default DefineFarmerSampleEstimateService;
