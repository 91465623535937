import {
    Button,
    CircularProgress,
} from "@mui/material";
import Save from "assets/SaveNew.svg";

export default function SaveButton({ loading, onSave }){
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            minHeight: '10vh',
            paddingBottom: '1.5rem',
        }}>
            <Button
                sx={{
                    padding: "6px 16px",
                    borderRadius: "4px",
                    backgroundColor: "#EF7D00",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#EF7D00",
                    },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={onSave}
                variant="contained"
            >
                {loading && <CircularProgress size={20} />}
                {"Save"}
            </Button>
        </div>
    )
}
