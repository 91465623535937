import { httpGrow } from "../http-common";

const getSegmentationKpi = async (query) => {
  const { pageNumber, pageSize, searchParam, skpioDescription } = query;

  const response = await httpGrow.get(`/segmentationKpis`, {
    params: {
      pageNumber,
      pageSize,
      searchKeyword: searchParam || undefined,
      origin: skpioDescription || undefined,
    },
  });
  return response;
};

const getSegmentationKpiById = async (segmentationKpiId) => {
  const response = await httpGrow.get(`/segmentationKpis/single-segmentation-kpi`, {
    params: {
      segmentationKpiId,
    },
  });
  return response;
};

const getRulesRoutines = async () => {
  const response = await httpGrow.get(`/segmentationKpisRoutinesRules/search`);
  return response;
};

const getOriginsData = async () => {
  const response = await httpGrow.post("/picklist", {
    pickListCodes: ["SKPIC"],
  });
  return response;
};

const createSegmentationKpi = (data) => {
  return httpGrow.post(`segmentationKpis`, data);
};

const deleteSegmentationKpi = (segmentationKpisId) => {
  return httpGrow.delete(`/segmentationKpis/${segmentationKpisId}`);
};

const SegmentationKpiService = {
  getSegmentationKpi,
  getSegmentationKpiById,
  getOriginsData,
  getRulesRoutines,
  createSegmentationKpi,
  deleteSegmentationKpi,
};

export default SegmentationKpiService;
