import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  propertyInformation: [],
};

const propertyAddSlice = createSlice({
  name: "propertyInformation",
  initialState,
  reducers: {
    updateProperty(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((newProperty) => {
          const existingPropertyIndex = state.propertyInformation.findIndex(
            (property) =>
              property.farmerPropertyLinkId === newProperty.farmerPropertyLinkId
          );
          if (existingPropertyIndex !== -1) {
            state.propertyInformation[existingPropertyIndex] = newProperty;
          } else {
            state.propertyInformation.push(newProperty);
          }
        });
      } else {
        console.error(
          "Payload for updateProperty should be an array of property objects."
        );
      }
    },
    deleteProperty(state, action) {
      state.propertyInformation = state.propertyInformation.filter(
        (property) => property.id !== action.payload
      );
    },
    clearProperty(state) {
      state.propertyInformation = [];
    },
  },
});

export const { updateProperty, deleteProperty, clearProperty } =
  propertyAddSlice.actions;
export default propertyAddSlice.reducer;
