// src\services\MSCService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "menu";

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, keyword = '', status } = query;
    const response = await httpGrow.get(`/${basename}/search`, {
        params: {
            pageNumber,
            pageSize: size,
            applicationPhase: keyword,
            status: status !== undefined ? status : undefined
        }
    });
    return response;
};

const createAPI = (data) => {
    return httpGrow.post(`/${basename}`, data);
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}`, data);
};

const deleteAPI = (menuId, modifiedByUser = 'ADMIN') => {
    return httpGrow.delete(`/${basename}/${menuId}`, {
        params: {
            modifiedByUser
        }
    });
};

const getCountriesAPI = async () => {
    const response = await httpGrow.get('/countries');
    return response;
};

const getMenuAPI = async (countryId) => {
    const response = await httpGrow.get('/menu', {
        params: {
            countryId: countryId
        }
    });
    return response;
};

const getSubMenuAPI = async (menuId) => {
    const response = await httpGrow.get('/menu/sub-menus', {
        params: {
            parentMenuId: menuId
        }
    });
    return response;
};

const createMenuAPI = (menuData) => {
    return httpGrow.post('/menu', menuData);
};

const getFunctionalitiesAPI = async (countryId) => {
    const response = await httpGrow.get('/menu/categories-drop-down', {
        params: {
            countryId: countryId
        }
    });
    return response;
};

const getSingleMenuDetailsAPI = async (menuId) => {
    const response = await httpGrow.get('/menu/single-menu', {
        params: {
            menuId: menuId
        }
    });
    return response;
};

const reorderMenuAPI = async (payload) => {
  const response = await httpGrow.put('/menu/sequence', payload, {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return response;
}

const MSCService = {
    getAPI,
    createAPI,
    updateAPI,
    deleteAPI,
    getCountriesAPI,
    getMenuAPI,
    getSubMenuAPI,
    createMenuAPI,
    getFunctionalitiesAPI,
    getSingleMenuDetailsAPI,
    reorderMenuAPI
};

export default MSCService;
