import { httpGrow } from "../http-common";

const getSupplyList = (query) => {
  return httpGrow.get(
    `/supplyTypeFieldNotebook?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
  );
};
//   http://20.13.204.151/grow_bs/culture?pageNumber=0&pageSize=10&countryCode=BRA

const searchSupplyType = ({
  pageNumber,
  pageSize,
  keyword,
  isUseNPK,
  isActive,
}) => {
  const params = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    keyword: keyword,
    isUseNPK: isUseNPK == "Yes" ? true : isUseNPK == "No" ? false : isUseNPK,
    isActive:
      isActive == "Active" ? true : isActive == "Inactive" ? false : isActive,
  };
  // const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
  // let newParams = Object.fromEntries(filteredEntries);
  // newParams['pageNumber'] = params.pageNumber
  return httpGrow.get("/supplyTypeFieldNotebook/search", { params });
};

const searchSupplyTypeDescription = ({ supplyTypeDescription, page, size }) => {
  const params = {
    supplyTypeDescription,
    pageNumber: page,
    pageSize: size,
  };
  // const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
  // let newParams = Object.fromEntries(filteredEntries);
  // newParams['pageNumber'] = params.pageNumber
  console.log("search desc params", params);
  return httpGrow.get("/supplyTypeFieldNotebook/searchDescription", { params });
};

const updateSupply = (data) => {
  return httpGrow.put(`/supplyTypeFieldNotebook`, data);
};

const createSupply = (data) => {
  return httpGrow.post(`/supplyTypeFieldNotebook`, data);
};

const getSupplyDropdownList = () => {
  return httpGrow.get(`/supplyTypeFieldNotebook/dropdown`);
};

// const getCultureById = ({id}) => {
//   return httpGrow.get(
//     `/culture/{cuCultureId}?cuCultureId=${id}`

//   );
// };
// http://20.13.204.151/grow_bs/culture

// const getAllCultureData = (countryCode) => {
//   return httpGrow.get(`http://20.13.204.151/grow_bs/curingunit/allCuringUnits?countryCode=${countryCode}`);
// };

// const getMenuItems =async ()=>{

//    const response =await httpGrow.get('/culture/cultureNames-and-Id')

//    return response
// }
// const getMenuItemsById =async (cuCultureId)=>{

//    const response =await httpGrow.get(`/culture/{cuCultureId}?cuCultureId=${cuCultureId}`)

//    return response
// }

const SupplyTypeService = {
  getSupplyList,
  createSupply,
  updateSupply,
  searchSupplyType,
  searchSupplyTypeDescription,
  getSupplyDropdownList,
  // getMenuItemsById,
  // getCultureById
};

export default SupplyTypeService;
