import React, { useState, useEffect } from 'react';
import { Drawer, Box, List, ListItem, ListItemText, Button, Typography, Collapse, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuStructureVisulization from '../../services/MenuStructureVisulization';
import { ReactComponent as MenuArrowDown } from "assets/MenuArrowDown.svg";
import { ReactComponent as MenuArrowUp } from "assets/MenuArrowUp.svg";
import MenuArrowLeft from "assets/MenuArrowLeft.svg";
import FavoutiteStarLine from "assets/FavouriteStarLine.svg";
import FavoutiteSolidLine from "assets/FavouriteSolidLine.svg";
import StarFill from "assets/star-fill.svg";

const Sidebar = ({ open, onClose, sx = {} }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [expandedMenus, setExpandedMenus] = useState({});
  const [subMenus, setSubMenus] = useState({});
  
  // New state for favorited submenus
  const [favoritedSubMenus, setFavoritedSubMenus] = useState({});

  // Single search state
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch main menu items
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await MenuStructureVisulization.getMenu();
        if (response.data && response.data.response) {
          // Sort menu items by menuOrder
          const sortedMenuItems = response.data.response.sort((a, b) => a.menuOrder - b.menuOrder);
          setMenuItems(sortedMenuItems);
        }
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    fetchMenuItems();
  }, []);

  // Fetch sub-menus when a menu is selected
  useEffect(() => {
    const fetchSubMenus = async () => {
      if (selectedMenu) {
        try {
          const response = await MenuStructureVisulization.getSubMenu({ parentMenuId: selectedMenu.menuId });
          if (response.data && response.data.response) {
            setSubMenus({
              [selectedMenu.menuId]: response.data.response
            });
          }
        } catch (error) {
          console.error('Error fetching sub-menus:', error);
        }
      }
    };

    fetchSubMenus();
  }, [selectedMenu]);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const handleBackClick = () => {
    setSelectedMenu(null);
  };

  const handleSubMenuClick = (menuName) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  const toggleFavorite = (subMenuId) => {
    setFavoritedSubMenus(prev => ({
      ...prev,
      [subMenuId]: !prev[subMenuId]
    }));
  };

  // Modify search change handler
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter menu items based on search term
  const filteredMenuItems = menuItems.filter(menu => 
    menu.menuName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Filter sub-menus based on search term
  const getFilteredSubMenus = (parentMenu) => {
    const currentSubMenus = subMenus[parentMenu.menuId] || parentMenu.subMenus || [];
    return currentSubMenus.filter(subMenu => 
      subMenu.menuName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const renderMenu = (menu, level = 0) => {
    if (!menu) return null;
    return (
      <List component="div" disablePadding key={menu.menuId}>
        <ListItem 
          button 
          onClick={() => handleMenuClick(menu)} 
          sx={{ 
            pl: level * 2, 
            py: 1.5,
            mb: 0.5,
            '&.MuiListItem-root': {
              paddingRight: 0,
            }
          }}
        >
          {menu.menuIcon ? (
            <img 
              src={`${menu.menuIcon}${
                process.env.REACT_APP_SAS
                  ? `?${process.env.REACT_APP_SAS}`
                  : ""
              }`}
              alt={menu.menuName} 
              style={{ width: 24, height: 24, marginRight: 10 }} 
            />
          ) : (
            <SettingsIcon 
              sx={{ 
                width: 24, 
                height: 24, 
                marginRight: 1, 
                color: 'gray' 
              }} 
            />
          )}
          {level === 0 && !selectedMenu && <ListItemText primary={menu.menuName} />}
        </ListItem>
      </List>
    );
  };

  const renderSubMenu = (parentMenu, level = 1) => {
    const currentSubMenus = subMenus[parentMenu.menuId] || parentMenu.subMenus || [];
    
    const filteredSubMenus = getFilteredSubMenus(parentMenu);
    
    if (!currentSubMenus.length) return null;

    return (
      <List component="div" disablePadding key={parentMenu.menuId}>
        {filteredSubMenus.map((subMenu) => (
          <React.Fragment key={subMenu.menuId}>
            <ListItem 
              button 
              onClick={() => handleSubMenuClick(subMenu.menuName)} 
              sx={{ 
                pl: level * 2, 
                py: 1.5,
                mb: 0.5,
                '&.MuiListItem-root': {
                  paddingRight: 0,
                }
              }}
            >
              {subMenu.menuIcon ? (
                <img 
                  src={`${subMenu.menuIcon}${
                    process.env.REACT_APP_SAS
                      ? `?${process.env.REACT_APP_SAS}`
                      : ""
                  }`}
                  alt={subMenu.menuName} 
                  style={{ width: 20, height: 20, marginRight: 10 }} 
                />
              ) : (
                <img 
                  src={favoritedSubMenus[subMenu.menuId] ? FavoutiteSolidLine : FavoutiteStarLine} 
                  alt="Favorite Icon" 
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering parent click
                    toggleFavorite(subMenu.menuId);
                  }}
                  style={{ 
                    width: 20, 
                    height: 20, 
                    marginRight: 10,
                    cursor: 'pointer'
                  }} 
                />
              )}
              <ListItemText 
                primary={subMenu.menuName}
                primaryTypographyProps={{
                  sx: {
                    color: 'var(--BAT-dark-blue, var(--Primary-Blue, #0E2B63))',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px', // 171.429%
                    letterSpacing: '0.5px',
                  }
                }}
              />
            </ListItem>
            {(subMenu.subMenus && subMenu.subMenus.length > 0) && (
              <Collapse in={expandedMenus[subMenu.menuName]} timeout="auto" unmountOnExit>
                {renderSubMenu(subMenu, level + 1)}
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Drawer 
      anchor="left" 
      open={open} 
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'absolute',
          boxShadow: 'none',
          overflow: 'hidden',
          ...sx,
        },
      }}
    >
      {/* Single TextField at the top of the drawer */}
      <Box sx={{ 
        padding: 2, 
        backgroundColor: 'white'
      }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={selectedMenu ? "Search Submenus" : "Search Menus"}
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px',
              height: '40px',
              backgroundColor: 'white'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: selectedMenu ? 400 : 300,
          height: 'calc(100% - 64px)', // Subtract TextField height and padding
          maxHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: selectedMenu ? 50 : 250,
            padding: 2,
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '4px',
            },
          }}
        >
          <List>
            {filteredMenuItems.map((menu) => renderMenu(menu))}
          </List>
        </Box>
        <Collapse 
          in={Boolean(selectedMenu)} 
          orientation="horizontal" 
          timeout="auto" 
          unmountOnExit
          sx={{
            width: selectedMenu ? 350 : 0,
            visibility: selectedMenu ? 'visible' : 'hidden',
          }}
        >
          <Box
            sx={{
              width: 350,
              padding: 2,
              borderLeft: '1px solid #ccc',
              height: '100%',
              maxHeight: '100%',
              overflowY: 'auto', 
              overflowX: 'auto',
              backgroundColor: "#E9EBEE",
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '4px',
              },
            }}
          >
            {selectedMenu && (
              <>
                <Box sx={{ minWidth: '350px', display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img 
                    src={MenuArrowLeft} 
                    alt="Back" 
                    onClick={handleBackClick} 
                    style={{ 
                      cursor: 'pointer', 
                      width: 10, 
                      height: 10 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{
                      color: 'var(--Grey-6, #3B454F)',
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      minWidth: '350px',
                    }}
                  >
                    {selectedMenu?.menuName}
                  </Typography>
                </Box>
                
                <Box sx={{ minWidth: '350px' }}>
                  {renderSubMenu(selectedMenu)}
                </Box>
              </>
            )}
          </Box>
        </Collapse>
      </Box>
    </Drawer>
  );
};

export default Sidebar;