import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  ListItemText,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const MultiSelect = ({ 
  label, 
  options, 
  placeholder, 
  sx, 
  variant, 
  disabled,
  value = [],
  onChange,
}) => {
  console.log('MultiSelect render:', {
    label,
    options,
    value,
    disabled
  });

  const formattedOptions = options.map(opt => {
    if (typeof opt === 'string') {
      return { value: opt, label: opt };
    }
    return {
      value: opt.value || opt.id || opt,
      label: opt.label || opt.name || opt.toString()
    };
  });

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    
    if (Array.isArray(selectedValues)) {
      const lastSelected = selectedValues[selectedValues.length - 1];
      
      const isAlreadySelected = value.some(v => 
        v.value === (lastSelected?.value || lastSelected)
      );

      let newValue;
      if (isAlreadySelected) {
        newValue = value.filter(v => 
          v.value !== (lastSelected?.value || lastSelected)
        );
      } else {
        const optionToAdd = formattedOptions.find(opt => 
          opt.value === (lastSelected?.value || lastSelected)
        );
        if (optionToAdd) {
          newValue = [...value, optionToAdd];
        } else {
          newValue = [...value, lastSelected];
        }
      }

      onChange(newValue);
    }
  };

  return (
    <FormControl variant={variant} fullWidth sx={sx}>
      <InputLabel 
        id={`${label}-label`}
        shrink
        sx={{
          '&.Mui-focused': {
            color: 'gray',
          },
          padding: '0 8px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((val) => (
              <Chip
                key={val.value}
                label={val.label}
                size="small"
                onDelete={(e) => {
                  e.stopPropagation();
                  const newValue = value.filter(v => v.value !== val.value);
                  onChange(newValue);
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {formattedOptions.map((option) => (
          <MenuItem 
            key={option.value}
            value={option}
            sx={{
              borderLeft: value?.some(v => v.value === option.value) ? '4px solid #004F9F' : 'none',
              '&.Mui-selected': {
                borderLeft: '4px solid #004F9F',
              },
              '&.Mui-selected:hover': {
                borderLeft: '4px solid #004F9F',
              }
            }}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function MultiSelectInput({ 
    label, 
    options = [], 
    placeholder = 'Select options',
    variant = "outlined",
    disabled = false,
    value,
    onChange,
    sx 
}) {
  return (
    <div style={{ width: '100%'}}>
      <MultiSelect 
        label={label} 
        options={options} 
        placeholder={placeholder}
        variant={variant}
        disabled={disabled}
        value={value}
        onChange={onChange}
        sx={sx}
      />
    </div>
  );
}