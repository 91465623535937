import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FurnaceService from "services/FurnaceService";
import SoilParameterService from "services/SoilParameterService";
import TransporterGetService from "services/TransporterGetService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TransporterTable from "./TransporterTable";
import { Transcribe } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import search from "assets/search.svg";
import { useTranslation } from 'react-i18next';
import debounce from "lodash.debounce";

const GetTransporterRegistration = () => {
  const navigate = useNavigate();
  const [soilParameterData, setSoilParameterData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [filteredSoilParameterData, setFilteredSoilParameterData] = useState(
    []
  );
  const [filteredTransporterData, setFilteredTransporterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [Filtermenu, setFiltermenu] = useState([]);

  const [fieldTechnician,setfieldTechnician]=useState("");
  const [status,setStatus]=useState("");
  
  
  const [fieldTech, setFieldTech] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { t } = useTranslation();

  const handleSearchChange = (value) => {
    // callSearchTransporterAPI(value);
    debounceSearch(value);
    // setSearchKeyword(value);
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      callSearchTransporterAPI(value);
    }, 1000),
    []
  );
  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    //callSearchSoilParameterAPI(0, itemsPerPage, searchKeyword, isActive);
  };

  const getTransporterByID = (id)=>{
      TransporterGetService.getTransporterID(id)
      .then((res)=>{
        const transporterResults = res?.data?.response|| [];
        console.log("result ",transporterResults);
        setTransporterData([transporterResults]);
       })
       .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTransporterData([]);
        setFilteredTransporterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const callTransporterAPI = (pageNumber, size, fieldTech,status) => {
    setLoading(true);
    const queryString = [
      pageNumber !== undefined ? `pageNumber=${encodeURIComponent(pageNumber)}` : null,
      size !== undefined ? `size=${encodeURIComponent(size)}` : null,
      fieldTech ? `fieldTechnicianUserId=${encodeURIComponent(fieldTech)}` : null,
      status ? `status=${encodeURIComponent(status)}` : null,
      // transporterName ? `transporterName=${encodeURIComponent(transporterName)}` : null,
    ]
      .filter(Boolean) // Remove null values
      .join("&");

    TransporterGetService.getTransporter(queryString)
      .then((res) => {
        const transporterResults = res?.data?.response?.transportDetails || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        console.log("dsd", transporterResults);
        setTransporterData(transporterResults);
        setFilteredTransporterData(transporterResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTransporterData([]);
        setFilteredTransporterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullTransporterAPI = () => {
    setLoading(true);
    const queryString = [
      currentPage !== undefined ? `pageNumber=${encodeURIComponent(currentPage)}` : null,
      `size=1000`,
     
      // transporterName ? `transporterName=${encodeURIComponent(transporterName)}` : null,
    ]
      .filter(Boolean) // Remove null values
      .join("&");
    TransporterGetService.getTransporter(queryString)
      .then((res) => {
       
        const transporterResults = res?.data?.response?.transportDetails || [];
        console.log("result ",transporterResults);
        const transporterList = transporterResults.map((e) => e.transporterName || []);
        const fieldTechList = [...new Set(transporterResults.map((e) => e.fieldTechnicianUser||'')
          .filter((user) => user.trim() !== '') )];
        setMenu(transporterResults);
        // console.log("listttttt ",fieldTechList)
        setFieldTech(transporterResults)
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTransporterData([]);
        setFilteredTransporterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchTransporterAPI = (Keyword) => {
    setLoading(true);
    setSearchKeyword(Keyword);
   TransporterGetService.searchTransporter(Keyword)
      .then((res) => {
        const Results = res?.data?.response;
        const transporterResults = res?.data?.response || [];
        const transporterList = transporterResults.map((e) => e.transporterName || []);
        console.log("LIST ",transporterList);
        console.log("memuuu ",menu)
        
        setFiltermenu(transporterList);
        //setFieldTech(fieldTechList)

        console.log("search",Results);
        //setCurrentPage(pageNumber);
        //setItemsPerPage(size);
        setSoilParameterData(Results);
        setFilteredSoilParameterData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilParameterData([]);
        setFilteredSoilParameterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callTransporterAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullTransporterAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchTransporterAPI(searchKeyword);
    } else {
      callTransporterAPI(pageNumber - 1, itemsPerPage);
    }
  };
  // useEffect(() =>{
  //   if(searchKeyword)callSearchTransporterAPI(searchKeyword);
  // },[searchKeyword])


  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t('TransportationRegistration.sections.title')}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 2,
          paddingBottom: 3,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{t('TransportationRegistration.filters.filtersLabel')}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            //justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl sx={{ flex: 1, maxWidth: "20rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                console.log("neww",newValue); 
                if (newValue) {
                  console.log("nn",newValue);
                  getTransporterByID(newValue.transporterID)
                  //callTransporterAPI(newValue);
                  //setSearchKeyword(newValue);
                  //callSearchSoilParameterAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  //callSearchSoilParameterAPI(0, itemsPerPage, "");
                }
              }}
              // onInputChange={(event, newInputValue) => {
              //   if (newInputValue) handleSearchChange(newInputValue);
              //   //else getfullTransporterAPI();
              // }}
              options={menu}
              getOptionLabel={(option) => option.transporterName || ""}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TransportationRegistration.filters.transporterName')}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    style: { height: "3.125rem" },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, maxWidth: "20rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                if (newValue) {
                  setfieldTechnician(newValue.ftResponsibleUID)
                  callTransporterAPI(currentPage,itemsPerPage,newValue.ftResponsibleUID);
                  // setSearchKeyword(newValue);
                  // callSearchFurnaceAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  // callSearchFurnaceAPI(0, itemsPerPage, "");
                }
              }}
              // onInputChange={(event, newInputValue) => {
              //   if (newInputValue) handleSearchChange(newInputValue);
              //   //else getfullTransporterAPI();
              // }}
              // options={[...new Set(fieldTech.map((e) => e.fieldTechnicianUser||''))]}
              options={menu}
              getOptionLabel={(option) => option.fieldTechnicianUser || ""}
              // getOptionLabel={(option) => option.fieldTechnicianUserId || ""}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('TransportationRegistration.filters.fieldTechnician')}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="search" />
                      </InputAdornment>
                    ),
                    style: { height: "3.125rem" },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, maxWidth: "15rem", width: "100%" }}>
            <TextField
              select
              name="status"
              label="Status"
              value={status || ""}
              onChange={(event) =>{
                // console.log("nn",newValue);
                setStatus(event.target.value)
              
                callTransporterAPI(currentPage,itemsPerPage,fieldTechnician,event.target.value)
              }
                // handleStatusChange(event.target.value)
                }
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
            >
              <MenuItem value={status}>Select an option</MenuItem>
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Blocked">Blocked</MenuItem>
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            onClick={() => navigate("/addtransporterRegistration")}
          >
            {t('addNew')}
          </Button>
        </Box>
      </Box>
      <TransporterTable transporterData={transporterData} />
    </Layout>
  );
};

export default GetTransporterRegistration;
