import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Modal, Switch, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./BranchTable.css";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";
import { useTranslation } from "react-i18next";
import BankFieldTechnicanConfigService from "services/BankFieldTechnicanConfigService";

const BranchTable = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortedData, setSortedData] = useState(data);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmSwitchOpen, setConfirmSwitchOpen] = useState(false);
  const [currentSwitchId, setCurrentSwitchId] = useState(null);
  const [localData, setLocalData] = useState(data);
  const [currentSwitchRow, setCurrentSwitchRow] = useState(null);

  useEffect(() => {
    setLocalData(data);
  }, [data]);


  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setSortedData((prevData) =>
      [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const cellStyle = {
    flex: "1",
    textAlign: "left",
    padding: "8px"
  };

  const handleSwitchChange = (row) => {
    setCurrentSwitchId(row.bankBranchConfigId);
    setCurrentSwitchRow(row);
    
    if (row.useThisRecord) {
      // If currently YES and switching to NO, show confirmation modal
      setConfirmSwitchOpen(true);
    } else {
      // If currently NO and switching to YES, update immediately
      updateBranchRecord(row, true);
    }
  };

  const handleConfirmSwitchChange = () => {
    // Update when user confirms switching from YES to NO
    updateBranchRecord(currentSwitchRow, false);
    setConfirmSwitchOpen(false);
  };

  const updateBranchRecord = async (row, newValue) => {
    try {
      // Prepare the data for the API call with all existing fields
      const updateData = {
        ...row, // Spread the existing row data
        useThisRecord: newValue, // Override useThisRecord with new value
        modifiedDateTimeUTC: new Date().toISOString(), // Update modified timestamp
        modifiedByUser: "85201405" // Hardcoded user ID
      };

      // Call the API to update the branch record
      const response = await BankFieldTechnicanConfigService.updateBranch(updateData);

      // Update local state if API call is successful
      setLocalData(prevData =>
        prevData.map(item =>
          item.bankBranchConfigId === row.bankBranchConfigId
            ? { ...item, useThisRecord: newValue }
            : item
        )
      );

      // Show success message
      setOpenAlert(true);
      setSeverity("success");
      setMessage(response?.data?.response);
    } catch (error) {
      // Handle error
      console.error("Error updating branch record:", error);
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Error updating branch record");
    }
  };

  // Update sortedData to use localData
  useEffect(() => {
    setSortedData(localData);
  }, [localData]);

  return (
    <div className="table-container">
      <table className="branch-custom-table">
        <thead>
          <tr>
            <th style={cellStyle}>
              {t("bankConfiguration.useThisRecord")}
            </th>
            <th style={cellStyle} onClick={() => handleSort("branchNumber")}>
              {t("bankConfiguration.branchNo")}
              {getSortIcon("branchNumber")}
            </th>
            <th style={cellStyle} onClick={() => handleSort("branchName")}>
              {t("bankConfiguration.branchName")}
              {getSortIcon("branchName")}
            </th>
            <th style={cellStyle} onClick={() => handleSort("postalCode")}>
              {t("bankConfiguration.postalCode")}
              {getSortIcon("postalCode")}
            </th>
            <th style={cellStyle} onClick={() => handleSort("regionName")}>
              {t("bankConfiguration.region")}
              {getSortIcon("regionName")}
            </th>
            <th style={cellStyle} onClick={() => handleSort("cityName")}>
              {t("bankConfiguration.city")}
              {getSortIcon("cityName")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((row) => (
            <tr key={row.bankBranchConfigId}>
              <td style={cellStyle}>
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center",
                  gap: "6px"
                }}>
                  <Box>{t("no")}</Box>
                  <RedSwitch 
                    checked={row.useThisRecord} 
                    onChange={() => handleSwitchChange(row)}
                    color="primary"
                  />
                  <Box>{t("yes")}</Box>
                </Box>
              </td>
              <td style={cellStyle}>{row.branchNumber}</td>
              <td style={cellStyle}>{row.branchName}</td>
              <td style={cellStyle}>{row.postalCode}</td>
              <td style={cellStyle}>{row.regionName}</td>
              <td style={cellStyle}>{row.cityName}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={confirmSwitchOpen}
        onClose={() => setConfirmSwitchOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("alert")}
          </Box>
          <Box sx={{ p: 2, flex: 1 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("areYouSure")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              p: 2,
              marginTop: "auto",
            }}
          >
            <Button
              onClick={() => setConfirmSwitchOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={handleConfirmSwitchChange}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BranchTable;
