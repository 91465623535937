import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import FurnaceTable from "./FurnaceTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FurnaceService from "services/FurnaceService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const GetFurnaceTypeRegistration = () => {
  const navigate = useNavigate();
  const [furnaceData, setFurnaceData] = useState([]);
  const [filteredFurnaceData, setFilteredFurnaceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
  };

  const callFurnaceAPI = (pageNumber, size) => {
    setLoading(true);
    FurnaceService.getFurnace({ pageNumber, size })
      .then((res) => {
        const furnaceResults = res?.data?.response?.furnaceTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFurnaceData(furnaceResults);
        setFilteredFurnaceData(furnaceResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullFurnaceMenuAPI = () => {
    setLoading(true);
    FurnaceService.getFurnace({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const furnaceResults = res?.data?.response?.furnaceTypes || [];
        const menues = furnaceResults.map((e) => e.ftFurnaceTypeName || []);
        setMenu(menues);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchFurnaceAPI = (pageNumber, size, Keyword) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      ftFurnaceTypeName: Keyword,
    };

    const params = [];

    if (query?.pageNumber !== undefined) {
      params.push(`pageNumber=${query.pageNumber}`);
    }
    if (query?.size !== undefined) {
      params.push(`pageSize=${query.size}`);
    }
    if (query?.ftFurnaceTypeName) {
      params.push(`ftFurnaceTypeName=${query.ftFurnaceTypeName}`);
    }
    const queryString = params.length ? `?${params.join("&")}` : "";

    FurnaceService.searchFurnace(queryString)
      .then((res) => {
        const Results = res?.data?.response?.furnaceTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFurnaceData(Results);
        setFilteredFurnaceData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFurnaceData([]);
        setFilteredFurnaceData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callFurnaceAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullFurnaceMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchFurnaceAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callFurnaceAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Furnace Type Registration"}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 2,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl sx={{ flex: 1, maxWidth: "35rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                if (newValue) {
                  setSearchKeyword(newValue);
                  callSearchFurnaceAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  callSearchFurnaceAPI(0, itemsPerPage, "");
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) handleSearchChange(newInputValue);
                else getfullFurnaceMenuAPI();
              }}
              options={menu}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
              renderInput={(params) => (
                <TextField {...params} label="Furnace Type" fullWidth />
              )}
            />
          </FormControl>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
            }}
            onClick={() => navigate("/addfurnaceTypeRegistration")}
          >
            ADD NEW
          </Button>
        </Box>
      </Box>
      <FurnaceTable furnaceData={filteredFurnaceData} />
    </Layout>
  );
};

export default GetFurnaceTypeRegistration;
