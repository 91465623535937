// SoilAnalysisApplicationPhase.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import SoilAnalysisApplicationPhaseTable from "./SoilAnalysisApplicationPhaseTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SoilAnalysisApplicationPhaseService from "services/SoilAnalysisApplicationPhaseService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import label from "../growingLabel.json";
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const SoilAnalysisApplicationPhase = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { t } = useTranslation();

  // using debounce so that it will not call api on every key stroke
  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, value, statusFilter);
    }, 300),
    [itemsPerPage, statusFilter]
  );

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, searchKeyword, statusFilter);
    if (currentPage === 0) {
      fetchMenuData();
    }
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, [currentPage, itemsPerPage]);

  // Function to handle search input change
  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value) {
      debouncedSearch(value);
    } else {
      resetFilters();
    }
  };

  // Function to handle status change
  const handleStatusChange = (status) => {
    setStatusFilter(status);
    fetchData(0, itemsPerPage, searchKeyword, status);
  };

  // Function to reset filters
  const resetFilters = () => {
    setSearchKeyword("");
    setStatusFilter("");
    setCurrentPage(0);
    fetchData(0, itemsPerPage);
  };

  // Function to fetch data from API
  const fetchData = (pageNumber, size, keyword = "", status = "") => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword,
      status:
        status === "Active" ? true : status === "Inactive" ? false : undefined,
    };
    SoilAnalysisApplicationPhaseService.getAPI(query)
      .then((res) => {
        const results = res?.data?.response?.soilAnalysisApplications || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0) {
          setMessage(keyword ? t('noDataAvailable') : t('noDataAvailable'));
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to fetch menu data
  const fetchMenuData = () => {
    setLoading(true);
    SoilAnalysisApplicationPhaseService.getAPI({
      pageNumber: currentPage,
      size: 50,
    })
      .then((res) => {
        const menuItems =
          res?.data?.response?.soilAnalysisApplications?.map(
            (e) => e.applicationPhase
          ) || [];
        setMenu(menuItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to handle page change
  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    fetchData(pageNumber - 1, itemsPerPage);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t('soilAnalysisApplicationPhase.title')}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* // Search and Filter Section */}
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
        }}
      >
        {/* // DownArrowIcon and Filter Lable */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t('filtersLable')}
          </Typography>
        </Grid>

        {/* // Search Input Field */}
        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={searchKeyword}
              onChange={(event, newValue) => handleSearchChange(newValue)}
              onInputChange={(event, newInputValue) =>
                handleSearchChange(newInputValue)
              }
              options={menu}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label={t('soilAnalysisApplicationPhase.applicationPhase')} 
                  fullWidth 
                  placeholder="-" 
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* // Status Filter Dropdown */}
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              select
              label={t('status')}
              value={statusFilter}
              onChange={(event) => handleStatusChange(event.target.value)}
              placeholder={t('selectoption')}
              InputProps={{
                endAdornment: statusFilter && (
                  <CloseIcon
                    sx={{
                      cursor: 'pointer',
                      mr: 1.5,
                      fontSize: '25px',
                      color: 'rgba(0, 0, 0, 0.54)'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusChange('');
                    }}
                  />
                ),
              }}
            >
              <MenuItem value="">
                {t('selectoption')}
              </MenuItem>
              <MenuItem value="Active">{t('active')}</MenuItem>
              <MenuItem value="Inactive">{t('inactive')}</MenuItem>
            </TextField>
          </FormControl>
        </Grid>

        {/* // Empty Grid Item for Spacing */}
        <Grid item xs={4}></Grid>

        {/* // Add New Button */}
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <Button
            sx={{
              borderRadius: 1,
              minWidth: 95,
              minHeight: 44,
              background: "#004F9F",
              mb: 1,
              mr: 2,
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addSoilAnalysisApplicationPhase", { 
              state: { edit: true } 
            })}
          >
            {t('addNew')}
          </Button>
        </Grid>
      </Grid>


      {/* // Table Section */}
      {totalItems > 0 ? (
        <SoilAnalysisApplicationPhaseTable
          SoilAnalysisApplicationPhaseData={data}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}
        >
          <Typography variant="h6" color="textSecondary">
            {t('noDataAvailable')}
          </Typography>
        </Box>
      )}

    </Layout>
  );
};

export default SoilAnalysisApplicationPhase;
