import { httpGrow } from "../http-common";

const getAPI = async (query) => {
    const { cropYearId , ihID  } = query;
    const response = await httpGrow.get(`/agricultural-supplies-targets`, {
        params: {
            cropYearId,
            ihID 
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getAgriculturalSupplies = async () => {
    const response = await httpGrow.get('agricultural-supplies/search?pageNumber=0&size=50', {
        params: {
            target: true
        }
    });
    return response;
};

const postAgriculturalSuppliesTargets = (data) => {
    return httpGrow.post('agricultural-supplies-targets', data);
};


const AgriculturalSuppliesTargetsService = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getAgriculturalSupplies,
    postAgriculturalSuppliesTargets
};

export default AgriculturalSuppliesTargetsService;
