import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal, TextField, InputAdornment, FormControl, FormLabel, Autocomplete, MenuItem, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import eyeIcon from "assets/eyeIcon.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import "./PreAssessmentRMStatusTable.css";
import RedSwitch from "utils/RedSwitch";
import { upperCase } from "lodash";
import FarmersContractPreAssessmentService from "services/FarmersContractPreAssessmentService";
import SearchIconSvg from '../../../../assets/SearchIcon.svg';
import CloseIcon from "@mui/icons-material/Close";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import { preAssessmentRMSchema } from "utils/validations";




const PreAssessmentRMFarmerStatusTable = (props) => {

    const navigate = useNavigate();
    const [farmerData, setFarmerData] = useState([]);
    const [values, setValues] = useState({});
    const [originalData, setOriginalData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false); // Modal state
    const [deleteId, setDeleteId] = useState(null); // ID of the record to delete
    const { t } = useTranslation();
    const [codeSearch, setCodeSearch] = useState("")
    const [fiscalIdSearch, setFiscalIdSearch] = useState("")
    const [nameSearch, setNameSearch] = useState("")
    const [hierarchicalNode, setHierarchicalNode] = useState("");
    const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
    const [hierarchyOptions, setHierarchyOptions] = useState([]);
    const [status, setStatus] = useState("");
    console.log('originaldata', originalData);
    console.log('originaldata values', values);
    console.log('filterdata values', farmerData);



    useEffect(() => {
        setFarmerData(props.data);
        setOriginalData(props.data);
        console.log("Use effect called")
    }, [props.data]);

    useEffect(() => {
        setValues(props.values)
    }, [props.values]);


    useEffect(() => {
        fetchInitialData();
        // handleViewHistory();
    }, []);

    const handleClose = () => {
        setOpen(false); // Close the modal without deleting
    };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        const sortedData = [...farmerData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setFarmerData(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                return <ArrowUpwardIcon fontSize="small" />;
            } else if (sortConfig.direction === "desc") {
                return <ArrowDownwardIcon fontSize="small" />;
            }
        }
        return <ArrowDownwardIcon fontSize="small" />;
    };

    // const handleCodeSearch = (event) => {
    //     const keyword = event.target.value; // Keep it as a string for "contains" logic
    //     const filteredData = originalData.filter((item) =>
    //         item.capacity.toString().includes(keyword) // Convert capacity to string and check if it includes the keyword
    //     );
    //     console.log("code search", typeof keyword, "=>", filteredData);
    //     setFarmerData(filteredData);
    //     setCodeSearch(keyword); // Update the search keyword state
    // };

    // const handleFiscalIdSearch = (event) => {
    //     setFiscalIdSearch(event.target.value)
    // }
    // const handleNameSearch = (event) => {
    //     setNameSearch(event.target.value)
    // }

    console.log("Filters", codeSearch, nameSearch, fiscalIdSearch, status, hierarchicalNode)

    const handleSearch = (event, field, value = null) => {
        const searchValue = value ?? event.target.value;
        console.log("searchValue", searchValue)
        switch (field) {
            case "code":
                setCodeSearch(searchValue);
                break;
            case "name":
                setNameSearch(searchValue);
                break;
            case "fiscalId":
                setFiscalIdSearch(searchValue);
                break;
            case "status":
                setStatus(searchValue);
                break;
            case "hierarchicalNode":
                setHierarchicalNode(searchValue);
                break;
            default:
                break;
        }

        filterTable({
            code: field === "code" ? searchValue : codeSearch,
            name: field === "name" ? searchValue : nameSearch,
            fiscalId: field === "fiscalId" ? searchValue : fiscalIdSearch,
            status: field === "status" ? searchValue : status,
            hierarchicalNode: field === "hierarchicalNode" ? searchValue : hierarchicalNode,
        });
    };

    const filterTable = ({ code, name, fiscalId, status, hierarchicalNode }) => {
        const filteredData = originalData.filter((item) => {
            return (
                (!code || item.farmerCode.toString().includes(code)) &&
                (!name || (item.farmerFullName && item.farmerFullName.toLowerCase().includes(name.toLowerCase()))) &&
                (!fiscalId || (item.cpf && item.cpf.toString().includes(fiscalId))) &&
                // (!status || item.status === (status === "Active")) &&
                (!status || item.status === status) &&
                (!hierarchicalNode || item.ihLocationCode === hierarchicalNode)
            );
        });

        setFarmerData(filteredData);
    };



    const fetchInitialData = async () => {
        try {
            const [hierarchyResponse] = await Promise.all([
                // InvestmentGroupLimitsService.getCropSeasonAPI(),
                FarmersContractPreAssessmentService.getHierarchyNodeAPI()
            ]);

            // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
            const flattenHierarchy = (node, result = [], level = 0) => {
                result.push({
                    label: node.locationName,
                    value: node.id,
                    level,
                    tag: node.levelName,
                    parentId: node.parentId || "parentNode"
                });
                node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
                return result;
            };
            setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };
    const handleHierarchyChange = (event, newValue) => {
        if (newValue) {
            setHierarchicalNode(newValue.value);
            setHierarchicalNodeId(newValue.value);
            // Log for debugging
            console.log('Selected node:', newValue);
            console.log('Node level:', newValue.level);
        } else {
            setHierarchicalNode("");
            setHierarchicalNodeId("");
        }
    };

    const handleDeleteConfirm = () => {
        setOpen(false);
        navigate("/preAssessmentReleaseModuleCropSeasonList");
    };

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const [errors, setErrors] = useState({});

    const handleSubmit = async () => {
        try {

            const schemaValidationErrors = {};

            try {
                await preAssessmentRMSchema.validate(originalData, { abortEarly: false });
            } catch (err) {
                err?.inner?.forEach((error) => {
                    const [index, field] = error.path.split(".");
                    schemaValidationErrors[`${index}.${field}`] = error.message;
                });
            }
            console.log("errors", schemaValidationErrors)
            if (Object.keys(schemaValidationErrors).length > 0) {
                setErrors(schemaValidationErrors);
                // setMessage("Validation errors occurred");
                // setSeverity("error");
                // setOpenAlert(true);
                return;
            }
            setErrors(schemaValidationErrors)
            const currentDateTime = new Date().toISOString();
            const currentUser = "Luffy";
            const payload = {
                createdDateTimeUTC: values.currentDateTime || currentDateTime,
                createdByUser: values.currentUser || currentUser,
                modifiedDateTimeUTC: values.currentDateTime || currentDateTime,
                modifiedByUser: values.currentUser || currentUser,
                fpaFarmerPreAssmntId: values.fpaFarmerPreAssmntId,
                crsId: values.crsId,
                fpaIsEnableCropSeason: values.fpaIsEnableCropSeason,
                cropSeason: values.cropSeason,
                farmerPreAssesmentDetails: originalData.map((data) => ({
                    createdDateTimeUTC: data.createdDateTimeUTC || "",
                    createdByUser: data.createdByUser || "",
                    modifiedDateTimeUTC: data.modifiedDateTimeUTC || "",
                    modifiedByUser: data.modifiedByUser || "Jnana",
                    fpadFarmerPreAsseDetailsId: data.fpadFarmerPreAsseDetailsId || "",
                    farmerId: data.farmerId || "",
                    fpadIsBlocked: data.fpadIsBlocked,
                    fpadBlockReason: data.fpadBlockReason || "",
                    // fiscalIdCpf: data.fiscalIdCpf || "",
                    // hCode: data.hCode,
                    // hName: data.hName,
                    // status: data.status,
                }))
            }

            // Debug log
            console.log('Sending payload to API:', payload);

            // Make the API call
            if (props.isNew) {
                const response = await FarmersContractPreAssessmentService.postFPAPI(payload);
                if (response.status === 201) {
                    setMessage(t("PreAssessmentFormBuilder.updateSuccess"));
                    setSeverity("success");
                    setOpenAlert(true);
                    navigate("/preAssessmentReleaseModuleCropSeasonList");
                }
            }
            else {
                const response = await FarmersContractPreAssessmentService.putFPAPI(payload);
                if (response.status === 201) {
                    setMessage(t("PreAssessmentFormBuilder.updateSuccess"));
                    setSeverity("success");
                    setOpenAlert(true);
                    navigate("/preAssessmentReleaseModuleCropSeasonList");
                }
            }

        } catch (error) {
            console.error("Error saving data:", error);
            setMessage(error.message || "Error saving Farmer preassessment");
            setSeverity("error");
            setOpenAlert(true);
        } finally {
            // setLoading(false);
        }

    }

    const handleBlockChange = (farmerId, event) => {
        console.log("checked", event.target.checked)
        setOriginalData((prevData) =>
            prevData.map((item) =>
                item.farmerId === farmerId
                    ? { ...item, fpadIsBlocked: event.target.checked }
                    : item
            )
        );
        setFarmerData((prevData) =>
            prevData.map((item) =>
                item.farmerId === farmerId
                    ? { ...item, fpadIsBlocked: event.target.checked }
                    : item
            )
        );
    };

    const handleBlockReasonChange = (farmerId, event) => {
        setOriginalData((prevData) =>
            prevData.map((item) =>
                item.farmerId === farmerId
                    ? { ...item, fpadBlockReason: event.target.value }
                    : item
            )
        );
        setFarmerData((prevData) =>
            prevData.map((item) =>
                item.farmerId === farmerId
                    ? { ...item, fpadBlockReason: event.target.value }
                    : item
            )
        );
    };


    return (
        <div className="table-container">
            <table className="parm-table">
                <thead>
                    <tr>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            {t("farmersPreAssessment.preAssessment")}{" "}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("fpadBlockReason")}
                        >
                            {t("farmersPreAssessment.blockReason")}{" "}
                            {getSortIcon("fpadBlockReason")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("farmerCode")}
                        >
                            {t("farmersPreAssessment.code")}{" "}
                            {getSortIcon("farmerCode")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("cpf")}
                        >
                            {t("farmersPreAssessment.fiscalId")}{" "}
                            {getSortIcon("cpf")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("farmerFullName")}
                        >
                            {t("farmersPreAssessment.name")}{" "}
                            {getSortIcon("farmerFullName")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("ihLocationName")}
                        >
                            {t("farmersPreAssessment.hNode")}{" "}
                            {getSortIcon("ihLocationName")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("status")}
                        >
                            {t("status")}
                            {getSortIcon("status")}
                        </th>
                        <th className="actions-header">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    justifyContent: "left",
                                }}
                            >
                                {t("Actions")}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td> {/* Empty cell for column 1 */}
                        <td></td> {/* Empty cell for column 2 */}
                        <td>
                            <TextField
                                name="code"
                                label={t('farmersPreAssessment.code')}
                                value={codeSearch}
                                onChange={(e) => handleSearch(e, 'code')}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px",
                                        minHeight: "50px",
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em",
                                    },
                                    width: "100%",
                                }}
                                InputProps={{
                                    style: { height: "50px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIconSvg} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </td>
                        <td>
                            <TextField
                                name="fiscalId"
                                label={t('farmersPreAssessment.fiscalId')}
                                value={fiscalIdSearch}
                                onChange={(e) => handleSearch(e, 'fiscalId')}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px",
                                        minHeight: "50px",
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em",
                                    },
                                    width: "100%",
                                }}
                                InputProps={{
                                    style: { height: "50px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIconSvg} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </td>
                        <td>
                            <TextField
                                name="name"
                                label={t('farmersPreAssessment.name')}
                                value={nameSearch}
                                onChange={(e) => handleSearch(e, 'name')}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px",
                                        minHeight: "50px",
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em",
                                    },
                                    width: "100%",
                                }}
                                InputProps={{
                                    style: { height: "50px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIconSvg} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </td>
                        <td>
                            <TextField
                                name="hNode"
                                label={t('farmersPreAssessment.hNode')}
                                value={hierarchicalNode}
                                onChange={(e) => handleSearch(e, 'hierarchicalNode')}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "7px",
                                        minHeight: "50px",
                                    },
                                    "& .MuiInputLabel-root": {
                                        lineHeight: "1.2em",
                                    },
                                    width: "100%",
                                }}
                                InputProps={{
                                    style: { height: "50px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIconSvg} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {/* <FormControl fullWidth>
                                <Autocomplete
                                    value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
                                    // disableClearable={true}
                                    // onChange={handleHierarchyChange}
                                    onChange={(event, newValue) =>
                                        handleSearch(event, "hierarchicalNode", newValue ? newValue.value : "")
                                    }
                                    options={hierarchyOptions}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>
                                                <div style={{ fontSize: '16px' }}>
                                                    {option.label}
                                                </div>
                                                <div style={{ fontSize: '10px', color: '#5A646E' }}>
                                                    {option.tag}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={t('farmersPreAssessment.hNode')}
                                            fullWidth
                                            // placeholder='Select an Option'
                                            // required={true}
                                            InputLabelProps={{ shrink: true }}
                                            sx={{
                                                '& .MuiInputBase-input::placeholder': {
                                                    opacity: 1,
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={SearchIconSvg} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl> */}
                        </td>
                        <td>
                            <FormControl sx={{ maxWidth: "120px", minWidth: "120px" }}>
                                <TextField
                                    select
                                    name="status"
                                    label={t("status")}
                                    value={status}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) => handleSearch(event, "status")}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: "7px",
                                            minHeight: "56px",
                                        },
                                        "& .MuiInputLabel-root": {
                                            lineHeight: "1.3em",
                                        },
                                        // maxWidth: "100%",
                                    }}
                                    InputProps={{
                                        style: { height: "56px" },
                                        endAdornment: status && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    // onClick={() => setStatus("")}
                                                    onClick={() => handleSearch(null, "status", "")}
                                                    style={{ marginRight: "15px" }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    <MenuItem value="Active">{t("active")}</MenuItem>
                                    <MenuItem value="InActive">{t("inactive")}</MenuItem>
                                </TextField>
                            </FormControl>
                        </td>
                        <td></td> {/* Empty cell for actions */}
                    </tr>
                    {farmerData?.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <FormControl component="fieldset">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.4rem",
                                            ml: 0.5,
                                        }}
                                    >
                                        {/* "No" label on the left */}
                                        <Typography
                                            textTransform={"upperCase"}
                                            sx={{
                                                fontWeight: "500",
                                                color: "#5A646E",
                                            }}
                                        >
                                            {t("farmersPreAssessment.blocked")}
                                        </Typography>

                                        {/* Switch */}
                                        <RedSwitch
                                            sx={{
                                                marginRight: "11px",
                                            }}
                                            checked={row.fpadIsBlocked}
                                            onChange={(event) => handleBlockChange(row.farmerId, event)}
                                            name="isActive"
                                            color="primary"
                                            disabled={!props.edit}
                                        />

                                        {/* "Yes" label on the right */}
                                        <Typography
                                            textTransform={"upperCase"}
                                            sx={{
                                                fontWeight: "500",
                                                color: "#5A646E",
                                            }}
                                        >
                                            {t("farmersPreAssessment.ok")}
                                        </Typography>
                                    </Box>
                                </FormControl>
                            </td>
                            <td>
                                <input
                                    value={row.fpadBlockReason}
                                    type="text"
                                    // placeholder="Enter capacity"
                                    maxLength={250}
                                    placeholder={
                                        errors[`[${index}].fpadBlockReason`] ? t("farmersPreAssessment.reasonRequired") : ""
                                    }
                                    className={`table-input ${!props.edit ? "read-only" : ""} ${row.fpadIsBlocked ? "blocked-input" : ""
                                        } ${errors[`[${index}].fpadBlockReason`] ? "input-error" : ""
                                        }`}
                                    onChange={(event) => handleBlockReasonChange(row.farmerId, event)}
                                    disabled={row.fpadIsBlocked || !props.edit}
                                />
                                {/* {errors[`[${index}].fpadBlockReason`] && (
                                    <div className="error-message">
                                        {errors[`[${index}].fpadBlockReason`]}
                                    </div>
                                )} */}
                            </td>
                            <td>{row.farmerCode}</td>
                            <td>{row.cpf}</td>
                            <td>{row.farmerFullName}</td>
                            <td>{row.ihLocationName}</td>
                            <td>{row.status}</td>
                            <td className="actions-cell">
                                <img
                                    className="btn edit-btn"
                                    src={eyeIcon}
                                    alt="Edit Button"
                                    onClick={(e) => {
                                        navigate(`/editFarmerRegistration/${row.farmerId}`, {
                                            state: {
                                                details: true,
                                            },
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={3}
                mb={2.5}
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                    zIndex: 1000
                }}
            >
                {/* Cancel Button - Left bottom corner */}
                <Button
                    style={{
                        backgroundColor: "#737D86",
                        padding: "6px 16px",
                        borderRadius: "4px",
                    }}
                    variant="contained"
                    onClick={props.edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
                    startIcon={<img src={arrowLeft} alt="Arrow" />}
                >
                    {props.edit == false ? "Back" : "Cancel"}
                </Button>
                <Modal
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "7px",
                            width: "585px",
                            margin: "auto",
                            mt: 25,
                            height: "200px",
                        }}
                    >
                        <Box
                            sx={{
                                background: "#102A62",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderTopLeftRadius: "7px",
                                borderTopRightRadius: "7px",
                            }}
                        >
                            <Typography
                                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                            >
                                {t("cancelTitle")}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2, fontWeight: 400 }}
                            >
                                {t("cancelMessage")}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px",
                                mb: 1,
                            }}
                        >
                            <Button
                                onClick={handleCloseModal}
                                sx={{
                                    background: "#737D86",
                                    width: "100px",
                                    color: "#ffffff",
                                    "&:hover": { background: "#737D86" },
                                }}
                            >
                                {t("no")}
                            </Button>
                            <Button
                                onClick={handleDeleteConfirm}
                                sx={{
                                    background: "#004F9F",
                                    width: "100px",
                                    color: "#ffffff",
                                    "&:hover": { background: "#004F9F" },
                                }}
                            >
                                {t("yes")}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Button
                    sx={{
                        padding: "6px 16px",
                        borderRadius: "4px",
                        backgroundColor: "#EF7D00",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#EF7D00",
                        },
                    }}
                    startIcon={<img src={Save} alt="Right Arrow" />}
                    disabled={!props.edit}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    {"Save"}
                </Button>

            </Box>
        </div>
    );
};

export default PreAssessmentRMFarmerStatusTable