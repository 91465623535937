import {
  Typography,
  Box,
  Checkbox,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import React, { useEffect, useState, useContext, useCallback } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CropPhaseTable from "./CropPhaseTable";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import iconSearchs from "../../../../assets/iconSearchs.svg";
import { CropPhaseService } from "services/CropPhaseService";
import debounce from "lodash/debounce";

function GetCropPhaseRegistration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [CropPhaseData, setCropPhaseData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  // const callCropPhaseAPI = (pageNumber, size) => {
  //     setLoading(true);
  //     console.log(`Calling API with pageNumber: ${pageNumber}, size: ${size}`);
  //     CropPhaseService.getCropPhase({ pageNumber, size })
  //         .then((res) => {
  //             setCurrentPage(pageNumber);
  //             setItemsPerPage(size);
  //             setLoading(false);
  //             setCropPhaseData(res?.data.response.cropPhases);
  //             console.log("phases", res?.data.response.cropPhases);
  //             setTotalItems(res?.data?.response?.totalItems);
  //             setNoRecordFound(false);
  //         })
  //         .catch((error) => {
  //             setLoading(false);
  //             setOpenAlert(true);
  //           });
  // };
  // const paginate = (pageNumber, itemsPerPage) => {
  //     callCropPhaseAPI(pageNumber - 1, itemsPerPage);
  //   };

  const callCropPhaseAPI = (pageNumber, size) => {
    setLoading(true);
    console.log(`Calling API with pageNumber: ${pageNumber}, size: ${size}`);
    CropPhaseService.getCropPhase({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        const cropPhases = res?.data?.response?.cropPhases || [];
        setCropPhaseData(cropPhases);
        console.log("phases", cropPhases);
        setTotalItems(res?.data?.response?.totalItems || 0);
        setNoRecordFound(cropPhases.length === 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API call failed", error);
        setLoading(false);
        setMessage(error.response.data.message)
        setOpenAlert(true);
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCropPhaseAPI(pageNumber - 1, itemsPerPage);
  };

  // const callSearchCropPhaseAPI = (pageNumber, size, keyword) => {
  //     setLoading(true);
  //     console.log(`Calling search API with pageNumber: ${pageNumber}, size: ${size}, keyword: ${keyword}`);
  //     CropPhaseService.searchCropPhase({ pageNumber, size, keyword })
  //         .then((res) => {
  //             setCurrentPage(pageNumber);
  //             setItemsPerPage(size);
  //             setLoading(false);
  //             setCropPhaseData(res?.data.response.cropPhases);
  //             setTotalItems(res?.data?.response?.totalItems);
  //             console.log("phases", res?.data.response.cropPhases);
  //             // setTotalItems(res?.data?.response?.totalItems);
  //         })
  //         .catch((error) => {
  //             setLoading(false);
  //             console.error('Search API call error:', error);
  //             setOpenAlert(true);
  //             // setMessage(`Error: ${error.response?.status} ${error.response?.statusText}`);
  //             setSeverity('error');
  //         });
  // };

  const callSearchCropPhaseAPI = (pageNumber, size, keyword) => {
    setLoading(true);
    setCropPhaseData([]); // Clear old data when making a new API call
    console.log(
      `Calling search API with pageNumber: ${pageNumber}, size: ${size}, keyword: ${keyword}`
    );

    CropPhaseService.searchCropPhase({ pageNumber, size, keyword })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCropPhaseData(res?.data.response.cropPhases || []);
        setTotalItems(res?.data?.response?.totalItems || 0);
        console.log("phases", res?.data.response.cropPhases);
      })
      .catch((error) => {
        // console.error('Search API call error:', error);
        // setSeverity('error');
        if (error.response?.status === 404) {
          setNoRecordFound(true);
          setSeverity("error");
          setOpenAlert(true);
          setMessage(error.response.data.message);
        } else {
          setOpenAlert(true);
          setMessage(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      callSearchCropPhaseAPI(0, itemsPerPage, value);
    }, 300),
    [itemsPerPage]
  );

  useEffect(() => {
    if (searchKeyword) {
      callSearchCropPhaseAPI(currentPage, itemsPerPage, searchKeyword);
    }
    // else {
    //     callCropPhaseAPI(currentPage, itemsPerPage);
    // }
    callCropPhaseAPI(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  // }, [currentPage, itemsPerPage, searchKeyword]);

  return (
    <div>
      <Layout
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        paginate={paginate}
        currentPage={currentPage}
        title={"Crop Phase List "}
      >
        <Box mt={4} mb={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Grid item xs={4} md={4} lg={4} style={{ padding: "2px" }}>
              <TextField
                sx={{ width: "270px", height: "55px" }}
                name={"search"}
                className="farmerTextField"
                placeholder="Search"
                variant="outlined"
                value={searchKeyword}
                onChange={handleSearchChange}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={iconSearchs} />
                    </InputAdornment>
                  ),
                }}
              />
              {!searchKeyword && (
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px !important", marginTop: "5px" }}
                >
                  No filters applied
                </Typography>
              )}
            </Grid>
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 160,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() =>
                navigate("/addCropPhaseRegistration", {
                  state: {
                    description: undefined,
                    status: undefined,
                    edit: true,
                    details: undefined,
                  },
                })
              }
            >
              Add Phase
            </Button>
          </Box>
          {!loading && <CropPhaseTable CropPhaseData={CropPhaseData} />}
        </Box>
      </Layout>
    </div>
  );
}

export default GetCropPhaseRegistration;
