import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill's default theme styles

const MyEditor = (props) => {
  // Initial content with various formatting, lists, and links
  const initialValue = `
    <p>This is a Heading 1</p><h2>This is a Heading 2</h2><p><strong>This text is bold</strong></p><p><span style="color: rgb(255, 153, 0);">This is colored Text</span></p><p><em>This text is italic</em></p><p><u>This text is underlined</u></p><p><s>This text is struck through</s></p><p><br></p><ul><li>Unordered list item 1</li><li>Unordered list item 2</li></ul><ol><li>Ordered list item 1</li><li>Ordered list item 2</li></ol><p>Here is a <a href="https://example.com" rel="noopener noreferrer" target="_blank">link to example.com</a> Click to edit or visit.</p><p><br></p><blockquote>This is a blockquote</blockquote><p>Here's an image:</p><p><img src="https://t3.ftcdn.net/jpg/03/18/60/62/360_F_318606217_Hk8jo2MVoI33SQOkYrfOF929J7JgIP0P.jpg" alt="Placeholder"></p>
  `;

  const [editorValue, setEditorValue] = useState(props?.value || initialValue); // Set initial value

  // Full toolbar configuration with all available options
  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'size': [] }],
      [{ 'header': '1'}, { 'header': '2'}, { 'header': '3'}, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      ['link', 'image', 'video'],
      [{ 'color': [] }, { 'background': [] }],
      ['code-block'],
      ['clean'], // To clear formatting
    ],
  };

  const handleChange = (value) => {
    setEditorValue(value); // Update editor value
    props.handleEditorChange(value)
  };

  return (
    <div>
      <h2>Quill Full Toolbar with Initial Content</h2>
      <ReactQuill
        value={editorValue} // Controlled value with initial data
        onChange={handleChange} // Handle text change
        modules={modules} // Full toolbar configuration
        theme="snow" // Choose theme (snow is default)
        placeholder="Write something..." // Placeholder text
      />
      <div>
        <h3>Editor Output:</h3>
        <div>{editorValue}</div>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  );
};

export default MyEditor;
