import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Chip,
  Select,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState, useRef, useEffect, useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import FarmerSegmentationService from "services/FarmerSegmentationService";
import CancelIcon from "@mui/icons-material/Cancel";
import NewContractTable from "./NewContractTable";

const NewContract = () => {
  const [farmerSearch, setFarmerSearch] = useState("");

  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cultureType, setCultureType] = useState("");
  const [cultureTypeOptions, setCultureTypeOptions] = useState([]);
  const [cultureTypeId, setCultureTypeId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);

  const data = [
    {
      code: 12345,
      cluster: 1,
      name: "cluster 1",
      segmentation: "s1",
      variety: "CSC1",
      visits: 1,
      plants: "15 k plants",
      ha: "0,6 ha",
      weight: "1500 kg",
      locality: "Morro Baixo",
      ESGstatus:'Approved',
      commercialStatus:"Contracted",
      city:'Santa Cruz'
    },
    {
      code: 67890,
      cluster: 0,
      name: "farmer 3",
      segmentation: "s2",
      variety: "CSC2",
      visits: 2,
      plants: "5 k plants",
      ha: "1,6 ha",
      weight: "100 kg",
      locality: "Morro Alto",
      ESGstatus:'ESG Blocked',
      commercialStatus:"Contracted",
      city:'Santa Cruz'
    },
    {
      code: 109238,
      cluster: 0,
      name: "farmer 3",
      segmentation: "s3",
      variety: "CSC2",
      visits: 2,
      plants: "5 k plants",
      ha: "1,6 ha",
      weight: "100 kg",
      locality: "Morro Alto",
      ESGstatus:'Pre Assess. Not Applied',
      commercialStatus:"Inactive",
      city:'Santa Cruz'
    },
    {
      code: 745683,
      cluster: 1,
      name: "cluster 2",
      segmentation: "s4",
      variety: "CSC2",
      visits: 2,
      plants: "5 k plants",
      ha: "1,6 ha",
      weight: "100 kg",
      locality: "Morro Alto",
      ESGstatus:'Pending Approval',
      commercialStatus:"Not Contracted",
      city:'Santa Cruz'
    },
  ];
  const ESGStatusOptions = [
    "Approved",
    "ESG Blocked",
    "Pending Approval",
    "Pre Assess. Not Applied"
  ];
  const CommercialstatusOptions = [
    "Contracted",
    "Not Contracted",
    "Inactive",
  ];
  const [selectedCommercialStatuses, setCommercialStatuses] = useState([]);
  const [selectedESGStatuses, setESGStatuses] = useState([]);

  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleDelete = (statusToDelete) => {
    setCommercialStatuses((current) =>
      current.filter((status) => status !== statusToDelete)
    );
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCommercialStatuses(typeof value === "string" ? value.split(",") : value);
  };
  const handleESGDelete = (statusToDelete) => {
    setESGStatuses((current) =>
      current.filter((status) => status !== statusToDelete)
    );
  };
  const handleESGChange = (event) => {
    const {
      target: { value },
    } = event;
    setESGStatuses(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearchKeywordChange = (event) => {
    setFarmerSearch(event.target.value);
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      // fetchData({ farmerSearch: event.target.value });
    }, 500); // Debounce to prevent too many API calls
  };

  useEffect(() => {
    fetchCultureType();
    fetchCropSeason();
  }, []);

  const fetchCultureType = async () => {
    try {
      let response = await FarmerSegmentationService.getCultureType();

      setCultureTypeOptions(
        response.data.response.map((item) => ({
          label: item.ctDescription,
          value: item.ctDescription,
          cultureTypeId: item.ctCultureTypeId, // Assuming there's an id field
        }))
      );
      console.log("setCultureTypeOptions", setCultureTypeOptions);
    } catch (error) {
      console.error("Error fetching cultureTypeOptions data:", error);
    }
  };
  const fetchCropSeason = async () => {
    try {
      let response = await FarmerSegmentationService.getCropSeason();

      setCropSeasonOptions(
        response.data.response.map((item) => ({
          label: item.cropYear,
          value: item.cropYear,
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );
      console.log("setCropSeasonOptions", setCropSeasonOptions);
    } catch (error) {
      console.error("Error fetching cropSeasonOptions data:", error);
    }
  };
  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);

    //fetchData(pageNumber - 1, itemsPerPage);
  };

  return (
    <>
      <Layout
        title={t("New Contract-2024-Select Farmers")}
        background="#ffffff"
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        paginate={paginate}
        currentPage={currentPage}
        useNewPagination={true}
      >
        <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", p: 2 }}>
          <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography variant="h8" display="flex">
              <ArrowDropDownIcon />
              {t("Filters")}
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth size="medium">
                <TextField
                  label={t("Farmer Search")}
                  value={farmerSearch}
                  onChange={handleSearchKeywordChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" }, // Directly setting the height
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cultureTypeOptions.find(
                      (option) => option.value === cultureType
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCultureType(newValue ? newValue.value : "");
                    //  fetchData({ cultureType: newValue ? newValue.value : "" });
                  }}
                  options={cultureTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Culture Type")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  borderRadius: 1,
                  minWidth: 95,
                  height: 50,
                  background: "#004F9F",
                  "&:hover": {
                    backgroundColor: "#102A62",
                  },
                }}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => navigate("/addGroup")}
              >
                {t("addNew")}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <FormControl fullWidth size="medium">
                <InputLabel>Commercial Status</InputLabel>
                <Select
                  multiple
                  value={selectedCommercialStatuses}
                  onChange={handleChange}
                  input={<OutlinedInput label="Commercial Status" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap:1 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={() => handleDelete(value)}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {CommercialstatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="medium">
                <InputLabel> ESG Status</InputLabel>
                <Select
                  multiple
                  value={selectedESGStatuses}
                  onChange={handleESGChange}
                  input={<OutlinedInput label=" ESG Status" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={() => handleESGDelete(value)}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {ESGStatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    cropSeasonOptions.find(
                      (option) => option.value === cropSeason
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCropSeason(newValue ? newValue.value : "");
                    //fetchData({ cropSeason: newValue ? newValue.value : "" });
                  }}
                  options={cropSeasonOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Crop Season")}
                      fullWidth
                      placeholder="Select an Option"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          opacity: 1,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

       <NewContractTable data={data}/>
      </Layout>
    </>
  );
};
export default NewContract;
