import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import eyeIcon from "assets/eyeIcon.svg";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import "./FarmersContractPreAssessmentTable.css";


const FarmersContractPreAssessmentTable = (props) => {
    const navigate = useNavigate();
    const [curingData, setCuringData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false); // Modal state
    const [deleteId, setDeleteId] = useState(null); // ID of the record to delete
    const { t } = useTranslation();
    console.log("curingData", curingData)

    useEffect(() => {
        setCuringData(props.data);
        setOriginalData(props.data);
    }, [props.data]);


    const handleClose = () => {
        setOpen(false); // Close the modal without deleting
    };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        const sortedData = [...curingData].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setCuringData(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                return <ArrowUpwardIcon fontSize="small" />;
            } else if (sortConfig.direction === "desc") {
                return <ArrowDownwardIcon fontSize="small" />;
            }
        }
        return <ArrowDownwardIcon fontSize="small" />;
    };

    return (
        <div className="table-container">
            <table className="fcpa-table">
                <thead>
                    <tr>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("cropSeason")}
                        >
                            {t("investmentGroupLimits.csSearchField")}{" "}
                            {getSortIcon("cropSeason")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("totalFarmerAvailable")}
                        >
                            {t("farmersPreAssessment.quantityFarmersAvail")}{" "}
                            {getSortIcon("totalFarmerAvailable")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("totalFarmersAccepted")}
                        >
                            {t("farmersPreAssessment.quantityFarmersAccept")}{" "}
                            {getSortIcon("totalFarmersAccepted")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("totalFarmersBlocked")}
                        >
                            {t("farmersPreAssessment.quantityFarmersblock")}{" "}
                            {getSortIcon("totalFarmersBlocked")}
                        </th>
                        <th
                            style={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                            onClick={() => handleSort("status")}
                        >
                            {t("status")}
                            {getSortIcon("status")}
                        </th>
                        <th className="actions-header">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    justifyContent: "left",
                                }}
                            >
                                {t("Actions")}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {curingData?.map((row, index) => (
                        <tr key={index}>
                            <td>{row.cropSeason}</td>
                            <td>{row.totalFarmerAvailable}</td>
                            <td>{row.totalFarmersAccepted}</td>
                            <td>{row.totalFarmersBlocked}</td>
                            <td>{row.status ? "Active" : "Inactive"}</td>
                            <td className="actions-cell">
                                <Tooltip title={t("edit")} arrow>
                                    <img
                                        className="btn edit-btn"
                                        src={_ButtonEdit_}
                                        alt="Edit Button"
                                        onClick={() =>
                                            navigate("/preAssessmentReleaseModuleFarmerStatus", {
                                                state: { row: row, edit: true, cropSeason: row.cropSeason, crsId: row.crsId },
                                            })
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title={t("view")} arrow>
                                    <img
                                        className="btn edit-btn"
                                        src={eyeIcon}
                                        alt="Edit Button"
                                        onClick={(e) => {
                                            navigate(`/preAssessmentReleaseModuleFarmerStatus`, {
                                                state: { row: row, edit: false, cropSeason: row.cropSeason, crsId: row.crsId }
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default FarmersContractPreAssessmentTable