import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GeneticService from "services/GeneticService";
import SoilParameterService from "services/SoilParameterService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GeneticTable from "./GeneticTable";
import { useTranslation } from "react-i18next";

const GetGeneticRegistration = () => {
  const navigate = useNavigate();
  const [geneticData, setGeneticData] = useState([]);
  const [filteredGeneticData, setFilteredGeneticData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [desc, setDesc] = useState([]);
  const [culture, setCulture] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCulture, setSelectedCulture] = useState("");

  const { t, i18n } = useTranslation();

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
  };


  const callGeneticAPI = (pageNumber, size) => {
    setLoading(true);
    GeneticService.getGenetic({ pageNumber, size })
      .then((res) => {
        const geneticResults = res?.data?.response?.GeneticTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setGeneticData(geneticResults);
        setFilteredGeneticData(geneticResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setGeneticData([]);
        setFilteredGeneticData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullGeneticAPI = () => {
    setLoading(true);
    GeneticService.getGenetic({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const geneticResults = res?.data?.response?.GeneticTypes || [];
        const menues = geneticResults.map((e) => e.gtGeneticAbbreviation || []);
        setMenu(menues);
        const descriptionList = geneticResults.map(
          (e) => e.gtGeneticDescription || []
        );
        setDesc(descriptionList);
        const cultureList = geneticResults;
        setCulture(cultureList);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setGeneticData([]);
        setFilteredGeneticData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchGeneticAPI = (
    pageNumber,
    size,
    Abb = "",
    Desc = "",
    cul = "",
    status = ""
  ) => {
    setLoading(true);

    const params = [
      `pageNumber=${pageNumber}`,
      `size=${size}`,
      Abb && `gtGeneticAbbreviation=${Abb}`,
      Desc && `gtGeneticDescription=${Desc}`,
      cul && `cultureId=${cul}`,
      `isActive=${status}`,
    ]
      .filter(Boolean)
      .join("&");

    GeneticService.searchGenetic(`?${params}`)
      .then((res) => {
        const results = res?.data?.response?.GeneticTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setGeneticData(results);
        setFilteredGeneticData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setGeneticData([]);
        setFilteredGeneticData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callGeneticAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullGeneticAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchGeneticAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callGeneticAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("geneticTypeRegistration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 1.5,
          height: "122px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>

        <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  setAbbreviation(newValue || ""); // Update Abbreviation state
                  callSearchGeneticAPI(
                    0,
                    itemsPerPage,
                    newValue,
                    description,
                    selectedCulture,
                    statusFilter
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setAbbreviation("");
                    callSearchGeneticAPI(
                      0,
                      itemsPerPage,
                      "",
                      description,
                      selectedCulture,
                      statusFilter
                    );
                  }
                }}
                options={menu}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("geneticTypeRegistration.abbreviation")}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  setDescription(newValue || ""); // Update Description state
                  callSearchGeneticAPI(
                    0,
                    itemsPerPage,
                    abbreviation,
                    newValue,
                    selectedCulture,
                    statusFilter
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setDescription("");
                    callSearchGeneticAPI(
                      0,
                      itemsPerPage,
                      abbreviation,
                      "",
                      selectedCulture,
                      statusFilter
                    );
                  }
                }}
                options={desc}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("geneticTypeRegistration.description")}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  const cultureName = newValue?.cultureId || "";
                  setSelectedCulture(cultureName); // Update Culture state
                  callSearchGeneticAPI(
                    0,
                    itemsPerPage,
                    abbreviation,
                    description,
                    cultureName,
                    statusFilter
                  );
                }}
                options={culture}
                getOptionLabel={(option) => option.cultureName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("geneticTypeRegistration.culture")}
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth>
              <TextField
                select
                name="status"
                label={t("Status")}
                value={statusFilter || ""}
                onChange={(event, newValue) => {
                  const status = event.target.value;
                  console.log("soos", status);
                  setStatusFilter(status); // Update the status filter
                  setCurrentPage(0); // Reset to the first page when the status changes

                  const isActive =
                    status === "Active"
                      ? true
                      : status === "Inactive"
                      ? false
                      : null;
                  callSearchGeneticAPI(
                    0,
                    itemsPerPage,
                    abbreviation,
                    description,
                    selectedCulture,
                    isActive
                  );
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0.438rem",
                    minHeight: "3.5rem",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
              >
                <MenuItem value="pl">Select an option</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md="auto"
            sx={{ mt: { xs: 2, md: 0 }, ml: { md: 15 } }}
          >
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
              }}
              onClick={() => navigate("/addgeneticTypeRegistrationn")}
            >
              {t('addNew')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <GeneticTable geneticData={filteredGeneticData} />
    </Layout>
  );
};

export default GetGeneticRegistration;
