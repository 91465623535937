import { httpGrow } from "../http-common";

const getNationality = async () => {
  const response = await httpGrow.get(
    `/transporter/nationality-details`
  );
  return response;
};

const getmaritalStatus = async () => {
  const response = await httpGrow.get(
    `/transporter/marital-details`
  );
  return response;
};

const getRegions = async () => {
  const response = await httpGrow.get(
    `/transporter/regions`
  );
  return response;
};

const getBankDetails = async () => {
  const response = await httpGrow.get(
    `/banks`
  );
  return response;
};

const getBankBranchDetails = async (bankRegistrationId) => {
  const response = await httpGrow.get(
    `/banks/branches/${bankRegistrationId}`
  );
  return response;
};

const getTransporter = async (transporterID) => {
  const response = await httpGrow.get(
    `/transporter/${transporterID}`
  );
  return response;
}

const createTransporterRegistration = (data) => {
  return httpGrow.post(`/transporter`, data);
};

const updateTransporter = (data) => {
  return httpGrow.put(`/transporter/complete-transporter-registration?batId=123`, data);
}
const deleteVehicle =(id) =>{
  return httpGrow.delete(`transporter/delete-vehicle/${id}`);
}

const TransporterRegistrationService = {
  getNationality,
  getmaritalStatus,
  getRegions,
  getBankDetails,
  getBankBranchDetails,
  createTransporterRegistration,
  getTransporter,
  updateTransporter,
  deleteVehicle
};

export default TransporterRegistrationService;
