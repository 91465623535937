import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import FiltersGrid from "./FiltersGrid";
import Divider from "components/common/GrowingDivider";
import ProductionTargetsTable from './ProductionTargetsTable';
import ProductionTargetsService from "services/ProductionTargetsService";
import ConfirmationModal from './ConfirmationModal';
import SaveButton from "./SaveButton";
import { set } from "date-fns";
import iconSearch from "assets/iconSearchs.svg";

export default function ProductionTargets() {
  const [cropSeason, setCropSeason] = useState("");
  const [tobaccoType, setTobaccoType] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [tobaccoTypeOptions, setTobaccoTypeOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [productionTargets, setProductionTargets] = useState(null);
  const [showFiltersGrid, setShowFiltersGrid] = useState(false);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [tobaccoTypeId, setTobaccoTypeId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchInitialData();

    // Set up interval to log table data every 3 seconds
    const intervalId = setInterval(() => {
      if (productionTargets) {
        console.log('Production Targets Data (3s interval):', productionTargets);
      }
    }, 3000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [productionTargets]);

  // Remove or comment out this useEffect since we'll handle it in the new one
  // useEffect(() => {
  //   if (cropSeason && tobaccoType && hierarchicalNode) {
  //     fetchProductionTargets();
  //   }
  // }, [cropSeason, tobaccoType, hierarchicalNode]);

  // Add new useEffect to watch the IDs
  useEffect(() => {
    if (cropSeasonId && tobaccoTypeId && hierarchicalNodeId) {
      fetchProductionTargets();
    } else {
      setProductionTargets(null); // Clear the table data if any of the required IDs is missing
    }
  }, [cropSeasonId, tobaccoTypeId, hierarchicalNodeId]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, tobaccoTypeResponse, hierarchyResponse] = await Promise.all([
        ProductionTargetsService.getCropSeasonAPI(),
        ProductionTargetsService.getTobaccoTypeAPI(),
        ProductionTargetsService.getHierarchyNodeAPI()
      ]);

      setCropSeasonOptions(cropSeasonResponse.data.response.map(item => ({
        label: item.cropYear.toString(),
        value: item.cropYear.toString(),
        cropSeasonId: item.cropYearUid,// Assuming there's an id field      
      })));

      
      setTobaccoTypeOptions(tobaccoTypeResponse.data.response.map(item => ({
        label: `${item.cultureType} - ${item.tobaccoGroup}`,
        value: `${item.cultureType} - ${item.tobaccoGroup}`,
        tobaccoTypeId: item.id // Assuming there's an id field
      })));
      
      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({ label: node.locationName, value: node.id, level });
        node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchProductionTargets = async () => {
    try {
      const response = await ProductionTargetsService.getAPI({
        cropYearUUID: cropSeasonId,
        tobaccoTypeUUID: tobaccoTypeId,
        ihId: hierarchicalNodeId
      });
      
      console.log('Received data:', response.data.response);
      
      if (response.data.response) {
        setProductionTargets(response.data.response);
      }
    } catch (error) {
      console.error("Error fetching production targets:", error);
      setProductionTargets(null);
    }
  };

  const handleHierarchyChange = (event, newValue) => {
    setHierarchicalNode(newValue?.value || "");
    setShowFiltersGrid(newValue?.level === 0);
  };

  const handleViewHistory = async () => {
    // Check if productionTargets exists and has a productionTargetId
    if (!productionTargets || !productionTargets.productionTargetId) {
      console.error("No production target ID available");
      return;
    }

    try {
      const response = await ProductionTargetsService.getPTHistoryAPI(productionTargets.productionTargetId);
      setHistoryData(response.data.response);
      
      // Open the modal only when View History is clicked
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };
  
  const handleSaveProductionTargets = async (payload) => {
    try {
      // Log data before save
      console.log('Production Targets Data BEFORE save:', productionTargets);
      console.log('Sending payload to API:', payload);
      
      if (!payload.productionTargetId) {
        console.error('Missing productionTargetId in payload');
        return;
      }

      // Ensure we're using the direct input values for both productivityTarget and contractTarget
      const updatedPayload = {
        ...payload,
        productivityTarget: productionTargets.productivityTarget, // Keep original productivity input
        contractTarget: productionTargets.contractTarget // Keep original contract input
      };

      const response = await ProductionTargetsService.putPTAPI(updatedPayload);
      if (response) {
        const updatedData = await ProductionTargetsService.getAPI({
          cropYearUUID: cropSeasonId,
          tobaccoTypeUUID: tobaccoTypeId,
          ihId: hierarchicalNodeId
        });
        
        setProductionTargets(updatedData.data.response);
        console.log('Production Targets Data AFTER save:', updatedData.data.response);
      }
    } catch (error) {
      console.error("Error saving production targets:", error);
    }
  };

  const handleTargetsChange = (newTargets) => {
    // Only update specific fields, leaving table distribution untouched
    setProductionTargets(prev => {
      if (!prev) return prev;
      
      return {
        ...prev,
        // Only update these specific fields, do NOT modify targetDistributionDtoList
        contractTarget: newTargets.contractTarget,
        productivityTarget: newTargets.productivityTarget,
        hectaresTarget: newTargets.hectaresTarget
      };
    });
  };

  const renderProductionTargetsTable = () => {
    if (!cropSeasonId || !tobaccoTypeId || !hierarchicalNodeId) {
      return null;
    }

    return (
      <ProductionTargetsTable 
        data={productionTargets}
        cropYearUUID={cropSeasonId}
        tobaccoTypeUUID={tobaccoTypeId}
        ihId={hierarchicalNodeId}
        onSave={handleSaveProductionTargets}
      />
    );
  };

  return (
    <Layout
      title={"Production Targets"}
      background="#ffffff"
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {Lable.filtersLable}
          </Typography>
        </Grid>

        <Grid item xs={4} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCropSeason(newValue.value);
                  setCropSeasonId(newValue.cropSeasonId);
                } else {
                  setCropSeason("");
                  setCropSeasonId("");
                }
              }}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Crop Year" 
                  placeholder="Select an option"
                  InputProps={{
                    ...params.InputProps,
                    style: { color: 'black' }
                  }}
                  fullWidth 
                  size="medium"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      textAlign: 'left',
                      color: 'black',
                      opacity: 1
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth >
            <Autocomplete
              value={tobaccoTypeOptions.find(option => option.value === tobaccoType) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setTobaccoType(newValue.value);
                  setTobaccoTypeId(newValue.tobaccoTypeId);
                } else {
                  setTobaccoType("");
                  setTobaccoTypeId("");
                }
              }}
              options={tobaccoTypeOptions}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Tobacco Type" 
                  placeholder="Select an option"
                  InputProps={{
                    ...params.InputProps,
                    style: { color: 'black' }
                  }}
                  fullWidth 
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      minHeight: '45px',
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      textAlign: 'left',
                      color: 'black',
                      opacity: 1
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setHierarchicalNode(newValue.value);
                  setHierarchicalNodeId(newValue.value);
                  setShowFiltersGrid(newValue.level === 0);
                } else {
                  setHierarchicalNode("");
                  setHierarchicalNodeId("");
                  setShowFiltersGrid(false);
                }
              }}
              options={hierarchyOptions}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Hierarchical Node" 
                  placeholder="Select an option"
                  fullWidth 
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={iconSearch}
                          alt="search"
                          style={{
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      textAlign: 'left',
                      color: 'black',
                      opacity: 1
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {showFiltersGrid && (
        <FiltersGrid 
          onViewHistory={handleViewHistory} 
          historyData={historyData}
          initialContractTarget={productionTargets?.contractTarget}
          initialProductivityTarget={productionTargets?.productivityTarget}
          onTargetsChange={handleTargetsChange}
        />
      )}

      {cropSeasonId && tobaccoTypeId && hierarchicalNodeId &&
      <Divider title="Subordinates Hierarchies & Distribution Targets" />}

      <Typography variant="h8" style={{color:"white"}}>S</Typography>
      
      {renderProductionTargetsTable()}

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        historyData={historyData}
      />
      
    </Layout>
  );
}
