import { httpGrow } from "../http-common";

const getCulture = async (query) => {
    const response = await httpGrow.get(`/culture?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`)
    return response;
}

const searchCulture = async (query) => {
    const {
        pageNumber = 0,
        size = 50,
        cultureName,
        isActive
    } = query;

    const response = await httpGrow.get(`/culture/search`, {
        params: {
            pageNumber,
            size,
            cultureName: cultureName || undefined,
            isActive: isActive !== undefined ? isActive : undefined
        }
    });
    return response;
};

const createCulture = (data) => {
    return httpGrow.post(
        `/culture`, data
    );
};

const updateCulture = (data) => {
    return httpGrow.put(`/culture`, data);
};

const deleteCulture = (cultureId) => {
    return httpGrow.delete(`/culture/${cultureId}`);
  };

const CultureRegistrationService = {
    getCulture,
    searchCulture,
    deleteCulture,
    createCulture,
    updateCulture
}

export default CultureRegistrationService;