import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  Grid,
  Typography,
  Box,
  Modal,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CultureTypeRegistrationService from "services/CultureTypeRegistrationService";
import debounce from "lodash/debounce";
import LocalConfigButton from "assets/LocalConfigButton.svg";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from "react-i18next";
import SearchDropInput from "components/common/SearchDropInput";
import { useNavigate } from "react-router-dom";
import "./GrowingBaseTable.css";
import { useQuery } from '@tanstack/react-query';
import useCultureStore from './useCultureStore';
import { useCultureData } from './useCultureData';

const ListCultureTypeRegistration = () => {
  const { t } = useTranslation();
  const { user } = AuthData();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const {
    searchKeyword,
    currentPage,
    itemsPerPage,
    showUsedRecords,
    sortConfig,
    setSearchKeyword,
    setCurrentPage,
    setItemsPerPage,
    setShowUsedRecords,
    setSortConfig
  } = useCultureStore();

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  const { 
    culturesQuery, 
    deleteMutation,
    cultureOptionsQuery
  } = useCultureData({
    pageNumber: currentPage,
    size: itemsPerPage,
    cultureName: searchKeyword
  });

  const { data: { cultures = [], totalItems = 0 } = {}, isLoading } = culturesQuery;
  const { data: cultureOptions = [] } = cultureOptionsQuery;

  const handleSearchChange = (newValue) => {
    const keyword = typeof newValue === 'string' 
      ? newValue 
      : newValue?.value || null;
    setSearchKeyword(keyword);
    setCurrentPage(0);
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteMutation.mutateAsync(deleteId);
      setMessage(t("deletedSuccessfully"));
      setSeverity("success");
    } catch (error) {
      setMessage("Error deleting record");
      setSeverity("error");
    } finally {
      setOpenAlert(true);
      setOpen(false);
    }
  };

  const handleSort = useCallback((key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc"
        ? "desc"
        : "asc";
    setSortConfig({ key, direction });
  }, [sortConfig]);

  // Create a memoized sorted data array
  const sortedCultures = useMemo(() => {
    if (!cultures || !sortConfig.key) return cultures;

    return [...cultures].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue === bValue) return 0;
      
      const comparison = aValue < bValue ? -1 : 1;
      return sortConfig.direction === "asc" ? comparison : -comparison;
    });
  }, [cultures, sortConfig]);

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("cultureTypeRegistration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        {/* Filter and arrow */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>

        {/* Search input field */}
        <Grid item xs={5} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <SearchDropInput
              label={t("curingTypeRegistration.culture")}
              options={cultureOptions}
              value={cultureOptions.find(option => option.value === searchKeyword) || null}
              onChange={handleSearchChange}
              placeholder={t("selectoption")}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
            />
          </FormControl>
        </Grid>

        {/* Empty Grid for Space */}
        <Grid item xs={0.2}></Grid>
      </Grid>

      {/* Table Section */}
      <Grid 
        container 
        justifyContent="center" 
        alignItems="center" 
        style={{ minHeight: 'calc(100% - 200px)' }}
      >
        {isLoading ? (
          <Typography variant="h6" align="center">
            {t("loading")}
          </Typography>
        ) : (
          <>
            {cultures.length === 0 ? (
              <Typography 
                variant="h6" 
                align="center" 
                style={{ 
                  width: '100%', 
                  textAlign: 'center',
                  marginTop: '150px'
                }}
              >
                {t("cultureTypeRegistration.noDataAvailable")}
              </Typography>
            ) : showUsedRecords ? (
              sortedCultures?.filter((row) => row.useThisRecord).length > 0 ? (
                <TableCultureTypeRegistration
                  data={sortedCultures.filter((row) => row.useThisRecord)}
                  handleDeleteClick={handleDeleteClick}
                  handleSort={handleSort}
                  getSortIcon={getSortIcon("cuCultureName")}
                  navigate={navigate}
                />
              ) : (
                // Set alert if no used records found
                <>
                  {setMessage("cityConfiguration.noUsedRecordsFound")}
                  {setSeverity("error")}
                  {setOpenAlert(true)}
                  <Typography 
                    variant="h6" 
                    align="center" 
                    style={{ 
                      width: '100%', 
                      textAlign: 'center',
                      marginTop: '50px'
                    }}
                  >
                    {t("cultureTypeRegistration.noDataAvailable")}
                  </Typography>
                </>
              )
            ) : (
              totalItems > 0 && (
                <TableCultureTypeRegistration
                  data={sortedCultures}
                  handleDeleteClick={handleDeleteClick}
                  handleSort={handleSort}
                  getSortIcon={getSortIcon}
                  navigate={navigate}
                />
              )
            )}
          </>
        )}
      </Grid>

      {/* Delete Confirmation Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "400px",
            margin: "auto",
            mt: 25,
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{t("confirmDelete")}</Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            {t("deletedSuccessfully")}
          </Typography>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={() => setOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

const TableCultureTypeRegistration = ({
  data,
  handleDeleteClick,
  handleSort,
  getSortIcon,
  navigate,
}) => {
  const { t } = useTranslation();

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("cuCultureName")}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
              }}
            >
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}>
                {t("curingTypeRegistration.culture")}
                {getSortIcon("cuCultureName")}
              </div>
            </th>
            <th
              onClick={() => handleSort("cuCultureTypeAmount")}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
              }}
            >
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}>
                {t("cultureTypeRegistration.toct")}
                {getSortIcon("cuCultureTypeAmount")}
              </div>
            </th>
            <th className="actions-header">
              {t("cultureTypeRegistration.actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              onClick={() =>
                navigate("/formCultureTypeRegistration", {
                  state: { row, edit: false },
                })
              }
              style={{ cursor: "pointer" }}
            >
              <td>{row.cuCultureName}</td>
              <td>{row.cuCultureTypeAmount}</td>
              <td
                className="actions-cell"
                onClick={(e) => e.stopPropagation()}
              >
                <Tooltip title={t("cultureTypeRegistration.localConfig")} arrow>
                  <img
                    className="btn config-btn"
                    src={LocalConfigButton}
                    alt="Config"
                    onClick={() => {
                      useCultureStore.getState().setSelectedCultureName(row.cuCultureName);
                      useCultureStore.getState().setSelectedCultureId(row.cuCultureId);
                      localStorage.setItem("cuCultureId",row.cuCultureId)
                      localStorage.setItem("cuCultureName",row.cuCultureName)
                      navigate("/configCultureTypeRegistration");
                    }}
                  />
                </Tooltip>
                
                <Tooltip title={t("cultureTypeRegistration.edit")} arrow>
                  <img
                    className="btn edit-btn"
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={() => {
                      console.log('Clicked edit for culture:', row);
                      useCultureStore.getState().setSelectedCultureName(row.cuCultureName);
                      useCultureStore.getState().setCultureTypeId(row.cuCultureId);
                      console.log('Set culture type ID:', row.cuCultureId);
                      navigate("/formCultureTypeRegistration", {
                        state: { edit: true }
                      });
                    }}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListCultureTypeRegistration;