import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Autocomplete,
  Divider,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import _Minus_ from "assets/_Minus_.svg";
import _Plus_ from "assets/_Plus_.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import TransporterGetService from "services/TransporterGetService";
import { useTranslation } from "react-i18next";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import InvestmentGroupLimitsService from "services/InvestmentGroupLimitsService";
import RedSwitch from "utils/RedSwitch";
import DateRangePicker from "./dateRangePicker";
import PriceParameterizationService from "services/PriceParameterizationService";
import priceValidationSchema from "utils/validations";
import { usePriceParameterizationStore } from "./store/usePriceParameterizationStore";

export default function EditPriceParameterization() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    priceListId,
    prizeInvestmentId,
    externalCode,
    description,
    edificationType,
    unitOfMeasure,
    maxBankFinanceValue,
    maxFarmerFinanceValue,
    perSupplierDestination,
    igInvestmentGroupDescription,
    inInvestmentName,
    prizeParaList,
  } = location.state.row || {};

  const {getPriceParameterizationData,priceParameterizationData}=usePriceParameterizationStore()
  console.log("price para list",prizeParaList);
  console.log("list from zustand--",priceParameterizationData)
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    priceDefinedBy: "hierarchical node",
  });

  

  const today = new Date();


  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState([]);
  const [city, setCity] = useState([]);
  console.log("city is", city);
  const [open, setOpen] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const { t } = useTranslation();
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [showAllPricList,setShowAllPriceList] =useState(true)
  console.log("showall",showAllPricList);
  const [priceList, setPriceList] = useState([
    {
      id: Date.now(),
      priceDefinedBy: "",
      startDate: "",
      endDate: "",
      supplier: null,
      minimumQuantity: "",
      price:"",
      hierarchicalNode: true,
      cityId: "",
      city: false,
    },
  ]);
  console.log("hierarchyOptionsData", hierarchyOptions);
  console.log("priceListing", priceList);

  const filterPriceList=priceList.length && priceList.filter((item)=>{
    const itemEndDate = new Date(item.endDate);
     return itemEndDate > today;
  })

  console.log("filterPriceList---",filterPriceList)

  const handleAddPrice = () => {
    setPriceList([
      ...priceList,
      {
        id: Date.now(), // Use a unique identifier like timestamp
        priceDefinedBy: "hierarchical node", // Default value
        startDate: "",
        endDate: "",
        supplier: null,
        minimumQuantity:"",
        price: "",
        hierarchicalNode: true, // Default selection
        city: false, // Default unselected
      },
    ]);
  };

  const handleDelete = async (priceId, priceListId) => {
    console.log("temp",priceId,typeof priceId);

    if (typeof priceId == 'number') {

      // If there's no priceListId, remove the row from the frontend only
      setPriceList(priceList.filter((price) => price.id !== priceId));
      // setMessage("Price List Details Deleted Successfully!");
      // setSeverity("success");
      //setOpenAlert(true);
      return;
    }
   
  
    // If there's a priceListId, proceed with backend deletion
    try {
      setLoading(true);
      const response = await PriceParameterizationService.deletePriceList(priceId);
      if (response.status === 200) {
        // setMessage(response.data.response);
        // setSeverity("success");
        //setOpenAlert(true);
         setPriceList(priceList.filter((price) => price.id !== priceId));
         //fetchPriceListData();
        //setTempPrizeParaList(tempPrizeParaList.filter((price) => price.id !== priceId));
      } else {
        setMessage("Failed to delete Price List");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting Price List");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting Price List:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  // const fetchPriceListData = async () => {
  //   try {
  //     const response = await PriceParameterizationService.GetPriceParameterizationList({pageNumber:0,size:5});
  //     console.log("Response from getPriceList:", response.data.response.priceParametrizationDetails,location.state.row);
  
  //     if (response.status === 200) {
        
  //       const result=response?.data?.response?.priceParametrizationDetails?.find(item=>item.prizeInvestmentId===prizeInvestmentId)
  //       console.log('result----',result)
  //       setPriceList(result?.prizeParaList); // Update the state with the latest data
  //     } else {
  //       console.error("Failed to fetch price list:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching price list data:", error);
  //     console.log("errorr hai ",error);
  //   }
  // };
 
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/priceParameterizationList");
  };
  const getCityList = async () => {
    try {
      const response = await TransporterGetService.getCityDetails();
      const d = response.data.response;
      // Store full city objects instead of just names
      setCity(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };
  console.log("errrrrrrrrrr", errors);
  const getSupplierList = async () => {
    try {
      const response = await PriceParameterizationService.getSupplierDetails();
      const d = response.data.response;
      // Store full city objects instead of just names
      setSupplier(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };
  console.log("supp", supplier);
  const fetchInitialData = async () => {
    try {
      const hierarchyResponse =
        await InvestmentGroupLimitsService.getHierarchyNodeAPI();
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
          label: node.locationName,
          value: node.id,
          level,
          tag: node.levelName,
          parentId: node.parentId || "parentNode",
        });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  console.log("price list new--", priceList);

  const handleChange = (id, field, value) => {
    setPriceList((prevPriceList) =>
      prevPriceList.map((item) => {
        if (item.id === id) {
          if (field === "hierarchicalNode") {
            return {
              ...item,
              hierarchicalNode: value,
              city: !value, // If hierarchicalNode is true, city should be false
            };
          } else if (field === "city") {
            return {
              ...item,
              city: value,
              hierarchicalNode: !value, // If city is true, hierarchicalNode should be false
            };
          } else {
            return { ...item, [field]: value }; // For other fields
          }
        }
        return item; // Return unchanged item for other IDs
      })
    );
  };

  const handleUpdate = async (event) => {
    console.log("Clicked");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validate the data
    try {
      await priceValidationSchema.validate(
        { priceList },
        { abortEarly: false }
      );
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          schemaValidationErrors[err.path] = err.message;
        });
      }
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const createEditPayload = (prizeInvestmentId, prizeParaList) => {
        return {
          prizeInvestmentId,
          prizeParaList: prizeParaList.map((item) => ({
            createdDateTimeUTC: new Date().toISOString(),
            createdByUser: "string", // Change this to actual user data
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: "string", // Change this to actual user data
            ...(typeof item.id === "string" && { priceListId: item.id }),
            startDate: item.startDate || "",
            endDate: item.endDate || "",
            suppliesRegistrationId: item.supplier.suppliesRegistrationId || "",
            minimumQuantity: item.minimumQuantity || 0,
            price: item.price || 0,
            priceDefinedBy: true,
            importHierarchyId: item?.priceDefinedBy?.value || "",
            cityId: item?.priceDefinedBy?.cityId || "",
          })),
        };
      };

      const payload = createEditPayload(prizeInvestmentId, priceList);
      console.log("Payload is:--", payload);
      try {
        setLoading(true);
        const response =
          await PriceParameterizationService.updatePriceParameter({
            ...payload,
          });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          navigate("/priceParameterizationList");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    } else {
      console.error("Validation errors:", schemaValidationErrors);
      setErrors(schemaValidationErrors); // Set errors to state for rendering in the form
      // setSeverity("error");
      // setMessage("Please check your inputs.");
      // setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchInitialData();
    getCityList();
    getSupplierList();
    getPriceParameterizationData(prizeInvestmentId)
    //fetchPriceListData();
  }, []);

  useEffect(() => {
    // Transform prizeParaList to match priceList structure
    const initialPriceList = priceParameterizationData.map((item) => ({
      id: item.priceListId,
      priceDefinedBy: item.cityId
        ? city.find((c) => c.cityId === item.cityId)
        : hierarchyOptions.find((c) => c.value === item.importHierarchyId),
      startDate: item.startDate,
      endDate: item.endDate,
      supplier: supplier.find(
        (item2) => item2.suppliesRegistrationId === item.suppliesRegistrationId
      ),
      minimumQuantity: item.minimumQuantity,
      price: item.price,
      hierarchicalNode: item.importHierarchyId ? true : false,
      city: item.cityId ? true : false,
    }));
    console.log("initialPriceList", initialPriceList);
    if (supplier.length > 0 && hierarchyOptions.length > 0 && city.length > 0)
      setPriceList(initialPriceList);
  }, [supplier, hierarchyOptions, city]);


  const resultPriceList=showAllPricList?priceList :filterPriceList || [];

  console.log("resultPriceList---",resultPriceList)

  return (
    <Layout
      title={t("priceParameterization.editPageTitle")}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: "176px",
              //  height: hasTransporterDataErrors() ? "500px" : "440px",
              //   padding: "10px",
              //   borderRadius: "5px",
              //  transition: "height 200ms ease-in-out",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("priceParameterization.baseInformation")}
              </Typography>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("externalCode")}
                    InputLabelProps={{ shrink: true }}
                    value={externalCode}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE !important",
                        "& fieldset": {
                          borderColor: "#BDBDBD", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#BDBDBD !important", // Override hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#BDBDBD !important", // Override focus border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("description")}
                    value={description}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="transporterName"
                    label={t("priceParameterization.investmentType")}
                    InputLabelProps={{ shrink: true }}
                    value={inInvestmentName}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.transporterName} // Show error state
                    helperText={errors.transporterName || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.transporterName ? "20px" : "0px",
                        opacity: errors.transporterName ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.transporterName ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("priceParameterization.edificationType")}
                    InputLabelProps={{ shrink: true }}
                    value={edificationType}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("priceParameterization.investmentGroups")}
                    InputLabelProps={{ shrink: true }}
                    value={igInvestmentGroupDescription}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="nationalityId"
                    label={t("priceParameterization.unitOfMeasure")}
                    InputLabelProps={{ shrink: true }}
                    value={unitOfMeasure}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.nationalityId}
                    helperText={errors.nationalityId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.nationalityId ? "20px" : "0px",
                        opacity: errors.nationalityId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.nationalityId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {nationalities.map((nationality) => (
                      <MenuItem
                        key={nationality.nationalityId}
                        value={nationality.nationalityId}
                      >
                        {nationality.nationality}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="maritalStatusId"
                    label={t("priceParameterization.maxBankFinancingValue")}
                    InputLabelProps={{ shrink: true }}
                    value={maxBankFinanceValue}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.maritalStatusId}
                    helperText={errors.maritalStatusId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.maritalStatusId ? "20px" : "0px",
                        opacity: errors.maritalStatusId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.maritalStatusId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {maritalStatuses.map((status) => (
                      <MenuItem
                        key={status.maritalStatusId}
                        value={status.maritalStatusId}
                      >
                        {status.maritalStatus}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="regionId"
                    label={t("priceParameterization.maxFarmerFinancingValue")}
                    InputLabelProps={{ shrink: true }}
                    value={maxFarmerFinanceValue}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    required
                    error={!!errors.regionId}
                    helperText={errors.regionId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.regionId ? "20px" : "0px",
                        opacity: errors.regionId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.regionId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {regions.map((region) => (
                      <MenuItem key={region.regionId} value={region.regionId}>
                        {region.stateCode}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="stateRegistrationNumber"
                    label={t("priceParameterization.supplierDestination")}
                    InputLabelProps={{ shrink: true }}
                    placeholder="-"
                    value={perSupplierDestination}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.stateRegistrationNumber} // Show error state
                    helperText={errors.stateRegistrationNumber || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.stateRegistrationNumber ? "20px" : "0px",
                        opacity: errors.stateRegistrationNumber ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.stateRegistrationNumber
                        ? "20px"
                        : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ paddingBottom: "100px" }}>
            <Box sx={{ width: "100%" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px 2px",
                  background: "#FFFFFF",
                  marginTop: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#5A646E",
                    marginLeft: "10px",
                  }}
                >
                  {t("priceParameterization.priceList")}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: "6px" }}>
                    {t("priceParameterization.showAll")}
                  </Box>
                  <RedSwitch
                    color="primary"
                    name="cclStatus"
                    disabled={!edit}
                    //checked={c?.cclStatus}
                    onChange={()=>setShowAllPriceList(!showAllPricList)}
                  />
                  <Box sx={{ ml: "6px" }}>
                    {t("priceParameterization.hideOldRecords")}
                  </Box>
                
                  <Button
                    variant="contained"
                    onClick={handleAddPrice}
                    disabled={!edit}
                    sx={{
                      minWidth: 95,
                      borderRadius: 1,
                      background: "#004F9F",
                      height: { xs: "40px", md: "44px" },
                      marginLeft: { xs: 0, md: "auto" },
                      marginRight: "10px",
                    }}
                  >
                    {t("TransportationRegistration.buttons.addNew")}
                  </Button>
                </div>
              </Box> */}

<Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 2px",
    background: "#FFFFFF",
    marginTop: 3,
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#5A646E",
      marginLeft: "10px",
    }}
  >
    {t("priceParameterization.priceList")}
  </Typography>
  <div style={{ display: "flex", alignItems: "center" }}>
    <Box sx={{ mr: "6px" }}>{t("priceParameterization.showAll")}</Box>
    <RedSwitch
      color="primary"
      name="cclStatus"
      disabled={!edit}
      onChange={() => setShowAllPriceList(!showAllPricList)}
    />
    <Box sx={{ ml: "6px" }}>{t("priceParameterization.hideOldRecords")}</Box>

    {/* Add a Box to create space between the text and the button */}
    <Box sx={{ width: "20px" }} />

    <Button
      variant="contained"
      onClick={handleAddPrice}
      disabled={!edit}
      sx={{
        minWidth: 95,
        borderRadius: 1,
        background: "#004F9F",
        height: { xs: "40px", md: "44px" },
        marginRight: "10px",
      }}
    >
      {t("TransportationRegistration.buttons.addNew")}
    </Button>
  </div>
</Box>


              <Box
                id="divider"
                sx={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px 20px 0px",
                  gap: "20px",
                  background: "#FFFFFF",
                  borderBottom: "5px solid #004F9F",
                }}
              ></Box>
            </Box>
            {resultPriceList.map((price, index) => (
              <Box
                key={price.id}
                sx={{
                  minHeight: "10%",
                  marginTop: 5,
                  flexGrow: 1,
                  paddingBottom: "0.5rem",
                  width: "100%",
                  "& .MuiTextField-root": { m: 1 },
                  "& .MuiFormLabel-root": {
                    color: "rgba(16, 42, 98, 1)",
                    "& .MuiFormLabel-asterisk": {
                      color: "rgba(239, 0, 0, 1)",
                      fontSize: "18px",
                    },
                  },
                }}
              >
                <form autoComplete="off" noValidate>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      bgcolor: "#EFEFEF",
                      height: "120px",
                      marginTop: "-25px",
                      borderRadius: "5px",
                      paddingRight: "10px",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6} md={1.9}>
                        <FormControl fullWidth >
                          <DateRangePicker
                            id={price.id}
                            onSelectDateRange={handleChange}
                            start={price.startDate}
                            end={price.endDate}
                            edit={edit}
                            error={!!errors[`priceList[${index}].startDate`]} // Show error state
                            helperText={
                              errors[`priceList[${index}].startDate`] || " "
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={2.1}>
                        <FormControl
                          fullWidth
                          error={
                            !!errors[
                              `priceList[${index}].supplier.suppliesRegistrationId`
                            ]
                          }
                        >
                          <Autocomplete
                            value={price?.supplier || null}
                            disabled={edit == false}
                            onChange={(event, newValue) => {
                              handleChange(price.id, "supplier", newValue);
                            }}
                            options={supplier}
                            getOptionLabel={(option) =>
                              option.supplierCompanyName || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.suppliesRegistrationId ===
                              value?.suppliesRegistrationId
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("priceParameterization.supplier")}
                                fullWidth
                                disabled={edit == false}
                                error={!!errors[`priceList[${index}].supplier`]} // Show error state
                                helperText={
                                  errors[`priceList[${index}].supplier`] || " "
                                }
                                sx={{  "& .MuiOutlinedInput-root": {
                                  padding: "7px",
                                  minHeight: "50px",
                                },}}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                key={option.suppliesRegistrationId}
                              >
                                {option.supplierCompanyName}
                              </li>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={1.9}>
                        <FormControl fullWidth>
                          <TextField
                            name="minimumQuantity"
                            label={t("priceParameterization.minimumQuantity")}
                            //InputLabelProps={{ shrink: true }}
                            value={price?.minimumQuantity}
                            //type="number"
                            //inputProps={{ min: 0 }}
                            onChange={(e) =>
                              handleChange(
                                price.id,
                                "minimumQuantity",
                                e.target.value
                              )
                            }
                            disabled={edit == false}
                            fullWidth
                            // error={!!errors.transporterName} // Show error state
                            // helperText={errors.transporterName || ""} // Show error message
                            error={
                              !!errors[`priceList[${index}].minimumQuantity`]
                            } // Show error state
                            helperText={
                              errors[`priceList[${index}].minimumQuantity`] ||
                              " "
                            } // Show error message
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                                // bgcolor: "#E9EBEE",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              style: { height: "50px" },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={1} sx={{marginBottom:"7px"}}>
                        <FormControl fullWidth>
                          <TextField
                            name="price"
                            label={t("priceParameterization.price")}
                            value={price?.price}

                            onChange={(e) =>
                              handleChange(price.id, "price", e.target.value)
                            }
                            disabled={edit == false}
                            fullWidth
                            error={!!errors[`priceList[${index}].price`]} // Show error state
                            helperText={
                              errors[`priceList[${index}].price`] || " "
                            }
                            sx={{
                              "& .MuiFormHelperText-root": {
                                whiteSpace:"nowrap",
                                fontSize: "13px !important", 
                               // marginRight:"15px !important"                      
                              },
                              "& .MuiOutlinedInput-root": {
                                padding: "7px",
                                minHeight: "50px",
                                //bgcolor: "#E9EBEE",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                              //   "& fieldset": {
                              //     borderColor: "#BDBDBD", // Default border color
                              //   },
                              //   "&:hover fieldset": {
                              //     borderColor: "#BDBDBD !important", // Override hover border color
                              //   },
                              //   "& .MuiFormHelperText-root": {
                              //     margin: 0,
                              //     height: errors.fiscalID ? "20px" : "0px",
                              //     opacity: errors.fiscalID ? 1 : 0,
                              //     transition: "all 200ms",
                              //   },
                              //   marginBottom: errors.fiscalID ? "20px" : "0px",
                              //   transition: "margin 200ms",
                            }}
                            InputProps={{
                              style: { height: "50px" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2.5}>
                        <FormControl component="fieldset" sx={{marginBottom:"17px"}}>
                          <FormLabel
                            component="legend"
                            disabled={edit == false}
                            sx={{
                              marginLeft: 2.5,
                              //marginBottom:"10px",
                              "&.Mui-focused": { color: "inherit" },
                              "&:hover": { color: "inherit" },
                            }}
                          >
                            {t("priceParameterization.priceDefinedBy")}
                          </FormLabel>
                          <RadioGroup
                            sx={{
                              marginLeft: 2.5,
                              //marginBottom:"30px",
                              display: "flex",
                              flexDirection: "row", // Ensures horizontal layout
                            }}
                            value={
                              priceList.find((item) => item.hierarchicalNode)
                                ?.id || ""
                            }
                          >
                            <FormControlLabel
                              value={price.id}
                              disabled={edit == false}
                              onChange={() =>
                                handleChange(price.id, "hierarchicalNode", true)
                              }
                              name="hierarchicalNode"
                              control={<Radio />}
                              label={t(
                                "priceParameterization.hierarchicalNode"
                              )}
                              sx={{
                                display: "flex", // Ensures each label is aligned horizontally
                                alignItems: "center", // Ensures items are vertically aligned
                                marginRight: 0, // Optional: Adds space between labels
                              }}
                              checked={price.hierarchicalNode === true}
                            />
                            <FormControlLabel
                              value={price.id}
                              disabled={edit == false}
                              onChange={() =>
                                handleChange(price.id, "city", true)
                              }
                              name="city"
                              control={<Radio />}
                              label={t("priceParameterization.city")}
                              sx={{
                                display: "flex", // Ensures each label is aligned horizontally
                                alignItems: "center", // Ensures items are vertically aligned
                                marginRight: 0, // Optional: Removes extra margin on the right
                                //marginLeft:"-30px",
                              }}
                              checked={price.city === true}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {price.hierarchicalNode && (
                        <Grid item xs={12} sm={6} md={2.5}>
                          <FormControl fullWidth sx={{marginBottom:"20px"}}>
                            <Autocomplete
                              value={price?.priceDefinedBy?.label || ""}
                              disabled={edit == false}
                              disableClearable={true}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  console.log(
                                    "NewValue is for hier :--",
                                    newValue
                                  );
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            ["priceDefinedBy"]: newValue,
                                          }
                                        : item
                                    )
                                  );
                                } else {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? { ...item, ["priceDefinedBy"]: "" }
                                        : item
                                    )
                                  );
                                }
                              }}
                              options={hierarchyOptions}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <div>
                                    <div style={{ fontSize: "16px" }}>
                                      {option.label}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "10px",
                                        color: "#5A646E",
                                      }}
                                    >
                                      {option.tag}
                                    </div>
                                  </div>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  name="hierarchicalNode"
                                  {...params}
                                  label={t("Hierarchical Node")}
                                  fullWidth
                                  //helperText={errors || ""}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    "& .MuiInputBase-input::placeholder": {
                                      opacity: 1,
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: { height: "50px" },
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      {price.city && (
                        <Grid item xs={12} sm={6} md={2.5}>
                          <FormControl fullWidth sx={{marginBottom:"20px"}}>
                            <Autocomplete
                              value={price?.priceDefinedBy?.cityName || ""}
                              disabled={edit == false}
                              disableClearable
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  console.log("NewValue is :--", newValue);
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            ["priceDefinedBy"]: newValue,
                                          }
                                        : item
                                    )
                                  );
                                } else {
                                  setPriceList((prevPriceList) =>
                                    prevPriceList.map((item) =>
                                      item.id === price.id
                                        ? {
                                            ...item,
                                            priceDefinedBy: {
                                              ...price.priceDefinedBy,
                                              cityName: "",
                                            },
                                          }
                                        : item
                                    )
                                  );
                                }
                              }}
                              //options={city.map((item) => item.cityName)} // Use cityName for options
                              options={city}
                              // renderOption={(props, option) => (
                              //   <li {...props}>
                              //     <div>
                              //       <div style={{ fontSize: "16px" }}>
                              //         {option.cityName}
                              //       </div>
                              //       <div
                              //         style={{
                              //           fontSize: "10px",
                              //           color: "#5A646E",
                              //         }}
                              //       >
                              //         {option.cityName}
                              //       </div>
                              //     </div>
                              //   </li>
                              // )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("City")}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  // helperText={
                                  //   errors[`priceList[${index}].price`] || " "
                                  // }
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "0 14px", // Add padding for consistent height
                                      minHeight: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                    "& input": {
                                      padding: "0 14px", // Add padding inside the input
                                    },
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { height: "100%" }, // Ensure the input fills the available height
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <div style={{ fontSize: "16px" }}>
                                    {option.cityName}
                                  </div>
                                </li>
                              )}
                              sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                  padding: "0 !important", // Prevent extra padding in the input
                                  minHeight: "50px", // Ensure consistent height
                                },
                              }}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>

                    {/* <img
                      src={_ButtonDelete_}
                      disabled={edit == false}
                      alt="Delete"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => handleDeletePrice(price.id)}
                    /> */}
                    <Tooltip title="DELETE" placement="top" arrow>
                    <img
                      src={_ButtonDelete_}
                      alt="Delete"
                      style={{
                        cursor: edit ? "pointer" : "default", // Use default cursor when disabled
                        marginRight: 5,
                        marginBottom:25,
                        pointerEvents: edit ? "auto" : "none", // Disable click interaction
                      }}
                      onClick={() => handleDelete(price.id,price.priceListId)} // This will only work when edit is true
                    />
                    </Tooltip>
                  </Box>
                </form>
              </Box>
            ))}
            <Divider
              sx={{
                position: "fixed",
                bottom: "82px",
                width: "97%",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "fixed",
                width: "96%",
                bottom: 6,
                padding: "0.5rem",
                background: "white",
                zIndex: 999,
              }}
            >
              {/* Cancel Button - Left bottom corner */}
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  height: "44px",
                  marginLeft: "10px",
                }}
                variant="contained"
                onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
                disabled={loading}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
              >
                {loading && <CircularProgress size={20} />}
                {edit == false ? t("back") : t("cancel")}
              </Button>

              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "585px",
                    margin: "auto",
                    mt: 25,
                    height: "200px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("cancelTitle")}
                    </Typography>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, fontWeight: 400 }}
                    >
                      {t("cancelMessage")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      onClick={handleDeleteConfirm}
                      sx={{
                        background: "#004F9F",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              {/* Save Button - Right bottom corner */}
              {edit != false && (
                <Button
                  sx={{
                    padding: "6px 16px",
                    borderRadius: "4px",
                    marginBottom: "16px",
                    backgroundColor: "#EF7D00",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#EF7D00",
                    },
                    marginRight: "10px",
                  }}
                  disabled={loading}
                  startIcon={<img src={Save} alt="Right Arrow" />}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  {loading && <CircularProgress size={20} />}
                  {t("save")}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
