import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import saveIcon from "assets/saveIcon.svg";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import SpacingServices from "services/SpacingServices";
import ExpandMoreIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import RedSwitch from "utils/RedSwitch";
import ContractTypeService from "services/ContractTypeService";
import { contractTypeSchema } from "utils/validations";

const addNewSpacingField = (t) => [
  {
    name: "lineSpacing",
    label: t("spacingRegistration.lineSpacing"),
    type: "text",
    required: true,
    disabled: false,
    width: "500px",
    helperText: t("validation.spacingHelper"),
  },
  {
    name: "status",
    label: t("spacingRegistration.status"),
    type: "switch",
    sx: { pt: 0 },
    disabled: false,
  },
];

const editSpacingField = (t) => [
  {
    name: "lineSpacing",
    label: t("spacingRegistration.lineSpacing"),
    type: "text",
    width: "500px",
    required: true,
    disabled: true,
  },
  {
    name: "status",
    label: t("spacingRegistration.status"),
    type: "switch",
    sx: { pt: 0 },
    disabled: false,
  },
];

const viewSpacingField = (t) => [
  {
    name: "lineSpacing",
    label: t("spacingRegistration.lineSpacing"),
    type: "text",
    width: "500px",
    required: true,
    disabled: true,
  },
  {
    name: "status",
    label: t("spacingRegistration.status"),
    type: "switch",
    sx: { pt: 0 },
    disabled: true,
  },
];

const numberInputStyles = {
  "& input[type=number]": {
    "-moz-appearance": "textfield", // Firefox
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
};

const AddContract = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = AuthData();
  const [open, setOpen] = useState(false);
  const { edit, spaceRegistrationUid, details, viewOnly } =
    location.state || {};

  const [contactType, setContactType] = useState();
  console.log("🚀 ~ AddContract ~ contactType:", contactType);

  const [config, setConfig] = useState({
    isLandsMandatory: false,
    isCuringUnitsEnabled: false,
    isCropInputRequestEnabled: false,
    isSelfFundingEnabled: false,
    isCostingEnabled: false,
    isInvestmentsEnabled: false,
    isThirdPartySuppliesEnabled: false,
    isPlotsEnabled: false,
    isTechnologyPlanningEnabled: false,
    isEstimatesEnabled: false,
    isLossesEnabled: false,
    isCompetitorEstimateEnabled: false,
  });
  console.log("🚀 ~ AddContract ~ confissssg:", config);

  const labelMapping = {
    isLandsMandatory: "Lands",
    isCuringUnitsEnabled: "Curing Units",
    isCropInputRequestEnabled: "Crop Input Request",
    isSelfFundingEnabled: "Self Funding",
    isCostingEnabled: "Costing",
    isInvestmentsEnabled: "Investments",
    isThirdPartySuppliesEnabled: "Third Party Supplies",
    isPlotsEnabled: "Plots",
    isTechnologyPlanningEnabled: "Technology Planning",
    isEstimatesEnabled: "Estimates",
    isLossesEnabled: "Losses",
    isCompetitorEstimateEnabled: "Competitor Estimate",
  };

  const handleToggles = (key) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [key]: !prevConfig[key],
    }));
  };

  const isDisabled = location.state ? viewOnly || !edit : false;

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  console.log("location?.state?.row", location?.state?.row);
  const [errors, setErrors] = useState({});
  console.log("🚀 ~ AddContract ~ errors:", errors);
  const [dateError, setDateError] = useState(false);
  const [spacingField, setSpacingField] = useState(
    location.state?.edit
      ? editSpacingField(t)
      : location.state?.viewOnly
      ? viewSpacingField(t)
      : addNewSpacingField(t)
  );
  console.log("🚀 ~ AddContract ~ spacingField:", spacingField);
  const [geneticType, setGeneticType] = useState([]);
  const [unitOfMeasure, setUnitOfMeasure] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const handleToggle = (event) => {
    setIsActive(event.target.checked);
  };

  const fetchGeneticType = async () => {
    SpacingServices.geneticType()
      .then((response) => {
        const geneticData = response.data.response
          .filter((item) => !item.isDelete)
          .map((item) => ({
            value: item.gtGeneticTypeId,
            label: `${item.gtGeneticAbbreviation} - ${item.gtGeneticDescription}`,
          }));

        setGeneticType(geneticData);

        // Update spacing field with the new options
        setSpacingField((prevFields) => {
          return prevFields.map((field) => {
            if (field.name === "geneticType") {
              return { ...field, options: geneticData };
            }
            return field;
          });
        });
      })
      .catch((error) => console.error("Error fetching genetic types:", error));
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate(-1);
  };

  const fetchUnitOfMeasure = async () => {
    SpacingServices.getUnitMeasurment()
      .then((response) => {
        const unitData = response.data.response.map((item) => ({
          value: item.unitOfMeasurementUid,
          label: `${item.description}`,
        }));
        setUnitOfMeasure(unitData);

        // Update spacing field with the new options
        setSpacingField((prevFields) => {
          return prevFields.map((field) => {
            if (field.name === "unitOfMeasure") {
              return { ...field, options: unitData };
            }
            return field;
          });
        });
      })
      .catch((error) =>
        console.error("Error fetching unit of measure:", error)
      );
  };

  useEffect(() => {
    if (location.state?.row) {
      const row = location.state.row;
      setConfig({
        isLandsMandatory: row.isLandsMandatory,
        isCuringUnitsEnabled: row.isCuringUnitsEnabled,
        isCropInputRequestEnabled: row.isCropInputRequestEnabled,
        isSelfFundingEnabled: row.isSelfFundingEnabled,
        isCostingEnabled: row.isCostingEnabled,
        isInvestmentsEnabled: row.isInvestmentsEnabled,
        isThirdPartySuppliesEnabled: row.isThirdPartySuppliesEnabled,
        isPlotsEnabled: row.isPlotsEnabled,
        isTechnologyPlanningEnabled: row.isTechnologyPlanningEnabled,
        isEstimatesEnabled: row.isEstimatesEnabled,
        isLossesEnabled: row.isLossesEnabled,
        isCompetitorEstimateEnabled: row.isCompetitorEstimateEnabled,
      });
      setContactType(row.contractType);
      setIsActive(row.isActive);
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Special handling for line spacing and plant spacing
    if (name === "lineSpacing" || name === "plantSpacing") {
      // Simply update the value
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));

      // Check if value exceeds reasonable length or decimal places
      const decimalPart = value.split(".")[1];
      const digitsWithoutDecimal = value.replace(".", "");

      let errorMessage = "";

      if (digitsWithoutDecimal.length > 10) {
        errorMessage = t("spacingRegistration.validation.tooManyDigits");
      } else if (decimalPart && decimalPart.length > 2) {
        errorMessage = t("spacingRegistration.validation.tooManyDecimalPlaces");
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));

      return;
    }

    // Default handling for other fields
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Validate `contactType` before making the API request
      await contractTypeSchema.validate({ contactType }, { abortEarly: false });

      const payload = {
        createdDateTimeUTC: new Date(),
        createdByUser: "adminUser",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "adminUser",
        contractType: contactType,
        isActive: isActive,
        ...config,
      };

      const response = await ContractTypeService.create(payload);

      if (response.status === 201) {
        navigate("/contractType");
        setSeverity("success");
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newErrors = {};
        err.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      } else {
        console.error("Error while submitting form:", err);
        if (err.response?.status === 409) {
          setSeverity("error");
          setMessage(
            err.response.data.message || "Contract Type already exists"
          );
        } else {
          setSeverity("error");
          setMessage("An error occurred while submitting the form");
        }
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      // Validate `contactType` before making the API request
      await contractTypeSchema.validate({ contactType }, { abortEarly: false });

      const payload = {
        createdDateTimeUTC: new Date(),
        createdByUser: "adminUser",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "adminUser",
        contractTypeId: location?.state?.row?.contractTypeId,
        contractType: contactType,
        isActive: isActive,
        ...config,
      };

      const response = await ContractTypeService.update(payload);

      if (response.status === 200) {
        navigate("/contractType");
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("snackbarMessage.updateFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newErrors = {};
        err.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors); // Set validation errors to display in the form
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout background={"#ffffff"} title="Contract Type Registration - Details">
      <Grid
        container
        sx={{
          minHeight: 380,
          width: "100%",
          "& .MuiTextField-root": {
            width: "100%",
            m: 0,
            color: "green !important",
          },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
            "& .Mui-focused": {
              color: "red !important",
            },
          },
          ...numberInputStyles,
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (location.state?.edit) {
              handleUpdate(e);
            } else {
              handleSubmit(e);
            }
          }}
          style={{ width: "100%" }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              backgroundColor: "#EFEFEF",
              height: "380px",
              borderRadius: "8px",
              padding: "16px",
              margin: 0,
            }}
          >
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <ExpandMoreIcon sx={{ color: "#000", marginLeft: "8px" }} />
                {t("form")}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ width: "100%", mt: 0 }}>
              <Grid item xs={12} md={8.5}>
                <TextField
                  fullWidth
                  label="Contract Type"
                  disabled={viewOnly}
                  value={contactType}
                  onChange={(e) => setContactType(e.target.value)}
                  error={Boolean(errors.contactType)}
                  helperText={errors.contactType || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} md={2} sx={{ position: "relative", ml: "130px" }}>
                <FormControl component="fieldset" sx={{ paddingTop: 1 }}>
                  <FormLabel
                    sx={{
                      color: "#5A646E !important",
                      mb: 1.0,
                      ml:"-120px",
                      fontWeight: "500",
                      // fontSize:"1.125rem !important",
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                    }}
                  >
                     {t("status ")}
                  <Typography component="span" color="error" fontWeight="600">
                    *
                  </Typography>
                 
                 
                  </FormLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginRight: 8 }}
                          >
                            Inactive
                          </Typography>
                          <RedSwitch
                            disabled={viewOnly}
                            checked={isActive}
                            onChange={handleToggle}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginLeft: 8 }}
                          >
                            Active
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              sx={{
                background: "white",
                padding: "5px",
                width: "100%",
                borderRadius: "5px",
                fontWeight: 600,
                fontSize: "15px",
                marginTop: "10px",
                color: "#5A646E",
                marginBottom: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>ENABLED/DISABLED FUNCTIONALITIES</Box>
            </Box>

            <Grid container spacing={2}>
              {Object.keys(config).map((key) => (
                <Grid item xs={12} sm={6} md={2} key={key}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box width="100%">
                      <Typography variant="subtitle1" gutterBottom>
                        {labelMapping[key]}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginRight: 8 }}
                      >
                        {key === "isLandsMandatory" ? "Optional" : "Disabled"}
                      </Typography>
                      <RedSwitch
                        disabled={viewOnly}
                        checked={config[key]}
                        onChange={() => handleToggles(key)}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginLeft: 8 }}
                      >
                        {key === "isLandsMandatory" ? "Mandatory" : "Enabled"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                position: "fixed",
                borderTop: "1px solid #E9EBEE",
                bottom: 30,
                padding: "5px",
                left: 0,
                right: 0,
                px: 2,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: " 6px, 16px, 6px, 16px",
                  borderRadius: " 4px",
                }}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
                variant="contained"
                type="button"
                onClick={viewOnly ? () => navigate(-1) : handleOpenModal}
              >
                {t("cancel")}
              </Button>

              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "585px",
                    margin: "auto",
                    mt: 25,
                    height: "200px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("cancelTitle")}
                    </Typography>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, fontWeight: 400 }}
                    >
                      {t("cancelMessage")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      onClick={handleDeleteConfirm}
                      sx={{
                        background: "#004F9F",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </Box>
                </Box>
              </Modal>
              {!location.state?.viewOnly && (
                <Button
                  sx={{
                    backgroundColor: "#EF7D00",
                    padding: " 6px, 16px, 6px, 11px",
                    borderRadius: " 4px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#EF7D00",
                    },
                  }}
                  disabled={details}
                  startIcon={<img src={saveIcon} alt="save icon" />}
                  type="submit"
                  variant="contained"
                >
                  {t("save")}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
};

const GridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", // Adjusts dynamically
  gap: "16px", // Space between switches
});

export default AddContract;
