import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import AgronomicLossTypeTable from "./AgronomicLossTypeTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AgronomicLossTypeService from "services/AgronomicLossTypeService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const GetAgronomicLossType = () => {
  const navigate = useNavigate();
  const [filALTData, setfilALTData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [status, setStatus] = useState(null);
  const { t } = useTranslation();

  const debounceTimeout = useRef(null); // Declare useRef at the top level of the component

  const handleSearchKeywordChange = (event, newValue) => {
    const value = newValue;
    setSearchKeyword(value);
    setCurrentPage(0);

    // Clear the previous timeout to debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after 2 seconds
    debounceTimeout.current = setTimeout(() => {
      if (value) {
        SearchAgronomicLossTypes(0, itemsPerPage, value, status);
      } else {
        SearchAgronomicLossTypes(0, itemsPerPage, "", status);
      }
    }, 1500);
  };

  const handleClearStatus = () => {
    setStatusFilter("");
    setStatus(null);
    setCurrentPage(0);
    SearchAgronomicLossTypes(0, itemsPerPage, searchKeyword, null);
  };

  const handleStatusChange = (statusValue) => {
    setStatusFilter(statusValue);
    setCurrentPage(0);
    const isActive =
      statusValue === "Active"
        ? true
        : statusValue === "Inactive"
        ? false
        : null;
    setStatus(isActive);
    SearchAgronomicLossTypes(0, itemsPerPage, searchKeyword, isActive);
  };

  const SearchAgronomicLossTypes = (
    pageNumber,
    pageSize,
    agronomicLossType,
    isActive
  ) => {
    let query = { pageNumber, pageSize, agronomicLossType, isActive };

    if (isActive === true || isActive === false) {
      query.isActive = isActive;
    }

    AgronomicLossTypeService.getAgronomicLossTypes(query)
      .then((res) => {
        const ALTResults =
          res?.data?.response?.agronomicLossTypeRegistration || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(pageSize);
        setfilALTData(ALTResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (ALTResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setfilALTData([]);
        setTotalItems(0);
      });
  };

  useEffect(() => {
    SearchAgronomicLossTypes(currentPage, itemsPerPage, searchKeyword, status);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    SearchAgronomicLossTypes(
      pageNumber - 1,
      itemsPerPage,
      searchKeyword,
      status
    );
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("agronomicLossTypeRegistration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography sx={{ color: "#666666", fontWeight: "600" }}>
            {t("filtersLable")}
          </Typography>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3.5}>
                <TextField
                  name="agronomicLossType"
                  label={t("agronomicLossTypeRegistration.agronomicLossType")}
                  value={searchKeyword}
                  onChange={(event) =>
                    handleSearchKeywordChange(event, event.target.value)
                  }
                  variant="outlined"
                  sx={{
                    width: "100%", // Full width within its container
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                      marginLeft: "10px",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px", marginLeft: "10px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#666666" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Status field */}
              <Grid item xs={12} sm={2.5}>
                <FormControl fullWidth>
                  <TextField
                    select
                    name="status"
                    label={t("status")}
                    value={statusFilter || "Select an Option"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: statusFilter && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="Select an Option" sx={{ display: "none" }}>
                      {t("selectoption")}
                    </MenuItem>
                    <MenuItem value="Active">{t("active")}</MenuItem>
                    <MenuItem value="Inactive">{t("inactive")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addAgronomicLossType")}
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Render table header if no data is found */}
      <AgronomicLossTypeTable
        ALTData={filALTData} // Update filtered curing data
        setALTData={setfilALTData}
      />
    </Layout>
  );
};

export default GetAgronomicLossType;
