import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState, useRef, useEffect, useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import FarmerSegmentationService from "services/FarmerSegmentationService";

const FarmerSegmentation = () => {
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cultureType, setCultureType] = useState("");
  const [cultureTypeOptions, setCultureTypeOptions] = useState([]);
  const [cultureTypeId, setCultureTypeId] = useState("");

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [tableData, setTableData] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);

  const handleSearchKeywordChange = (event) => {
    setGroupName(event.target.value);
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      fetchData({ groupName: event.target.value });
    }, 500); // Debounce to prevent too many API calls
  };

  const handlePageChange = (direction) => {
    if (direction === "previous" && currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
      fetchData({
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
      });
    }
  };

  const handleCultureTypeChange = (event, newValue) => {
    setCultureType(newValue ? newValue.value : "");
    fetchData({ cultureType: newValue ? newValue.value : "" });
  };

  //console.log(itemsPerPage, totalItems, currentPage, "PageDetails");

  useEffect(() => {
    fetchCultureType();
    fetchCropSeason();
    // fetchData();
  }, []);

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, groupName,cropSeason,cultureType );
  }, [currentPage, itemsPerPage]);

  const fetchCultureType = async () => {
    try {
      let response = await FarmerSegmentationService.getCultureType();

      setCultureTypeOptions(
        response.data.response.map((item) => ({
          label: item.ctDescription.toString(),
          value: item.ctDescription.toString(),
          cultureTypeId: item.ctCultureTypeId, // Assuming there's an id field
        }))
      );
      console.log("setCultureTypeOptions", setCultureTypeOptions);
    } catch (error) {
      console.error("Error fetching cultureTypeOptions data:", error);
    }
  };
  const fetchCropSeason = async () => {
    try {
      let response = await FarmerSegmentationService.getCropSeason();

      setCropSeasonOptions(
        response.data.response.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );
      console.log("setCropSeasonOptions", setCropSeasonOptions);
    } catch (error) {
      console.error("Error fetching cropSeasonOptions data:", error);
    }
  };
  const fetchData = async (filters = {}) => {
    try {
      const query = {
       pageNumber: currentPage,
       pageSize: itemsPerPage,
        groupName:
          filters.groupName !== undefined ? filters.groupName : groupName,
        ctDescription:
          filters.cultureType !== undefined ? filters.cultureType : cultureType,
        cropYear:
          filters.cropSeason !== undefined ? filters.cropSeason : cropSeason,
      };
      const cleanQuery = Object.fromEntries(
        Object.entries(query).filter(
          ([_, value]) => value !== "" && value !== null
        )
      );

      const response =
        await FarmerSegmentationService.getFarmerSegmentationData(query);
      if (response.data.response.length === 0) {
        setTableData([]);
        // setMessage(t("No records found"));
        // setSeverity("warning");
        // setOpenAlert(true);        
        setTotalItems(0);
      } else {
        setTableData(
          response.data.response.farmerSegmentationTobaccoGroupDetails || []
        );

        setTotalItems(response.data.response.totalItems); // Assuming totalItems is in response
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setTableData([]);
        // setMessage(t("No records found"));
        // setSeverity("warning");
        // setOpenAlert(true);
        setTotalItems(0);
      } else {
        console.error("Error fetching farmer segmentation data:", error);
      }
    }
  };
  // const paginate = (pageNumber, itemsPerPage) => {
  //   setCurrentPage(pageNumber - 1);
  //   console.log(itemsPerPage,pageNumber,'Page Details')
  //   if (!groupName && !cultureType && !cropSeason) {
  //     fetchData(pageNumber - 1, itemsPerPage);
  //   } else {
  //     fetchData(
  //       pageNumber - 1,
  //       itemsPerPage,
  //       groupName,
  //       cultureType,
  //       cropSeason
  //     );
  //   }
  // };
  const paginate = (pageNumber, newItemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(newItemsPerPage);
    
    fetchData({
      pageNumber: pageNumber - 1,
      pageSize: newItemsPerPage,
      groupName,
      cultureType,
      cropSeason
    });
  };
  
  const handleDeleteSuccess = async () => {
    // Refetch data for current page to get new records
    await fetchData({
      pageNumber: currentPage,
      pageSize: itemsPerPage,
      groupName,
      cultureType,
      cropSeason
    });
  };

  return (
    <Layout
      title={t("Farmer Segmentation - Tobacco Priority Definition List (List)")}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
      // onPageChange={handlePageChange}
    >
      <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", p: 2 }}>
        <Grid item xs={12} sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex" fontWeight={600}>
            <ArrowDropDownIcon />
            {t("Filters")}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormControl fullWidth size="medium">
              <TextField
                label={t("Group Name")}
                value={groupName}
                onChange={handleSearchKeywordChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                    fontWeight: "600",
                  },
                  width: "100%",
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3.5}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cultureTypeOptions.find(
                    (option) => option.value === cultureType
                  ) || null
                }
                onChange={handleCultureTypeChange}
                options={cultureTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Culture Type")}
                    fullWidth
                    placeholder="Select an Option"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em", // Adjust label spacing if needed
                        fontWeight: "600",
                      },
                      width: "100%",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  cropSeasonOptions.find(
                    (option) => option.value === cropSeason
                  ) || null
                }
                onChange={(event, newValue) => {
                  setCropSeason(newValue ? newValue.value : "");
                  fetchData({ cropSeason: newValue ? newValue.value : "" });
                }}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Crop Season")}
                    fullWidth
                    placeholder="Select an Option"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em", // Adjust label spacing if needed
                        fontWeight: "600",
                      },
                      width: "100%",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                height: 50,
                background: "#004F9F",
                "&:hover": {
                  backgroundColor: "#102A62",
                },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addGroup")}
            >
              {t("addNew")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {tableData.length >= 0 && <Table tableData={tableData}   onDeleteSuccess={handleDeleteSuccess}/>}
    </Layout>
  );
};

export default FarmerSegmentation;
