// Table.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./FarmerContractTable.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { SnackbarContext } from "context/snackBarContext";
import Arrow from "assets/UpDownArrow.svg";
import Details from "assets/Details.svg";
import Pendencies from "assets/Pendencies.svg";
import PendenciesSymbol from "assets/PendenciesSymbol.svg";
import view from "assets/view.svg";

const FarmerContractTable = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [tableData, setTableData] = useState(data);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const handleMoreDetailsClick = () => {
    setShowMoreDetails(!showMoreDetails); 
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  return (
    <div className="table-container">
      <table className="farmercluster-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("code")}
            >
              {t("Farmer Code")}
              {getSortIcon("code")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("name")}
            >
              {t("Farmer Name")}
              {getSortIcon("name")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("segmentation")}
            >
              {t("Segmentation")}
              {getSortIcon("segmentation")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("variety")}
            >
              {t("Variety")}
              {getSortIcon("variety")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("visits")}
            >
              {t("# Visits")}
              {getSortIcon("visits")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("plants")}
            >
              {t("# k plants")}
              {getSortIcon("plants")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("ha")}
            >
              {t("# ha")}
              {getSortIcon("ha")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("weight")}
            >
              {t("# kg")}
              {getSortIcon("weight")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("locality")}
            >
              {t("Locality")}
              {getSortIcon("locality")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ cursor: "pointer" }}>
                <td>
                  <Tooltip
                    title={showMoreDetails[index] ? "Less Details" : "More Details"}
                    arrow
                    placement="top"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                      }}
                      onClick={()=>handleMoreDetailsClick(index)}
                    >
                      <img src={Details} alt="Details" />
                      <img
                        src={Arrow}
                        style={{
                          transform: !showMoreDetails && "rotate(180deg)",
                        }}
                        height="10px"
                        alt="Details"
                      />
                    </div>
                  </Tooltip>
                  {row.code}
                </td>
                <td>{row.name}</td>
                <td>{row.segmentation}</td>
                <td>{row.variety}</td>
                <td>{row.visits}</td>
                <td>{row.plants}</td>
                <td>{row.weight}</td>
                <td>{row.ha}</td>
                <td>{row.locality}</td>
                <td className="actions-cell">
                  <Tooltip title="" arrow placement="top">
                    <img
                      className="btn edit-btn"
                      src={PendenciesSymbol}
                      alt="Pendencies Symbol"
                    />
                  </Tooltip>

                  <Tooltip title="VIEW" arrow placement="top">
                    <img
                      className="btn delete-btn"
                      src={view}
                      alt="View"
                      onClick={() => handleOpen(row.code)}
                    />
                  </Tooltip>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {showMoreDetails && <div className="more-details-box">abc</div>}
    </div>
  );
};

export default FarmerContractTable;
