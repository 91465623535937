import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import FiltersGrid from "./FiltersGrid";
import Divider from "components/common/GrowingDivider";
import ProductionTargetsTable from './ProductionTargetsTable';
import ProductionTargetsService from "services/ProductionTargetsService";
import ConfirmationModal from './ConfirmationModal';
import SaveButton from "./SaveButton";
import { set } from "date-fns";

export default function ProductionTargets() {
  const [cropSeason, setCropSeason] = useState("");
  const [tobaccoType, setTobaccoType] = useState("");
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [tobaccoTypeOptions, setTobaccoTypeOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [productionTargets, setProductionTargets] = useState(null);
  const [showFiltersGrid, setShowFiltersGrid] = useState(false);
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [tobaccoTypeId, setTobaccoTypeId] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPtdId, setSelectedPtdId] = useState(null);

  useEffect(() => {
    fetchInitialData();
    handleViewHistory();
  }, []);

  // Remove or comment out this useEffect since we'll handle it in the new one
  // useEffect(() => {
  //   if (cropSeason && tobaccoType && hierarchicalNode) {
  //     fetchProductionTargets();
  //   }
  // }, [cropSeason, tobaccoType, hierarchicalNode]);

  // Add new useEffect to watch the IDs
  useEffect(() => {
    if (cropSeasonId && tobaccoTypeId && hierarchicalNodeId) {
      fetchProductionTargets();
    } else {
      setProductionTargets(null);
      setSelectedPtdId(null); // Reset ptdId when filters change
    }
  }, [cropSeasonId, tobaccoTypeId, hierarchicalNodeId]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, tobaccoTypeResponse, hierarchyResponse] = await Promise.all([
        ProductionTargetsService.getCropSeasonAPI(),
        ProductionTargetsService.getTobaccoTypeAPI(),
        ProductionTargetsService.getHierarchyNodeAPI()
      ]);

      setCropSeasonOptions(cropSeasonResponse.data.response.map(item => ({
        label: item.cropYear.toString(),
        value: item.cropYear.toString(),
        cropSeasonId: item.cropYearUid,// Assuming there's an id field      
      })));

      
      setTobaccoTypeOptions(tobaccoTypeResponse.data.response.map(item => ({
        label: `${item.cultureType} - ${item.tobaccoGroup}`,
        value: `${item.cultureType} - ${item.tobaccoGroup}`,
        tobaccoTypeId: item.id // Assuming there's an id field
      })));
      
      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({ label: node.locationName, value: node.id, level });
        node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchProductionTargets = async () => {
    try {
      const selectedNode = hierarchyOptions.find(option => option.value === hierarchicalNodeId);
      
      if (!selectedNode) {
        console.error("Selected node not found");
        return;
      }

      // For level 2 nodes
      if (selectedNode.level === 2) {
        // Get level 0 data to find parent's ptdId
        const rootResponse = await ProductionTargetsService.getAPI({
          cropYearUUID: cropSeasonId,
          tobaccoTypeUUID: tobaccoTypeId,
          ihId: hierarchyOptions.find(option => option.level === 0).value
        });

        if (!rootResponse.data.response) {
          console.error("No root data found");
          return;
        }

        const rootProductionTargetId = rootResponse.data.response.productionTargetId; // Store root ID

        // Find parent's distribution
        const parentDistribution = rootResponse.data.response.targetDistributionDtoList.find(
          dist => dist.ihId === hierarchyOptions.find(option => option.level === 1).value
        );

        if (!parentDistribution) {
          console.error("Parent distribution not found");
          return;
        }

        // Get parent's distribution details
        const parentDistResponse = await ProductionTargetsService.getPTdistAPI(parentDistribution.ptdId);
        
        if (!parentDistResponse.data.response) {
          console.error("No parent distribution data found");
          return;
        }

        // Find current node's distribution
        const currentDistribution = parentDistResponse.data.response.targetDistributionDtoList.find(
          dist => dist.ihId === hierarchicalNodeId
        );

        if (!currentDistribution) {
          console.error("Current distribution not found");
          return;
        }

        // Finally get the actual distribution data for the selected node
        const finalDistResponse = await ProductionTargetsService.getPTdistAPI(currentDistribution.ptdId);
        
        if (finalDistResponse.data.response) {
          setProductionTargets({
            ...finalDistResponse.data.response,
            productionTargetId: rootProductionTargetId // Add root production target ID
          });
          handleViewHistory(currentDistribution.ptdId);
          setSelectedPtdId(currentDistribution.ptdId);
        }
        return;
      }

      // For level 1 nodes
      if (selectedNode.level === 1) {
        const rootResponse = await ProductionTargetsService.getAPI({
          cropYearUUID: cropSeasonId,
          tobaccoTypeUUID: tobaccoTypeId,
          ihId: hierarchyOptions.find(option => option.level === 0).value
        });

        if (!rootResponse.data.response) {
          console.error("No root data found");
          return;
        }

        const rootProductionTargetId = rootResponse.data.response.productionTargetId; // Store root ID

        const distribution = rootResponse.data.response.targetDistributionDtoList.find(
          dist => dist.ihId === hierarchicalNodeId
        );

        if (distribution) {
          const distResponse = await ProductionTargetsService.getPTdistAPI(distribution.ptdId);
          
          if (distResponse.data.response) {
            setProductionTargets({
              ...distResponse.data.response,
              productionTargetId: rootProductionTargetId // Add root production target ID
            });
            handleViewHistory(distribution.ptdId);
            setSelectedPtdId(distribution.ptdId);
          }
          return;
        }
      }

      // For level 0 nodes
      const response = await ProductionTargetsService.getAPI({
        cropYearUUID: cropSeasonId,
        tobaccoTypeUUID: tobaccoTypeId,
        ihId: hierarchicalNodeId
      });
      
      if (response.data.response) {
        setProductionTargets(response.data.response);
        handleViewHistory(response.data.response.productionTargetId);
        setSelectedPtdId(null);
      }
    } catch (error) {
      console.error("Error fetching production targets:", error);
      setProductionTargets(null);
      setSelectedPtdId(null);
    }
  };

  const fetchDistributionData = async (ptdId) => {
    try {
      const response = await ProductionTargetsService.getPTdistAPI(ptdId);
      if (response.data.response) {
        setProductionTargets(prev => ({
          ...prev,
          targetDistributionDtoList: response.data.response.targetDistributionDtoList
        }));
      }
    } catch (error) {
      console.error("Error fetching distribution data:", error);
    }
  };

  // Add new useEffect to watch for changes in selectedPtdId
  useEffect(() => {
    const selectedNode = hierarchyOptions.find(option => option.value === hierarchicalNodeId);
    if (selectedPtdId && selectedNode && selectedNode.level > 0) {
      console.log('Fetching distribution data for ptdId:', selectedPtdId);
      fetchDistributionData(selectedPtdId);
    }
  }, [selectedPtdId, hierarchicalNodeId]);

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setHierarchicalNodeId(newValue.value);
      setShowFiltersGrid(newValue.level === 0);
      
      // Log for debugging
      console.log('Selected node:', newValue);
      console.log('Node level:', newValue.level);
    } else {
      setHierarchicalNode("");
      setHierarchicalNodeId("");
      setShowFiltersGrid(false);
      setSelectedPtdId(null);
    }
  };

  // const handleViewHistory = async () => {
  //   try {
  //     const response = await ProductionTargetsService.getAPI({
  //       // cropYearUUID: cropSeasonId,
  //       // tobaccoTypeUUID: tobaccoTypeId,
  //       // ihId: hierarchicalNodeId
  //       cropYearUUID: '0388f9d6-08c4-42a6-a8a2-54838e09ec91',
  //       tobaccoTypeUUID: '0a2a8ea3-922d-1a59-8192-3302e3220034',
  //       // tobaccoTypeUUID: '0a000086-9126-1489-8191-26d618e00070',
  //       ihId: '59598205-95c3-47ac-b166-1a5f43f7ae3e'
  //     });
  //     setHistoryData(response.data.response.targetDistributionDtoList);
  //     // setIsModalOpen(true);
  //     console.log("respp:", historyData);
  //   } catch (error) {
  //     console.error("Error fetching history data:", error);
  //   }
  // };

  const handleViewHistory = async (productionTargetId) => {
    if (!productionTargetId) {
      console.error("No production target ID provided");
      return;
    }
    try {
      const response = await ProductionTargetsService.getPTHistoryAPI(productionTargetId);
      setHistoryData(response.data.response);
      // setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };
  
  const handleSaveProductionTargets = async (payload) => {
    try {
      console.log('Sending payload to API:', payload);
      
      if (!payload.productionTargetId) {
        console.error('Missing productionTargetId in payload');
        return;
      }


      const response = await ProductionTargetsService.putPTAPI(payload);
      if (response) {
        await fetchProductionTargets(); // Refresh the data
      }
    } catch (error) {
      console.error("Error saving production targets:", error);
    }
  };

  const handleTargetsChange = (newTargets) => {
    // Update the productionTargets state with new values
    setProductionTargets(prev => {
      if (!prev) return prev;
      
      const updatedDistribution = prev.targetDistributionDtoList.map(dist => ({
        ...dist,
        // Update productivity target for all rows if updateAllRows flag is true
        productivityTarget: newTargets.updateAllRows ? newTargets.productivityTarget : dist.productivityTarget,
        // Update contract target proportionally if needed
        contractTarget: newTargets.updateAllRows ? 
          (dist.contractTarget / prev.contractTarget) * newTargets.contractTarget : 
          dist.contractTarget,
        // Recalculate hectares based on new values
        hectaresTarget: newTargets.updateAllRows ? 
          ((dist.contractTarget / prev.contractTarget) * newTargets.contractTarget * 1000) / newTargets.productivityTarget :
          dist.hectaresTarget
      }));

      return {
        ...prev,
        contractTarget: newTargets.contractTarget,
        productivityTarget: newTargets.productivityTarget,
        hectaresTarget: newTargets.hectaresTarget,
        targetDistributionDtoList: updatedDistribution
      };
    });
  };

  const renderProductionTargetsTable = () => {
    if (!cropSeasonId || !tobaccoTypeId || !hierarchicalNodeId) {
      return null;
    }

    return (
      <ProductionTargetsTable 
        data={productionTargets}
        cropYearUUID={cropSeasonId}
        tobaccoTypeUUID={tobaccoTypeId}
        ihId={hierarchicalNodeId}
        onSave={handleSaveProductionTargets}
      />
    );
  };

  // Add debugging useEffect
  useEffect(() => {
    console.log('Current hierarchicalNodeId:', hierarchicalNodeId);
    console.log('Current selectedPtdId:', selectedPtdId);
    console.log('Current hierarchyOptions:', hierarchyOptions);
  }, [hierarchicalNodeId, selectedPtdId, hierarchyOptions]);

  return (
    <Layout
      title={"Production Targets"}
      background="#ffffff"
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {Lable.filtersLable}
          </Typography>
        </Grid>

        <Grid item xs={4} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCropSeason(newValue.value);
                  setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                } else {
                  setCropSeason("");
                  setCropSeasonId(""); // This will trigger useEffect
                }
              }}
              // onInputChange={renderProductionTargetsTable()}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField {...params} label="Crop Season" fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={tobaccoTypeOptions.find(option => option.value === tobaccoType) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setTobaccoType(newValue.value);
                  setTobaccoTypeId(newValue.tobaccoTypeId); // This will trigger useEffect
                } else {
                  setTobaccoType("");
                  setTobaccoTypeId(""); // This will trigger useEffect
                }
              }}
              // onInputChange={renderProductionTargetsTable()}
              options={tobaccoTypeOptions}
              renderInput={(params) => (
                <TextField {...params} label="Tobacco Type" fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
              onChange={handleHierarchyChange}
              options={hierarchyOptions}
              renderInput={(params) => (
                <TextField {...params} label="Hierarchical Node" fullWidth />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {showFiltersGrid && (
        <FiltersGrid 
          onViewHistory={handleViewHistory} 
          historyData={historyData}
          initialContractTarget={productionTargets?.contractTarget}
          initialProductivityTarget={productionTargets?.productivityTarget}
          onTargetsChange={handleTargetsChange}
        />
      )}

      {cropSeasonId && tobaccoTypeId && hierarchicalNodeId &&
      <Divider title="Subordinates Hierarchies & Distribution Targets" />}

      <Typography variant="h8" style={{color:"white"}}>S</Typography>
      
      {renderProductionTargetsTable()}

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        historyData={historyData}
      />
      
    </Layout>
  );
}
