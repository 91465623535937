// GetLocalityConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
  Divider,
  CircularProgress,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useCultureStore from "./useCultureStore";
import RedSwitch from "utils/RedSwitch";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import brasilFlag from "assets/BrasilFlag.svg";
import mexicoFlag from "assets/MexicoFlag.svg";
import bangladeshFlag from "assets/BangladeshFlag.svg";
import pakistanFlag from "assets/PakistanFlag.svg";
import CultureTypeRegistrationService from "services/CultureTypeRegistrationService";
import * as Yup from "yup";

const data = [
  { col1: "Row 1, Col 1", col2: "Row 1, Col 2" },
  { col1: "Row 2, Col 1", col2: "Row 2, Col 2" },
  { col1: "Row 3, Col 1", col2: "Row 3, Col 2" },
  { col1: "Row 4, Col 1", col2: "Row 4, Col 2" },
  { col1: "Row 5, Col 1", col2: "Row 5, Col 2" },
  { col1: "Row 6, Col 1", col2: "Row 6, Col 2" },
  { col1: "Row 7, Col 1", col2: "Row 7, Col 2" },
  { col1: "Row 8, Col 1", col2: "Row 8, Col 2" },
];

const ConfigCTR = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAtStart, setIsAtStart] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [ctData, setCtData] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const selectedCultureName =
    useCultureStore((state) => state.selectedCultureName) ||
    localStorage.getItem("cuCultureName");
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const selectedCultureId =
    useCultureStore((state) => state.selectedCultureId) ||
    localStorage.getItem("cuCultureId");
  console.log("sdfgh", selectedCultureId, selectedCultureName);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/listCultureTypeRegistration");
  };

  // const BRA = countryData.filter((c) => c.countryCode == "BRA");
  // console.log("bgd", BRA);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    console.log("clicked");
    event.preventDefault();
    
    // Validation check before submission
    const hasLengthOverflow = countryData.some(country => 
      (country.ctcLocalAbbreviation && country.ctcLocalAbbreviation.length > 50) ||
      (country.ctcLocalDescription && country.ctcLocalDescription.length > 50) ||
      (country.ctcExternalCode && country.ctcExternalCode.length > 7)
    );

    if (hasLengthOverflow) {
      setSeverity("error");
      setMessage("Please ensure all fields are within the maximum length limit.");
      setOpenAlert(true);
      return;
    }

    setLoading(true);

    try {
      //await furnaceSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = countryData;

      console.log("payload hai", payload);
      setLoading(true);
      const response =
        await CultureTypeRegistrationService.createCultureCountrydata(payload);
      console.log("🚀 ~ handleSubmit ~ response:", response);
      setLoading(false);
      if (response.status === 200) {
        navigate("/listCultureTypeRegistration");
        setSeverity("success");
        setMessage("Record saved successfully!");
        setOpenAlert(true);
        // setLoading(false);
        return;
      }
      if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      console.log("error hai");
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form:"
        );
        setOpenAlert(true);
      }
    }
  };

  // const getSortIcon = (key) => {
  //   if (sortConfig.key === key) {
  //     if (sortConfig.direction === "asc") {
  //       return <ArrowUpwardIcon fontSize="small" />;
  //     } else if (sortConfig.direction === "desc") {
  //       return <ArrowDownwardIcon fontSize="small" />;
  //     }
  //   }
  //   // Default icon (ArrowDownward) when no sorting is applied
  //   return <ArrowDownwardIcon fontSize="small" />;
  // };

  const handleChange = (event, index, CountryLinkId) => {
    const { name, value, type, checked } = event.target;
    
    setCountryData((prevData) =>
      prevData.map((item) =>
        item.cultureTypeCountryLinkId === CountryLinkId
          ? { 
              ...item, 
              [name]: type === 'checkbox' ? checked : value 
            }
          : item
      )
    );
  };
  // useEffect(()=>{
  //  console.log("dattaa",countryData);
  // },[countryData])
  const cultureConfig = () => {
    setLoading(true);
    CultureTypeRegistrationService.getCultureTypeByCultureId(
      //"0a2a8e2a-922c-16c7-8192-2cfaa05e000e"
      selectedCultureId
    )
      .then((res) => {
        const cultureConfigResults = res?.data?.response || [];
        console.log("opppp", cultureConfigResults.ctCultureTypeId);
        const countryData = cultureConfigResults.flatMap((item) =>
          item.cultureTypeCountryLinkDTOs.map((country) => ({
            ...country, // Include all properties of the country object
            ctCultureTypeId: item.ctCultureTypeId, // Add ctCultureTypeId from the parent item
          }))
        );
        console.log("cccccc",countryData);
        //setCountryData(countryData);
        
        setCountryData(countryData);
        const ctData = cultureConfigResults.map((item) => ({
          ctAbbreviation: item.ctAbbreviation,
          ctDescription: item.ctDescription,
        }));
        setCtData(ctData);
      })
      .catch((error) => {
        // setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        // setSeverity("error");
        // setFurnaceData([]);
        // setFilteredFurnaceData([]);
        // setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    cultureConfig();
  }, []);

  return (
    <Layout
      //   itemsPerPage={itemsPerPage}
      //   totalItems={totalItems}
      //   paginate={paginate}
      //   currentPage={currentPage}
      title={t("curingTypeRegistration.pageTitle3")}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* Filter Section */}
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        {/* Filter and arrow */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("curingTypeRegistration.cultureinfo")}
          </Typography>
        </Grid>

        {/* input field */}
        <Grid item xs={12} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="culture-type-input"
              variant="outlined"
              size="small"
              disabled
              value={selectedCultureName}
              InputProps={{
                sx: {
                  backgroundColor: "#F5F5F5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "1rem",
          margin: "1rem 0",
        }}
      >
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              color: "#5A646E",
            }}
          >
            Culture Type - Global Value
          </Typography>
        </div>
        {isAtStart && (
          <>
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <img
                  src={brasilFlag}
                  alt="flag"
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#5A646E",
                  }}
                >
                  Brasil (ptBR)
                </Typography>
              </div>
            </div>

            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img
                  src={bangladeshFlag}
                  alt="flag"
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#5A646E",
                  }}
                >
                  Bangladesh (bd_BD)
                </Typography>
              </div>
            </div>
          </>
        )}

        {!isAtStart && (
          <>
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <img
                  src={mexicoFlag}
                  alt="flag"
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#5A646E",
                  }}
                >
                  Mexico (es_MX)
                </Typography>
              </div>
            </div>
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <img
                  src={pakistanFlag}
                  alt="flag"
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#5A646E",
                  }}
                >
                  Pakistan (en_PK)
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "7px",
        }}
      >
        <div style={{ width: "30%", maxHeight: "100%", overflowY: "auto" }}>
          <table className="custom-table" style={{ width: "100%" }}>
            <thead>
              <tr className="culture-table-row">
                <th style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  {t("Abbreviation")}
                </th>
                <th style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  {t("Description")}
                </th>
              </tr>
            </thead>
            <tbody>
              {ctData.map((row, index) => (
                <tr
                  key={index}
                  style={{ paddingBottom: "10px", paddingTop: "10px", height: "100px" }}
                  className="culture-table-row"
                >
                  <td style={{ padding: "8px" }}>{row.ctAbbreviation}</td>
                  <td style={{ padding: "8px" }}>{row.ctDescription}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{ width: "70%", minHeight: "350px" }}>
          {isAtStart && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "7px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{ width: "50%", height: "100%", overflowY: "auto" }}
                >
                  <table className="custom-table">
                    <thead>
                      <tr className="second-custom-table-row">
                        <th
                          //onClick={() => handleSort("ftFurnaceTypeName")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                            //justifyContent: "space-between",
                          }}
                        >
                          {t("Local Abbr.")}
                        </th>

                        <th
                          //onClick={() => handleSort("ftFurnaceTypeName")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("Ext. Code")}
                        </th>
                        <th
                          //onClick={() => handleSort("isActive")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("Local Description")}
                        </th>
                        <th>{t("Active")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {countryData
                        .filter((c) => c.countryCode == "BRA")
                        .map((c, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: "#EFEFEF",
                              height: "120px",
                            }}
                            className="second-custom-table-row"
                          >
                            {/* TextField for Local Abbreviation */}
                            <td>
                              <TextField
                                name="ctcLocalAbbreviation"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={
                                  c.cclStatus ? c.ctcLocalAbbreviation : ""
                                }
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50 
                                    ? "Maximum length is 50 characters" 
                                    : ""
                                }
                                error={c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE !important"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "2.875rem" },
                                }}
                              />
                            </td>

                            {/* TextField for External Code */}
                            <td style={{ paddingLeft: "2px" }}>
                              <TextField
                                name="ctcExternalCode"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={c.cclStatus ? c.ctcExternalCode : ""}
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcExternalCode && c.ctcExternalCode.length > 7 
                                    ? "Maximum length is 7 characters" 
                                    : ""
                                }
                                error={c.ctcExternalCode && c.ctcExternalCode.length > 7}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "0.438rem",
                                    minHeight: "3.125rem",
                                    width: "85px",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "3.125rem" },
                                }}
                              />
                            </td>

                            {/* TextField for Local Description */}
                            <td style={{ width: "100%", paddingLeft: "7px" }}>
                              <TextField
                                name="ctcLocalDescription"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={c.cclStatus ? c.ctcLocalDescription : ""}
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcLocalDescription && c.ctcLocalDescription.length > 50 
                                    ? "Maximum length is 50 characters" 
                                    : ""
                                }
                                error={c.ctcLocalDescription && c.ctcLocalDescription.length > 50}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "0.438rem",
                                    minHeight: "3.125rem",
                                    width: "100%",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "3.125rem" },
                                }}
                              />
                            </td>

                            {/* Switch for cclStatus */}
                            <td>
                              <Box sx={{ mr: "6px" }}>{t("No")}</Box>
                              <RedSwitch
                                color="primary"
                                name="cclStatus"
                                checked={c?.cclStatus}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                              />
                              <Box sx={{ ml: "6px" }}>{t("Yes")}</Box>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <table className="custom-table" style={{ width: "50%" }}>
                  <thead>
                    <tr className="second-custom-table-row">
                      <th
                        //onClick={() => handleSort("ftFurnaceTypeName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                          //justifyContent: "space-between",
                        }}
                      >
                        {t("Local Abbr.")}
                      </th>

                      <th
                        //onClick={() => handleSort("ftFurnaceTypeName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Ext. Code")}
                      </th>
                      <th
                        //onClick={() => handleSort("isActive")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Local Description")}
                      </th>
                      <th>{t("Active")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countryData
                      .filter((c) => c.countryCode == "BGD")
                      .map((row, index) => (
                        // console.log("ds",row)
                        <tr
                          style={{ backgroundColor: "#EFEFEF", height: "120px", }}
                          className="second-custom-table-row"
                        >
                          <td>
                            <TextField
                              name="ctcLocalAbbreviation"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  row.cultureTypeCountryLinkId
                                )
                              }
                              value={
                                row.cclStatus ? row.ctcLocalAbbreviation : ""
                              }
                              disabled={!row.cclStatus}
                              helperText={
                                row.ctcLocalAbbreviation && row.ctcLocalAbbreviation.length > 50 
                                  ? "Maximum length is 50 characters" 
                                  : ""
                              }
                              error={row.ctcLocalAbbreviation && row.ctcLocalAbbreviation.length > 50}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  backgroundColor: !row.cclStatus
                                    ? "#E9EBEE !important"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "2.875rem" },
                              }}
                            />
                          </td>
                          <td style={{ paddingLeft: "2px" }}>
                            <TextField
                              name="ctcExternalCode"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  row.cultureTypeCountryLinkId
                                )
                              }
                              value={row.cclStatus ? row.ctcExternalCode : ""}
                              disabled={!row.cclStatus}
                              helperText={
                                row.ctcExternalCode && row.ctcExternalCode.length > 7 
                                  ? "Maximum length is 7 characters" 
                                  : ""
                              }
                              error={row.ctcExternalCode && row.ctcExternalCode.length > 7}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "0.438rem",
                                  minHeight: "3.125rem",
                                  width: "85px",
                                  backgroundColor: !row.cclStatus
                                    ? "#E9EBEE"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "3.125rem" },
                              }}
                            />
                          </td>
                          <td style={{ width: "100%", paddingLeft: "7px" }}>
                            <TextField
                              name="ctclocalDescription"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  row.cultureTypeCountryLinkId
                                )
                              }
                              value={
                                row.cclStatus ? row.ctclocalDescription : ""
                              }
                              disabled={!row.cclStatus}
                              helperText={
                                row.ctclocalDescription && row.ctclocalDescription.length > 50 
                                  ? "Maximum length is 50 characters" 
                                  : ""
                              }
                              error={row.ctclocalDescription && row.ctclocalDescription.length > 50}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "0.438rem",
                                  minHeight: "3.125rem",
                                  width: "100%",
                                  backgroundColor: !row.cclStatus
                                    ? "#E9EBEE"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "3.125rem" },
                              }}
                            />
                          </td>
                          <td>
                            <Box sx={{ mr: "6px" }}>{t("No")}</Box>
                            <RedSwitch
                              color="primary"
                              name="cclStatus"
                              checked={row?.cclStatus}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  row.cultureTypeCountryLinkId
                                )
                              }
                            />
                            <Box sx={{ ml: "6px" }}>{t("Yes")}</Box>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {!isAtStart && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "7px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{ width: "50%", height: "100%", overflowY: "auto" }}
                >
                  <table className="custom-table">
                    <thead>
                      <tr className="second-custom-table-row">
                        <th
                          //onClick={() => handleSort("ftFurnaceTypeName")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                            //justifyContent: "space-between",
                          }}
                        >
                          {t("Local Abbr.")}
                        </th>

                        <th
                          //onClick={() => handleSort("ftFurnaceTypeName")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("Ext. Code")}
                        </th>
                        <th
                          //onClick={() => handleSort("isActive")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("Local Description")}
                        </th>
                        <th>{t("Active")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {countryData
                        .filter((c) => c.countryCode == "MEX")
                        .map((c, index) => (
                          // console.log("ds",row)
                          <tr
                            style={{
                              backgroundColor: "#EFEFEF",
                              height: "60px",
                            }}
                            className="second-custom-table-row"
                          >
                            <td>
                              <TextField
                                name="ctcLocalAbbreviation"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={
                                  c.cclStatus ? c.ctcLocalAbbreviation : ""
                                }
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50 
                                    ? "Maximum length is 50 characters" 
                                    : ""
                                }
                                error={c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "2.875rem" },
                                }}
                              />
                            </td>
                            <td style={{ paddingLeft: "2px" }}>
                              <TextField
                                name="ctcExternalCode"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={c.cclStatus ? c.ctcExternalCode : ""}
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcExternalCode && c.ctcExternalCode.length > 7 
                                    ? "Maximum length is 7 characters" 
                                    : ""
                                }
                                error={c.ctcExternalCode && c.ctcExternalCode.length > 7}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "0.438rem",
                                    minHeight: "3.125rem",
                                    width: "85px",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "3.125rem" },
                                }}
                              />
                            </td>
                            <td style={{ width: "100%", paddingLeft: "7px" }}>
                              <TextField
                                name="ctcLocalDescription"
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                                value={c.cclStatus ? c.ctcLocalDescription : ""}
                                disabled={!c.cclStatus}
                                helperText={
                                  c.ctcLocalDescription && c.ctcLocalDescription.length > 50 
                                    ? "Maximum length is 50 characters" 
                                    : ""
                                }
                                error={c.ctcLocalDescription && c.ctcLocalDescription.length > 50}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "0.438rem",
                                    minHeight: "3.125rem",
                                    width: "100%",
                                    backgroundColor: !c.cclStatus
                                      ? "#E9EBEE"
                                      : "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    lineHeight: "1.2rem",
                                  },
                                }}
                                InputProps={{
                                  style: { height: "3.125rem" },
                                }}
                              />
                            </td>
                            <td>
                              <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                              <RedSwitch
                                color="primary"
                                name="cclStatus"
                                checked={c?.cclStatus}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    index,
                                    c.cultureTypeCountryLinkId
                                  )
                                }
                              />
                              <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <table className="custom-table" style={{ width: "50%" }}>
                  <thead>
                    <tr className="second-custom-table-row">
                      <th
                        //onClick={() => handleSort("ftFurnaceTypeName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                          //justifyContent: "space-between",
                        }}
                      >
                        {t("Local Abbr.")}
                      </th>

                      <th
                        //onClick={() => handleSort("ftFurnaceTypeName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Ext. Code")}
                      </th>
                      <th
                        //onClick={() => handleSort("isActive")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Local Description")}
                      </th>
                      <th>{t("Active")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countryData
                      .filter((c) => c.countryCode == "PAK")
                      .map((c, index) => (
                        // console.log("ds",row)
                        <tr
                          style={{ backgroundColor: "#EFEFEF", height: "60px" }}
                          className="second-custom-table-row"
                        >
                          <td>
                            <TextField
                              name="ctcLocalAbbreviation"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  c.cultureTypeCountryLinkId
                                )
                              }
                              value={c.cclStatus ? c.ctcLocalAbbreviation : ""}
                              disabled={!c.cclStatus}
                              helperText={
                                c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50 
                                  ? "Maximum length is 50 characters" 
                                  : ""
                              }
                              error={c.ctcLocalAbbreviation && c.ctcLocalAbbreviation.length > 50}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  backgroundColor: !c.cclStatus
                                    ? "#E9EBEE"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "2.875rem" },
                              }}
                            />
                          </td>
                          <td style={{ paddingLeft: "2px" }}>
                            <TextField
                              name="ctcExternalCode"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  c.cultureTypeCountryLinkId
                                )
                              }
                              value={c.cclStatus ? c.ctcExternalCode : ""}
                              disabled={!c.cclStatus}
                              helperText={
                                c.ctcExternalCode && c.ctcExternalCode.length > 7 
                                  ? "Maximum length is 7 characters" 
                                  : ""
                              }
                              error={c.ctcExternalCode && c.ctcExternalCode.length > 7}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "0.438rem",
                                  minHeight: "3.125rem",
                                  width: "85px",
                                  backgroundColor: !c.cclStatus
                                    ? "#E9EBEE"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "3.125rem" },
                              }}
                            />
                          </td>
                          <td style={{ width: "100%", paddingLeft: "7px" }}>
                            <TextField
                              name="ctcLocalDescription"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  c.cultureTypeCountryLinkId
                                )
                              }
                              value={c.cclStatus ? c.ctcLocalDescription : ""}
                              disabled={!c.cclStatus}
                              helperText={
                                c.ctcLocalDescription && c.ctcLocalDescription.length > 50 
                                  ? "Maximum length is 50 characters" 
                                  : ""
                              }
                              error={c.ctcLocalDescription && c.ctcLocalDescription.length > 50}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "0.438rem",
                                  minHeight: "3.125rem",
                                  width: "100%",
                                  backgroundColor: !c.cclStatus
                                    ? "#E9EBEE"
                                    : "#FFFFFF",
                                },
                                "& .MuiInputLabel-root": {
                                  lineHeight: "1.2rem",
                                },
                              }}
                              InputProps={{
                                style: { height: "3.125rem" },
                              }}
                            />
                          </td>
                          <td>
                            <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                            <RedSwitch
                              color="primary"
                              name="cclStatus"
                              checked={c?.cclStatus}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  index,
                                  c.cultureTypeCountryLinkId
                                )
                              }
                            />
                            <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div style={{ width: "100%", position: "relative" }}>
            {/* Thin line */}
            <div
              style={{
                width: "100%",
                height: "3px",
                backgroundColor: "#E9EBEE",
                borderRadius: "5px",
              }}
            />
            {/* Thick line */}
            <div
              onClick={() => setIsAtStart(!isAtStart)} // Handle click
              style={{
                width: "50%", // Half the width of the thin line
                height: "21px",
                backgroundColor: "#737D86",
                position: "absolute",
                top: "-7px", // Center the thick line vertically
                left: "0", // Keep it at the start
                transform: isAtStart ? "translateX(0)" : "translateX(100%)", // Toggle position
                transition: "transform 0.5s ease", // Smooth animation
                borderRadius: "4px",
                cursor: "pointer", // Indicate clickable element
              }}
            />
          </div>
        </div>
      </div>

    {/* New spacer div */}
    <div style={{ height: "20vh", width: "100%" }}></div>


      <Divider
        sx={{
          position: "fixed",
          bottom: "82px",
          width: "97%",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "fixed",
          width: "96%",
          bottom: 22,
          padding: "0.5rem",
          background: "white",
          zIndex: 999,
        }}
      >
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            height: "44px",
            marginLeft: "10px",
          }}
          variant="contained"
          onClick={handleOpenModal}
          disabled={loading}
          startIcon={<img src={arrowLeft} alt="Arrow" />}
        >
          {loading && <CircularProgress size={20} />}
          {"Cancel"}
        </Button>

        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "585px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                {t("cancelTitle")}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: 400 }}
              >
                {t("cancelMessage")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("no")}
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("yes")}
              </Button>
            </Box>
          </Box>
        </Modal>

        {
          //edit&&
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
              marginRight: "10px",
            }}
            onClick={handleSubmit}
            disabled={loading}
            startIcon={<img src={Save} alt="Right Arrow" />}
            variant="contained"
          >
            {loading && <CircularProgress size={20} />}
            {"Save"}
          </Button>
        }
      </Box>
    </Layout>
  );
};

export default ConfigCTR;
