import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Button from "@mui/material/Button";
import arrowDown from "assets/arrowDown.svg";
import { StyledTreeItem } from "utils/Styledtreeitem";
import { useSelector, useDispatch } from "react-redux";
import { generalInformationSchema } from "utils/validations";
import { updateGeneralInformation } from "slices/generalInformation";
import RenderField from "utils/RenderField";
import { generalFormConfig } from "./FormConfig";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useLocation, useParams } from "react-router-dom";
import {
  convertToDateString,
  toBoolean,
  toStringBoolean,
} from "utils/CommonFunction";
import PickListService from "services/PickListService";

export default function GeneralInformation() {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const { edit, details } = location.state || {};

  console.log("🚀 ~ ISEDit:", edit, details);

  console.log("🚀 ~ GeneralInformation ~ id:", id);
  const generalInformation = useSelector(
    (state) => state?.farmers?.generalInformation
  );
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState(generalInformation || {});
  console.log("🚀 ~ GeneralInformation ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [picklistData, setPicklistData] = useState([]);
  const [generalFormConfigs, setGeneralFormConfigs] =
    useState(generalFormConfig);
  console.log(
    "🚀 ~ GeneralInformation ~ generalFormConfigs:",
    generalFormConfigs
  );

  console.log(
    "🚀 ~ GeneralInformation ~ generalInformation:",
    generalInformation
  );

  console.log("dcfdc", toStringBoolean(values.dripIrrigation));

  const getFarmerUniqueCode = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerUniqueCode();
      return response.data.response;
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({
          pickListCodes: [
            "FRGINFMI",
            "FRGINFC47",
            "FRGINFNC",
            "FRGINFUDI",
            "FRGINFAL",
            "FRGINFRE1",
          ],
        });
        const picklistResponse = response.data.response;

        const groupedPicklistData = Object.keys(picklistResponse).map(
          (pickListCode) => ({
            pickListCode,
            items: picklistResponse[pickListCode].map((item) => ({
              pickListItemCode: item.pickListItemCode,
              pickListDescription: item.pickListDescription,
            })),
          })
        );

        setPicklistData(groupedPicklistData);

        const minorsUnder18 =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFMI")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const adultsOver18 =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFAL")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const dripIrrigation =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFUDI")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const city =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFC47")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        const country =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFNC")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        const regions =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFRE1")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];

        setGeneralFormConfigs((prevConfig) =>
          prevConfig.map((field) => {
            if (field.name === "region" && !edit) {
              return {
                ...field,
                options: [...regions],
              };
            }
            if (field.name === "nationality" && !edit) {
              return {
                ...field,
                disabled: false,
              };
            }
            if (field.name === "dripIrrigation") {
              return {
                ...field,
                options: [...dripIrrigation],
              };
            }
            if (field.name === "minorsUnder18") {
              return {
                ...field,
                options: [...minorsUnder18],
              };
            }
            if (field.name === "adultsOver18") {
              return {
                ...field,
                options: [...adultsOver18],
              };
            }
            if (field.name === "city") {
              return {
                ...field,
                options: [...city],
              };
            }
            if (field.name === "farmerCountry") {
              return {
                ...field,
                options: [...country],
              };
            }
            if (field.name === "regionOfBirth" && !edit) {
              return {
                ...field,
                options: [...regions],
              };
            }
            return field;
          })
        );
      } catch (error) {
        console.error("Error fetching picklist data:", error);
      }
    };

    fetchPicklistData();
  }, []);

  const getFarmerByIds = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      const values = response.data.response;
      console.log("VALUESSS", values);
      setValues(values);

      dispatch(
        updateGeneralInformation({
          farmerId: values.farmerId,
          farmerFullName: values.farmerFullName,
          farmerCode: values.farmerCode,
          cpf: values.cpf,
          dateOfBirth: convertToDateString(values.dateOfBirth),
          gender: values.gender,
          email: values.email,
          nationalId: values.nationalId,
          dripIrrigation: values.dripIrrigation,
          contactNumber: values.contactNumber,
          farmerCountry: values.farmerCountry,
          region: values.region,
          city: values.city,
          locality: values.locality,
          zipCode: values.zipCode,
          issuingAuthority: values.issuingAuthority,
          regionOfBirth: values.regionOfBirth,
          maritalStatus: values.maritalStatus,
          fatherName: values.fatherName,
          motherName: values.motherName,
          minorsUnder18: values.minorsUnder18,
          adultsOver18: values.adultsOver18,
          latitude: values.latitude,
          longitude: values.longitude,
          emissionDt: convertToDateString(values.emissionDt),
          residence: values.residence,
          nationalityCountry: values.nationalityCountry,
          nationality: values.nationality,
          spouseName: values.spouseName,
          altitude: values.altitude,
          externalCode: values.externalCode,
        })
      );

      return values;
    } catch (error) {
      console.error("Error getting farmer by Id:", error);
      return null;
    }
  };

  useEffect(() => {
    if (values.cpf === "") {
      getFarmerByIds();
    }
  }, []);

  useEffect(() => {
    if (!id) {
      const setFarmerCode = async () => {
        const uniqueCode = await getFarmerUniqueCode();
        setValues((prevValues) => ({
          ...prevValues,
          farmerCode: uniqueCode,
        }));
        dispatch(updateGeneralInformation({ farmerCode: uniqueCode }));
      };

      setFarmerCode();
    }
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue =
      name === "altitude" || name === "latitude" || name === "longitude"
        ? value === "0" || value === "0."
          ? value
          : value.replace(/^0+(?!\.)/, "")
        : value;
    setValues((prevValues) => {
      if (name === "maritalStatus" && value === "Single") {
        return {
          ...prevValues,
          [name]: value,
          spouseName: "",
        };
      }

      return {
        ...prevValues,
        [name]: newValue,
      };
    });
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  // const handleDateChange = (date) => {
  //   if (!date || date === "Invalid Date") {
  //     setDateError(true);
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       dateOfBirth: "",
  //     }));
  //     return;
  //   }

  //   setDateError(false);

  //   const dateValue = new Date(date);
  //   const year = dateValue.getFullYear();
  //   const month = String(dateValue.getMonth() + 1).padStart(2, "0");
  //   const day = String(dateValue.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;

  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     dateOfBirth: formattedDate,
  //   }));
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await generalInformationSchema.validate(values, { abortEarly: false });
      setErrors({});
      dispatch(
        updateGeneralInformation({
          farmerFullName: values.farmerFullName,
          farmerCode: values.farmerCode,
          cpf: values.cpf,
          dateOfBirth: convertToDateString(values.dateOfBirth),
          gender: values.gender,
          email: values.email,
          nationalId: values.nationalId,
          dripIrrigation: values.dripIrrigation,
          contactNumber: values.contactNumber,
          farmerCountry: values.farmerCountry,
          region: values.region,
          city: values.city,
          locality: values.locality,
          zipCode: values.zipCode,
          issuingAuthority: values.issuingAuthority,
          regionOfBirth: values.regionOfBirth,
          maritalStatus: values.maritalStatus,
          fatherName: values.fatherName,
          motherName: values.motherName,
          minorsUnder18: values.minorsUnder18,
          adultsOver18: values.adultsOver18,
          latitude: values.latitude,
          longitude: values.longitude,
          nationality: values.nationality,
          emissionDt: convertToDateString(values.emissionDt),
          residence: values.residence,
          nationalityCountry: values.nationalityCountry,
          spouseName: values.spouseName,
          altitude: values.altitude,
          externalCode: values.externalCode,
        })
      );
      setSeverity("success");
      setMessage("Saved successfully");
      setOpenAlert(true);
      // handleTabChange(event, 1)
      console.log("displathc Successdullt");
    } catch (err) {
      console.log("rtt", err);
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const getCoordinates = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude, altitude } = position.coords;
          const fetchAltitude = async (lat, lng) => {
            try {
              const response = await fetch(
                `https://api.open-elevation.com/api/v1/lookup?locations=${lat},${lng}`
              );
              const data = await response.json();
              if (data.results && data.results[0]) {
                return data.results[0].elevation.toFixed(6);
              }
              return "";
            } catch (error) {
              console.error("Error fetching altitude:", error);
              return "";
            }
          };

          const finalAltitude =
            altitude !== undefined && altitude !== null
              ? altitude.toFixed(6)
              : await fetchAltitude(latitude, longitude);
          setValues((prevValues) => ({
            ...prevValues,
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            altitude: finalAltitude,
          }));
        },
        (error) => {
          console.error("Error getting coordinates:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleClear = (field) => {
    if (field === "maritalStatus") {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
        spouseName: "",
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
      }));
    }
  };

  useEffect(() => {
    setGeneralFormConfigs((prevFields) =>
      prevFields.map((field) => {
        if (field.name === "spouseName") {
          if (values.maritalStatus === "Married") {
            return {
              ...field,
              required: true,
              disabled: false,
            };
          } else {
            return {
              ...field,
              required: false,
              disabled: true,
            };
          }
        }
        return field;
      })
    );
  }, [values.maritalStatus]);

  return (
    <Box
      sx={{
        minHeight: 180,
        marginLeft: "0px",
        flexGrow: 10,
        width: "100%",
        paddingBottom: "10rem",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={["1", "3", "4"]}
        >
          <StyledTreeItem nodeId="1" labelText="General Information">
            {generalFormConfigs.slice(0, 19).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
                details={details === true ? true : false}
              />
            ))}
          </StyledTreeItem>
          <StyledTreeItem nodeId="3" labelText="Family Information">
            {generalFormConfigs.slice(19, 27).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
                details={details === true ? true : false}
              />
            ))}
          </StyledTreeItem>
          <StyledTreeItem nodeId="4" labelText="Geographic Coordinates">
            {generalFormConfigs.slice(27).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
                details={details === true ? true : false}
              />
            ))}
            <Button
              onClick={getCoordinates}
              sx={{ mt: 2, background: "#004F9F" }}
              variant="contained"
              disabled={details === true ? true : false}
            >
              Capture my Coordinates
            </Button>
          </StyledTreeItem>
        </TreeView>
        {/* {edit &&  */}
        <Button
          style={{
            float: "right",
            marginBottom: "10px",
            background: "#EF7D00",
          }}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          Save Changes
        </Button>
        {/* } */}
      </form>
    </Box>
  );
}
