import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Table.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import SoilAnalysisApplicationPhaseService from "services/SoilAnalysisApplicationPhaseService";
import { SnackbarContext } from "context/snackBarContext";

const SoilAnalysisApplicationPhaseTable = ({
  SoilAnalysisApplicationPhaseData: initialData,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => setData(initialData), [initialData]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await SoilAnalysisApplicationPhaseService.deleteAPI(
        deleteId
      );
      if (response.status === 200) {
        setMessage("RECORD DELETED SUCCESSFULLY!");
        setSeverity("success");
        setData((prevData) =>
          prevData.filter((item) => item.applicationId !== deleteId)
        );
      } else {
        setMessage("Failed to delete record");
        setSeverity("error");
      }
    } catch (error) {
      setMessage("Error deleting record");
      setSeverity("error");
    } finally {
      setOpenAlert(true);
      setOpen(false);
    }
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData(
      [...data].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            {["applicationPhase", "status"].map((key) => (
              <th
                key={key}
                onClick={() => handleSort(key)}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {key === "applicationPhase" ? "Application Phase" : "Status"}
                </div>
                <div>{getSortIcon(key)}</div>
              </th>
            ))}
            <th className="actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.applicationPhase}</td>
              <td>{row.status ? "Active" : "Inactive"}</td>
              <td className="actions-cell">
                <img
                  className="btn edit-btn"
                  src={_ButtonEdit_}
                  alt="Edit Button"
                  onClick={() =>
                    navigate("/addSoilAnalysisApplicationPhase", {
                      state: { row, edit: true },
                    })
                  }
                />
                <img
                  className="btn delete-btn"
                  src={_ButtonDelete_}
                  alt="Delete"
                  onClick={() => handleDeleteClick(row.applicationId)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SoilAnalysisApplicationPhaseTable;
