import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { SnackbarContext } from "context/snackBarContext";
import CultureTypeRegistrationService from 'services/CultureTypeRegistrationService';

export const useCultureData = (queryParams) => {
  const queryClient = useQueryClient();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  console.log('useCultureData called with params:', queryParams); // Debug log

  const culturesQuery = useQuery({
    queryKey: ['cultures', queryParams],
    queryFn: () => CultureTypeRegistrationService.getAPI(queryParams),
    select: (data) => ({
      cultures: data?.data?.response?.Culture || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.Culture || [])
        .map(item => item.cuCultureName))]
        .map(name => ({ value: name, label: name }))
    })
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => CultureTypeRegistrationService.deleteAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['cultures']);
    }
  });

  const cultureTypeQuery = useQuery({
    queryKey: ['cultureType', queryParams.cultureTypeId],
    queryFn: async () => {
      console.log('Fetching culture type data for ID:', queryParams.cultureTypeId);
      try {
        if (!queryParams.cultureTypeId) {
          console.warn('No Culture Type ID provided');
          return null; // Return null instead of throwing an error
        }

        const response = await CultureTypeRegistrationService.getCultureTypeByCultureId(queryParams.cultureTypeId);
        
        // Add additional validation
        if (!response || !response.data) {
          console.warn('Invalid or empty response from API');
          return null;
        }

        console.log('API Response:', response);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        
        // More granular error handling
        if (error.response.status===404) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setMessage(`No Culture Types registered for this Culture. Please create a Culture Type before proceeding.`);
        } else if (error.request) {
          // The request was made but no response was received
          setMessage('No response received from server');
        } else {
          // Something happened in setting up the request that triggered an Error
          setMessage('Error in request setup');
        }
        
        setSeverity('error');
        setOpenAlert(true);
        
        return null; // Return null to prevent query from throwing
      }
    },
    enabled: !!queryParams.cultureTypeId,
    retry: 1, // Limit retry attempts
    retryDelay: 1000, // 1 second between retries
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
    onError: (error) => {
      console.error('Query Error:', error);
      setMessage('Error fetching culture type data');
      setSeverity('error');
      setOpenAlert(true);
    }
  });

  const geneticTypesQuery = useQuery({
    queryKey: ['geneticTypes', queryParams],
    queryFn: () => CultureTypeRegistrationService.getGeneticTypeAPI(queryParams),
    select: (data) => ({
      geneticTypes: data?.data?.response?.GeneticTypes || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.GeneticTypes || [])
        .map(item => item.gtGeneticDescription))]
        .map(description => ({ value: description, label: description }))
    })
  });

  const curingTypesQuery = useQuery({
    queryKey: ['curingTypes', queryParams],
    queryFn: () => CultureTypeRegistrationService.getCuringTypeAPI(queryParams),
    select: (data) => ({
      curingTypes: data?.data?.response?.curingTyoes || [],
      totalItems: data?.data?.response?.totalItems || 0,
      menu: [...new Set((data?.data?.response?.curingTyoes || [])
        .map(item => item.curingType))]
        .map(type => ({ value: type, label: type }))
    })
  });

  const cultureOptionsQuery = useQuery({
    queryKey: ['cultureOptions'],
    queryFn: () => CultureTypeRegistrationService.getCultureFull({ pageNumber: 0, pageSize: 999 }),
    select: (data) => {
      const cultures = data?.data?.response?.Culture || [];
      return cultures.map(culture => ({
        value: culture.cuCultureName,
        label: culture.cuCultureName,
        id: culture.cuCultureId
      }));
    }
  });

  const postCultureTypeMutation = useMutation({
    mutationFn: (cultureTypeData) => CultureTypeRegistrationService.postCultureTypeAPI(cultureTypeData),
    onSuccess: () => {
      queryClient.invalidateQueries(['cultures']);
      setMessage('Culture Type created successfully');
      setSeverity('success');
      setOpenAlert(true);
    },
    onError: (error) => {
      console.error('Error creating culture type:', error);
      setMessage('Error creating culture type');
      setSeverity('error');
      setOpenAlert(true);
    }
  });

  return {
    culturesQuery,
    deleteMutation,
    cultureTypeQuery,
    geneticTypesQuery,
    curingTypesQuery,
    cultureOptionsQuery,
    postCultureTypeMutation,
  };
};