import Layout from "components/structure/Layout";
import React, { useState, useContext, useEffect, useCallback } from "react";
import PreAssessmentTable from "./PreAssessmentTable";
import { SnackbarContext } from "context/snackBarContext";
import PreAssessmentFormService from "services/PreAssessmentESGFormService";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import CropSeasonService from "services/CropSeasonService";
import TransporterGetService from "services/TransporterGetService";

const RegistrationOptions = styled.div`
  background: #efefef;
  height: 230px;
  padding: 10px 10px;
  border-radius: 5px;
`;

const PreAssesmentApplication = () => {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [errors, setErrors] = useState({});
  const [cropYear, setCropYear] = useState([]);
  const [city, setCity] = useState([]);
  const [locality, setLocality] = useState([]);
  console.log("🚀 ~ PreAssesmentApplication ~ city:", city);
  console.log("🚀 ~ PreAssesmentApplication ~ cropYear:", cropYear);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    farmerId: "",
    cropSeasonId: "0A2A910C-9344-112F-8193-4421BA110000",
  });

  const [searchData, setSearchData] = useState({
    geneticType: "",
    cropSeason: "",
    city: "",
    locality: "",
    commercialStatus: "",
    esgStatus:"",
    segmentation:""
  });
  console.log("🚀 ~ PreAssesmentApplication ~ searchData:", searchData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const callFarmerApi = (pageNumber, size, cropSeasonId) => {
    PreAssessmentFormService.getAllFarmersData({
      pageNumber,
      size,
      cropSeasonId,
    })
      .then((res) => {
        const farmerResults = res?.data?.response?.farmers || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setData(farmerResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (
          farmerResults.length === 0
          //  && searchKeyword.length > 0
        ) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setData([]);
        setTotalItems(0);
      });
  };

  const callCropSeasonApi = (pNumber, size) => {
    CropSeasonService.getCropSeasons({
      pageNumber: 0,
      size: 100,
    })
      .then((res) => {
        const rows = res?.data?.response?.cropSeason.map(
          (cropseason, index) => ({
            cropYear: cropseason.cropYear,
            seasonId: cropseason.cropSeasonId,
          })
        );

        setCropYear(rows);
      })
      .catch((error) => {
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchData);

      // If no search parameters are provided, fetch all data
      if (Object.keys(cleanedParams).length === 0) {
        callFarmerApi(currentPage, itemsPerPage, values.cropSeasonId);
        return;
      }

      // Convert the status to boolean for the API
      let isActive;
      if (cleanedParams.isActive) {
        isActive = cleanedParams.isActive === t("active");
      }

      // Find the genetic description based on the selected geneticType ID
      // let geneticDescription;
      // if (cleanedParams.geneticType) {
      //   const selectedGenetic = geneticType.find(
      //     (item) => item.value === cleanedParams.geneticType
      //   );
      //   geneticDescription = selectedGenetic?.label;
      // }

      PreAssessmentFormService.getAllFarmersData({
        cropSeasonId: searchData.cropSeason || values.cropSeasonId,
        searchKeyword: searchData.geneticType, // Using description instead of ID
        isActive: isActive,
        pageNumber: currentPage,
        city: searchData.city.cityName,
        locality: searchData.locality,
        commercialStatuses: searchData.commercialStatus,
        esgStatuses: searchData.esgStatus,
        size: itemsPerPage,
      })
        .then((response) => {
          setData(response?.data?.response.farmers);
          setCurrentPage(response?.data?.response?.currentPage || 0);
          setItemsPerPage(itemsPerPage);
          setTotalItems(response?.data?.response?.totalItems || 0);
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            setData([]);
          }
        });
    }, 500),
    [
      searchData.geneticType,
      searchData.cropSeason,
      currentPage,
      itemsPerPage,
      t,
      searchData.city.cityName,
      searchData.locality,
      searchData.commercialStatus,
      searchData.esgStatus
    ]
  );

  const getCityList = async () => {
    try {
      const response = await TransporterGetService.getCityDetails();
      console.log("polarCity", response.data.response);
      const d = response.data.response;
      // Store full city objects instead of just names
      setCity(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  useEffect(() => {
    if (
      searchData.geneticType ||
      searchData.cropSeason ||
      searchData.city.cityName ||
      searchData.locality ||
      searchData.commercialStatus || 
      searchData.esgStatus
    ) {
      handleSearch();
    } else {
      callFarmerApi(currentPage, itemsPerPage, values.cropSeasonId);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchData, currentPage, itemsPerPage, handleSearch]);

  const handleClear = async (name) => {
    const newData = {
      ...searchData,
      [name]: "",
    };
    setSearchData(newData);

    if (
      !newData.geneticType &&
      !newData.cropSeason &&
      !newData.city.cityName &&
      !newData.locality &&
      !newData.commercialStatus &&
      !newData.esgStatus
    ) {
      await callFarmerApi(0, itemsPerPage, values.cropSeasonId);
    }
  };

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "" || cleanedParams[key] === "PL") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const getLocalityList = async (id) => {
    try {
      const response = await TransporterGetService.getLocality({ id });
      console.log("polarCity", response.data.response);
      const d = response.data.response;
      // Store full city objects instead of just names
      setLocality(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  useEffect(() => {
    callFarmerApi(currentPage, itemsPerPage, values.cropSeasonId);
    callCropSeasonApi();
    getCityList();
    if (searchData.city?.cityId) {
      getLocalityList(searchData.city.cityId);
    }
  }, [currentPage, itemsPerPage, searchData.city]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    callFarmerApi(pageNumber - 1, itemsPerPage, values.cropSeasonId);
  };

  return (
    <Layout
      title={"Pre Assessment - Form Application"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      background="#ffffff"
      useNewPagination={true}
    >
      <RegistrationOptions>
        <Typography variant="h8" display="flex">
          <ArrowDropDownIcon />
          {t("filtersLable")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: "20px",
          }}
        >
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
                gap: "1rem",
                marginLeft: 1,
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", md:600 },
                  minHeight: 50,
                }}
              >
                <TextField
                  id="geneticType"
                  labelId="cy-select-label"
                  label={"Search Farmer"}
                  value={searchData.geneticType}
                  name="geneticType"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.geneticType && (
                      <CloseIcon
                        onClick={() => handleClear("geneticType")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 290 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="cropSeason"
                  labelId="cy-select-label-status"
                  label={"Crop Season"}
                  value={searchData.cropSeason || "PL"}
                  name="cropSeason"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.cropSeason && (
                      <CloseIcon
                        onClick={() => handleClear("cropSeason")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  {cropYear.map((s) => (
                    <MenuItem key={s} value={s.seasonId}>
                      {s.cropYear}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 290 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="city"
                  labelId="cy-select-label-status"
                  label={"City"}
                  value={
                    city.find(
                      (item) => item.cityId === searchData.city.cityId
                    ) || "PL"
                  }
                  name="city"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.city && (
                      <CloseIcon
                        onClick={() => handleClear("city")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  {city.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s.cityName}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 290 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="locality"
                  labelId="cy-select-label-status"
                  label={"Locality"}
                  value={searchData.locality || "PL"}
                  name="locality"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.locality && (
                      <CloseIcon
                        onClick={() => handleClear("locality")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  {locality.map((s) => (
                    <MenuItem key={s.localityName} value={s.localityName}>
                      {s.localityName}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
                gap: "1rem",
                marginLeft: 1,
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="commercialStatus"
                  labelId="cy-select-label-status"
                  label={"Commercial Status"}
                  value={searchData.commercialStatus || "PL"}
                  name="commercialStatus"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.commercialStatus && (
                      <CloseIcon
                        onClick={() => handleClear("commercialStatus")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  <MenuItem value="Contracted">Contracted</MenuItem>{" "}
                  <MenuItem value="Not Contracted">Not Contracted</MenuItem>{" "}
                  <MenuItem value="Inactive">Inactive</MenuItem>
                  {/* {cropYear.map((s) => (
                      <MenuItem key={s} value={s.seasonId}>
                        {s.cropYear}
                      </MenuItem>
                    ))} */}
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="esgStatus"
                  labelId="cy-select-label-status"
                  label={"ESG Status"}
                  value={searchData.esgStatus || "PL"}
                  name="esgStatus"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.esgStatus && (
                      <CloseIcon
                        onClick={() => handleClear("esgStatus")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  <MenuItem value="ESG Blocked">ESG Blocked</MenuItem>{" "}
                  <MenuItem value="Pending Pre Assessment">
                    Pending Pre Assessment
                  </MenuItem>{" "}
                  <MenuItem value="Approved">Approved </MenuItem>
                  <MenuItem value="Pending ESG Approval">Pending ESG Approval </MenuItem>

                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="transporter"
                  labelId="cy-select-label-status"
                  label={"Transporter"}
                  value={
                  searchData.transporter
                  }
                  name="transporter"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.transporter && (
                      <CloseIcon
                        onClick={() => handleClear("transporter")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  {city.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s.cityName}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="segmentation"
                  labelId="cy-select-label-status"
                  label={"Segmentation"}
                  value={searchData.segmentation || "PL"}
                  name="segmentation"
                  onChange={handleChange}
                  error={!!errors.geneticType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.segmentation && (
                      <CloseIcon
                        onClick={() => handleClear("segmentation")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    {t("spacingRegistration.menuTitle")}
                  </MenuItem>
                  <MenuItem value="SN">SN </MenuItem>
                </TextField>
                <FormHelperText error={true}>
                  {errors.geneticType}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </RegistrationOptions>
      <PreAssessmentTable
        data={data}
        setData={setData}
        cropSeasonId={values.cropSeasonId}
      />
    </Layout>
  );
};

export default PreAssesmentApplication;
