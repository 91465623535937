import { httpGrow } from "../http-common";

const getSoilAnalysis = async (query) => {
  const response = await httpGrow.get(
    `/soil-analysis/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};

const getAllFarmer = async () => {
  const response = await httpGrow.get(`/farmer/getAllFarmerList`);
  return response;
};

const createSoilAnalysis = (data) => {
  return httpGrow.post(`/soil-analysis`, data);
};

const updateSoilAnalysis = (data) => {
  return httpGrow.put(`soil-analysis`, data);
};

const searchSoilAnalysis = async (query) => {
  let url = `/soil-analysis/find?pageNumber=${query?.pageNumber}&size=${
    query?.size || 50
  }`;

  if (query?.farmerInput) {
    url += `&farmerInput=${query.farmerInput}`;
  }
  if (query?.soilAnalysisDescription) {
    url += `&soilAnalysisDescription=${query.soilAnalysisDescription}`;
  }
  if (query?.saStatus !== undefined) {
    url += `&saStatus=${query.saStatus}`;
  }

  const response = await httpGrow.get(url);
  return response;
};

const searchFarmer = async (query) => {
  const response = await httpGrow.get(
    `/farmer/find?pageNumber=${query?.pageNumber || 0}&size=${
      query?.size || 50
    }&userInput=${query?.keyword || ""}`
  );
  return response;
};

const deleteSoilAnalysis = (soilAnalysisId) => {
  return httpGrow.delete(`/soil-analysis/deleteSoilAnalysis/${soilAnalysisId}`);
};

const getSoilAnalysisParameter = async (query) => {
  const response = await httpGrow.get(
    `/soil-analysis-parameters/list?pageNumber=${query?.pageNumber || 0}&size=${query?.size || 50}`
  );
  return response;
};

const importSoilAnalysis = (file, countryId, languageId, createdByUser, modifiedByUser) => {
  const formData = new FormData();
  formData.append('file', file);

  return httpGrow.post('/soil-analysis/import', formData, {
    params: {
      countryId,
      languageId,
      createdByUser,
      modifiedByUser
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json'
    }
  });
};

const downloadTemplate = () => {
  return httpGrow.get('/soil-analysis/template', {
    responseType: 'blob'
  });
};

const SoilAnalysisService = {
  getSoilAnalysis,
  getAllFarmer,
  createSoilAnalysis,
  updateSoilAnalysis,
  searchSoilAnalysis,
  searchFarmer,
  deleteSoilAnalysis,
  getSoilAnalysisParameter,
  importSoilAnalysis,
  downloadTemplate
};

export default SoilAnalysisService;
