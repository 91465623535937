import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  Paper,
  ListItem,
  List,
} from "@mui/material";
import "./Table.css";
import SignatureCanvas from "react-signature-canvas";
import Layout from "components/structure/Layout";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Arrow from "assets/UpDownArrow.svg";
import Details from "assets/Details.svg";
import Plus from "assets/Plus.svg";
import download from "assets/download.svg";
import downloadIcon from "assets/DownloadIcon2.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PreAssessmentFormService from "services/PreAssessmentESGFormService";
import Divider from "components/common/GrowingDivider";
import { SnackbarContext } from "context/snackBarContext";

const PreAssementDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [openComment, setOpenComment] = useState(false);
  const [signaturePad, setSignaturePad] = useState(null);
  const [isSignatureValid, setIsSignatureValid] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState("");
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [sections, setSections] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { farmerId, cropSeasonId, edit } = location?.state; // Access the passed row data
  const row = {
    functionalityName: "Season Registration",
    requestDate: "2024-11-11",
    status: "Pending Approval",
    requestor: "Alfada RombusCola",
    approvalDesc: "Description",
  };
  const { t } = useTranslation();

  const handleMoreDetailsClick = () => {
    setShowMoreDetails(!showMoreDetails); // Toggle visibility of the MoreDetails box
  };

  const callAsessmentApi = (farmerId, cropSeasonId) => {
    PreAssessmentFormService.getPreAssessmentFormDetails({
      farmerId,
      cropSeasonId,
    })
      .then((res) => {
        const farmerResults = res?.data?.response?.farmerDetails || {};

        const updatedFields = formFields.map((field) => ({
          ...field,
          value: farmerResults[field.id] || " ",
        }));
        setFormFields(updatedFields);

        const sectionResults = res?.data?.response?.sections || [];
        setSections(sectionResults);

        // Initialize answeredQuestions based on sectionResults
        const initializedAnsweredQuestions = sectionResults.flatMap((section) =>
          section.questions
            .filter((question) => question.givenAnswerId) // Only include questions with a givenAnswerId
            .map((question) => ({
              questionId: question.questionId,
              answerIds: [question.givenAnswerId], // Store the givenAnswerId in answerIds
            }))
        );
        setAnsweredQuestions(initializedAnsweredQuestions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    callAsessmentApi(farmerId, cropSeasonId);
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleCloseComment = () => setOpenComment(false);

  const handleAnswerChange = (questionId, newAnswerId) => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        questions: section.questions.map((q) =>
          q.questionId === questionId ? { ...q, givenAnswerId: newAnswerId } : q
        ),
      }))
    );

    setAnsweredQuestions((prevAnsweredQuestions) => {
      const questionExists = prevAnsweredQuestions.find(
        (answeredQuestion) => answeredQuestion.questionId === questionId
      );

      if (questionExists) {
        // Update the existing answered question
        return prevAnsweredQuestions.map((answeredQuestion) =>
          answeredQuestion.questionId === questionId
            ? { ...answeredQuestion, answerIds: [newAnswerId] }
            : answeredQuestion
        );
      } else {
        // Add a new answered question
        return [
          ...prevAnsweredQuestions,
          {
            questionId,
            answerIds: [newAnswerId],
          },
        ];
      }
    });
  };

  const [formFields, setFormFields] = useState([
    {
      id: "fiscalId",
      label: "Fiscal ID",
      value: "",
    },
    {
      id: "farmerName",
      label: "Farmer",
      value: "",
    },
    {
      id: "registry",
      label: "Rural Environment Registry",
      value: "",
    },
    {
      id: "city",
      label: "City",
      value: "",
    },
    {
      id: "state",
      label: "State",
      value: "",
    },
    {
      id: "cropSeason",
      label: "Crop Season",
      value: "",
    },
    {
      id: "fieldTechnicianName",
      label: "Field Technician",
      value: "",
    },
    {
      id: "esgStatus",
      label: "ESG Status",
      value: "",
    },
    {
      id: "approvalDate",
      label: "Approval/Reject Date",
      value: "",
    },
  ]);

  const handleClearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
      setIsSignatureValid(false);
    }
  };


  const handleSaveSignature = () => {
    if (signaturePad && !signaturePad.isEmpty()) {
      const signatureDataURL = signaturePad.toDataURL(); // Save signature as a base64 string
      setSignatureUrl(signatureDataURL);
      console.log("Signature Saved:", signatureDataURL);
      handleUpdate(signatureDataURL); // Pass the signature directly
    } else {
      alert("Please provide a signature before submitting.");
    }
  };
  
  const handleUpdate = async (signatureDataURL) => {
    setLoading(true);
    try {
      const payload = {
        farmerId,
        cropSeasonId,
        userId: null,
        signature: {
          fileName: "Signature.png",
          base64FileContent: signatureDataURL, // Use the passed signature
        },
        countryId: "9B72D29C-1555-480B-AC8B-3B10F383B5F6",
        languageId: null,
        answeredQuestions: answeredQuestions,
      };
      console.log("Payload : ", payload);
  
      const response = await PreAssessmentFormService.createPreAssessmentData(payload);
  
      if (response.status === 201) {
        navigate("/preAssessmentFormApplication");
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
      } else {
        console.error("Failed to update form");
        setSeverity("error");
        setMessage("Failed to update form");
      }
    } catch (error) {
      setSeverity("error");
      setMessage(error?.response?.data?.message || error?.message);
    }
    setOpenAlert(true);
    setLoading(false);
  };
  

  const isSubmitDisabled = sections.some((section) =>
    section.questions.some((item) => !item.givenAnswerId)
  );

  return (
    <Layout title="Pre Assessment - Form Application" background="#ffffff">
      {edit === false && (
        <Box
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)", // Subtle shadow all around
            borderRadius: "5px",
            height: "max-content",
            paddingBottom: "40px",
            border: "1px solid rgba(0, 0, 0, 0.08)", // Very light border for paper effect
            marginBottom: "15px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography sx={{ fontWeight: "600", color: "#666666" }}>
              Approval Instance
            </Typography>
          </Box>
          <table className="pre-assessment-table">
            <thead>
              <tr>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("System Module")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Approval Description")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Requestor")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Request Date")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("status")}
                </th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  {t("Approval Level")}
                </th>
                <th className="actions-header">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      justifyContent: "left",
                    }}
                  >
                    {t("Details/Actions")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  marginBottom: "0px",
                  borderBottom: !showMoreDetails && "1px solid lightgray",
                }}
              >
                <td>{row.functionalityName}</td>
                <td>{row.approvalDesc}</td>
                <td>{row.requestor}</td>
                <td>{row.requestDate}</td>
                <td>{row.status}</td>
                <td>{"Level 1"}</td>
                <td
                  className="actions-cell"
                  onClick={handleMoreDetailsClick} // Toggle MoreDetails box on click
                  style={{ cursor: "pointer", backgroundColor: "#EFEFEF" }}
                >
                  <Tooltip
                    title={showMoreDetails ? "Less Details" : "More Details"}
                    arrow
                    placement="top"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                      }}
                    >
                      <img src={Details} alt="Details" />
                      <img
                        src={Arrow}
                        style={{
                          transform: !showMoreDetails && "rotate(180deg)",
                        }}
                        height="12px"
                        alt="Details"
                      />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
          {showMoreDetails && (
            <div className="more-details-box">
              <table className="more-details-table">
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Approval Level")}</th>
                    <th>{t("User")}</th>
                    <th>{t("Comment")}</th>
                    <th>{t("Action")}</th>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("Attachment")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{row.requestDate}</td>
                    <td>{"Level 1"}</td>
                    <td>{row.requestor}</td>
                    <td>{row.approvalDesc}</td>
                    <td>{row.status}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={downloadIcon} alt="Download Icon" />
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Buttons Section */}
              <div className="button-container">
                <div className="left-buttons">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#102A62",
                      alignItems: "center",
                      gap: "8px",
                      color: "#ffffff",
                    }}
                    onClick={() => setOpenComment(true)}
                  >
                    <img
                      src={Plus}
                      alt="Plus Icon"
                      style={{ width: 20, height: 17 }}
                    />
                    {t("ADD COMMENT")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#004F9F",
                      alignItems: "center",
                      gap: "8px",
                      color: "#ffffff",
                    }}
                  >
                    <img
                      src={download}
                      alt="Download Icon"
                      style={{ width: 20, height: 17 }}
                    />
                    {t("EXPORT HISTORY")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      )}

      <>
        <Paper sx={{ p: 2, backgroundColor: "#f5f5f5", padding: "42px 20px" }}>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <Grid item xs={12} sm={6} md={2.4} key={field.id}>
                <TextField
                  label={field.label}
                  value={field.value}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "#e9ebee !important",
                    borderRadius: "4px",
                    "& .MuiInputLabel-root": {
                      color: "black",
                      fontWeight: "550",
                    },
                    "& .MuiOutlinedInput-root": {
                      minHeight: "50px",
                      backgroundColor: "#e9ebee",
                      color: "black",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dedfe0",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dedfe0",
                      },
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      backgroundColor: "#e9ebee !important",
                      color: "black",
                    },
                  }}
                  size="small"
                />
              </Grid>
            ))}
          </Grid>
        </Paper>

        <Box sx={{ width: "100%", mt: 5 }}>
          {sections.map((section, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Divider
                title={t(`${section.sectionName}`)}
                sx={{ borderColor: "#1976d2", borderWidth: 2, mb: 2 }}
              />

              <List sx={{ bgcolor: "#ffffff", marginTop: "10px" }}>
                {section.questions.map((question, itemIndex) => (
                  <ListItem
                    key={itemIndex}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 15px",
                      marginBottom: "10px",
                      bgcolor: "#EFEFEF",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography sx={{ flex: 1 }}>
                      {question.questionDescription}
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        value={
                          question.answers.find(
                            (answer) =>
                              answer.answerId === question.givenAnswerId
                          )?.answerDescription || ""
                        }
                        sx={{ ml: 2 }}
                        onChange={(e) =>
                          handleAnswerChange(
                            question.questionId,
                            question.answers.find(
                              (answer) =>
                                answer.answerDescription === e.target.value
                            )?.answerId
                          )
                        }
                      >
                        {question.answers.map((answer, answerIndex) => (
                          <FormControlLabel
                            key={answer.answerId}
                            value={answer.answerDescription}
                            control={<Radio disabled={edit === false} />}
                            label={answer.answerDescription}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          //   backgroundColor: "#f5f5f5",
        }}
      >
        {/* Submit Section */}
        <Box
          sx={{
            position: "fixed",
            background: "white",
            bottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #e9ebee",
            padding: "10px 0",
            zIndex: "111",
          }}
        >
          <Button
            sx={{
              height: "46px",
              background: "#737D86",
              color: "white",
              "&:hover": { background: "#5a6872" },
            }}
            onClick={() => navigate("/preAssessmentFormApplication")}
          >
            Back
          </Button>
          {edit !== false && (
            <Button
              onClick={handleOpenModal}
              disabled={isSubmitDisabled}
              sx={{
                height: "46px",
                background: isSubmitDisabled ? "#cccccc" : "#EF7D00",
                color: "white",
                "&:hover": {
                  background: isSubmitDisabled ? "#cccccc" : "#EF7D00",
                },
                marginRight: "30px",
              }}
            >
              Submit for Approval
            </Button>
          )}
        </Box>

        {/* Signature Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="signature-modal-title"
          aria-describedby="signature-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50rem",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography
                onClick={handleCloseModal}
                sx={{ cursor: "pointer", color: "#004F9F", fontWeight: 500 }}
              >
                Cancel
              </Typography>
              <Typography
                id="signature-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Signature
              </Typography>
              <Typography
                onClick={handleClearSignature}
                sx={{ cursor: "pointer", color: "#ACB4BA", fontWeight: 500 }}
              >
                Clear
              </Typography>
            </Box>
            <SignatureCanvas
              ref={(ref) => setSignaturePad(ref)}
              penColor="black"
              canvasProps={{
                width: 800,
                height: 350,
                style: { border: "1px solid #000", borderRadius: "8px" },
              }}
              onEnd={() => setIsSignatureValid(!signaturePad?.isEmpty())}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <Typography
                disabled={!isSignatureValid}
                onClick={handleSaveSignature}
                sx={{
                  color: isSignatureValid ? "black" : "#cccccc",
                  cursor: "pointer",
                }}
              >
                Done
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openComment}
          onClose={handleCloseComment}
          aria-labelledby="signature-modal-title"
          aria-describedby="signature-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50rem",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <Button onClick={handleCloseComment}>Close</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Layout>
  );
};

export default PreAssementDetail;
