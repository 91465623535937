import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import arrowLeft from "assets/arrowLeft.svg";
import { useTranslation } from "react-i18next";
import BranchTable from "./BranchTable";
import BankFieldTechnicanConfigService from "services/BankFieldTechnicanConfigService";

const BranchesList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bankRegistrationId = location?.state?.bankRegistrationId;
  const bankConfigID = location?.state?.bankConfigID;
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [postalCode, setPostalCode] = useState("");
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [showUsedRecords, setShowUsedRecords] = useState(false);
  const [branchNumber, setBranchNumber] = useState("");
  const [branchName, setBranchName] = useState("");

  // Debug logging
  useEffect(() => {
    console.log("Bank Registration ID:", bankRegistrationId);
    console.log("Bank Config ID:", bankConfigID);
  }, [bankRegistrationId, bankConfigID]);

  // Fetch regions when bankRegistrationId is available
  useEffect(() => {
    if (bankRegistrationId) {
      fetchRegions();
    }
  }, [bankRegistrationId]);

  // Fetch cities when component mounts or bankRegistrationId changes
  useEffect(() => {
    if (bankRegistrationId) {
      fetchCities();
    }
  }, [bankRegistrationId]);

  // Initial data fetch
  useEffect(() => {
    if (bankRegistrationId) {
      fetchData(currentPage, itemsPerPage);
    } else {
      setMessage("Bank registration ID is missing");
      setSeverity("error");
      setOpenAlert(true);
    }
  }, [currentPage, itemsPerPage, bankRegistrationId]);

  const fetchData = (pageNumber, size) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      bankConfigID: bankConfigID,
    };

    BankFieldTechnicanConfigService.getBranchList(query)
      .then((res) => {
        const results =
          res?.data?.response?.bankBranchFieldTechnicianConfigurationResponse ||
          [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching branch list:", error);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRegions = () => {
    BankFieldTechnicanConfigService.getRegion({ bankRegistrationId })
      .then((res) => {
        const regions = res?.data?.response || [];
        const formattedRegions = regions.map((region) => ({
          regionName: region,
        }));
        setRegionOptions(formattedRegions);
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      });
  };

  const fetchCities = () => {
    BankFieldTechnicanConfigService.getCity({ bankRegistrationId })
      .then((res) => {
        const cities = res?.data?.response || [];
        const formattedCities = cities.map((city) => ({
          cityName: city,
        }));
        setCityOptions(formattedCities);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      });
  };

  // New search function
  const searchBranches = (pageNumber, size) => {
    // Check if all search fields are empty
    const isSearchEmpty =
      !branchNumber &&
      !branchName &&
      !postalCode &&
      !region &&
      !city &&
      !showUsedRecords;

    // If all search fields are empty, call fetchData
    if (isSearchEmpty) {
      fetchData(pageNumber, size);
      return;
    }

    setLoading(true);
    const query = {
      pageNumber,
      pageSize: size,
      bankConfigID,
      branchNumber: branchNumber || undefined,
      branchName: branchName || undefined,
      postalCode: postalCode || undefined,
      region: region?.regionName || region || undefined,
      city: city?.cityName || city || undefined,
      isUsed: showUsedRecords || undefined,
    };

    BankFieldTechnicanConfigService.searchBranch(query)
      .then((res) => {
        const results =
          res?.data?.response?.bankBranchCityFieldTechConfigResponse || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        console.error("Error searching branch list:", error);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(() => {
      setCurrentPage(0);
      searchBranches(0, itemsPerPage);
    }, 1000),
    [branchNumber, branchName, postalCode, region, city, showUsedRecords]
  );

  // Trigger search when filter values change
  useEffect(() => {
    if (bankRegistrationId) {
      debouncedSearch();
    }
  }, [branchNumber, branchName, postalCode, region, city, showUsedRecords]);

  // Initial data load
  useEffect(() => {
    if (bankRegistrationId) {
      fetchData(currentPage, itemsPerPage);
    } else {
      setMessage("Bank registration ID is missing");
      setSeverity("error");
      setOpenAlert(true);
    }
  }, []);

  // Pagination effect
  useEffect(() => {
    if (bankRegistrationId) {
      searchBranches(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  // Consistent TextField styling
  const textFieldStyle = {
    "& .MuiOutlinedInput-root": {
      height: 46,
      "& input": {
        height: 46,
        padding: "0 14px",
        boxSizing: "border-box",
      },
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -9px) scale(0.75)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  };

  const handleBack = () => {
    navigate("/bankConfiguration");
  };

  return (
    <Layout
      title={t("bankConfiguration.branchesList")}
      background="#ffffff"
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 1,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
          height: "auto",
          minHeight: "130px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex" sx={{ marginLeft: 2 }}>
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <TextField
              label={t("bankConfiguration.branchNo")}
              value={branchNumber}
              onChange={(e) => setBranchNumber(e.target.value)}
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#102a62" }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <TextField
              label={t("bankConfiguration.branchName")}
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              sx={textFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#102a62" }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <TextField
              label={t("bankConfiguration.postalCode")}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              sx={textFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#102a62" }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={region}
              onChange={(event, newValue) => {
                setRegion(newValue);
              }}
              options={regionOptions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.regionName || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("bankConfiguration.region")}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyle}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#102a62" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={city}
              onChange={(event, newValue) => {
                setCity(newValue);
              }}
              options={cityOptions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.cityName || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("bankConfiguration.city")}
                  fullWidth
                  variant="outlined"
                  sx={textFieldStyle}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#102a62" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            paddingLeft: "16px !important",
            paddingTop: "8px !important",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showUsedRecords}
                onChange={(e) => setShowUsedRecords(e.target.checked)}
                color="primary"
              />
            }
            label={t("usedRecords")}
            sx={{ margin: 0 }}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Typography variant="h6" align="center">
          {t("loading")}
        </Typography>
      ) : (
        <BranchTable data={data} />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "fixed",
          width: "100%",
          left: 0,
          bottom: "19px",
          background: "white",
          zIndex: 999,
          padding: "0.5rem",
          borderTop: "1px solid var(--Gray-1, #E9EBEE)",
        }}
      >
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            marginLeft: "30px",
          }}
          variant="contained"
          onClick={handleBack}
          startIcon={<img src={arrowLeft} alt="Arrow" />}
        >
          {t("back")}
        </Button>
      </Box>
    </Layout>
  );
};

export default BranchesList;
