import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  TextField,
  FormHelperText,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import editButton from "../../../assets/editButton.svg";
import Layout from "components/structure/Layout";
import { useNavigate } from "react-router-dom";
import Service from "../../../services/SpacingServices";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import iconView from "../../../assets/iconView.svg";
import iconEdit from "../../../assets/iconEdit.svg";
import { SnackbarContext } from "context/snackBarContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SpacingTable from "./SpacingTable";
import { debounce } from "lodash";
import label from "../Growing/growingLabel.json";

const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  height: 10px;
  margin-top: 5px;
`;

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const GetSpacing = () => {
  const [tableVisible, setTableVisible] = useState(true);
  const [spacing, setCropYear] = useState(2024);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState();
  console.log("🚀 ~ GetSpacing ~ data:", data);
  const [errors, setErrors] = useState({});
  const [tobaccoType, setTobaccoType] = useState("");
  const [loading, setLoading] = React.useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [tobaccoGroups, setTobaccoGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  console.log("🚀 ~ GetSpacing ~ tobaccoGroups:", tobaccoGroups);
  const statusData = ["Active", "Inactive"];
  const [status, setStatus] = useState([]);
  const [tempRows, setTempRows] = useState();
  const [tempData, setTempData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [recordsError, setRecordsError] = useState(false);
  const [searchData, setSearchData] = useState({
    tobaccoType: "",
    status: "",
  });

  console.log("ssp", data);
  console.log("sspSD", searchData);

  const handleButtonClick = async () => {
    navigate("/addSpacing");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchData);
      console.log("cleanedParams", cleanedParams);
      if (
        Object.keys(cleanedParams).length === 1 &&
        cleanedParams.countryCode
      ) {
        fetchData(currentPage, itemsPerPage);
        return;
      }
      console.log("currentPage", currentPage);
      Service.filter({
        countryCode: "BRA",
        searchParams: cleanedParams,
        page: currentPage,
        size: itemsPerPage,
      })
        .then((response) => {
          setData(response?.data?.response);
          setCurrentPage(response?.data?.response?.currentPage || 0);
          setItemsPerPage(itemsPerPage);
          setTotalItems(response?.data?.response?.totalItems || 0);
          setRecordsError(false);
          if (response.status === 404) {
            setCurrentPage(0);
          }
        })
        .catch((error) => {
          setRecordsError(true);
          if (error?.response?.status === 404) {
            setCurrentPage(0);
          }
        });
    }, 500),
    [searchData.tobaccoType, searchData.status, currentPage, itemsPerPage]
  );

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchData)).length > 0) {
      handleSearch();
    } else {
      console.log("Api");
      fetchData(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchData, currentPage, itemsPerPage, handleSearch]);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  // const handleTobaccoGroup = (event) => {
  //   setSearchData({
  //     ...searchData,
  //     tobaccoType: event.target.value,
  //   });

  //   filterByTobaccoType(event.target.value);
  // };

  // const filterByTobaccoType = async (tobaccoType) => {
  //   try {
  //     setLoading(true);
  //     const response = await Service.filter({
  //       countryCode: "BRA",
  //       tobaccoType: tobaccoType,
  //       pageNumber: 0,
  //       pageSize: itemsPerPage,
  //     });
  //     const spacingRegistrations =
  //       response?.data?.response?.spacingRegistrations || [];
  //     const rows = spacingRegistrations.map((spacing, index) => ({
  //       id: index + 1,
  //       tobaccoType: spacing.tobaccoType,
  //       countryCode: spacing.countryCode,
  //       languageId: spacing.languageId,
  //       cropYearUid: spacing.cropYearUid,
  //       lineSpacing: spacing.lineSpacing,
  //       spaceRegistrationUid: spacing.spaceRegistrationUid,
  //       plantSpacing: spacing.plantSpacing,
  //       status: spacing.status,
  //     }));
  //     setData(response?.data?.response);
  //     setCurrentPage(0);
  //     setItemsPerPage(itemsPerPage);
  //     setTotalItems(response?.data?.response?.totalItems || 0);
  //     setRows(rows);
  //     setTempRows(rows);
  //     setLoading(false);
  //     if (response.status === 404) {
  //       setRows([]);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     if (error?.response?.status === 404) {
  //       setRows([]);
  //     }
  //   }
  // };

  const columns = [
    {
      field: "tobaccoType",
      headerName: "Tobacco Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lineSpacing",
      headerName: "Line Spacing",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "plantSpacing",
      headerName: "Plant Spacing",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unitOfMeasure",
      headerName: "Unit Of Measure",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        console.log("params", params);
        return [
          <GridActionsCellItem
            icon={<img src={editButton} alt="edit" />}
            label="Edit"
            className="gridActionCellEditSingle"
            onClick={() =>
              navigate(`/addSpacing`, {
                state: {
                  id: params.id,
                  tobaccoType: params.row.tobaccoType,
                  countryId: params.row.countryId,
                  languageId: params.row.languageId,
                  cropYearUid: params.row.cropYearUid,
                  lineSpacing: params.row.lineSpacing,
                  plantSpacing: params.row.plantSpacing,
                  unitOfMeasure: params.row.unitOfMeasure,
                  spaceRegistrationUid: params.row.spaceRegistrationUid,
                  status: params.row.status,
                  edit: true,
                },
              })
            }
          />,

          <GridActionsCellItem
            icon={<img src={iconView} height="30px" width="30px" alt="View" />}
            className="gridActionCell gridActionCellEdit"
            label="Details"
            onClick={() =>
              navigate(`/addSpacing`, {
                state: {
                  id: params.id,
                  tobaccoType: params.row.tobaccoType,
                  countryId: params.row.countryId,
                  languageId: params.row.languageId,
                  cropYearUid: params.row.cropYearUid,
                  lineSpacing: params.row.lineSpacing,
                  plantSpacing: params.row.plantSpacing,
                  unitOfMeasure: params.row.unitOfMeasure,
                  spaceRegistrationUid: params.row.spaceRegistrationUid,
                  status: params.row.status,
                  edit: true,
                  details: true,
                },
              })
            }
            showInMenu
          />,
          <GridActionsCellItem
            icon={<img src={iconEdit} height="30px" width="30px" />}
            className="gridActionCell gridActionCellEdit"
            label="Edit"
            onClick={() =>
              navigate(`/addSpacing`, {
                state: {
                  id: params.id,
                  tobaccoType: params.row.tobaccoType,
                  countryId: params.row.countryId,
                  languageId: params.row.languageId,
                  cropYearUid: params.row.cropYearUid,
                  lineSpacing: params.row.lineSpacing,
                  plantSpacing: params.row.plantSpacing,
                  unitOfMeasure: params.row.unitOfMeasure,
                  spaceRegistrationUid: params.row.spaceRegistrationUid,
                  status: params.row.status,
                  edit: true,
                },
              })
            }
            showInMenu
          />,
        ];
      },
    },
  ];

  const paginate = (pageNumber, itemsPerPage) => {
    fetchData(pageNumber - 1, itemsPerPage);
  };

  const fetchData = async (pNumber = 0, size = itemsPerPage) => {
    try {
      const response = await Service.get({ pageNumber: pNumber, size: size });
      setData(response?.data?.response);
      setTempData(response?.data?.response);
      setCurrentPage(pNumber);
      setItemsPerPage(size);
      setLoading(false);
      setTotalItems(response?.data?.response?.totalItems);
      if (response.status === 404) {
        setRecordsError(true);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 404) {
        setRecordsError(true);
      }
    }
  };
  useEffect(() => {
    Service.getTobbacoType()
      .then((response) => {
        // Extract crop years from response
        console.log("cultureResponse", response);
        const cropYearsFromApi = response.data.response.map(
          (item) => item.tobaccoGroup
        );
        setTobaccoGroups(cropYearsFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // const handleClear = (name) => {
  //   setSearchData({
  //     ...searchData,
  //     [name]: "",
  //   });
  //   fetchData(0, itemsPerPage);
  // };
  const handleClear = (name) => {
    setSearchData({
      ...searchData,
      [name]: "",
    });
  };

  useEffect(() => {
    fetchData(0, itemsPerPage);
  }, []);

  return (
    <Layout
      title="Spacing Registration"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      useNewPagination={true}
      currentPage={currentPage}
    >
      <div>
        <RegistrationOptions>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="tobaccoType"
                  labelId="cy-select-label"
                  label={"Tobacco Type"}
                  value={searchData.tobaccoType || "PL"}
                  name="tobaccoType"
                  onChange={handleChange}
                  error={!!errors.tobaccoType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.tobaccoType && (
                      <CloseIcon
                        onClick={() => handleClear("tobaccoType")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    Select an option
                  </MenuItem>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {tobaccoGroups.map((tobacco) => (
                    <MenuItem key={tobacco} value={tobacco}>
                      {tobacco}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.tobaccoType}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 400 },
                  minHeight: 50,
                }}
              >
                <TextField
                  select
                  id="status"
                  labelId="cy-select-label-status"
                  label={"Status"}
                  value={searchData.status || "PL"}
                  name="status"
                  onChange={handleChange}
                  error={!!errors.tobaccoType}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    displayEmpty: true,
                    endAdornment: searchData.status && (
                      <CloseIcon
                        onClick={() => handleClear("status")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="PL" disabled sx={{ display: "none" }}>
                    Select an option
                  </MenuItem>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {statusData.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.tobaccoType}
                </FormHelperText>
              </FormControl>

              {/* <FormControl
                  sx={{ width: { xs: "100%", md: 222 }, minHeight: 50 }}
                >
                  <TextField
                    select
                    id="tobaccoType"
                    labelId="tobacco-select-label"
                    label={"Select Tobacco Type"}
                    value={tobaccoType}
                    onChange={(e) => setTobaccoType(e.target.value)}
                    error={!!errors.tobaccoType}
                  >
                    <MenuItem value="">Select Tobacco Type</MenuItem>
                    <MenuItem value={"CT1"}>CT1</MenuItem>
                    <MenuItem value={"CT2"}>CT2</MenuItem>
                    <MenuItem value={"CT3"}>CT3</MenuItem>
                    <MenuItem value={"CT4"}>CT4</MenuItem>
                 
                  </TextField>
                  <FormHelperText error={true}>
                    {errors.tobaccoType}
                  </FormHelperText>
                </FormControl> */}
            </Grid>
          </Grid>
          <Grid
            style={{ justifyContent: "flex-end" }}
            item
            xs={12}
            md={12}
            xl={12}
          >
            <div>
              <Button
                sx={{
                  width: { xs: "100%", md: 150 },
                  minHeight: 55,
                  borderRadius: 2,
                  background: "#004F9F",
                }}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={handleButtonClick}
              >
                Add New
              </Button>
            </div>
          </Grid>
        </RegistrationOptions>

        {/* {data && (
          <DataContainer>
            <DataTable data={data} rows={rows} columns={columns} />
          </DataContainer>
        )} */}
        {!loading &&
          data &&
          data.spacingRegistrations &&
          data.spacingRegistrations.length > 0 &&
          !recordsError ? (
          <SpacingTable data={data.spacingRegistrations} />
        ) : (
          <div>{label.noDataMessage}</div>
        )}
      </div>
    </Layout>
  );
};

export default GetSpacing;
