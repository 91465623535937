import React, { useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Typography,
  Modal,
  Grid,
  Divider,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import _Minus_ from "assets/_Minus_.svg";
import _Plus_ from "assets/_Plus_.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import RuleService from "services/RuleService";
import { rulesSchema } from "utils/validations";

export default function AddRules() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("vkkkk", location);
  const { segmentationKpisRoutinesRulesId, code, name, description,isDelete } =
    location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    segmentationKpisRoutinesRulesId: segmentationKpisRoutinesRulesId,
    code: code || "",
    name: name || "", // Assuming this is the tray type description
    description: description || "",
    isDelete: isDelete === true ? true : false,

  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/segmentationKPI-Rules");
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("WERTYUI", name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    console.log("Calledsubmit");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    try {
      await rulesSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        code: values?.code,
        name: values?.name,
        description: values?.description,
        isDelete: values?.isDelete ?? false,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };
      console.log("payload is:",payload);
      try {
        setLoading(true);
        const response = await RuleService.createRules({ ...payload });
        setLoading(false);

        if (response.status === 201) {
          setSeverity("success");
          setMessage("Record saved successfuly!");
          setOpenAlert(true);
          navigate("/segmentationKPI-Rules");
        }
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.submissionFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        segmentationKpisRoutinesRulesId,
        code: values?.code,
        name: values?.name,
        description: values?.description,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };
      console.log("Update Payload:", payload);
      try {
        setLoading(true);
        const response = await RuleService.updateRule({ ...payload });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          navigate("/segmentationKPI-Rules");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout
      title={t("Segmentation KPI's - Routines and Rules Registration (Form)")}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              //height: "342px",
              height:"auto"
              //  height: hasTransporterDataErrors() ? "500px" : "440px",
              //   padding: "10px",
              //   borderRadius: "5px",
              //  transition: "height 200ms ease-in-out",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>{t("form")}</Typography>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={8}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label={t("segmentationKPI.name")}
                    InputLabelProps={{ shrink: true }}
                    value={values?.name}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.name} // Show error state
                    helperText={errors.name || " "} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3.8}>
                <FormControl fullWidth>
                  <TextField
                    name="code"
                    label={t("code")}
                    InputLabelProps={{ shrink: true }}
                    value={values?.code}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.code} // Show error state
                    helperText={errors.code || " "} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name="description"
                    label={t("description")}
                    InputLabelProps={{ shrink: true }}
                    //placeholder="-"
                    value={values?.description}
                    disabled={edit == false}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.description} // Show error state
                    helperText={errors.description || " "} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px", // Adjust padding
                        alignItems: "flex-start", // Align content to the top
                      },
                    }}
                    InputProps={{
                      style: {
                        height: "216px", // Fixed height
                        padding: "8px", // Ensure padding inside the input
                        lineHeight: "1.5", // Set appropriate line height for text alignment
                      },
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ paddingBottom: "100px" }}>
            <form autoComplete="off" noValidate>
              <Divider
                sx={{
                  position: "fixed",
                  bottom: "82px",
                  width: "97%",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "fixed",
                  width: "96%",
                  bottom: 6,
                  padding: "0.5rem",
                  background: "white",
                  zIndex: 999,
                }}
              >
                {/* Cancel Button - Left bottom corner */}
                <Button
                  style={{
                    backgroundColor: "#737D86",
                    padding: "6px 16px",
                    borderRadius: "4px",
                    marginBottom: "16px",
                    height: "44px",
                    marginLeft: "10px",
                  }}
                  variant="contained"
                  onClick={
                    edit == false ? handleDeleteConfirm : handleOpenModal
                  }
                  disabled={loading}
                  startIcon={<img src={arrowLeft} alt="Arrow" />}
                >
                  {loading && <CircularProgress size={20} />}
                  {edit == false ? t("back") : t("cancel")}
                </Button>

                <Modal
                  open={open}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "7px",
                      width: "585px",
                      margin: "auto",
                      mt: 25,
                      height: "200px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#102A62",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "20px",
                          fontWeight: 500,
                        }}
                      >
                        {t("cancelTitle")}
                      </Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2, fontWeight: 400 }}
                      >
                        {t("cancelMessage")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        mb: 1,
                      }}
                    >
                      <Button
                        onClick={handleCloseModal}
                        sx={{
                          background: "#737D86",
                          width: "100px",
                          color: "#ffffff",
                          "&:hover": { background: "#737D86" },
                        }}
                      >
                        {t("no")}
                      </Button>
                      <Button
                        onClick={handleDeleteConfirm}
                        sx={{
                          background: "#004F9F",
                          width: "100px",
                          color: "#ffffff",
                          "&:hover": { background: "#004F9F" },
                        }}
                      >
                        {t("yes")}
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* Save Button - Right bottom corner */}
                {edit != false && (
                  <Button
                    sx={{
                      padding: "6px 16px",
                      borderRadius: "4px",
                      marginBottom: "16px",
                      backgroundColor: "#EF7D00",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#EF7D00",
                      },
                      marginRight: "10px",
                    }}
                    disabled={loading}
                    startIcon={<img src={Save} alt="Right Arrow" />}
                    variant="contained"
                    onClick={!edit ? handleSubmit : handleUpdate}
                  >
                    {loading && <CircularProgress size={20} />}
                    {t("save")}
                  </Button>
                )}
              </Box>
            </form>
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
