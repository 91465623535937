import React, { useEffect, useState } from "react";
import { Typography, Button, Modal, Box } from "@mui/material";
import "./SpacingTable.css";
import detailsIcon from "../../../assets/eyeIcon.svg";
import _ButtonEdit_ from "../../../assets/_ButtonEdit_.svg";
import { useNavigate } from "react-router-dom";

const SpacingTable = (props) => {
  const navigate = useNavigate();
  const [spacingData, setspacingData] = useState([]);
  useEffect(() => {
    setspacingData(props.data);
  }, [props.data]);

  console.log("spacingData", props.data);

  return (
    <div className="table-container">
      <table className="full-width-table">
        <thead>
          <tr className="HierarchyHead">
            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
              }}
            >
              Tobacco Type
            </th>

            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => {}}
            >
              Line Spacing
            </th>
            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => {}}
            >
              Plant Spacing
            </th>
            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => {}}
            >
              Unit Of Measure
            </th>

            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => {}}
            >
              Status
            </th>

            <th
              style={{
                textAlign: "center",
                paddingBottom: "1%",
                paddingLeft: "7%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {spacingData?.map((item, index) => (
            <tr key={index} className="hierarchyColumn">
              <td style={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.tobaccoType}
                </Typography>
              </td>

              <td style={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.lineSpacing}
                </Typography>
              </td>

              <td style={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.plantSpacing}
                </Typography>
              </td>

              <td style={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.unitOfMeasure}
                </Typography>
              </td>

              <td
                style={{
                  textAlign: "left",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {item.status ? "Active" : "Inactive"}
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    marginTop: "-25px",
                  }}
                >
                  <Button
                    style={{ marginTop: "15px", marginRight: "-15px" }}
                    onClick={() =>
                      navigate("/addSpacing", {
                        state: {
                          id: index,
                          tobaccoType: item.tobaccoType,
                          countryId: item.countryId,
                          languageId: item.languageId,
                          cropYearUid: item.cropYearUid,
                          lineSpacing: item.lineSpacing,
                          plantSpacing: item.plantSpacing,
                          unitOfMeasure: item.unitOfMeasure,
                          noOfPlants: item.noOfPlants,
                          spaceRegistrationUid: item.spaceRegistrationUid,
                          status: item.status,
                          edit: true,
                        },
                      })
                    }
                  >
                    <img src={_ButtonEdit_} alt="Edit Button" />
                  </Button>

                  <Button
                    style={{ marginTop: "20px", marginRight: "5px" }}
                    onClick={() =>
                      navigate(`/addSpacing`, {
                        state: {
                          id: index,
                          tobaccoType: item.tobaccoType,
                          countryId: item.countryId,
                          languageId: item.languageId,
                          cropYearUid: item.cropYearUid,
                          lineSpacing: item.lineSpacing,
                          plantSpacing: item.plantSpacing,
                          unitOfMeasure: item.unitOfMeasure,
                          noOfPlants: item.noOfPlants,
                          spaceRegistrationUid: item.spaceRegistrationUid,
                          status: item.status,
                          edit: true,
                          details: true,
                        },
                      })
                    }
                  >
                    <div>
                      <img src={detailsIcon} alt="Details" />
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default SpacingTable;
