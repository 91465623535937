import { httpGrow } from "../http-common";

const getBankList = async (query) => {
  const response = await httpGrow.get(
    `/bankFieldTechnicianConfig/bank-config-list?pageNumber=${query?.pageNumber}&size=${query?.size}&batOneId=85193243`
  );
  return response;
};

const searchBank = async (query) => {
  const {
      pageNumber = 0,
      size = 50,
      keyword,
      isUsed
  } = query;

  const response = await httpGrow.get(`/bankFieldTechnicianConfig/search-bank-abbreviation?&batId=85193243`, {
      params: {
          pageNumber,
          size,
          keyword: keyword || undefined,
          isUsed: isUsed !== undefined ? isUsed : undefined
      }
  });
  return response;
};

const searchBranch = async (query) => {
  const {
      pageNumber = 0,
      pageSize = 10,
      branchNumber,
      branchName,
      postalCode,
      city,
      region,
      isUsed,
      batId = 85193243
  } = query;

  const response = await httpGrow.get(`/bankFieldTechnicianConfig/search-bank-branch-details?`, {
      params: {
          pageNumber,
          pageSize,
          branchNumber: branchNumber || undefined,
          branchName: branchName || undefined,
          postalCode: postalCode || undefined,
          city: city || undefined,
          region: region || undefined,
          isUsed: isUsed !== undefined ? isUsed : undefined,
          batId
      }
  });
  return response;
};

const getBranchList = async (query) => {
  const response = await httpGrow.get(
    `/bankFieldTechnicianConfig/branch-config-list?pageNumber=${query?.pageNumber}&size=${query?.size}&bankConfigID=${query?.bankConfigID}`
  );
  return response;
};

const getCity = async (query) => {
  const response = await httpGrow.get(
    `/bankFieldTechnicianConfig/city-details?bankRegistrationId=${query.bankRegistrationId}`
  );
  return response;
};

const getRegion = async (query) => {
  const response = await httpGrow.get(
    `/bankFieldTechnicianConfig/region-details?bankRegistrationId=${query.bankRegistrationId}`
  );
  return response;
};



const updateBank = async (data) => {
  const response = await httpGrow.put(`/bankFieldTechnicianConfig/bank-field-update`, data);
  return response;
};

const updateBranch = async (data) => {
  const response = await httpGrow.put(`/bankFieldTechnicianConfig/branch-field-update`, data);
  return response;
};

const BankFieldTechnicanConfigService = {
  getBankList,
  getBranchList,
  searchBank,
  searchBranch,
  updateBank,
  updateBranch,
  getCity,
  getRegion
};

export default BankFieldTechnicanConfigService;
