import { styled } from "@mui/material/styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Save from "assets/SaveNew.svg";
import PaymentPolicyLimitsDistributionService from "services/PaymentPolicyLimitsDistributionService";
import { debounce } from "lodash";

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: "separate",
  borderSpacing: "0px 4px",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const SubHeaderCell2 = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "0px!important",
  paddingRight: "5px !important",
  borderBottom: "none",
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const PaymentPolicyLimitsTable = forwardRef(
  (
    {
      data,
      cropYearUUID,
      ihId,
      childNodes,
      isTopHierarchyNode,
      refreshData,
      setShowExitWarningOverlayFeed,
    },
    ref
  ) => {
    const [tableData, setTableData] = useState([]);
    console.log("INITIAL Data", data, isTopHierarchyNode);

    const { setOpenAlert, setSeverity, setMessage } =
      useContext(SnackbarContext);
    const { t } = useTranslation();
    const columns = ["Distributed", "Consumed", "Balance"];
    const [showColumns, setShowColumns] = useState({
      distributed: true,
      consumed: true,
      balance: true,
    });
    const [minColumnModel, setMinColumnModel] = useState(false);

    const handleColumnChange = (key, isChecked) => {
      const uncheckedCount = Object.values(showColumns).filter(
        (value) => !value
      ).length;

      if (!isChecked && uncheckedCount >= 2) {
        setMinColumnModel(true);
        return;
      }
      setShowColumns((prev) => ({
        ...prev,
        [key]: isChecked,
      }));
      setMinColumnModel(false);
    };

    const [manageItemsModal, setManageItemsModal] = useState(false);
    const [removePolicyModal, setRemovePolicyModal] = useState(false);
    const [removedPolicyTableId, setRemovedPolicyTableId] = useState();
    const [policyList, setPolicyList] = useState([]);
    const [paymentListSearch, setPaymentListSearch] = useState("");
    const [policyHeader, setPolicyHeader] = useState([]);
    const [paymentPolicyTotal, setPaymentPolicyTotal] = useState([]);

    const handlePolicyListChange = (row, isChecked) => {
      if (!isChecked) {
        // for false i.e unchecked
        setRemovedPolicyTableId(row.paymentPolicyId);
        setRemovePolicyModal(true);
      } else {
        // for true i.e checked
        setPolicyList((prevList) =>
          prevList.map(
            (item) =>
              item.paymentPolicyId === row.paymentPolicyId
                ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
          )
        );
        setPolicyHeader((prevHeader) => [
          ...prevHeader,
          { ...row, isHeader: true },
        ]);
      }
    };

    const handlePolicyHeaderChange = (selectedHeader) => {
      setPolicyHeader((prevHeader) =>
        prevHeader.map(
          (header) =>
            // Check if the header's description matches the selectedHeader's description
            header.PPDescription === selectedHeader.PPDescription
              ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
              : header // Leave the other headers unchanged
        )
      );
    };

    const handleRemovePolicyFromModal = async () => {
      let policyDeletePermanent = false;
      data.forEach((item) => {
        if (item.paymentPolicyId === removedPolicyTableId) {
          policyDeletePermanent = true;
        }
      });
      if (policyDeletePermanent) {
        try {
          const response =
            await PaymentPolicyLimitsDistributionService.deletePolicyAPI(
              cropYearUUID,
              removedPolicyTableId
            );

          if (response?.status === 200) {
            setPolicyList((prevList) =>
              prevList.map(
                (item) =>
                  item.paymentPolicyId === removedPolicyTableId
                    ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
                    : item // Leave the rest unchanged
              )
            );
            setPolicyHeader((prevHeader) =>
              prevHeader.filter(
                (item) => item.paymentPolicyId !== removedPolicyTableId
              )
            );
            refreshData();
          }
        } catch (error) {
          console.error("Error deleting data:", error);
          setMessage(error.message || "Error deleteing Policy");
          setSeverity("error");
          setOpenAlert(true);
        }
      } else {
        setPolicyList((prevList) =>
          prevList.map(
            (item) =>
              item.paymentPolicyId === removedPolicyTableId
                ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
          )
        );
        setPolicyHeader((prevHeader) =>
          prevHeader.filter(
            (item) => item.paymentPolicyId !== removedPolicyTableId
          )
        );
      }
    };

    const handleTargetChange = (
      inputValue,
      importHierarchyId,
      description,
      rowIndex
    ) => {
      setShowExitWarningOverlayFeed(true);
      setTableData((prev) => {
        const updatedData = [...prev];
        const targetRow = updatedData.find(
          (row) =>
            row.importHierarchyId === importHierarchyId &&
            row.policyDescription === description
        );
        if (targetRow) {
          targetRow.target = inputValue; // Update target value as a string
        }
        return updatedData;
      });
    };

    const fetchPolicyLimits = async (keyword) => {
      try {
        const policyResponse =
          await PaymentPolicyLimitsDistributionService.getPolicyAPI({
            keyword: keyword,
          });

        const updatedPolicyGroups =
          policyResponse.data.response.paymentPolicy.map((policy) => ({
            ...policy,
            inHeaderList: policy.status,
          }));
        setPolicyList(updatedPolicyGroups);
      } catch (error) {
        console.error("Error fetching policy data:", error);
        setPolicyList([]);
      }
    };

    const debouncedFetchPolicyList = useCallback(
      debounce((value) => {
        fetchPolicyLimits(value);
      }, 300),
      [policyList]
    );

    const handlePolicyListSearch = (event) => {
      const { value } = event.target;
      setPaymentListSearch(value);
      debouncedFetchPolicyList(value);
    };

    const fetchPolicyHeader = async () => {
      try {
        const policyResponse =
          await PaymentPolicyLimitsDistributionService.getPolicyAPI({
            keyword: paymentListSearch,
          });

        const IsActivePolicyGroups =
          policyResponse.data.response.paymentPolicy.filter(
            (policy) => policy.status === true
          );

        const updatedIsActivePolicyGroups = IsActivePolicyGroups.map(
          (group) => ({
            ...group,
            isHeader: group.status,
          })
        );

        setPolicyHeader(updatedIsActivePolicyGroups);
      } catch (error) {
        console.error("Error fetching policy data:", error);
      }
    };

    useEffect(() => {
      fetchPolicyHeader();
      fetchPolicyLimits("");
    }, []);

    useEffect(() => {
      if (childNodes && policyList && policyHeader) {
        // Initialize initialData as an empty array if data is not provided or empty
        let initialData = data && data.length > 0 ? [...data] : [];
        console.log("initialData", initialData);

        childNodes.forEach((child) => {
          const childExists = initialData.some(
            (item) => item.importHierarchyId === child.value
          );

          if (!childExists) {
            policyHeader.forEach((policy) => {
              const policyExists = initialData.some(
                (item) =>
                  item.importHierarchyId === child.value &&
                  item.policyDescription === policy.PPDescription
              );

              if (!policyExists) {
                initialData.push({
                  importHierarchyId: child.value,
                  policyDescription: policy.PPDescription,
                  paymentPolicyId: policy.paymentPolicyId,
                  target: 0,
                  distributed: 0,
                  consumed: 0,
                  balance: 0,
                  createdDateTimeUTC: new Date().toISOString(),
                  modifiedDateTimeUTC: new Date().toISOString(),
                  createdByUser: "defaultUser",
                  modifiedByUser: "defaultUser",
                  paymentPolicyLimitsDistId: "defaultId",
                  isDelete: false,
                });
              }
            });
          } else {
            policyHeader.forEach((policy) => {
              const policyExists = initialData.some(
                (item) =>
                  item.importHierarchyId === child.value &&
                  item.policyDescription === policy.PPDescription
              );

              if (!policyExists) {
                initialData.push({
                  importHierarchyId: child.value,
                  policyDescription: policy.PPDescription,
                  paymentPolicyId: policy.paymentPolicyId,
                  target: 0,
                  distributed: 0,
                  consumed: 0,
                  balance: 0,
                  createdDateTimeUTC: new Date().toISOString(),
                  modifiedDateTimeUTC: new Date().toISOString(),
                  createdByUser: "defaultUser",
                  modifiedByUser: "defaultUser",
                  paymentPolicyLimitsDistId: "defaultId",
                  isDelete: false,
                });
              }
            });
          }
        });
        setTableData(initialData);
      }
    }, [data, childNodes, policyList]);

    const calculatePolicyTotals = (tableData) => {
      const totals = tableData.reduce((acc, group) => {
        const existingGroup = acc.find(
          (item) => item.policyDescription === group.policyDescription
        );

        if (existingGroup) {
          // If the group with the same policyDescription exists, update totals
          existingGroup.totalTarget =
            Math.round((existingGroup.totalTarget + group.target) * 100) / 100;
          existingGroup.totalDistributed =
            Math.round(
              (existingGroup.totalDistributed + group.distributed) * 100
            ) / 100;
          existingGroup.totalConsumed =
            Math.round((existingGroup.totalConsumed + group.consumed) * 100) /
            100;
          existingGroup.totalBalance =
            Math.round((existingGroup.totalBalance + group.balance) * 100) /
            100;
          existingGroup.available =
            Math.round(
              (existingGroup.totalTarget - existingGroup.totalDistributed) * 100
            ) / 100;
        } else {
          // If it's a new policyDescription, create a new entry
          acc.push({
            policyDescription: group.policyDescription,
            totalTarget: group.target,
            totalDistributed: group.distributed,
            totalConsumed: group.consumed,
            totalBalance: group.balance,
            available: group.target - group.distributed,
          });
        }

        return acc;
      }, []);

      return totals;
    };

    useEffect(() => {
      if (tableData) {
        const updatedTotals = calculatePolicyTotals(tableData);
        setPaymentPolicyTotal(updatedTotals);
      }
    }, [tableData]);

    useImperativeHandle(ref, () => ({
      save: handleSave,
    }));

    const handleSave = async () => {
      try {
        const currentDateTime = new Date().toISOString();
        const currentUser = "currentUser"; // Replace with actual user info
        const payload = tableData.map((item) => ({
          createdDateTimeUTC:
            item.paymentPolicyLimitsDistId === "defaultId"
              ? currentDateTime
              : item.createdDateTimeUTC,
          createdByUser:
            item.paymentPolicyLimitsDistId === "defaultId"
              ? currentUser
              : item.createdDateTimeUTC,
          modifiedDateTimeUTC: currentDateTime,
          modifiedByUser: currentUser,
          paymentPolicyLimitsDistId:
            item.paymentPolicyLimitsDistId === "defaultId"
              ? ""
              : item.paymentPolicyLimitsDistId,
          importHierarchyId: item.importHierarchyId,
          cropYearId: cropYearUUID,
          paymentPolicyId: item.paymentPolicyId,
          target: item.target,
          distributed: item.distributed,
          consumed: item.consumed,
          balance: item.balance,
          isDelete: item.isDelete,
        }));

        console.log("Sending payload to API:", payload);

        await PaymentPolicyLimitsDistributionService.putPaymentPolicyDistributionAPI(
          payload
        );
        setShowExitWarningOverlayFeed(false);
        setMessage("Policies saved successfully");
        setSeverity("success");
        setOpenAlert(true);
        refreshData();
        return true;
      } catch (error) {
        console.error("Error saving data:", error);
        setMessage(error.message || "Error saving policies");
        setSeverity("error");
        setOpenAlert(true);
      }
    };

    return (
      <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First Grid Item */}
          <Grid item xs={4}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography fontWeight="700" color="#666666">
                  {t("paymentPolicyLimits.showColumns")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: 1,
                  overflowX: "auto", // Enables horizontal scrolling
                  whiteSpace: "nowrap", // Prevents wrapping of child elements
                }}
              >
                {columns.map((column) => {
                  const isDisabled =
                    Object.values(showColumns).filter((value) => value).length <
                      2 && showColumns[column.toLowerCase()];

                  return (
                    <Box key={column}>
                      <FormControlLabel
                        control={
                          <Tooltip
                            title={
                              isDisabled
                                ? t("paymentPolicyLimits.columnTooltip")
                                : ""
                            }
                            arrow
                            disableInteractive
                          >
                            <span>
                              <Checkbox
                                disabled={isDisabled}
                                checked={showColumns[column.toLowerCase()]}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#004F9F", // Color when the checkbox is checked
                                  },
                                  "&.Mui-disabled": {
                                    color: "gray", // Color when the checkbox is disabled
                                  },
                                }}
                                onChange={(e) =>
                                  handleColumnChange(
                                    column.toLowerCase(),
                                    e.target.checked
                                  )
                                }
                              />
                            </span>
                          </Tooltip>
                        }
                        label={column}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "16px",
                            fontWeight: 400,
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>

          {/* Second Grid Item */}
          <Grid item xs={8}>
            <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
                <Typography fontWeight="700" color="#666666">
                  {t("paymentPolicyLimits.showHeaders")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Header checkboxes */}
                <Box
                  sx={{
                    flex: 1,
                    overflowX: "auto",
                    display: "flex",
                    gap: 1,
                    ml: 1,
                  }}
                >
                  {policyHeader &&
                    policyHeader.length > 0 &&
                    policyHeader.map((header) => {
                      // Calculate the count of selected headers
                      const selectedHeadersCount = policyHeader.filter(
                        (h) => h.isHeader
                      ).length;

                      // Determine if the checkbox should be disabled
                      const isDisabled =
                        selectedHeadersCount < 2 && header.isHeader;

                      return (
                        <Box
                          item
                          key={header.paymentPolicyId}
                          sx={{
                            flexShrink: 0, // Prevents items from shrinking
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Tooltip
                                title={
                                  isDisabled
                                    ? t("paymentPolicyLimits.headerTooltip")
                                    : ""
                                }
                                arrow
                              >
                                <span>
                                  {" "}
                                  {/* Wrapper for Tooltip to work on disabled elements */}
                                  <Checkbox
                                    checked={header.isHeader}
                                    disabled={isDisabled}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#004F9F", // Color when the checkbox is checked
                                      },
                                      "&.Mui-disabled": {
                                        color: "gray", // Color when the checkbox is disabled
                                      },
                                    }}
                                    onChange={() =>
                                      handlePolicyHeaderChange(header)
                                    }
                                  />
                                </span>
                              </Tooltip>
                            }
                            label={header.PPDescription}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "16px",
                                fontWeight: 400,
                              },
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
                {/* Manage Items button */}
                {isTopHierarchyNode && (
                  <Box
                    sx={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ mr: 2, backgroundColor: "#004F9F", mt: "-5px" }}
                      onClick={() => setManageItemsModal(true)}
                    >
                      Manage Items
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
          <Grid item xs={4}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "25px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SubHeaderCell
                      colSpan={4}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "26px",
                      }}
                    ></SubHeaderCell>
                  </TableRow>
                  <TableRow>
                    <SecondaryHeaderCell colSpan={4} sx={{ fontWeight: 600 }}>
                      {t("paymentPolicyLimits.hnSearchField")}
                    </SecondaryHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((row, index) => (
                    <TableRow key={index}>
                      <DataCell colSpan={4}>{row.label}</DataCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TotalCell colSpan={4}>TOTALS</TotalCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
          <Grid item xs={8}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {policyHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) => (
                        <HeaderCell
                          colSpan={Math.max(
                            2,
                            1 +
                              Object.values(showColumns).filter(Boolean).length
                          )}
                          sx={{ borderRight: "5px solid white" }}
                        >
                          {row.PPDescription}
                        </HeaderCell>
                      ))}
                  </TableRow>
                  <TableRow>
                    {policyHeader
                      .filter((header) => header.isHeader === true)
                      .map((header) =>
                        paymentPolicyTotal
                          .filter(
                            (item) =>
                              item.policyDescription === header.PPDescription
                          )
                          .map((policy) => (
                            <SubHeaderCell2
                              colSpan={Math.max(
                                2,
                                1 +
                                  Object.values(showColumns).filter(Boolean)
                                    .length
                              )}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "left",
                                  marginLeft: 0,
                                  width: "97%", // Full width for inner content
                                  border: "1px solid #E9EBEE",
                                  borderRadius: "8px", // Rounded top-left edge
                                  padding: "13px 9px 13px 5px",
                                }}
                              >
                                {t("paymentPolicyLimits.available/target")}
                                <span>
                                  <>
                                    {policy.available} / {policy.totalTarget}
                                  </>
                                </span>
                              </Box>
                            </SubHeaderCell2>
                          ))
                      )}
                  </TableRow>
                  <TableRow>
                    {policyHeader
                      .filter((header) => header.isHeader === true)
                      .map((row, index) =>
                        ["Target", "Distributed", "Consumed", "Balance"]
                          .filter((header) => {
                            const key = header.toLowerCase(); // Convert to lowercase to match state keys
                            return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                          })
                          .map((header) => (
                            <SecondaryHeaderCell
                              key={header}
                              style={{
                                borderLeft:
                                  header === "Target"
                                    ? "none"
                                    : "5px solid white",
                                borderRight: "5px solid white",
                              }}
                            >
                              {header}
                            </SecondaryHeaderCell>
                          ))
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((child) => (
                    <TableRow key={child.value}>
                      {policyHeader
                        .filter((header) => header.isHeader === true)
                        .map((header) => {
                          const rows = tableData.filter(
                            (data) =>
                              data.importHierarchyId === child.value &&
                              data.policyDescription === header.PPDescription
                          );
                          return rows.map((row, rowIndex) => {
                            return [
                              "target",
                              "distributed",
                              "consumed",
                              "balance",
                            ].map((key, i) => {
                              const value = row[key]; // Access the corresponding value

                              if (key === "target") {
                                // Render "target" as a TextField
                                return (
                                  <StyledTextField
                                    type="number"
                                    key={`${rowIndex}-${i}`}
                                    fullWidth
                                    variant="outlined"
                                    value={
                                      value === 0
                                        ? "0" // Show zero explicitly
                                        : /^\d+(\.\d+)?$/.test(value)
                                        ? value
                                            .toString()
                                            .replace(/^0+(?=\d)/, "") // Remove leading zeros for whole numbers
                                        : value
                                    }
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                          display: "none",
                                        },
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === "e" ||
                                        e.key === "E" ||
                                        e.key === "-" ||
                                        e.key === "+"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Validate and handle changes for "target"
                                      if (
                                        /^\d{0,8}(\.\d{0,2})?$/.test(inputValue)
                                      ) {
                                        handleTargetChange(
                                          parseFloat(inputValue) || 0,
                                          child.value,
                                          header.PPDescription
                                        ); // Update the "target" value
                                      }
                                    }}
                                  />
                                );
                              } else {
                                // Render other values as plain text
                                if (!showColumns[key]) return null;
                                return (
                                  <DataCell key={`${rowIndex}-${i}`}>
                                    {value?.toLocaleString()}
                                  </DataCell>
                                );
                              }
                            });
                          });
                        })}
                    </TableRow>
                  ))}
                  <TableRow>
                    {policyHeader
                      .filter((header) => header.isHeader === true)
                      .map((item) =>
                        paymentPolicyTotal
                          .filter(
                            (total) =>
                              total.policyDescription === item.PPDescription
                          )
                          .map((itemTotal) => (
                            <>
                              <TotalCell>{itemTotal.totalTarget}</TotalCell>
                              {showColumns.distributed && (
                                <TotalCell>
                                  {itemTotal.totalDistributed}
                                </TotalCell>
                              )}
                              {showColumns.consumed && (
                                <TotalCell>{itemTotal.totalConsumed}</TotalCell>
                              )}
                              {showColumns.balance && (
                                <TotalCell>{itemTotal.totalBalance}</TotalCell>
                              )}
                            </>
                          ))
                      )}
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
        </Grid>
        <Divider
          sx={{
            width: "100%", // Ensures the divider spans the full width
            marginBottom: 1, // Adds spacing between the divider and the button
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              marginBottom: 4,
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            onClick={handleSave}
            variant="contained"
          >
            {t("save")}
          </Button>
        </Box>
        <Modal
          open={manageItemsModal}
          onClose={() => setManageItemsModal(false)}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "929px",
              margin: "auto",
              mt: 2,
              maxHeight: "90vh", // Prevent modal from exceeding viewport height
              display: "flex",
              flexDirection: "column", // Ensures the layout respects natural flow
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                color: "#fff",
                pt: 2,
                pb: 1,
              }}
            >
              {t("paymentPolicyLimits.modalTitle")}
            </Box>
            <Box
              sx={{
                background: "#EFEFEF",
                borderRadius: "10px",
                padding: "1rem",
                height: "110px",
                margin: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
                <Typography>{t("filtersLable")}</Typography>
              </Box>
              <TextField
                label={t("paymentPolicyLimits.description")}
                value={paymentListSearch}
                onChange={handlePolicyListSearch}
                size="medium"
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder="-"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1, // Allows the scrollable area to take up remaining space
                overflowY: "auto", // Enables scrolling  // Enable vertical scrolling
                overflowX: "hidden", // Prevent horizontal scrolling
                padding: "1rem",
                margin: 1,
              }}
            >
              {policyList && policyList.length > 0 ? (
                policyList.map((row) => (
                  <Box
                    item
                    key={row.paymentPolicyId}
                    sx={{
                      background: "#EFEFEF",
                      borderRadius: "10px",
                      padding: "1rem",
                      margin: 1,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row.inHeaderList}
                          onChange={() =>
                            handlePolicyListChange(row, !row.inHeaderList)
                          }
                        />
                      }
                      label={row.PPDescription}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Typography>{t("paymentPolicyLimits.noData")}</Typography>
              )}
            </Box>
            <Box
              sx={{
                position: "sticky", // Make it stick within the modal
                bottom: 0, // Stick to the bottom of the container
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "60px",
                zIndex: 2, // Ensure it stays above the content
              }}
            >
              <Button
                onClick={() => setManageItemsModal(false)}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("paymentPolicyLimits.close")}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={removePolicyModal}
          onClose={() => setRemovePolicyModal(false)}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "560px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                color: "#fff",
              }}
            >
              {t("paymentPolicyLimits.removeItemConfirm")}
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "pre-wrap",
                  width: "550px",
                  overflowX: "auto",
                }}
              >
                {t("paymentPolicyLimits.removeMessage")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "60px",
                pt: 4,
              }}
            >
              <Button
                onClick={() => setRemovePolicyModal(false)}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("no")}
              </Button>

              <Button
                onClick={() => {
                  handleRemovePolicyFromModal();
                  setRemovePolicyModal(false);
                }}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("yes")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
);
export default PaymentPolicyLimitsTable;
