import React, { useState } from 'react';
import { TextField, Button, Switch, FormControlLabel, Typography, Box, Grid } from '@mui/material';
import { ChromePicker } from 'react-color';
import { useLocation, useNavigate } from 'react-router-dom';
import MyEditor from '../Editor/MyEditor';

const AddArticle = ({ onAddArticle }) => {
  const [articleName, setArticleName] = useState('');
  const [itemColor, setItemColor] = useState('#ffffff');
  const [itemTextColor, setItemTextColor] = useState('#000000');
  const [availableOnWeb, setAvailableOnWeb] = useState(false);
  const [availableOnMobile, setAvailableOnMobile] = useState(false);
  const [icon, setIcon] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [editorValue, setEditorValue] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  let state = location?.state;

  const handleAddArticle = () => {
    const newArticle = {
      id: Date.now(),
      name: articleName,
      itemColor,
      itemTextColor,
      availableOnWeb,
      availableOnMobile,
      icon,
      attachments,
      editorValue
    };
    let m = state;
    console.log("m", m);
    m.push(newArticle);
    navigate('/pocArticle', { state: m }); // Navigate back to the article list
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }}>
        Add New Article
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <MyEditor handleEditorChange = {(value)=>setEditorValue(value)}/>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              maxWidth: 600,
              margin: '0 auto',
              padding: 2,
              backgroundColor: '#f9f9f9',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <TextField
              label="Article Name"
              value={articleName}
              onChange={(e) => setArticleName(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ marginBottom: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <Box sx={{ flex: 1, marginRight: 1 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Item Color
                </Typography>
                <ChromePicker color={itemColor} onChangeComplete={(color) => setItemColor(color.hex)} />
              </Box>
              <Box sx={{ flex: 1, marginLeft: 1 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Item Text Color
                </Typography>
                <ChromePicker color={itemTextColor} onChangeComplete={(color) => setItemTextColor(color.hex)} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <FormControlLabel
                control={<Switch checked={availableOnWeb} onChange={(e) => setAvailableOnWeb(e.target.checked)} />}
                label="Available on Web"
              />
              <FormControlLabel
                control={<Switch checked={availableOnMobile} onChange={(e) => setAvailableOnMobile(e.target.checked)} />}
                label="Available on Mobile"
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <Button variant="contained" component="label">
                Upload Icon
                <input type="file" hidden onChange={(e) => setIcon(e.target.files[0])} />
              </Button>
              <Button variant="contained" component="label">
                Upload Attachments
                <input type="file" hidden multiple onChange={(e) => setAttachments(e.target.files)} />
              </Button>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleAddArticle}>
                Add Article
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddArticle;