import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  Modal,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import arrowLeft from "assets/arrowLeft.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Save from "assets/SaveNew.svg";
import RedSwitch from "utils/RedSwitch";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import DragIcon from "assets/_DragabbleIcon_.svg";
import PAFBService from "services/PAFBService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const PreAssessmentFormBuilderDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  // Add state for modal
  const [open, setOpen] = useState(false);

  // Initialize formData with a more comprehensive structure
  const [formData, setFormData] = useState({
    createdByUser: "RAMESH",
    modifiedByUser: "RAMESH",
    fcpFbFormVersion: "",
    fcpFbStartDate: null,
    fcpFbEndDate: null,
    isDelete: false,
    sectionDtoList: [
      {
        createdByUser: "RAMESH",
        modifiedByUser: "RAMESH",
        fcpFsSectionName: "",
        fcpFsSectionOrder: 1,
        fcpFsRequiredForExistingFarmer: false,
        fcpFsRequiredForNewFarmer: false,
        isDelete: false,
        questionDtoList: [
          {
            createdByUser: "RAMESH",
            modifiedByUser: "RAMESH",
            fcpFqQuestionCode: "",
            fcpFqQuestion: "",
            isDelete: false,
            answerDtoList: [
              {
                createdByUser: "RAMESH",
                modifiedByUser: "RAMESH",
                fcpFaAnswerCode: "",
                fcpFqFaAnswer: "",
                fcpFaScore: "",
                fcpFaDisqualifyingAnswer: false,
                isDelete: false,
              },
            ],
          },
        ],
      },
    ],
  });

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  // Filter states
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [searchParams, setSearchParams] = useState({
    description: "",
    startDate: null,
    endDate: null,
  });

  // Add new state for form version and dates
  const [formVersion, setFormVersion] = useState("");

  // Update the state to track individual field submission
  const [formSubmitted, setFormSubmitted] = useState({
    formVersion: false,
    startDate: false,
  });

  // Modify handleDateChange to reset only start date submission
  const handleDateChange = (name) => (newValue) => {
    // Reset start date submission state when date changes
    setFormSubmitted((prev) => ({
      ...prev,
      startDate: false,
    }));

    // Update search params
    setSearchParams((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    // Update formData with date in the specific format without timezone
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        [`fcpFb${name.charAt(0).toUpperCase() + name.slice(1)}`]: newValue
          ? dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
      };

      console.log("FormData after date change:", updatedFormData);
      return updatedFormData;
    });
  };

  // Modify handleFormVersionChange to reset only form version submission
  const handleFormVersionChange = (e) => {
    const version = e.target.value;

    // Limit the input to 50 characters
    if (version.length > 50) {
      return; // Prevent further input if limit is reached
    }

    // Reset form version submission state when user starts typing
    setFormSubmitted((prev) => ({
      ...prev,
      formVersion: false,
    }));

    // Update form version state
    setFormVersion(version);

    // Update formData with form version
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        fcpFbFormVersion: version,
      };

      console.log("FormData after version change:", updatedFormData);
      return updatedFormData;
    });
  };

  const [values, setValues] = useState({
    requiredForExistingFarmer: false,
    requiredForNewFarmer: false,
  });

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [name]: checked,
      };

      console.log("Switch values:", updatedValues);
      return updatedValues;
    });
  };

  const handleDelete = () => {
    // Implement your delete logic here
    // This could open a confirmation modal, or directly delete the section
    // For example:
    // setOpenDeleteConfirmModal(true);
    // or
    // deleteSectionFromForm();
  };

  // Add a new state for cancel modal
  const [openCancelModal, setOpenCancelModal] = useState(false);

  // Update handleCancel to show cancel confirmation modal
  const handleCancel = () => {
    if (isViewMode) {
      // If in view mode, directly navigate back to the list page
      navigate("/preAssessmentFormBuilderList");
    } else {
      // For edit/create mode, show the cancel confirmation modal
      setOpenCancelModal(true);
    }
  };

  // Add handler to close cancel modal
  const handleCancelModalClose = () => {
    setOpenCancelModal(false);
  };

  // Add handler to confirm cancellation
  const handleCancelConfirm = () => {
    // Close the modal
    setOpenCancelModal(false);

    // Navigate to the pre-assessment form builder list page
    navigate("/preAssessmentFormBuilderList");
  };

  // Update handleOpenCancelModal to use setOpen
  const handleOpenCancelModal = () => {
    setOpen(true);
  };

  // Update handleCloseCancelModal to use setOpen
  const handleCloseCancelModal = () => {
    setOpen(false);
  };

  // Update handleDeleteCancelConfirm to use setOpen
  const handleDeleteCancelConfirm = () => {
    // Close the modal
    setOpen(false);

    // Navigate to the pre-assessment form builder list page
    navigate("/preAssessmentFormBuilderList");
  };

  // Update handleSave to use formData
  const handleSave = async () => {
    // Set form submitted to true for both fields to trigger validation display
    setFormSubmitted({
      formVersion: true,
      startDate: true,
    });

    try {
      // Validate form data before saving
      const isValid = validateFormData();

      if (isValid) {
        // Reset form submitted state after successful validation
        setFormSubmitted({
          formVersion: false,
          startDate: false,
        });

        // Check if we're in edit or duplicate mode
        const locationState = location.state || {};
        const { row, edit, duplicate } = locationState;

        // Prepare payload
        const payload = {
          ...formData,
          // Add additional fields for update scenario
          ...(edit || duplicate
            ? {
                createdDateTimeUTC:
                  row?.createdDateTimeUTC || new Date().toISOString(),
                createdByUser: row?.createdByUser || "RAMESH",
                modifiedDateTimeUTC: new Date().toISOString(),
                modifiedByUser: "RAMESH",
                fcpFbId: row?.fcpFbId, // Include ID for update
              }
            : {}),
        };

        // Decide which service method to use
        const saveMethod =
          edit || duplicate
            ? PAFBService.updatePreAssessmentForm
            : PAFBService.createPreAssessmentForm;

        // Call appropriate service method
        const response = await saveMethod(payload);

        if (response && response.status === 200) {
          setOpenAlert(true);
          setSeverity("success");
          setMessage(
            edit || duplicate
              ? t("PreAssessmentFormBuilder.updateSuccess")
              : t("PreAssessmentFormBuilder.addSuccess")
          );
          navigate("/preAssessmentFormBuilderList");
        }
      } else {
        // Show a general error message
        // setOpenAlert(true);
        // setSeverity("error");
        // setMessage(t("PreAssessmentFormBuilder.validationError"));
      }
    } catch (error) {
      // Reset form submitted state in case of error
      setFormSubmitted({
        formVersion: false,
        startDate: false,
      });

      // Check for backend error message
      const errorMessage =
        error.response?.data?.message || error.message || t("saveErrorMessage");

      setOpenAlert(true);
      setSeverity("error");
      setMessage(errorMessage);
      console.error("Error saving form:", error);
    }
  };

  // Update the form errors state to be more comprehensive
  const [formErrors, setFormErrors] = useState({
    sections: [],
  });

  // Update the validateFormData function to include start date validation
  const validateFormData = () => {
    let isValid = true;
    const errors = { sections: [] };

    // Validate form version
    if (!formVersion || formVersion.trim() === "") {
      // setOpenAlert(true);
      // setSeverity('error');
      // setMessage(t("PreAssessmentFormBuilder.formVersionRequired"));
      isValid = false;
    }

    // Validate start date
    if (!searchParams.startDate) {
      // setOpenAlert(true);
      // setSeverity('error');
      // setMessage(t("PreAssessmentFormBuilder.startDateRequired"));
      isValid = false;
    }

    // Validate each section
    formData.sectionDtoList.forEach((section, sectionIndex) => {
      const sectionErrors = {
        description: false,
        sectionOrder: false,
        questions: [],
      };

      // Validate section name
      if (!section.fcpFsSectionName || section.fcpFsSectionName.trim() === "") {
        sectionErrors.description = true;
        isValid = false;
      }

      // Validate section order
      if (!section.fcpFsSectionOrder || isNaN(section.fcpFsSectionOrder)) {
        sectionErrors.sectionOrder = true;
        isValid = false;
      }

      // Validate questions within the section
      section.questionDtoList.forEach((question, questionIndex) => {
        const questionErrors = {
          questionCode: false,
          questionText: false,
          answers: [],
        };

        // Validate question code
        if (
          !question.fcpFqQuestionCode ||
          question.fcpFqQuestionCode.trim() === ""
        ) {
          questionErrors.questionCode = true;
          isValid = false;
        }

        // Validate question text
        if (!question.fcpFqQuestion || question.fcpFqQuestion.trim() === "") {
          questionErrors.questionText = true;
          isValid = false;
        }

        // Validate answers
        question.answerDtoList.forEach((answer, answerIndex) => {
          const answerErrors = {
            answerCode: false,
            answerText: false,
            score: false,
          };

          // Validate answer code
          if (!answer.fcpFaAnswerCode || answer.fcpFaAnswerCode.trim() === "") {
            answerErrors.answerCode = true;
            isValid = false;
          }

          // Validate answer text
          if (!answer.fcpFqFaAnswer || answer.fcpFqFaAnswer.trim() === "") {
            answerErrors.answerText = true;
            isValid = false;
          }

          // Validate score
          if (answer.fcpFaScore === "" || isNaN(answer.fcpFaScore)) {
            answerErrors.score = true;
            isValid = false;
          }

          questionErrors.answers.push(answerErrors);
        });

        sectionErrors.questions.push(questionErrors);
      });

      errors.sections.push(sectionErrors);
    });

    // Update form errors state
    setFormErrors(errors);

    return isValid;
  };

  // Modify the section state structure to include questions
  const [sections, setSections] = useState([
    {
      id: 1,
      description: "",
      sectionOrder: "",
      requiredForExistingFarmer: false,
      requiredForNewFarmer: false,
      questions: [
        {
          id: 1,
          questionCode: "",
          questionText: "",
          answers: [
            {
              id: 1,
              answerCode: "",
              answerText: "",
              score: "",
              isDisqualifying: false,
            },
          ],
        },
      ],
    },
  ]);

  // Modify handleAddSection to initialize with empty questions
  const handleAddSection = () => {
    if (isViewMode) return;

    const newSection = {
      id: Math.random().toString(),
      fcpFsId: "",
      description: "",
      sectionOrder: "",
      requiredForExistingFarmer: false,
      requiredForNewFarmer: false,
      questions: [], // Initialize with empty questions array
    };

    setSections([...sections, newSection]);
    updateFormData();
  };

  // Modify handleAddQuestion to add to specific section
  const handleAddQuestion = (sectionId) => {
    if (isViewMode) return;

    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            questions: [
              ...section.questions,
              {
                id: Math.random().toString(),
                fcpFqId: "",
                questionCode: "",
                questionText: "",
                answers: [
                  {
                    id: Math.random().toString(),
                    fcpFaId: "",
                    answerCode: "",
                    answerText: "",
                    score: "",
                    isDisqualifying: false,
                  },
                ],
              },
            ],
          };
        }
        return section;
      });
    });

    updateFormData();
  };

  // Modify handleQuestionChange to work with section-specific questions
  const handleQuestionChange = (sectionId, questionId, field, value) => {
    if (isViewMode) return;

    // Limit the question text to 500 characters
    if (field === "questionText" && value.length > 500) {
      return; // Prevent further input if limit is reached
    }

    // Limit the question code to 200 characters
    if (field === "questionCode" && value.length > 200) {
      return; // Prevent further input if limit is reached
    }

    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            questions: section.questions.map((question) =>
              question.id === questionId
                ? { ...question, [field]: value }
                : question
            ),
          };
        }
        return section;
      });
    });

    // Find the section and question indices
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);
    const questionIndex = sections[sectionIndex].questions.findIndex(
      (q) => q.id === questionId
    );

    // Clear the specific error for this field
    setFormErrors((prev) => {
      const updatedSections = [...prev.sections];
      if (
        updatedSections[sectionIndex] &&
        updatedSections[sectionIndex].questions[questionIndex]
      ) {
        updatedSections[sectionIndex].questions[questionIndex][
          field === "questionCode" ? "questionCode" : "questionText"
        ] = false;
      }
      return { ...prev, sections: updatedSections };
    });

    updateFormData();
  };

  // Modify handleAnswerChange for section-specific questions
  const handleAnswerChange = (
    sectionId,
    questionId,
    answerId,
    field,
    value
  ) => {
    if (isViewMode) return;

    // Limit the answer text to 500 characters
    if (field === "answerText" && value.length > 500) {
      return; // Prevent further input if limit is reached
    }

    // Limit the answer code to 200 characters
    if (field === "answerCode" && value.length > 200) {
      return; // Prevent further input if limit is reached
    }

    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            questions: section.questions.map((question) =>
              question.id === questionId
                ? {
                    ...question,
                    answers: question.answers.map((answer) =>
                      answer.id === answerId
                        ? {
                            ...answer,
                            [field]:
                              field === "score"
                                ? value === ""
                                  ? ""
                                  : parseInt(value, 10)
                                : value,
                          }
                        : answer
                    ),
                  }
                : question
            ),
          };
        }
        return section;
      });
    });

    // Find the section, question, and answer indices
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);
    const questionIndex = sections[sectionIndex].questions.findIndex(
      (q) => q.id === questionId
    );
    const answerIndex = sections[sectionIndex].questions[
      questionIndex
    ].answers.findIndex((a) => a.id === answerId);

    // Clear the specific error for this field
    setFormErrors((prev) => {
      const updatedSections = [...prev.sections];
      if (
        updatedSections[sectionIndex] &&
        updatedSections[sectionIndex].questions[questionIndex] &&
        updatedSections[sectionIndex].questions[questionIndex].answers[
          answerIndex
        ]
      ) {
        // Dynamically set the error based on the field
        switch (field) {
          case "answerCode":
            updatedSections[sectionIndex].questions[questionIndex].answers[
              answerIndex
            ].answerCode = false;
            break;
          case "answerText":
            updatedSections[sectionIndex].questions[questionIndex].answers[
              answerIndex
            ].answerText = false;
            break;
          case "score":
            updatedSections[sectionIndex].questions[questionIndex].answers[
              answerIndex
            ].score = false;
            break;
        }
      }
      return { ...prev, sections: updatedSections };
    });

    updateFormData();
  };

  // Add these state declarations near your other state declarations
  const [openQuestionDeleteModal, setOpenQuestionDeleteModal] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);

  // Modify handleDeleteQuestion to show modal instead of direct deletion
  const handleDeleteQuestion = (questionId) => {
    if (isViewMode) return; // Prevent deleting questions in view mode

    // Find the question to get its backend ID (fcpFqId)
    const questionToDelete = sections
      .find((section) => section.questions.some((q) => q.id === questionId))
      ?.questions.find((q) => q.id === questionId);

    // Set the question to be deleted
    setQuestionToDelete({
      id: questionId,
      fcpFqId: questionToDelete?.fcpFqId,
    });

    // Open the delete confirmation modal
    setOpenQuestionDeleteModal(true);
  };

  // Add a new method to handle question deletion confirmation
  const handleDeleteQuestionConfirm = async () => {
    if (questionToDelete) {
      try {
        // If the question has a backend ID, attempt to delete it from the backend
        if (questionToDelete.fcpFqId) {
          await PAFBService.deleteQuestionApi(questionToDelete.fcpFqId);
        }

        // Update local state to remove the question
        setSections((prevSections) =>
          prevSections.map((section) => ({
            ...section,
            questions: section.questions.filter(
              (q) => q.id !== questionToDelete.id
            ),
          }))
        );

        // Show success message using existing context methods
        setOpenAlert(true);
        setSeverity("success");
        setMessage(t("PreAssessmentFormBuilder.questionDeletedSuccessfully"));
      } catch (error) {
        console.error("Error deleting question:", error);

        // Show error message using existing context methods
        setOpenAlert(true);
        setSeverity("error");
        setMessage(t("PreAssessmentFormBuilder.errorDeletingQuestion"));
      }

      // Close the delete modal
      handleQuestionDeleteModalClose();
    }
  };

  // Add methods to handle modal open/close
  const handleQuestionDeleteModalClose = () => {
    setOpenQuestionDeleteModal(false);
    setQuestionToDelete(null);
  };

  // Add a new state for section delete modal
  const [openSectionDeleteModal, setOpenSectionDeleteModal] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  // Modify the handleDeleteSection method
  const handleDeleteSection = (idToRemove) => {
    if (isViewMode) return; // Prevent deleting sections in view mode

    // Set the section to be deleted
    setSectionToDelete(idToRemove);

    // Open the delete confirmation modal
    setOpenSectionDeleteModal(true);
  };

  // Add a new method to handle section deletion confirmation
  const handleDeleteSectionConfirm = async () => {
    if (sectionToDelete) {
      try {
        // Find the section to get its backend ID (fcpFsId)
        const sectionToDeleteDetails = sections.find(
          (section) => section.id === sectionToDelete
        );

        // If the section has a backend ID, attempt to delete it from the backend
        if (sectionToDeleteDetails && sectionToDeleteDetails.fcpFsId) {
          await PAFBService.deleteSectionApi(sectionToDeleteDetails.fcpFsId);
        }

        // Update local state to remove the section
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== sectionToDelete)
        );

        // Update form data after deletion
        updateFormData();

        // Show success message using existing context methods
        setOpenAlert(true);
        setSeverity("success");
        setMessage(t("PreAssessmentFormBuilder.sectionDeletedSuccessfully"));
      } catch (error) {
        console.error("Error deleting section:", error);

        // Show error message using existing context methods
        setOpenAlert(true);
        setSeverity("error");
        setMessage(t("PreAssessmentFormBuilder.errorDeletingSection"));
      }

      // Close the delete modal and reset the section to delete
      handleSectionDeleteModalClose();
    }
  };

  // Add method to close section delete modal
  const handleSectionDeleteModalClose = () => {
    setOpenSectionDeleteModal(false);
    setSectionToDelete(null);
  };

  // Modify the updateFormData method to explicitly use the current questions state
  const updateFormData = (updatedSections = sections) => {
    console.log("updateFormData called with:", {
      updatedSections: updatedSections,
      currentSections: sections,
    });

    setFormData((prevFormData) => {
      const currentDateTime = new Date().toISOString();

      // Generate default form version if not set
      const defaultFormVersion = "";

      // Generate default dates if not set
      const defaultStartDate = "";
      const defaultEndDate = "";

      const updatedFormData = {
        // Preserve existing ID if in edit mode
        ...(prevFormData.fcpFbId ? { fcpFbId: prevFormData.fcpFbId } : {}),

        // Timestamps
        createdDateTimeUTC: prevFormData.createdDateTimeUTC || currentDateTime,
        createdByUser: "RAMESH",
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: "RAMESH",

        // Form version - use existing or generate default
        fcpFbFormVersion: formVersion || defaultFormVersion,

        // Start and end dates - use existing search params or generate defaults
        fcpFbStartDate: searchParams.startDate
          ? dayjs(searchParams.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : defaultStartDate,
        fcpFbEndDate: searchParams.endDate
          ? dayjs(searchParams.endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : defaultEndDate,

        isDelete: false,

        // Sections with enhanced details
        sectionDtoList: updatedSections.map((section, sectionIndex) => {
          // Log section details with more information
          console.log(`Processing section ${sectionIndex}:`, {
            sectionId: section.id,
            sectionIdType: typeof section.id,
            questions: section.questions.map((q) => ({
              id: q.id,
              sectionId: q.sectionId,
              sectionIdType: typeof q.sectionId,
            })),
          });

          return {
            // Include section even if ID is empty
            ...(section.fcpFsId ? { fcpFsId: section.fcpFsId } : {}),

            createdByUser: "RAMESH",
            modifiedByUser: "RAMESH",
            fcpFsSectionName: section.description,
            fcpFsSectionOrder: section.sectionOrder || "",
            fcpFsRequiredForExistingFarmer: section.requiredForExistingFarmer,
            fcpFsRequiredForNewFarmer: section.requiredForNewFarmer,
            isDelete: false,

            questionDtoList: section.questions.map(
              (question, questionIndex) => ({
                // Include question even if ID is empty
                ...(question.fcpFqId ? { fcpFqId: question.fcpFqId } : {}),

                createdDateTimeUTC: currentDateTime,
                createdByUser: "RAMESH",
                modifiedDateTimeUTC: currentDateTime,
                modifiedByUser: "RAMESH",

                fcpFqQuestionCode: question.questionCode || ``,
                fcpFqQuestion: question.questionText,
                isDelete: false,

                answerDtoList: question.answers.map((answer, answerIndex) => ({
                  // Include answer even if ID is empty
                  ...(answer.fcpFaId ? { fcpFaId: answer.fcpFaId } : {}),

                  createdDateTimeUTC: currentDateTime,
                  createdByUser: "RAMESH",
                  modifiedDateTimeUTC: currentDateTime,
                  modifiedByUser: "RAMESH",

                  fcpFaAnswerCode: answer.answerCode || ``,
                  fcpFqFaAnswer: answer.answerText,
                  fcpFaScore:
                    answer.score === "" ? "" : parseInt(answer.score, 10),
                  fcpFaDisqualifyingAnswer: answer.isDisqualifying,
                  isDelete: false,
                })),
              })
            ),
          };
        }),
      };

      console.log("Updated FormData:", updatedFormData);
      return updatedFormData;
    });
  };

  // Add this handler for drag end
  const onDragEnd = (result, questionId) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setSections((prevSections) => {
      const updatedSections = prevSections.map((section) => {
        if (section.id === questionId) {
          const reorderedAnswers = reorder(
            section.questions.find((q) => q.id === questionId).answers,
            result.source.index,
            result.destination.index
          );
          return {
            ...section,
            questions: section.questions.map((q) =>
              q.id === questionId ? { ...q, answers: reorderedAnswers } : q
            ),
          };
        }
        return section;
      });

      // Update formData after reordering
      updateFormData();
      return updatedSections;
    });
  };

  // In the useEffect or initial state setup
  const [isViewMode, setIsViewMode] = useState(false);
  const [isFormVersionReadOnly, setIsFormVersionReadOnly] = useState(false);

  useEffect(() => {
    // Get state from navigation
    const locationState = location.state || {};
    const { row, edit, duplicate, view } = locationState;

    // Set view mode
    setIsViewMode(view === true);

    // Determine if form version should be read-only
    const formVersionReadOnly = edit;

    // Update state to track form version read-only status
    setIsFormVersionReadOnly(formVersionReadOnly);

    const fetchFormDetails = async () => {
      if (row && (edit || duplicate || view)) {
        try {
          // Call getFormDetailsApi to fetch form details
          const response = await PAFBService.getFormDetailsApi(
            row.fcpFbFormVersion,
            duplicate ? true : false
          );

          if (response && response.data && response.data.response) {
            const formDetails = response.data.response;

            console.log("Fetched Form Details:", formDetails);

            // Populate form version and dates
            setFormVersion(formDetails.fcpFbFormVersion);

            // Set search params for dates
            setSearchParams({
              startDate: formDetails.fcpFbStartDate
                ? dayjs(formDetails.fcpFbStartDate)
                : null,
              endDate: formDetails.fcpFbEndDate
                ? dayjs(formDetails.fcpFbEndDate)
                : null,
            });

            // Filter out deleted sections and their contents
            const filteredSections = formDetails.sectionDtoList
              .filter((section) => !section.isDelete)
              .map((section) => ({
                id: section.fcpFsId || Math.random(),
                fcpFsId: section.fcpFsId,
                description: section.fcpFsSectionName,
                sectionOrder: section.fcpFsSectionOrder,
                requiredForExistingFarmer:
                  section.fcpFsRequiredForExistingFarmer,
                requiredForNewFarmer: section.fcpFsRequiredForNewFarmer,
                questions:
                  section.questionDtoList
                    ?.filter((question) => !question.isDelete)
                    .map((question) => ({
                      id: question.fcpFqId || Math.random(),
                      fcpFqId: question.fcpFqId,
                      questionCode: question.fcpFqQuestionCode,
                      questionText: question.fcpFqQuestion,
                      answers:
                        question.answerDtoList
                          ?.filter((answer) => !answer.isDelete)
                          .map((answer) => ({
                            id: answer.fcpFaId || Math.random(),
                            fcpFaId: answer.fcpFaId,
                            answerCode: answer.fcpFaAnswerCode,
                            answerText: answer.fcpFqFaAnswer,
                            score: answer.fcpFaScore.toString(), // Convert to string for TextField
                            isDisqualifying: answer.fcpFaDisqualifyingAnswer,
                          })) || [],
                    })) || [],
              }));

            // Set sections
            setSections(filteredSections);

            // Manually update formData to ensure all details are populated
            setFormData((prev) => {
              const updatedFormData = {
                ...prev,
                ...formDetails,
                sectionDtoList: filteredSections.map((section) => ({
                  fcpFsId: section.fcpFsId,
                  fcpFsSectionName: section.description,
                  fcpFsSectionOrder: section.sectionOrder,
                  fcpFsRequiredForExistingFarmer:
                    section.requiredForExistingFarmer,
                  fcpFsRequiredForNewFarmer: section.requiredForNewFarmer,
                  questionDtoList: section.questions.map((question) => ({
                    fcpFqId: question.fcpFqId,
                    fcpFqQuestionCode: question.questionCode,
                    fcpFqQuestion: question.questionText,
                    answerDtoList: question.answers.map((answer) => ({
                      fcpFaId: answer.fcpFaId,
                      fcpFaAnswerCode: answer.answerCode,
                      fcpFqFaAnswer: answer.answerText,
                      fcpFaScore: parseFloat(answer.score),
                      fcpFaDisqualifyingAnswer: answer.isDisqualifying,
                    })),
                  })),
                })),
              };

              console.log("Updated Form Data:", updatedFormData);
              return updatedFormData;
            });

            // When fetching form details, set form version as read-only only for edit mode
            setIsFormVersionReadOnly(edit);
          }
        } catch (error) {
          console.error("Error fetching form details:", error);
          setOpenAlert(true);
          setSeverity("error");
          setMessage(t("errorFetchingFormDetails"));
        }
      }
    };

    fetchFormDetails();
  }, [location.state]);

  // Modify the existing useEffect for logging
  useEffect(() => {
    console.log("Complete FormData:", JSON.stringify(formData, null, 2));

    const disqualifyingAnswers = formData.sectionDtoList.flatMap((section) =>
      section.questionDtoList.flatMap((question) =>
        question.answerDtoList.filter((answer) => {
          console.log("Checking answer:", {
            answerId: answer.fcpFaId,
            isDisqualifying: answer.fcpFaDisqualifyingAnswer,
          });
          return answer.fcpFaDisqualifyingAnswer;
        })
      )
    );

    console.log("Disqualifying Answers:", disqualifyingAnswers);
  }, [formData]);

  // Modify the handleDeleteAnswer method to use the delete confirmation modal
  const handleDeleteAnswer = (questionId, answerId, fcpFaId) => {
    if (isViewMode) return; // Prevent deleting answers in view mode

    // Open delete confirmation modal with answer details
    handleDeleteModalOpen({
      type: "answer",
      id: answerId,
      questionId: questionId,
      fcpFaId: fcpFaId,
    });
  };

  // Modify handleDeleteModalConfirm to handle answer deletion
  const handleDeleteModalConfirm = async () => {
    if (itemToDelete) {
      if (itemToDelete.type === "answer") {
        try {
          // If the answer has a backend ID, attempt to delete it from the backend
          if (itemToDelete.fcpFaId) {
            await PAFBService.deleteAnswerApi(itemToDelete.fcpFaId);
          }

          // Update local state to remove the answer
          setSections((prevSections) =>
            prevSections.map((section) => ({
              ...section,
              questions: section.questions.map((q) =>
                q.id === itemToDelete.questionId
                  ? {
                      ...q,
                      answers: q.answers.filter(
                        (a) => a.id !== itemToDelete.id
                      ),
                    }
                  : q
              ),
            }))
          );

          // Show success message using existing context methods
          setOpenAlert(true);
          setSeverity("success");
          setMessage(t("PreAssessmentFormBuilder.answerDeletedSuccessfully"));
        } catch (error) {
          console.error("Error deleting answer:", error);

          // Show error message using existing context methods
          setOpenAlert(true);
          setSeverity("error");
          setMessage(t("PreAssessmentFormBuilder.errorDeletingAnswer"));
        }
      }

      // Close the delete modal
      handleDeleteModalClose();
    }
  };

  // Add a useEffect to log changes in sections
  useEffect(() => {
    console.log("Sections Changed:", {
      sections: sections.map((s) => ({
        id: s.id,
        idType: typeof s.id,
      })),
    });

    // Trigger form data update whenever sections change
    updateFormData();
  }, [sections]);

  // Add these state declarations near your other state declarations
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Add these handler methods
  const handleDeleteModalOpen = (item) => {
    setItemToDelete(item);
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setItemToDelete(null);
  };

  // Add this new handler for adding answers
  const handleAddAnswer = (sectionId, questionId) => {
    if (isViewMode) return;

    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            questions: section.questions.map((question) => {
              if (question.id === questionId) {
                return {
                  ...question,
                  answers: [
                    ...question.answers,
                    {
                      id: Math.random().toString(),
                      fcpFaId: "",
                      answerCode: "",
                      answerText: "",
                      score: "",
                      isDisqualifying: false,
                    },
                  ],
                };
              }
              return question;
            }),
          };
        }
        return section;
      });
    });

    updateFormData();
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      title={t("PreAssessmentFormBuilder.title2")}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* Filter Section Container - Provides a gray background for filtering form details */}
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        {/* Filter Section Header with Dropdown Icon */}
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* Grid Container for Filter Inputs - Allows searching and filtering form versions */}
          <Grid container spacing={2}>
            {/* Form Version Input - Allows searching by description */}
            <Grid item xs={12} sm={3}>
              <TextField
                name="description"
                label={t("PreAssessmentFormBuilder.formVersion")}
                value={formVersion}
                onChange={handleFormVersionChange}
                disabled={isViewMode || isFormVersionReadOnly}
                required
                error={
                  formSubmitted.formVersion &&
                  (!formVersion || formVersion.trim() === "")
                }
                helperText={
                  formSubmitted.formVersion &&
                  (!formVersion || formVersion.trim() === "")
                    ? t("PreAssessmentFormBuilder.formVersionRequired")
                    : ""
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    ...(isViewMode && {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #0000003B",
                      },
                    }),
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                  width: "100%",
                }}
                InputProps={{
                  style: { height: "50px" },
                  readOnly: isViewMode || isFormVersionReadOnly,
                }}
              />
            </Grid>

            {/* Start Date Picker - Allows filtering by start date */}
            <Grid item xs={12} sm={2}>
              <DatePicker
                label={t("PreAssessmentFormBuilder.startDate")}
                value={searchParams.startDate}
                onChange={handleDateChange("startDate")}
                disabled={isViewMode}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    error: formSubmitted.startDate && !searchParams.startDate,
                    helperText:
                      formSubmitted.startDate && !searchParams.startDate
                        ? t("PreAssessmentFormBuilder.startDateRequired")
                        : "",
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        minHeight: "50px",
                        padding: "7px",
                        ...(isViewMode && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    },
                    InputProps: {
                      style: { height: "50px" },
                      readOnly: isViewMode,
                    },
                  },
                }}
              />
            </Grid>

            {/* End Date Picker - Allows filtering by end date */}
            <Grid item xs={12} sm={2}>
              <DatePicker
                label={t("PreAssessmentFormBuilder.endDate")}
                value={searchParams.endDate}
                onChange={handleDateChange("endDate")}
                disabled={isViewMode}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        minHeight: "50px",
                        padding: "7px",
                        ...(isViewMode && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    },
                    InputProps: {
                      style: { height: "50px" },
                      readOnly: isViewMode,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>

      {/* Sections Header - Shows "Sections" title and "Add New" button */}
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 10px",
            background: "#FFFFFF",
            marginTop: 3,
          }}
        >
          <Typography variant="h6">
            {t("PreAssessmentFormBuilder.sections")}
          </Typography>
          <Button
            variant="contained"
            onClick={handleAddSection}
            sx={{
              backgroundColor: "#004F9F",
              color: "white",
              "&:hover": {
                backgroundColor: "#003A7F",
              },
            }}
            disabled={isViewMode}
          >
            {t("addNew")}
          </Button>
        </Box>
        {/* Blue divider line */}
        <Box id="divider" sx={{ borderBottom: "5px solid #004F9F" }}></Box>
      </Box>

      {/* Render sections dynamically */}
      {sections.map((section, sectionIndex) => (
        <Box
          key={section.id}
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            padding: "1rem",
            minHeight: section.questions.length > 0 ? "500px" : "200px",
            marginTop: "10px",
            position: "relative",
            overflowY: "auto",
          }}
        >
          {/* Grid Container for Section Details Inputs */}
          <Grid container spacing={2}>
            {/* Section Name Input */}
            <Grid item xs={3}>
              <TextField
                name="description"
                label={t("PreAssessmentFormBuilder.sectionName")}
                value={section.description}
                onChange={(e) => {
                  setSections((prevSections) =>
                    prevSections.map((s) =>
                      s.id === section.id
                        ? { ...s, description: e.target.value }
                        : s
                    )
                  );
                  // Clear error when user starts typing
                  setFormErrors((prev) => ({
                    ...prev,
                    sections: prev.sections.map((s, i) =>
                      i === sectionIndex ? { ...s, description: false } : s
                    ),
                  }));
                }}
                disabled={isViewMode}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    ...(isViewMode && {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #0000003B",
                      },
                    }),
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                  width: "100%",
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
                required
                error={formErrors.sections[sectionIndex]?.description}
                helperText={
                  formErrors.sections[sectionIndex]?.description
                    ? t("PreAssessmentFormBuilder.sectionNameRequired")
                    : ""
                }
              />
            </Grid>

            {/* Section Order Input */}
            <Grid item xs={2}>
              <TextField
                name="sectionOrder"
                label={t("PreAssessmentFormBuilder.sectionOrder")}
                value={section.sectionOrder || ""}
                disabled={isViewMode}
                onChange={(e) => {
                  // Ensure only numeric input
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setSections((prevSections) =>
                    prevSections.map((s) =>
                      s.id === section.id
                        ? { ...s, sectionOrder: numericValue }
                        : s
                    )
                  );
                  // Clear error when user starts typing
                  setFormErrors((prev) => ({
                    ...prev,
                    sections: prev.sections.map((s, i) =>
                      i === sectionIndex ? { ...s, sectionOrder: false } : s
                    ),
                  }));
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    ...(isViewMode && {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #0000003B",
                      },
                    }),
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  width: "100%",
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
                required
                type="number"
                error={formErrors.sections[sectionIndex]?.sectionOrder}
                helperText={
                  formErrors.sections[sectionIndex]?.sectionOrder
                    ? t("PreAssessmentFormBuilder.sectionOrderRequired")
                    : ""
                }
              />
            </Grid>

            {/* Switch for Existing Farmer Requirement */}
            <Grid item xs={3} sx={{ marginLeft: 2 }}>
              <FormControl
                component="fieldset"
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#0E2B63 !important",
                  },
                }}
              >
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    fontWeight: 500,
                    "&.Mui-focused": {
                      color: "#0E2B63 !important",
                    },
                  }}
                >
                  {t("PreAssessmentFormBuilder.requiredForExistingFarmer")}
                </FormLabel>
                <Box
                  sx={{ display: "flex", alignItems: "center", margin: "auto" }}
                >
                  <Typography
                    sx={{
                      mr: 3,
                      ml: 3,
                      color: "#0E2B63",
                      "&.MuiTypography-root": {
                        color: "#0E2B63 !important",
                      },
                    }}
                  >
                    {t("No")}
                  </Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={section.requiredForExistingFarmer}
                        onChange={(e) => {
                          setSections((prevSections) =>
                            prevSections.map((s) =>
                              s.id === section.id
                                ? {
                                    ...s,
                                    requiredForExistingFarmer: e.target.checked,
                                  }
                                : s
                            )
                          );
                        }}
                        disabled={isViewMode}
                        name="requiredForExistingFarmer"
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          ml: 0,
                          color: "#0E2B63",
                          "&.MuiTypography-root": {
                            color: "#0E2B63 !important",
                          },
                        }}
                      >
                        {t("yes")}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Grid>

            {/* Switch for New Farmer Requirement */}
            <Grid item xs={3} sx={{ marginLeft: 2 }}>
              <FormControl
                component="fieldset"
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#0E2B63 !important",
                  },
                }}
              >
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    fontWeight: 500,
                    "&.Mui-focused": {
                      color: "#0E2B63 !important",
                    },
                  }}
                >
                  {t("PreAssessmentFormBuilder.requiredForNewFarmer")}
                </FormLabel>
                <Box
                  sx={{ display: "flex", alignItems: "center", margin: "auto" }}
                >
                  <Typography
                    sx={{
                      mr: 3,
                      ml: 3,
                      color: "#0E2B63",
                      "&.MuiTypography-root": {
                        color: "#0E2B63 !important",
                      },
                    }}
                  >
                    {t("No")}
                  </Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={section.requiredForNewFarmer}
                        onChange={(e) => {
                          setSections((prevSections) =>
                            prevSections.map((s) =>
                              s.id === section.id
                                ? {
                                    ...s,
                                    requiredForNewFarmer: e.target.checked,
                                  }
                                : s
                            )
                          );
                        }}
                        disabled={isViewMode}
                        name="requiredForNewFarmer"
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          ml: 0,
                          color: "#0E2B63",
                          "&.MuiTypography-root": {
                            color: "#0E2B63 !important",
                          },
                        }}
                      >
                        {t("yes")}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Grid>

            {/* Delete Button for Section */}
            <Grid
              item
              xs={0.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: 0,
              }}
            >
              {sections.length > 1 && (
                <img
                  src={_ButtonDelete_}
                  alt={t("delete")}
                  onClick={() => handleDeleteSection(section.id)}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                  }}
                />
              )}
            </Grid>
          </Grid>

          {/* Questions Header - Shows "Questions" title and "Add New" button */}
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 10px",
                background: "#FFFFFF",
                marginTop: 3,
              }}
            >
              <Typography variant="h6">
                {t("PreAssessmentFormBuilder.questions")}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleAddQuestion(section.id)}
                sx={{
                  backgroundColor: "#004F9F",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#003A7F",
                  },
                }}
                disabled={isViewMode}
              >
                {t("addNew")}
              </Button>
            </Box>
          </Box>

          {/* Questions Details Container */}
          {section.questions.map((question, questionIndex) => (
            <Box
              key={question.id}
              sx={{
                border: "1px solid var(--Gray-2, #ACB4BA)",
                background: "#FFFFFF",
                marginTop: "10px",
                boxShadow: "0px 4px 4px 0px #00000040",
                padding: "1rem",
              }}
            >
              {/* Question Row */}
              <Grid container spacing={2} alignItems="center">
                {/* Question Code Input */}
                <Grid item xs={3}>
                  <TextField
                    name="questionCode"
                    label={t("PreAssessmentFormBuilder.questionCode")}
                    value={question.questionCode}
                    disabled={isViewMode}
                    onChange={(e) =>
                      handleQuestionChange(
                        section.id,
                        question.id,
                        "questionCode",
                        e.target.value
                      )
                    }
                    fullWidth
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        ...(isViewMode && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                    error={
                      formErrors.sections[sectionIndex]?.questions[
                        questionIndex
                      ]?.questionCode
                    }
                    helperText={
                      formErrors.sections[sectionIndex]?.questions[
                        questionIndex
                      ]?.questionCode
                        ? t("PreAssessmentFormBuilder.questionCodeRequired")
                        : ""
                    }
                  />
                </Grid>

                {/* Question Text Input */}
                <Grid item xs={6}>
                  <TextField
                    name="questionText"
                    label={t("PreAssessmentFormBuilder.question")}
                    value={question.questionText}
                    onChange={(e) =>
                      handleQuestionChange(
                        section.id,
                        question.id,
                        "questionText",
                        e.target.value
                      )
                    }
                    disabled={isViewMode}
                    fullWidth
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        ...(isViewMode && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                    error={
                      formErrors.sections[sectionIndex]?.questions[
                        questionIndex
                      ]?.questionText
                    }
                    helperText={
                      formErrors.sections[sectionIndex]?.questions[
                        questionIndex
                      ]?.questionText
                        ? t("PreAssessmentFormBuilder.questionTextRequired")
                        : ""
                    }
                  />
                </Grid>

                {/* Delete Button for Question */}
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginLeft: "154px",
                  }}
                >
                  <img
                    src={_ButtonDelete_}
                    alt={t("delete")}
                    onClick={() => handleDeleteQuestion(question.id)}
                    style={{
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </Grid>
              </Grid>

              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, question.id)}
              >
                <Droppable droppableId={`answers-${question.id}`}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {question.answers.map((answer, index) => (
                        <Draggable
                          key={answer.id}
                          draggableId={`answer-${question.id}-${answer.id}`}
                          index={index}
                          isDragDisabled={isViewMode}
                        >
                          {(provided) => (
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              sx={{ marginTop: 1 }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* Drag Handle */}
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                {!isViewMode && (
                                  <>
                                    <div {...provided.dragHandleProps}>
                                      <img
                                        src={DragIcon}
                                        alt={t("drag")}
                                        style={{
                                          cursor: "move",
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={_ButtonDelete_}
                                      alt={t("delete")}
                                      onClick={() => {
                                        handleDeleteAnswer(
                                          question.id,
                                          answer.id,
                                          answer.fcpFaId // Pass the backend ID if it exists
                                        );
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        width: "24px",
                                        height: "24px",
                                      }}
                                    />
                                  </>
                                )}
                              </Grid>

                              {/* Answer Code Input */}
                              <Grid item xs={2}>
                                <TextField
                                  name="answerCode"
                                  label={t(
                                    "PreAssessmentFormBuilder.answerCode"
                                  )}
                                  value={answer.answerCode}
                                  onChange={(e) => {
                                    handleAnswerChange(
                                      section.id,
                                      question.id,
                                      answer.id,
                                      "answerCode",
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  required
                                  disabled={isViewMode}
                                  sx={{
                                    marginTop: "-7px",
                                    "& .MuiOutlinedInput-root": {
                                      padding: "7px",
                                      minHeight: "50px",
                                      ...(isViewMode && {
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            border: "1px solid #0000003B",
                                          },
                                      }),
                                    },
                                    "& .MuiInputLabel-root": {
                                      lineHeight: "1.2em",
                                    },
                                  }}
                                  InputProps={{
                                    style: { height: "50px" },
                                  }}
                                  FormHelperTextProps={{
                                    sx: { fontSize: "0.76rem !important" }, // Ensures font size applies to helper text
                                  }}
                                  error={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.answerCode
                                  }
                                  helperText={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.answerCode
                                      ? t(
                                          "PreAssessmentFormBuilder.answerCodeRequired"
                                        )
                                      : " "
                                  }
                                />
                              </Grid>

                              {/* Answer Text Input */}
                              <Grid item xs={5}>
                                <TextField
                                  name="answerText"
                                  label={t("PreAssessmentFormBuilder.answer")}
                                  value={answer.answerText}
                                  onChange={(e) => {
                                    handleAnswerChange(
                                      section.id,
                                      question.id,
                                      answer.id,
                                      "answerText",
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  required
                                  disabled={isViewMode}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "7px",
                                      minHeight: "50px",
                                      ...(isViewMode && {
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            border: "1px solid #0000003B",
                                          },
                                      }),
                                    },
                                    "& .MuiInputLabel-root": {
                                      lineHeight: "1.2em",
                                    },
                                  }}
                                  InputProps={{
                                    style: { height: "50px" },
                                  }}
                                  error={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.answerText
                                  }
                                  helperText={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.answerText
                                      ? t(
                                          "PreAssessmentFormBuilder.answerTextRequired"
                                        )
                                      : " "
                                  }
                                />
                              </Grid>

                              {/* Score Input */}
                              <Grid item xs={2}>
                                <TextField
                                  name="score"
                                  label={t("PreAssessmentFormBuilder.score")}
                                  value={answer.score}
                                  onChange={(e) => {
                                    handleAnswerChange(
                                      section.id,
                                      question.id,
                                      answer.id,
                                      "score",
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  required
                                  type="number"
                                  disabled={isViewMode}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      padding: "7px",
                                      minHeight: "50px",
                                      ...(isViewMode && {
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            border: "1px solid #0000003B",
                                          },
                                      }),
                                    },
                                    "& .MuiInputLabel-root": {
                                      lineHeight: "1.2em",
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                  }}
                                  InputProps={{
                                    style: { height: "50px" },
                                  }}
                                  error={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.score
                                  }
                                  helperText={
                                    formErrors.sections[sectionIndex]
                                      ?.questions[questionIndex]?.answers[index]
                                      ?.score
                                      ? t(
                                          "PreAssessmentFormBuilder.scoreRequired"
                                        )
                                      : " "
                                  }
                                />
                              </Grid>

                              {/* Disqualifying Response Switch */}
                              <Grid item xs={2}>
                                <FormControl
                                  component="fieldset"
                                  sx={{
                                    "& .MuiFormLabel-root.Mui-focused": {
                                      color: "#0E2B63 !important",
                                    },
                                  }}
                                >
                                  <FormLabel
                                    sx={{
                                      color: "#0E2B63",
                                      mb: 2,
                                      ml: 0.5,
                                      fontFamily: "Montserrat",
                                      fontSize: "18px",
                                      fontWeight: 500,
                                      "&.Mui-focused": {
                                        color: "#0E2B63 !important",
                                      },
                                    }}
                                  >
                                    {t(
                                      "PreAssessmentFormBuilder.disqualifyingResponse"
                                    )}
                                  </FormLabel>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "auto",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        mr: 3,
                                        ml: 3,
                                        color: "#0E2B63",
                                        "&.MuiTypography-root": {
                                          color: "#0E2B63 !important",
                                        },
                                      }}
                                    >
                                      {t("No")}
                                    </Typography>
                                    <FormControlLabel
                                      control={
                                        <RedSwitch
                                          sx={{ marginRight: "11px" }}
                                          checked={answer.isDisqualifying}
                                          onChange={(e) => {
                                            handleAnswerChange(
                                              section.id,
                                              question.id,
                                              answer.id,
                                              "isDisqualifying",
                                              e.target.checked
                                            );
                                          }}
                                          disabled={isViewMode}
                                          name="isDisqualifying"
                                          color="primary"
                                        />
                                      }
                                      label={
                                        <Typography
                                          sx={{
                                            ml: 0,
                                            color: "#0E2B63",
                                            "&.MuiTypography-root": {
                                              color: "#0E2B63 !important",
                                            },
                                          }}
                                        >
                                          {t("yes")}
                                        </Typography>
                                      }
                                      labelPlacement="end"
                                    />
                                  </Box>
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* New Answer Button - Only show in non-view mode */}
              {!isViewMode && (
                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 10px",
                      marginLeft: "6rem",
                      width: "90%",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleAddAnswer(section.id, question.id)}
                      sx={{
                        backgroundColor: "#004F9F",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#003A7F",
                        },
                      }}
                    >
                      {t("newAns")}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Box>
          ))}
        </Box>
      ))}

      {/* Empty Space */}
      <Box sx={{ marginTop: "100px" }}></Box>

      {/* Cancel and Save */}
      {!isViewMode && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          mb={3}
          sx={{
            position: "fixed",
            bottom: -4,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            variant="contained"
            startIcon={<img src={arrowLeft} alt="Arrow" />}
            onClick={handleCancel}
          >
            {t("back")}
          </Button>

          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            variant="contained"
            onClick={handleSave}
          >
            {t("save")}
          </Button>
        </Box>
      )}

      {/* For view mode, show only the cancel button */}
      {isViewMode && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={3}
          mb={3}
          sx={{
            position: "fixed",
            bottom: -4,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            variant="contained"
            startIcon={<img src={arrowLeft} alt="Arrow" />}
            onClick={handleCancel}
          >
            {t("back")}
          </Button>
        </Box>
      )}

      {/* Replace existing delete modals with the new component */}
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
      />

      <DeleteConfirmationModal
        open={openQuestionDeleteModal}
        onClose={handleQuestionDeleteModalClose}
        onConfirm={handleDeleteQuestionConfirm}
      />

      {/* New Cancel Confirmation Modal */}
      <DeleteConfirmationModal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        onConfirm={handleCancelConfirm}
        title={t("cancelTitle")}
        message={t("cancelMessage")}
      />

      {/* New Delete Confirmation Modal for sections */}
      <DeleteConfirmationModal
        open={openSectionDeleteModal}
        onClose={handleSectionDeleteModalClose}
        onConfirm={handleDeleteSectionConfirm}
        title={t("deleteConfirmation")}
        message={t("deleteConfirmationMessage")}
      />
    </Layout>
  );
};

export default PreAssessmentFormBuilderDetails;
