// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-colour g rect {
    fill: #EF7D00 !important;
  }
  /* .gantt-container {
    width: 90vw; 
    margin: 0 auto;
} */`, "",{"version":3,"sources":["webpack://./src/components/pages/Growing/CropSeason/GanttChart.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;EAC1B;EACA;;;GAGC","sourcesContent":[".bar-colour g rect {\n    fill: #EF7D00 !important;\n  }\n  /* .gantt-container {\n    width: 90vw; \n    margin: 0 auto;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
