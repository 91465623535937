import React, { useState } from "react";
import { Grid, TextField, InputAdornment, Box, MenuItem, Checkbox, Tooltip, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import plusIcon from "../../../../../src/assets/plusIcon.svg"
import { useNavigate } from "react-router-dom";
import iconSearchs from "../../../../../src/assets/iconSearchs.svg"
import CheckboxTick from "../../../../../src/assets/CheckboxTick.svg"
import CheckboxUntick from "../../../../../src/assets/CheckboxUntick.svg"
import Layout from "../../../structure/Layout";
import './Users.css'
import { useEffect } from "react";
import UserService from "../../../../services/UserService";
import { json2csv } from 'json-2-csv';
import DataTable from '../../../../../src/utils/DataTable/DataTable'
import { useTranslation } from 'react-i18next';
import CommonService from "services/CommonService";
import { AuthData } from "auth/AuthWarpper";
import { SnackbarContext } from "context/snackBarContext";
import EditButton from "utils/EditButton";
const Users = (props) => {
  const { t, i18n } = useTranslation();

  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = React.useState(false);
  const [profiles, setProfiles] = React.useState(null);
  const [searchInput, setSearchInput] = React.useState(null);
  const [searchProfile, setSearchProfile] = React.useState(null);

  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [globalQuery, setGlobalQuery] = React.useState(null);


  const { user } = AuthData();
  const LoggedUser = user

  const {
    severity,
    message,
    openAlert,
    setOpenAlert,
    setSeverity,
    setMessage,
  } = React.useContext(SnackbarContext);
  const [loggedinUserType, setLoggedinUserType] = React.useState(user?.profileAttribute)
  // const [loggedinUserType, setLoggedinUserType] = React.useState('Global Admin')

  useEffect(() => {
    let query = `?pageNumber=${paginationModel.page}&size=${paginationModel.pageSize}`
    if (loggedinUserType == 'Local') {
      if (globalQuery) {
        query = `/find${query + globalQuery}`
      }
      if(searchInput == null  && searchProfile == null && searchInput != ""){
        query += `&countryCode=${LoggedUser.countryCode}&attribute=Local`
      }
    } else {
      if (globalQuery) {
        query = `/find${query + globalQuery}`
      }
    }
    UserService.FetchAllUser(query).then((res) => {
      let total = res?.data?.response?.totalItems
      let pages = res?.data?.response?.totalPages
      let currentPage = res?.data?.response?.currentPage
      let users = res?.data?.response?.users
      users.forEach((o, i) => o.id = i + 1);
      setUserData(users);
      setTotalRowCount(total)
      if (loggedinUserType == "Local") fetchProfiles()
    }).catch((err) => {
      console.log('err', err)
    })
  }, [paginationModel.page]);


  React.useEffect(() => {
    if (searchProfile != null || searchInput != null) {
      const getData = setTimeout(() => {
        let query = ''
        let queryPage = `?pageNumber=${paginationModel.page}&size=${paginationModel.pageSize}`

        let profileName = ''

        let item = profiles?.filter((ele) => ele.profileCode == searchProfile);
        if (item && item.length > 0) {
          profileName = item[0]?.profileName
          query = query + `&profileName=${profileName == "No Profile" ? 'NA' : profileName}`
        }
        if (loggedinUserType == 'Local') {
          if (profileName == 'No Profile') {
            if (searchInput) query += `&userInput=${searchInput == null ? "" : searchInput}`
          } else {
            query += `&userInput=${searchInput == null ? "" : searchInput}`
          }
        } else {
          query += `&userInput=${searchInput == null ? "" : searchInput}`
        }

        if (loggedinUserType == 'Local') query = query + `&attribute=Local&countryCode=${LoggedUser.countryCode}`
        UserService.findUsers(queryPage + query).then((res) => {
          let users = res?.data?.response?.users
          let total = res?.data?.response?.totalItems
          setTotalRowCount(total);
          setGlobalQuery(query)
          users.forEach((o, i) => o.id = i + 1);
          setUserData(users);
        }).catch((error) => {
          setUserData([]);
          setPaginationModel({
            page: 0,
            pageSize: 10,
          })
          let message = error?.response?.data?.message || error?.message
          if (error?.response?.status == 500) {
            message = error.message
          }
          console.log('error', error);
          setMessage(message)
          setOpenAlert(true)
          setSeverity("error")
        })
      }, 1000)
      return () => clearTimeout(getData)
    }
  }, [searchInput, searchProfile])


  const navigate = useNavigate();

  const columns = [
    { field: 'userName', headerName: 'Full Name', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'profileName', headerName: 'Profile', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'email', headerName: 'Email', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'batOneId', headerName: 'Bat One ID', flex: 1, headerAlign: 'center', align: 'center' },
    { 
      field: 'location', 
      headerName: 'Location', 
      flex: 1,
      headerAlign: 'center',
       align: 'center',
      renderCell: (params) => (
        <Tooltip 
        style={{backgroundColor:"white"}}
        title={
          <Typography sx={{color:"black",backgroundColor:"white",border:"1px solid black", margin:"-10px" ,padding:"5px",fontSize:"12px"}}>  {/* Customize Tooltip content */}
            {params?.value?.join(', ')}
          </Typography>
        }
        placement="bottom">
          <span style={{ 
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis' 
           
          }}>
            {params?.value?.join(', ')}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'active', headerName: 'Active', flex: 1, headerAlign: 'center', align: 'center',
      type: 'actions',
      getActions: (params) => {
        return [
          // <Switch defaultChecked={params.row.active} onClick={() => onChangeActive(params.row)} />
          <Checkbox defaultChecked={params.row.active} onClick={() => onChangeActive(params.row)}
            icon={<img src={CheckboxUntick} />}
            checkedIcon={<img src={CheckboxTick} />}
          />
        ];
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        const state = {
          previousData: params.row,
          edit: true,
          id: params.id,
          attribute: params.attributes,
        };
        const url = `${params.row?.batOneId}`;
        console.log(url)
        return [<EditButton state={{state:params.row , mode: "Edit"}} url={url} />];
      },
    },
  ];
  const Localcolumns = [
    { field: 'userName', headerName: 'Full Name', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'profileName', headerName: 'Profile', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'batOneId', headerName: 'Bat One ID', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'email', headerName: 'Email', flex: 1, headerAlign: 'center', align: 'center' },
    { 
      field: 'location', 
      headerName: 'Location', 
      flex: 1,
      headerAlign: 'center',
       align: 'center',
      renderCell: (params) => (
        <Tooltip 
        style={{backgroundColor:"white"}}
        title={
          <Typography sx={{color:"black",backgroundColor:"white",border:"1px solid black", margin:"-10px" ,padding:"5px",fontSize:"12px"}}>  {/* Customize Tooltip content */}
            {params?.value?.join(', ')}
          </Typography>
        }
        placement="bottom">
          <span style={{ 
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis' 
           
          }}>
            {params?.value?.join(', ')}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'active', headerName: 'Active', flex: 1, headerAlign: 'center', align: 'center',
      type: 'actions',
      getActions: (params) => {
        return [
          // <Switch defaultChecked={params.row.active} onClick={() => onChangeActive(params.row)} />
          <Checkbox defaultChecked={params.row.active} onClick={() => onChangeActive(params.row)}
            icon={<img src={CheckboxUntick} />}
            checkedIcon={<img src={CheckboxTick} />}
          />
        ];
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        const state = {
          previousData: params.row,
          edit: true,
          id: params.id,
          attribute: params.attributes,
        };
        const url = `${params.row?.batOneId}`;
        console.log(url)
        return [<EditButton state={{state:params.row , mode: "Edit"}} url={url} />];
      },
    },
  ];
  const currencies = [
    {
      value: 'Region',
      label: 'Region',
    },
    {
      value: 'Status',
      label: 'Status',
    },
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];
  const handleDownload = () => {
    let cd = json2csv(userData,
      { excelBOM: true }
    )
    const url = window.URL.createObjectURL(new Blob([cd]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "yourfilename.csv")
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const onChangeActive = (row) => {
    let data = JSON.parse(JSON.stringify(row));
    data.active = !data.active
    data.fileDetailsList = []
    UserService.saveUserSingle(data)
      .then(res => {
        setLoading(false);
        navigate('/users')
        setMessage("Updated successfully")
        setOpenAlert(true)
        setSeverity("success")
      })
      .catch(error => {
        console.error(error);
        setMessage(error?.response?.data?.message || error?.message)
        setOpenAlert(true)
        setSeverity("error")
        setLoading(false)
      });
  };
  const fetchProfiles = (file) => {
    CommonService.fetchProfiles()
      .then(response => {
        let profiles = response?.data?.response?.profiles;
        let local_profiles = profiles.filter(ele => ele.profileAttribute == 'Local');
        local_profiles.push(
          {
            profileCode: 'NA',
            profileName: "No Profile",
          }
        )
        setProfiles(local_profiles)
      })
      .catch(error => {
        console.log(error)
      });
  };
  console.log('loggedinUserType', loggedinUserType)

  const setSearchProfilefun = ((val) => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
    setSearchProfile(val)
   
  })
  const setSearchInputfun = ((val) => {
    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
    setSearchInput(val)
  })
  return (
    <Layout title={t("manageUsers.pageTitle")}>
      <Box className="usersHeading">{t("manageUsers.headingGet")}</Box>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={loggedinUserType == "Local" ? 12 : 6} md={loggedinUserType == "Local" ? 5 : 3}>

          <Box sx={{ display: "flex", gap: "10px" }} >
            {loggedinUserType == "Local" &&
              <TextField
                select
                fullWidth
                label={searchProfile ? "" : "Profile"}
                onChange={(event) => setSearchProfilefun(event.target.value)}
                name="profileCode"
                variant="outlined"
              >
                <MenuItem value="">
                  Select Profile
                </MenuItem>
                {profiles && profiles.map((option) => (
                  <MenuItem key={option.profileCode} value={option.profileCode}>
                    {option.profileName}
                  </MenuItem>
                ))}
              </TextField>
            }

            <TextField fullWidth className="user-search-input"
              placeholder={t("search")} variant="outlined"
              onChange={(event) => setSearchInputfun(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={iconSearchs} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "30px",
                }
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={2} display={"flex"} justifyContent={"flex-end"}>
          <Box sx={{ right: '1.6rem', padding: "10px" }}>
            {/* <Button style={{ backgroundColor: "#112A63", height: "40px", marginLeft: "40px" }}
            variant="contained" type='button' onClick={(e) => handleDownload(e)}>
            {t('buttons.import')}</Button> */}
            <Button style={{ backgroundColor: "#004F9F", borderRadius: "30px" }}
              variant="contained"
              startIcon={<img src={plusIcon} />}
              onClick={() => navigate('/users/add')}> {t("add")}</Button>
          </Box>
        </Grid>

      </Grid>

      {userData &&
        <DataTable
          rows={userData}
          totalRowCount={totalRowCount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={loggedinUserType == 'Local' ? Localcolumns : columns} onClick={(e) => console.log(e.target.value)} />
      }

    </Layout>
  );
};



export default Users;
