// src\services\LocalityConfigurationService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "supplierConfiguration";
//const user= "9A6708BB-5DF8-4F38-9AB3-169D69371373" //DEV
const user = "ef9e61b3-d637-47b3-9c0b-0c4d800e996a" //SIT

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, keyword, useRecord } = query;
    const response = await httpGrow.get(`/${basename}/search/${user}`, {
        params: {
            pageNumber,
            pageSize: size,
            searchTerm: keyword || undefined,
            useThisRecord: useRecord ? true : undefined,
        }
    });
    return response;
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}`, data);
};

const getCityAPI = async () => {
    const response = await httpGrow.get(`/city`);
    return response;
};

const getRegionAPI = async () => {
    const response = await httpGrow.get(`/region`);
    return response;
};

const SupplierConfigurationService = {
    getAPI,
    updateAPI,
};

export default SupplierConfigurationService;
