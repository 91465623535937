import { httpGrow } from "../http-common";

// User Story constants
const basename = "competitor-configuration";

const getAPI = async (query) => {
  const { pageNumber = 0, size = 50, batOneId, createdByUser } = query;
  const response = await httpGrow.get(`/${basename}/list`, {
    params: {
      pageNumber,
      size: size,
      batOneId: batOneId || 85189949,
      createdByUser: createdByUser || "UI",
    },
  });
  return response;
};

const searchAPI = async (query) => {
  const { pageNumber, size, keyword, region, showUsedRecords, batOneId } = query;
  const response = await httpGrow.get(`/${basename}/search`, {
    params: {
      pageNumber,
      size,
      tccTobaccoCompanyName: keyword || undefined,
      tccRegion: region || undefined,
      useThisRecord: showUsedRecords || undefined,
      batOneId: batOneId || 85189949,
    },
  });
  return response;
};

const updateAPI = (data) => {
  return httpGrow.put(`/${basename}/update`, data);
};

const CompetitorConfigurationService = {
  getAPI,
  searchAPI,
  updateAPI,
};

export default CompetitorConfigurationService;
