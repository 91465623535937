import React, { useState, useEffect, useContext } from "react";
import "./Tray.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Typography, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import TrayService from "services/TrayService";
import { useTranslation } from "react-i18next";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TrayTable = (props) => {
  const [trayData, setTrayData] = useState([]);
  const { t } = useTranslation();
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...trayData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTrayData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (ttTrayTypeId) => {
    setDeleteId(ttTrayTypeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await TrayService.deleteTray(deleteId);
      if (response.status === 200) {
        setMessage("Deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setTrayData((prevData) =>
          prevData.filter((item) => item.ttTrayTypeId !== deleteId)
        );
        props.refreshData();
      } else {
        setMessage("Failed to delete tray type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting tray type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting tray type:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    setTrayData(props.trayData);
    setOriginalData(props.trayData);
  }, [props.trayData]);

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("ttTrayTypeDescription")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {t("trayType.trayTypeDescription")}{" "}
              {getSortIcon("ttTrayTypeDescription")}
            </th>
            <th
              onClick={() => handleSort("ttNoOfCells")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {t("trayType.numberofCells")} {getSortIcon("ttNoOfCells")}
            </th>
            <th
              onClick={() => handleSort("ttLossPercentage")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {t("trayType.loss")} {getSortIcon("ttLossPercentage")}
            </th>
            <th
              onClick={() => handleSort("isActive")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {t("status")} {getSortIcon("isActive")}
            </th>
            {/* <th className="actions-header">Actions</th> */}
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  cursor: "pointer",
                  // justifyContent: "left",
                  justifyContent: "space-between",
                }}
                onClick={
                  // () => handleSort("")
                  {}
                }
              >
                {t("actions")}
                {/* {getSortIcon("")} */}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {trayData.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewtrayRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.ttTrayTypeDescription}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewtrayRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.ttNoOfCells}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewtrayRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.ttLossPercentage}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewtrayRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.isActive === true ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <>
                  <Tooltip title="EDIT" placement="top" arrow>
                    <img
                      className="btn edit-btn"
                      onClick={() =>
                        navigate("/edittrayRegistration", {
                          state: { row: row, edit: true },
                        })
                      }
                      src={_ButtonEdit_}
                      alt="Edit"
                    />
                  </Tooltip>
                </>
                <Tooltip title="DELETE" placement="top" arrow>
                  <img
                    className="btn delete-btn"
                    onClick={() => handleOpen(row.ttTrayTypeId)}
                    src={_ButtonDelete_}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteModal.heading")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteModal.sure")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "50px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TrayTable;
