import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Modal, Switch, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./BankTable.css"
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";

import { useTranslation } from "react-i18next";
import BankIcon from "assets/Bank.svg"
import BankFieldTechnicanConfigService from "services/BankFieldTechnicanConfigService";

const BankConfigurationTable = ({ TableData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmSwitchOpen, setConfirmSwitchOpen] = useState(false);
  const [currentSwitchId, setCurrentSwitchId] = useState(null);
  const [localTableData, setLocalTableData] = useState(TableData);
  const [currentSwitchRow, setCurrentSwitchRow] = useState(null);

  useEffect(() => {
    setLocalTableData(TableData);
  }, [TableData]);

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return localTableData;
    
    return [...localTableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) 
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) 
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [localTableData, sortConfig]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleBankIconClick = (row) => {
    navigate("/branchesList", { 
      state: { 
        bankConfigID: row.bankConfigId,
        bankRegistrationId: row.bankRegistrationId 
      } 
    });
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const updateBankRecord = async (row, newValue) => {
    try {
      // Prepare the data for the API call with all existing fields
      const updateData = {
        ...row, // Spread the existing row data
        useThisRecord: newValue, // Override useThisRecord with new value
        modifiedDateTimeUTC: new Date().toISOString(), // Update modified timestamp
        modifiedByUser: "85201405" // Hardcoded user ID or get from context/auth
      };

      // Call the API to update the bank record
      const response = await BankFieldTechnicanConfigService.updateBank(updateData);

      // Update local state if API call is successful
      setLocalTableData(prevData =>
        prevData.map(item =>
          item.bankConfigId === row.bankConfigId
            ? { ...item, useThisRecord: newValue }
            : item
        )
      );

      // Show success message
      setOpenAlert(true);
      setSeverity("success");
      setMessage(response?.data?.response);
    } catch (error) {
      // Handle error
      console.error("Error updating bank record:", error);
      setOpenAlert(true);
      setSeverity("error");
      setMessage("Error updating branch record");
    }
  };

  const handleSwitchChange = (row) => {
    setCurrentSwitchId(row.bankConfigId);
    setCurrentSwitchRow(row);
    
    if (row.useThisRecord) {
      // If currently YES and switching to NO, show confirmation modal
      setConfirmSwitchOpen(true);
    } else {
      // If currently NO and switching to YES, update immediately
      updateBankRecord(row, true);
    }
  };

  const handleConfirmSwitchChange = () => {
    // Update when user confirms switching from YES to NO
    updateBankRecord(currentSwitchRow, false);
    setConfirmSwitchOpen(false);
  };

  return (
    <div className="table-container">
      <table className="gw-custom-table">
        <thead>
          <tr>
            <th style={{ maxWidth: "10vw" }}>{t("bankConfiguration.useThisRecord")}</th>
            <th style={{ minWidth: "40vw" }} onClick={() => handleSort("bankName")}>
              {t("bankConfiguration.bank")}
              {getSortIcon("bankName")}
            </th>
            <th style={{ minWidth: "25vw" }} onClick={() => handleSort("abbreviation")}>
              {t("bankConfiguration.abbreviation")}
              {getSortIcon("abbreviation")}
            </th>
            <th>
              {t("bankConfiguration.viewBranches")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row) => (
            <tr key={row.bankConfigId}>
              <td style={{ maxWidth: "20vw" }}>
                <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                <RedSwitch
                  checked={row.useThisRecord}
                  onChange={() => handleSwitchChange(row)}
                  disabled={false}
                />
                <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
              </td>
              <td style={{ minWidth: "40vw" }}>{row.bankName}</td>
              <td style={{ minWidth: "25vw" }}>{row.abbreviation}</td>
              <td style={{ minWidth: "15vw", textAlign: "right" }}>
                <img 
                  src={BankIcon} 
                  alt="Bank Icon" 
                  onClick={() => handleBankIconClick(row)}
                  style={{ cursor: 'pointer' }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={confirmSwitchOpen}
        onClose={() => setConfirmSwitchOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("alert")}
          </Box>
          <Box sx={{ p: 2, flex: 1 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("areYouSure")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              p: 2,
              marginTop: "auto",
            }}
          >
            <Button
              onClick={() => setConfirmSwitchOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={handleConfirmSwitchChange}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BankConfigurationTable;
