// Table.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import MoreDetails from "assets/MoreDetails.svg";
import view from "assets/view.svg";
import download from "assets/download.svg";
import Plus from "assets/Plus.svg";
import Approve from "assets/Approve.svg";
import Reject from "assets/Reject.svg";

const Table = ({ Data: initialData }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  // const [open, setOpen] = useState(false); // Modal state
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]); // Track expanded rows
  const [data, setData] = useState(initialData);

  const handleViewClick = (row) => {
    if (row.status !== "Rejected" && row.status !== "Approved") {
      navigate("/preassesmentform", { state: { row } });
    } else {
      console.log(`Navigation prevented for status: ${row.status}`);
    }
  };
  useEffect(() => {
    setData(initialData); // Keep state in sync if initialData changes
  }, [initialData]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };
 

  const handleMoreDetailsClick = (index) => {
    if (expandedRows.includes(index)) {
      // If row is already expanded, remove it from the expandedRows array
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      // Otherwise, add it to the expandedRows array
      setExpandedRows([...expandedRows, index]);
    }
  };

 
  return (
    <div className="table-container">
      <table className="approval-dashboard-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("functionalityName")}
            >
              {t("System Module")} {getSortIcon("functionalityName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("approvalDesc")}
            >
              {t("Approval Description")} {getSortIcon("approvalDesc")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("requestor")}
            >
              {t("Requestor")} {getSortIcon("requestor")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("requestDate")}
            >
              {t("Request Date")} {getSortIcon("requestDate")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("status")}
            >
              {t("status")}
              {getSortIcon("status")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("level")}
            >
              {t("Approval Level")} {getSortIcon("level")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Details/Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
        {data?.length > 0 ? (
          data.map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ cursor: "pointer" }}>
                <td>{row.functionalityName}</td>
                <td>{row.approvalDesc}</td>
                <td>{row.requestor}</td>
                <td>{row.requestDate}</td>
                <td>{row.status}</td>
                <td>{"Level 1"}</td>
                <td className="actions-cell">
                  <Tooltip title="view" arrow placement="top">
                    <img
                      src={view}
                      alt="view"
                      onClick={() => handleViewClick(row)} // Navigate to another page
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>

                  <Tooltip title=" Show Details" arrow placement="top">
                    <img
                      src={MoreDetails}
                      alt="Details"
                      onClick={() => handleMoreDetailsClick(index)} // Toggle details for the respective row
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </td>
              </tr>

              {/* Render More Details Row if expanded */}
              {expandedRows.includes(index) && (
                <tr>
                  <td colSpan={7}>
                    <div className="more-details-box">
                      <table className="more-details-table">
                        <thead>
                          <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Approval Level")}</th>
                            <th>{t("User")}</th>
                            <th>{t("Comment")}</th>
                            <th>{t("Action")}</th>
                            <th>{t("Attachment")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{row.requestDate}</td>
                            <td>{"Level 1"}</td>
                            <td>{row.requestor}</td>
                            <td>{row.approvalDesc}</td>
                            <td>{row.status}</td>
                            <td>{row.status}</td>
                          </tr>
                        </tbody>
                      </table>

                      {/* Buttons Section */}
                      <div className="button-container">
                        <div className="left-buttons">
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#102A62",
                              alignItems: "center",
                              gap: "8px",
                              color: "#ffffff",
                            }}
                          >
                            <img
                              src={Plus}
                              alt="Plus Icon"
                              style={{ width: 20, height: 17 }}
                            />
                            {t("ADD COMMENT")}
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#004F9F",
                              alignItems: "center",
                              gap: "8px",
                              color: "#ffffff",
                            }}
                          >
                            <img
                              src={download}
                              alt="Download Icon"
                              style={{ width: 20, height: 17 }}
                            />
                            {t("EXPORT HISTORY")}
                          </Button>
                        </div>

                        {/* Conditionally render Approve and Reject buttons */}
                        {row.status !== "Rejected" &&
                          row.status !== "Approved" && (
                            <div className="right-buttons">
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#50AF47",
                                  alignItems: "center",
                                  gap: "8px",
                                  color: "#ffffff",
                                }}
                              >
                                <img
                                  src={Approve}
                                  alt="Approve Icon"
                                  style={{ width: 20, height: 25 }}
                                />
                                {t("APPROVE")}
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#EF0000",
                                  alignItems: "center",
                                  gap: "8px",
                                  color: "#ffffff",
                                }}
                              >
                                <img
                                  src={Reject}
                                  alt="Reject Icon"
                                  style={{ width: 20, height: 25 }}
                                />
                                {t("REJECT")}
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          // Display "Record not found" if no data
          <tr>
            <td colSpan={7}>
              <Typography
                variant="h6"
                align="center"
                sx={{ padding: 2, color: "gray" }}
              >
                {t("Record not found")}
              </Typography>
            </td>
          </tr>
        )}
      </tbody>

      </table>
    </div>
  );
};

export default Table;
