import React, { useState, useEffect, useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Icon } from "@mui/material";
import deletebutton from "assets/deletebutton.svg";
import { FormControlLabel } from "@mui/material";
import "components/pages/Growing/Farmer_Management/Farmer_Management/FarmerAdd/FarmerAdd.css";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import { cropPhaseSchema, cultureSchema } from "utils/validations";
import { SnackbarContext } from "context/snackBarContext";
import Layout from "components/structure/Layout";
import RedSwitch from "utils/RedSwitch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import leftIcon from "assets/iconLeft.svg";
import CultureService from "services/CultureService";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { CropPhaseService } from "services/CropPhaseService";
import * as Yup from "yup";
import { isAction } from "@reduxjs/toolkit";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, IconButton } from "@mui/material";
import iconDelete from "../../../../assets/iconDelete.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddCropPhaseRegistration(props) {
  const params = useParams();
  const { id } = params;
  const location = useLocation();

  const { item, edit } = location.state || {};
  console.log("🚀 ~ item:", item, edit);

  const cropphaseID = item?.cropPhaseId;
  const navigate = useNavigate();

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [phaseNameError, setPhaseNameError] = useState({
    tex: "",
    isActive: false,
  });
  const [cultureError, setCultureError] = useState({
    text: "",
    isActive: false,
  });

  const [values, setValues] = useState({
    PhaseName: "",
    culture: "",
    status: "",
    IsMessage: false,
    message: "",
    days: "",
  });
  console.log("values", values);
  // const [errors, setErrors] = useState({
  //   PhaseName: "",
  //   culture: "",
  //   Status: false,
  // });

  const [errors, setErrors] = useState({});
  console.log("errors", errors);
  const [FunctionalityItems, setFunctionalityItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const [culture, setCulture] = React.useState([]);

  const [cultureProps, setCultureProps] = useState([]);
  const [deletedFunctionalities, setDeletedFunctionalities] = useState([]);
  const handleRemoveCulture = (valueToRemove) => {
    if (edit === true) {
      setCulture((prev) => prev.filter((item) => item !== valueToRemove));
      setCultureProps((prev) =>
        prev.filter((item) => item.cuCultureName !== valueToRemove)
      );
    }
  };

  const [isDisabled, setDisabled] = useState(true);

  const disableButton = () => {
    setDisabled(true);
  };

  const enableButton = () => {
    setDisabled(false);
  };

  useEffect(() => {
    if (!item || !item.cultures) {
      return;
    }

    const newCultureProps = item.cultures.map((culture) => ({
      cropPhaseCultureId: culture.cropPhaseCultureId || "",
      cuCultureCode: culture.cuCultureCode || "",
      cuCultureId: culture.cuCultureId || "",
      cuCultureName: culture.cuCultureName || "",
      cuStatus: culture.cuStatus || false,
    }));
    let cultureArray = [];
    for (let newculture of newCultureProps) {
      cultureArray.push(newculture.cuCultureName);
    }

    setCulture(cultureArray);
    setCultureProps(newCultureProps);
  }, [item]);

  useEffect(() => {
    const fetchData = async () => {
      if (!item) {
        return;
      }
      let culturestr = "";
      if (item?.cultures) {
        culturestr = item.cultures.map((e) => e.cuCultureName).join(",");
      }
      await setValues({
        PhaseName: item?.cropPhaseName,
        culture: culturestr,
        status: item.isActive == "Active" ? true : false, // Assuming data.Status exists in the response
        IsMessage: item?.isSendMessage === true ? "send" : "unsend",
        message: item?.messageText,
        days: item.messageDays,
      });
      if (item.isActive == "Inactive") {
        disableButton();
      } else if (item.isActive == "Active") {
        enableButton();
      }
    };
    const setdatabox = async () => {
      if (!item) {
        return;
      }

      const Functionalities = item?.cpFunctionalities;
      const transformedData = TransformDataa(Functionalities);
      setBoxes(transformedData);
    };

    fetchData();
    setdatabox();
  }, [item]);

  const TransformDataa = (data) => {
    return data.map((item) => ({
      text: item?.functionalityName,
      sendMessage: item?.isSendMessage === true ? "send" : "unsend",
      days: item.messageDays,
      message: item.messageText,
      sendMessage: item?.isMessageSent === true ? "send" : "unsend",
      cpFunctionalityId: item?.cpFunctionalityId,
      isDeleted: item?.isDeleted,
    }));
  };

  const getFunctionalityApi = () => {
    setLoading(true);
    CropPhaseService.getFunctionality()
      .then((res) => {
        setFunctionalityItems(res.data.response?.GRCPFUN);
        setLoading(false);
      })
      .catch((error) => { });
  };

  const getMenuItemsAPI = () => {
    setLoading(true);
    CultureService.getMenuItems()
      .then((res) => {
        const allculure = res.data.response;
        const activecultures = allculure.filter((c) => c.cuStatus === true);
        setMenuItems(activecultures);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleCultureChange = (event) => {
    const {
      target: { value },
    } = event;
    setCulture(typeof value === "string" ? value.split(",") : value);

    const selectedCultures = menuItems.filter((c) =>
      value.includes(c.cuCultureName)
    );
    console.log("culture", selectedCultures);
    setCultureProps(selectedCultures);
    setValues((prevValues) => ({
      ...prevValues,
      culture: selectedCultures,
    }));
  };

  useEffect(() => {
    getMenuItemsAPI();
    getFunctionalityApi();
  }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // if(!values.PhaseName){
  //   //   setPhaseNameError({isActive:true, text: "Phase Name is required."});
  //   //   throw new Error("Submission failed");
  //   // }
  //   // else{
  //   //    setPhaseNameError({isActive:false,text:""});
  //   // }
  //   // Validation logic
  // if (!values.PhaseName) {
  //    setPhaseNameError({ isActive: true, text: "Phase Name is required." });
  //    throw new Error("Submission failed");
  // }
  //  else if (!/^[a-zA-Z0-9 ]*$/.test(values.PhaseName)) {
  //    setPhaseNameError({ isActive: true, text: "No special characters allowed." });
  //    throw new Error("Submission failed");
  // }
  //  else if (/^\s|\s$/.test(values.PhaseName)) {
  //   setPhaseNameError({ isActive: true, text: "No leading or trailing spaces allowed." });
  //   throw new Error("Submission failed");
  // }
  //  else {
  // setPhaseNameError({ isActive: false, text: "" });
  // }

  //   if(cultureProps?.length===0){
  //     setCultureError({isActive:true,text:"Culture is required."});
  //     throw new Error("Submission failed");
  //   }
  //   else{
  //      setCultureError({isActive:false,text:""})
  //   }
  //   // console.log("Priyam", values);
  //   try {
  //     const transformData = (data) => {
  //       return data.map((item) => ({
  //         functionalityName: item.text,
  //         isSendMessage: item?.sendMessage === "send" ? true : false,
  //         messageDays: item.days,
  //         messageText: item.message,
  //         isMessageSent: item?.sendMessage === "send" ? true : false,
  //         isDeleted: false,
  //       }));
  //     };
  //     const transformedData = transformData(boxes);

  //     const payload = {
  //       createdDateTimeUTC: new Date(),
  //       createdByUser: "sdw",
  //       modifiedDateTimeUTC: new Date(),
  //       modifiedByUser: "sdw",
  //       cropPhaseName: values?.PhaseName,
  //       isSendMessage: values?.IsMessage == "send" ? true : false,
  //       messageDays: values?.days,
  //       messageText: values?.message,
  //       isActive: values.status === true ? "Active" : "Inactive",
  //       cpFunctionalities: [...transformedData],
  //       cultures: cultureProps,
  //     };
  //     // console.log("Priyamm", payload);
  //     console.log("Priyam", values);
  //     await cropPhaseSchema.validate(values, { abortEarly: false });

  //     setLoading(true);
  //     const response = await CropPhaseService.createCropPhase({ ...payload });
  //     setLoading(false);
  //     if (response.status === 201) {
  //       setSeverity("success");
  //       setMessage(
  //         values.equipmentId ? "Edited successfully" : "Saved successfully"
  //       );
  //       setOpenAlert(true);
  //       navigate("/cropPhaseRegistration");
  //       // setLoading(false);
  //     } else {
  //       console.error("Failed to submit form");
  //       setSeverity("error");
  //       setMessage("Submission failed");
  //       setOpenAlert(true);
  //     }
  //   } catch (err) {
  //     console.log("Errrop", err);
  //     setLoading(false);
  //     if (err instanceof Yup.ValidationError) {
  //       let newError = {};
  //       err?.inner?.forEach((err) => {
  //         newError[err.path] = err.message;
  //         console.log("msg", err.message);
  //       });
  //       setErrors(newError);
  //     } else if (err.response.status === 409) {
  //       setSeverity("error");
  //       setMessage(err.response.data.message);
  //       setOpenAlert(true);
  //     } else {
  //       console.log("Error during submission:");
  //       setSeverity("error");
  //       setMessage("Submission failed");
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};

    const transformData = (data) => {
      return data.map((item) => ({
        functionalityName: item.text,
        isSendMessage: item?.sendMessage === "send" ? true : false,
        messageDays: item.days,
        messageText: item.message,
        isMessageSent: item?.sendMessage === "send" ? true : false,
        isDeleted: false,
      }));
    };

    const transformedData = transformData(boxes);

    const payload = {
      createdDateTimeUTC: new Date(),
      createdByUser: "sdw",
      modifiedDateTimeUTC: new Date(),
      modifiedByUser: "sdw",
      cropPhaseName: values?.PhaseName,
      isSendMessage: values?.IsMessage == "send" ? true : false,
      messageDays: values?.days,
      messageText: values?.message,
      isActive: values.status === true ? "Active" : "Inactive",
      cpFunctionalities: [...transformedData],
      cultures: cultureProps,
    };
    //   try {
    //     // Validate the schema
    //     await cropPhaseSchema.validate(payload, { abortEarly: false });
    //   } catch (err) {
    //     err?.inner?.forEach((error) => {
    //       const path = error.path.split(".");

    //       if (path.length > 1) {
    //         const [indexString] = path[0].split("[");
    //         const index = path[0].match(/\[(.*?)\]/)[1];
    //         const field = path[1];

    //         schemaValidationErrors.functionality =
    //           schemaValidationErrors.functionality || [];
    //         schemaValidationErrors.functionality[index] =
    //           schemaValidationErrors.functionality[index] || {};
    //         schemaValidationErrors.functionality[index][field] = error.message;
    //       }
    //     });

    //     // Set the validation errors
    //     setErrors(schemaValidationErrors);
    //   }

    //   //Applying validation on Phase Name

    //  if (!values.PhaseName) {
    //    setPhaseNameError({ isActive: true, text: "Phase Name is required." });
    //    throw new Error("Submission failed");
    // }
    //  else if (!/^[a-zA-Z0-9 ]*$/.test(values.PhaseName)) {
    //    setPhaseNameError({ isActive: true, text: "No special characters allowed." });
    //    throw new Error("Submission failed");
    // }
    //  else if (/^\s|\s$/.test(values.PhaseName)) {
    //   setPhaseNameError({ isActive: true, text: "No leading or trailing spaces allowed." });
    //   throw new Error("Submission failed");
    // }
    //   else {
    //     setPhaseNameError({ isActive: false, text: "" });
    //   }
    //   if (cultureProps?.length === 0) {
    //     setCultureError({ isActive: true, text: "Culture is required." });
    //     throw new Error("Submission failed");
    //   } else {
    //     setCultureError({ isActive: false, text: "" });
    //   }

    //   if (Object.keys(schemaValidationErrors).length === 0) {
    try {
      await cropPhaseSchema.validate(payload, { abortEarly: false });
      setErrors({});
      const response = await CropPhaseService.createCropPhase({ ...payload });
      if (response.status === 201) {
        setSeverity("success");
        setMessage(
          values.equipmentId ? "Edited successfully" : "Saved successfully"
        );
        setOpenAlert(true);
        navigate("/cropPhaseRegistration");
      } else {
        throw new Error("Submission failed");
      }
    } catch (err) {
      //  catch (error) {
      //   console.error("Error during submission:", error);
      //   setSeverity("error");
      //   setMessage("Submission failed");
      //   setOpenAlert(true);
      // }
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
    // } else {
    //   console.log("schema validation", schemaValidationErrors);
    //   setErrors(schemaValidationErrors);
    // }
  };

  const handleUpdate = async () => {
    let schemaValidationErrors = {};

    const transformData = (data) => {
      return data.map((item) => ({
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "sdw",
        functionalityName: item.text,
        isSendMessage: item?.sendMessage === "send" ? true : false,
        messageDays: item.days,
        messageText: item.message,
        isMessageSent: item?.sendMessage === "send" ? true : false,
        cpFunctionalityId: item.cpFunctionalityId,
        isDeleted: item.isDeleted,
      }));
    };

    const transformedData = transformData(boxes);
    const deletedTransformedData = transformData(deletedFunctionalities);
    for (let data of transformedData) {
      if (!data.cpFunctionalityId) {
        delete data["cpFunctionalityId"];
      }
    }
    const oldCultures = item?.cultures || [];

    for (let oldCulture of oldCultures) {
      const cultureIndex = cultureProps.findIndex(
        (newCulture) => newCulture.cuCultureId === oldCulture.cuCultureId
      );

      if (cultureIndex !== -1) {
        cultureProps[cultureIndex] = oldCulture;
      } else {
        oldCulture["cuStatus"] = false;
        cultureProps.push(oldCulture);
      }
    }

    const payload = {
      modifiedDateTimeUTC: new Date(),
      modifiedByUser: "sdw",
      cropPhaseId: cropphaseID,
      cropPhaseName: values?.PhaseName,
      isSendMessage: values?.IsMessage == "send" ? true : false,
      messageDays: values?.days,
      messageText: values?.message,
      cpFunctionalities: [...transformedData, ...deletedTransformedData],
      cultures: cultureProps,
      isActive: values.status === true ? "Active" : "Inactive",
    };
    // try {
    //   // Validate the schema
    //   await cropPhaseSchema.validate(payload, { abortEarly: false });
    // } catch (err) {
    //   err?.inner?.forEach((error) => {
    //     const path = error.path.split(".");

    //     if (path.length > 1) {
    //       const [indexString] = path[0].split("[");
    //       const index = path[0].match(/\[(.*?)\]/)[1];
    //       const field = path[1];

    //       schemaValidationErrors.functionality =
    //         schemaValidationErrors.functionality || [];
    //       schemaValidationErrors.functionality[index] =
    //         schemaValidationErrors.functionality[index] || {};
    //       schemaValidationErrors.functionality[index][field] = error.message;
    //     }
    //   });

    //   // Set the validation errors
    //   setErrors(schemaValidationErrors);
    // }

    // if (!values.PhaseName) {
    //   setPhaseNameError({ isActive: true, text: "Phase Name is required." });
    //   throw new Error("Submission failed");
    // } else if (!/^[a-zA-Z0-9 ]*$/.test(values.PhaseName)) {
    //   setPhaseNameError({
    //     isActive: true,
    //     text: "No special characters allowed.",
    //   });
    //   throw new Error("Submission failed");
    // } else if (/^\s|\s$/.test(values.PhaseName)) {
    //   setPhaseNameError({
    //     isActive: true,
    //     text: "No leading or trailing spaces allowed.",
    //   });
    //   throw new Error("Submission failed");
    // } else {
    //   setPhaseNameError({ isActive: false, text: "" });
    // }

    // if (cultureProps?.length === 0) {
    //   setCultureError({ isActive: true, text: "Culture is required." });
    //   throw new Error("Submission failed");
    // } else {
    //   setCultureError({ isActive: false, text: "" });
    // }

    // if (Object.keys(schemaValidationErrors).length === 0) {
    try {
      await cropPhaseSchema.validate(payload, { abortEarly: false });
      setErrors({});
      setLoading(true);
      const response = await CropPhaseService.updateCropPhase({ ...payload });
      setLoading(false);

      if (response.data.statusCode === 200) {
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        navigate("/cropPhaseRegistration");
      } else {
        throw new Error("Submission failed");
      }
    } catch (err) {
      // setLoading(false);
      // console.error("Error during update:", error);
      // setSeverity("error");
      // setMessage(error.response.data.message);
      // setOpenAlert(true);
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("checkTest", name + value + type + checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (type === "checkbox") {
      if (checked) {
        enableButton();
      } else {
        disableButton();
      }
    }
  };

  const [boxes, setBoxes] = useState([]);
  console.log("boxes", boxes);
  const [deletableIndex, setDeletableIndex] = useState(null);
  const handleAddFunctionality = () => {
    setBoxes([...boxes, { id: boxes.length, text: "", isDeleted: false }]);
  };
  const handleTextChange = (index, field, value) => {
    if (field === "global") {
      setValues((prevValues) => ({
        ...prevValues,
        IsMessage: value,
      }));
    } else {
      setBoxes((prevBoxes) =>
        prevBoxes.map((box, i) =>
          i === index ? { ...box, [field]: value } : box
        )
      );
    }
  };
  const handleDelete = () => {
    let count = 0;
    for (let box of boxes) {
      if (count === deletableIndex) {
        box["isDeleted"] = true;
        setDeletedFunctionalities([...deletedFunctionalities, box]);
      }
      count += 1;
    }

    setBoxes(boxes.filter((box, i) => i !== deletableIndex));
    handleClose();
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (index) => {
    setDeletableIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setDeletableIndex(null);
    setOpen(false);
  };

  const [dropdownStates, setDropdownStates] = useState({});

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const [cultureDropdown, setCultureDropdown] = useState(false);
  const handleCultureDropdown = () => {
    setCultureDropdown((prevState) => !prevState);
  };
  return (
    <Layout title={"Crop Phase Registration "}>
      <Box
        sx={{
          minHeight: 180,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <Button
          style={{
            margin: "30px 0px 30px 10px",
            color: "#004F9F",
            borderColor: "#004F9F",
            textTransform: "none"
          }}
          required
          variant="outlined"
          startIcon={
            <Icon>
              <img
                alt="edit"
                style={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100%"
                src={leftIcon}
              />
            </Icon>
          }
          onClick={(event) => navigate("/cropPhaseRegistration")}
        >
          {" "}
          Back Home
        </Button>
        <Box
          sx={{
            minHeight: "125vh",
            flexGrow: 1,
            paddingBottom: "10rem",
            width: "100%",
            "& .MuiTextField-root": { m: 1 },
            "& .MuiFormLabel-root": {
              color: "rgba(16, 42, 98, 1)",
              "& .MuiFormLabel-asterisk": {
                color: "rgba(239, 0, 0, 1)",
                fontSize: "18px",
              },
            },
          }}
        >
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="PhaseName"
                label="Phase Name"
                value={values.PhaseName}
                onChange={handleChange}
                error={!!errors.cropPhaseName}
                helperText={errors.cropPhaseName}
                placeholder="-"
                InputLabelProps={{ shrink: true }}
                // error={phaseNameError.isActive}
                // helperText={phaseNameError.isActive && phaseNameError.tex}
                variant="outlined"
                disabled={!edit}
                required
                sx={{
                  mt: 2,
                  width: "25.313rem !important",
                  height: "3.5rem",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-disabled": {
                      "& fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                }}
              />
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1rem 0",
                  position: "relative",
                }}
              > */}
              <TextField
                select
                name="culture"
                label="Culture"
                sx={{
                  m: 1,
                  width: "21.5rem !important",
                  height: "3.5rem",
                  // ml: "100px !important",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-disabled": {
                      "& fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                }}
                value={culture}
                disabled={!edit}
                onChange={handleCultureChange}
                error={!!errors.cultures}
                helperText={errors.cultures}
                required
                placeholder="-"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  // open: cultureDropdown,
                  // onClose: () => handleCultureDropdown(),
                  // onOpen: () => handleCultureDropdown(),
                  multiple: true,
                  renderValue: (selected) =>
                    selected.length ? (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            style={{
                              margin: 2,
                            }}
                            onClick={(event) => event.stopPropagation()}
                            onDelete={(event) => {
                              // event.stopPropagation();
                              handleRemoveCulture(value);
                            }}
                            disabled={edit === false}
                            deleteIcon={
                              <IconButton size="small">
                                <CloseIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              </IconButton>
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      "Select an option"
                    ),
                  MenuProps: MenuProps,
                }}
              // InputProps={{
              //   endAdornment: (
              //     <>
              //       <InputAdornment position="end">
              //         {console.log("incomingMode", isDisabled, edit)}
              //         <ArrowDropDownIcon
              //           onClick={(event) =>
              //             edit === false ? {} : handleCultureDropdown()
              //           }
              //           style={{
              //             width: "10px",
              //             cursor: edit === false ? "default" : "pointer",
              //             // marginLeft: "28px",
              //           }}
              //         />
              //       </InputAdornment>
              //     </>
              //   ),
              // }}
              >
                {menuItems.map((c, index) => (
                  <MenuItem
                    key={index}
                    value={c.cuCultureName}
                    sx={{
                      borderBottom: "1px solid gray",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                      backgroundColor: (theme) =>
                        theme.palette.action.selected === "transparent"
                          ? "transparent"
                          : undefined,
                      "&.Mui-selected": {
                        backgroundColor: "transparent !important",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                      },
                    }}
                  >
                    <Checkbox checked={culture.includes(c.cuCultureName)} />
                    <ListItemText primary={c.cuCultureName} />
                  </MenuItem>
                ))}
              </TextField>

              {/* <FormHelperText error={cultureError.isActive}>
                  {cultureError.isActive && cultureError.text}
                </FormHelperText> */}
              {/* </div> */}
              <Box
                sx={{
                  ml: "3px",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      color: "#5A646E !important",
                      mb: 2,
                      ml: 2,
                      fontWeight: 500,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                    }}
                  >
                    Status
                    <span style={{ color: "red" }}> *</span>
                  </FormLabel>
                  <FormControlLabel
                    sx={{
                      ml: 2,
                      display: "flex",
                      // alignItems: "center",
                      // gap: "1rem",
                    }}
                    control={
                      <RedSwitch
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        color="primary"
                        disabled={!edit}
                      />
                    }
                    label={
                      <Box sx={{ width: "4rem", fontWeight: "500", color: "#737D86" }}>
                        {values?.status === true ? "Active" : "Inactive"}
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                gap: "2rem",
                marginTop: "100px",
              }}
            >
              <Button
                sx={{
                  borderRadius: 100,
                  width: "305px",
                  height: "44px",
                  background: "#004F9F",
                  // color:"#004F9F",
                  marginTop: -6.5,
                  marginLeft: { xs: 1 },
                }}
                disabled={isDisabled || edit === false}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => handleAddFunctionality()}
              >
                ADD FUNCTIONALITY RELATED
              </Button>
            </Box>

            {boxes.map((box, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  padding: "1rem",
                  bgcolor: "#E9EBEE",
                  width: box?.sendMessage === "send" ? "98%" : "55%",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-20px",
                    padding: "1rem",
                    alignItems: "center",
                  }}
                >
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <TextField
                      select
                      label="Functionality"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                            "&:hover fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                          },
                        },
                      }}
                      // error={!!errors?.functionalityName}
                      // helperText={errors?.functionalityName}
                      error={
                        !!errors?.functionality?.[index]?.functionalityName
                      }
                      helperText={
                        errors?.functionality?.[index]?.functionalityName
                      }
                      // required
                      disabled={
                        isDisabled || edit === false
                        // isDisabled || edit === false
                        // !edit
                        //   ? item?.isActive === "Inactive"
                        //     ? true
                        //     : false
                        //   : edit
                      }
                      fullWidth
                      value={box.text}
                      onChange={(event) =>
                        edit
                          ? handleTextChange(index, "text", event.target.value)
                          : {}
                      }
                      // SelectProps={{
                      //   IconComponent: ArrowDropDownIcon,
                      // }}
                      SelectProps={{
                        open: dropdownStates[index] || false,
                        onOpen: () => handleDropdownToggle(index),
                        onClose: () => handleDropdownToggle(index),
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <CloseIcon
                                onClick={() =>
                                  isDisabled || edit === false
                                    ? {}
                                    : handleTextChange(index, "text", "")
                                }
                                style={{
                                  width: "18px",
                                  cursor:
                                    isDisabled || edit === false
                                      ? "default"
                                      : "pointer",
                                  // marginRight: "12px",
                                }}
                              />
                            </InputAdornment>
                            <InputAdornment position="end">
                              {console.log("incomingMode", isDisabled, edit)}
                              <ArrowDropDownIcon
                                onClick={() => handleDropdownToggle(index)}
                                style={{
                                  width: "10px",
                                  cursor:
                                    edit === false ? "default" : "pointer",
                                  // marginLeft: "28px",
                                }}
                              />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    >
                      {FunctionalityItems.map((item, idx) => (
                        <MenuItem key={idx} value={item?.pickListDescription}>
                          {item?.pickListDescription}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  {/* <FormControl sx={{ minWidth: "18.75rem" }}>
                    <TextField
                      select
                      label="Functionality"
                      variant="outlined"
                      fullWidth
                      value={box.text}
                      onChange={(event) =>
                        handleTextChange(index, "text", event.target.value)
                      }
                      error={
                        !!errors?.functionality?.[index]?.functionalityName
                      }
                      helperText={
                        errors?.functionality?.[index]?.functionalityName
                      }
                      SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    >
                      {FunctionalityItems.map((item, idx) => (
                        // console.log("desc",item.pickListDescription)
                        <MenuItem key={idx} value={item?.pickListDescription}>
                          {item?.pickListDescription}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl> */}
                  {/* <img
                    onClick={() =>
                      !edit
                        ? item?.isActive === "Inactive"
                          ? "not-allowed"
                          : handleClickOpen(index)
                        : edit
                        ? "not-allowed"
                        : handleClickOpen(index)
                    }
                    src={deletebutton}
                    alt="Delete"
                    style={{
                      marginLeft: "1rem",
                      height: "100%",
                      width: "100px",
                      cursor: !edit
                        ? item?.isActive === "Inactive"
                          ? "not-allowed"
                          : "pointer"
                        : edit
                        ? "not-allowed"
                        : "pointer",
                      opacity: !edit
                        ? item?.isActive === "Inactive"
                          ? 0.5
                          : 1
                        : edit
                        ? 0.5
                        : 1,
                      display: "block",
                      pointerEvents:
                        !edit && item?.isActive !== "Inactive"
                          ? "auto"
                          : "none",
                    }}
                  /> */}

                  <Button
                    sx={{
                      borderRadius: "2.116rem",
                      backgroundColor: "#9F00001F",
                      borderColor: "#EF0000",
                      color: "#EF0000",
                      cursor: edit
                        ? item?.isActive === "Inactive"
                          ? "not-allowed"
                          : "pointer"
                        : !edit
                          ? "not-allowed"
                          : "pointer",
                      opacity: edit
                        ? item?.isActive === "Inactive"
                          ? 0.5
                          : 1
                        : !edit
                          ? 0.5
                          : 1,
                      pointerEvents:
                        edit && item?.isActive !== "Inactive" ? "auto" : "none",
                      "&:hover": {
                        backgroundColor:
                          item?.isActive === "Inactive"
                            ? "#9F00001F"
                            : "#EF00001A",
                        borderColor:
                          item?.isActive === "Inactive" ? "#EF0000" : "#FF0000",
                      },
                    }}
                    variant="outlined"
                    onClick={() =>
                      edit
                        ? item?.isActive === "Inactive"
                          ? "not-allowed"
                          : handleClickOpen(index)
                        : !edit
                          ? "not-allowed"
                          : handleClickOpen(index)
                    }
                    disabled={isDisabled || edit === false}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginRight: "5px",
                      }}
                    >
                      <img src={iconDelete} alt="delete" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        height: "14px",
                        color: "#EF0000",
                        marginBottom: "5px",
                      }}
                    >
                      Delete
                    </div>
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure want to delete  Functionality?"}
                    </DialogTitle>

                    <DialogActions>
                      <Button onClick={() => handleClose()}>Disagree</Button>
                      <Button onClick={() => handleDelete()} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-30px",
                    width: "100%",
                    padding: "1rem",
                    pointerEvents: edit === false ? "none" : "auto",
                  }}
                >
                  {/* <FormControl
                    component="fieldset"
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <RadioGroup
                      // name={send-message-${box.id}}
                      name={`send-message-${box.id}`}
                      value={box.sendMessage}
                      onChange={(event) =>
                        handleTextChange(
                          index,
                          "sendMessage",
                          event.target.value
                        )
                      }
                      sx={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <FormControlLabel
                        value="send"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontWeight: "normal" }}>
                            Send Message
                          </Typography>
                        }
                        sx={{ marginRight: 2 }}
                        disabled={
                          isDisabled
                          // !edit
                          //   ? item?.isActive === "Inactive"
                          //     ? true
                          //     : false
                          //   : edit
                        }
                      />
                    </RadioGroup>
                  </FormControl> */}

                  <div
                    disabled={isDisabled || edit === false}
                    checked={box.sendMessage === "send"}
                    onClick={() =>
                      isDisabled || edit === false
                        ? {}
                        : handleTextChange(
                          index,
                          "sendMessage",
                          box.sendMessage === "send" ? "" : "send"
                        )
                    }
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      border: `2px solid ${box.sendMessage === "send" ? "#004F9F" : "gray"
                        }`,
                      backgroundColor: "transparent",
                      cursor: isDisabled ? "default" : "pointer",
                      // cursor: isDisabled ? "not-allowed" : "pointer",
                      // display: "inline-block",
                      marginRight: "10px",
                      position: "relative",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <div
                      style={{
                        width: 14,
                        height: 14,
                        borderRadius: "50%",
                        backgroundColor:
                          box.sendMessage === "send"
                            ? "#004F9F"
                            : "transparent",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                  <Typography
                    sx={{
                      fontWeight: "normal",
                      maxLines: 1,
                    }}
                  >
                    Send Message
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // width: "500px",
                    }}
                  >
                    <TextField
                      name="days"
                      variant="outlined"
                      type="number"
                      inputProps={{ min: 0 }}
                      disabled={
                        isDisabled || edit === false
                        // !edit
                        //   ? item?.isActive === "Inactive"
                        //     ? true
                        //     : false
                        //   : edit
                      }
                      size="small"
                      value={box.days || 0}
                      onChange={(event) =>
                        handleTextChange(index, "days", event.target.value)
                      }
                      sx={{
                        width: "80px !important",
                        marginRight: "1rem",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                            "&:hover fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                          },
                        },
                      }} // Adjust width here
                    />
                    <div>days before crop ending</div>
                  </Box>
                </Box>

                {box.sendMessage === "send" && (
                  <TextField
                    name="message"
                    variant="outlined"
                    multiline
                    minRows={3}
                    disabled={
                      isDisabled || edit === false
                      // !edit
                      //   ? item?.isActive === "Inactive"
                      //     ? true
                      //     : false
                      //   : edit
                    }
                    value={box.message}
                    placeholder={`The ${box.text || "functionality"
                      } will end in ${box.days || 0} days.`}
                    onChange={(event) =>
                      handleTextChange(index, "message", event.target.value)
                    }
                    error={!!errors[`cpFunctionalities[${index}].messageText`]}
                    helperText={
                      errors[`cpFunctionalities[${index}].messageText`] || ""
                    }
                    sx={{
                      marginTop: "20px",
                      width: "100% !important",
                      minHeight: "20px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                    }}
                  />
                )}
              </Box>
            ))}

            <hr style={{ marginTop: "40px", marginBottom: "20px" }} />

            <Box
              sx={{
                marginTop: "10px",
                // bgcolor:'yellow',
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                pointerEvents: edit === false ? "none" : "auto",
              }}
            >
              {/* <FormControl component="fieldset">
                <RadioGroup
                  name="IsMessage"
                  value={values.IsMessage}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="send"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontWeight: "normal" }}>
                        Send Message
                      </Typography>
                    }
                    sx={{ marginRight: 4 }}
                    // disabled={edit}
                  />
                </RadioGroup>
              </FormControl> */}
              <div
                // checked={values.IsMessage === "send"}
                onClick={(event) =>
                  handleTextChange(
                    0,
                    "global",
                    values.IsMessage === "unsend" ? "send" : "unsend"
                  )
                }
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  border: `2px solid ${values.IsMessage === "send" ? "#004F9F" : "gray"
                    }`,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  // display: "inline-block",
                  marginRight: "10px",
                  position: "relative",
                  transition: "background-color 0.3s ease",
                }}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: "50%",
                    backgroundColor:
                      values.IsMessage === "send" ? "#004F9F" : "transparent",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <Typography
                sx={{
                  fontWeight: "normal",
                  maxLines: 1,
                }}
              >
                Send Message
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "col",
                  alignItems: "center",
                  // marginLeft: "-35px",
                  // width: "100px",
                }}
              >
                <TextField
                  name="days"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                  size="small"
                  value={values.days || 0}
                  onChange={handleChange}
                  sx={{ width: "80px !important", marginRight: "1rem" }}
                // disabled={edit}
                />
              </Box>
              <div style={{ marginRight: "190px" }}>
                days before crop ending
              </div>
            </Box>
            {values.IsMessage === "send" && (
              <Box>
                <TextField
                  name="message"
                  variant="outlined"
                  value={values.message}
                  placeholder="Write the message here"
                  onChange={handleChange}
                  error={!!errors?.messageText}
                  helperText={errors?.messageText}
                  sx={{
                    width: "100% !important",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                        "&:hover fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px",
                        },
                      },
                    },
                  }}
                  disabled={edit === false}
                />
              </Box>
            )}
            {edit != false && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                  marginTop: "100px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#737D86",
                    padding: "6px 16px",
                    borderRadius: "4px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5C646B",
                    },
                  }}
                  startIcon={<img src={arrowLeft} alt="Left Arrow" />}
                  variant="contained"
                  type="secondary"
                  disabled={loading}
                  onClick={() => navigate("/cropPhaseRegistration")}
                >
                  {loading && <CircularProgress size={20} />}
                  {edit == true ? "Cancel" : "Back"}
                </Button>
                {/* {!edit && ( */}
                <Button
                  sx={{
                    backgroundColor: "#004F9F",
                    padding: " 6px, 16px, 6px, 11px",
                    borderRadius: " 4px",
                    color: "white",
                  }}
                  endIcon={<img src={arrowRight} alt="Right Arrow" />}
                  type="button"
                  variant="contained"
                  onClick={id === undefined ? handleSubmit : handleUpdate}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={20} />}
                  {id === undefined ? "Save" : "Update"} Phase Registration
                </Button>
                {/* )} */}
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </Layout>
  );
}
