import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import CountryFilter from "./CountryFilter";
import CultureRegistrationService from "services/CultureRegistrationService";

const AddCultureRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { cuCultureName, cuStatus, cuCultureId, countryId, languageId } =
    location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    cuCultureName: cuCultureName || "",
    cuStatus: cuStatus === true ? true : false,
    countryId: countryId || "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
    languageId: languageId || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/getCultureRegistration");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const cultureNameTrimmed = values?.cuCultureName?.trim();

    // Validation checks
    if (!cultureNameTrimmed) {
      schemaValidationErrors.cuCultureName = t("Culture Registration.formErrors.cultureNameRequired");
    } else if (!specialCharPattern.test(cultureNameTrimmed)) {
      schemaValidationErrors.cuCultureName = t("Culture Registration.formErrors.noSpecialChars");
    } else if (cultureNameTrimmed !== values?.cuCultureName) {
      schemaValidationErrors.cuCultureName = t("Culture Registration.formErrors.noLeadingTrailingSpaces");
    }

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      createdDateTimeUTC: new Date().toISOString(),
      modifiedDateTimeUTC: new Date().toISOString(),
      createdByUser: "Mayur",
      modifiedByUser: "Mayur",
      cuCultureTypeAmount: 0,
      cuCultureName: cultureNameTrimmed,
      cuStatus: values.cuStatus,
      linkedCountries: [
        {
          createdDateTimeUTC: new Date().toISOString(),
          modifiedDateTimeUTC: new Date().toISOString(),
          createdByUser: "Mayur",
          modifiedByUser: "Mayur",
          cultureCountryLinkId: "",
          cclStatus: true,
          countryCode: "BRA"
        },
        {
          createdDateTimeUTC: new Date().toISOString(),
          modifiedDateTimeUTC: new Date().toISOString(),
          createdByUser: "Mayur",
          modifiedByUser: "Mayur",
          cultureCountryLinkId: "",
          cclStatus: true,
          countryCode: "MEX"
        },
        {
          createdDateTimeUTC: new Date().toISOString(),
          modifiedDateTimeUTC: new Date().toISOString(),
          createdByUser: "Mayur",
          modifiedByUser: "Mayur",
          cultureCountryLinkId: "",
          cclStatus: true,
          countryCode: "BGD"
        }
      ]
    };

    // Add cuCultureId to payload if in edit mode
    if (edit) {
      payload.cuCultureId = cuCultureId;
    }

    try {
      setLoading(true);
      let response;

      if (edit) {
        // PUT request for edit
        response = await CultureRegistrationService.updateCulture({
          ...payload,
        });
      } else {
        // POST request for create
        response = await CultureRegistrationService.createCulture({
          ...payload,
        });
      }
      
      setLoading(false);

      if (response.status === 200 || response.status === 201) {
        setSeverity("success");
        setMessage(t("RECORD SAVED SUCCESSFULLY!"));
        setOpenAlert(true);
        navigate("/getCultureRegistration");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(
          t("Culture Registration.messages.cultureExists", {
            name: values.cuCultureName,
          })
        );
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("Culture Registration.messages.submissionFailed"));
        setOpenAlert(true);
      }
    }
  };

  return (
    <Layout title={t("Culture Registration.title")} background="#ffffff">
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t("form")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
              borderRadius: "5px",
            }}
          >
            <TextField
              name="cuCultureName"
              label="Culture"
              value={values?.cuCultureName}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                  marginLeft: "10px",
                },
              }}
              InputProps={{
                style: { height: "50px", marginLeft: "10px" },
              }}
            />
            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel sx={{ color: "#0E2B63", mb: 2, ml: 0.5, '&.Mui-focused': {
                      color: '#0E2B63 !important'
                    } }}>
                  {t("status")}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>{t("inactive")}</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values.cuStatus}
                        onChange={handleChange}
                        name="cuStatus"
                        color="primary"
                        disabled={edit === false}
                      />
                    }
                    label={
                      <Typography sx={{ ml: 0 }}>{t("active")}</Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 2px",
                background: "#FFFFFF",
                marginTop: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#5A646E",
                  marginLeft: "10px",
                }}
              >
                Available For
              </Typography>
            </Box>
            <Box
              id="divider"
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 20px 0px",
                gap: "20px",
                background: "#FFFFFF",
                borderBottom: "5px solid #004F9F",
              }}
            ></Box>
          </Box>

          <CountryFilter />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              left: 0, // Ensure it starts from the left edge
              bottom: "19px", // Place at the bottom of the screen
              background: "white",
              zIndex: 999,
              padding: "0.5rem",
              borderTop: "1px solid var(--Gray-1, #E9EBEE)",
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                marginLeft: "30px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? t("back") : t("cancel")}
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                  marginRight: "30px",
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                variant="contained"
                onClick={handleSave}
              >
                {loading ? <CircularProgress size={20} /> : t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
};

export default AddCultureRegistration;
