import { httpGrow } from "../http-common";

const getGenetic = async (query) => {
  const response = await httpGrow.get(
    `/geneticType/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};
const getCulture = () => {
  const response = httpGrow.get(`/culture/cultureNames-and-Id`);
  return response;
};

const createGenetic = (data) => {
  return httpGrow.post(`/geneticType/save`, data);
};

const updateGenetic = (data) => {
  return httpGrow.put(`/geneticType/update`, data);
};

const deleteGenetic = (gtGeneticTypeId) => {
  return httpGrow.delete(`/geneticType/delete/${gtGeneticTypeId}`);
};
const searchGenetic = async (queryString) => {
  const response = await httpGrow.get(`/geneticType/search${queryString}`);
  return response;
};

const GeneticService = {
  getGenetic,
  getCulture,
  createGenetic,
  deleteGenetic,
  searchGenetic,
  updateGenetic,
};
export default GeneticService;
