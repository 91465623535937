import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import SupplyConfigurationTable from "./SupplyConfigurationTable";
import SearchTextInput from "components/common/SearchTextInput";
import { useTranslation } from "react-i18next";
import SupplierConfigurationService from "services/SupplierConfigurationService";


const SupplierConfiguration = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("SupplyConfig")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showUsedRecords, setShowUsedRecords] = useState(false);

  console.log("usedRecords", showUsedRecords)
  const handleShowUsedRecords = () => {
    setShowUsedRecords(!showUsedRecords);
    fetchData(0, itemsPerPage, searchKeyword, !showUsedRecords);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      console.log('usedRecordscalback', showUsedRecords)
      fetchData(0, itemsPerPage, value, showUsedRecords);
    }, 10),
    [itemsPerPage, showUsedRecords]
  );

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value === "") {
      fetchData(0, itemsPerPage); // Fetch data without search keyword when input is cleared
    } else {
      debouncedSearch(value);
    }
  };

  const fetchData = (
    pageNumber,
    size,
    keyword,
    useRecord
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword,
      useRecord,
    };
    console.log("usedRecordsquery", query)
    SupplierConfigurationService.getAPI(query)
      .then((res) => {
        const results =
          res?.data?.response?.supplierConfiguration || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0 && keyword.length > 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, searchKeyword, showUsedRecords);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("SupplyConfig", itemsPerPage)
    fetchData(pageNumber - 1, itemsPerPage, searchKeyword, showUsedRecords);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("supplierConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          padding: "1rem",
          height: "110px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <SearchTextInput
              label={t("supplierConfiguration.searchField")}
              value={searchKeyword}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder={t("")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} textAlign={"right"}>
            <FormControlLabel
              label={t("usedRecords")}
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "rgba(0, 0, 0, 0.6)",
                  fontWeight: 400
                },
              }}
              control={
                <Checkbox
                  checked={showUsedRecords}
                  onChange={handleShowUsedRecords}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />

          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Typography variant="h6" align="center">
          {t("loading")}
        </Typography>
      ) : (
        <>
          {(
            totalItems > 0 && <SupplyConfigurationTable TableData={data} />
          )}
          {/* {showUsedRecords ? (
            data.filter((row) => row.useThisRecord).length > 0 ? (
              <SupplyConfigurationTable
                TableData={data.filter((row) => row.useThisRecord)}
              />
            ) : (
              // Set alert if no used records found
              <>
                {setMessage("No used records found.")}
                {setSeverity("error")}
                {setOpenAlert(true)}
                <Typography variant="h6" align="center">
                  {t("notfound")}
                </Typography>
              </>
            )
          ) : (
            totalItems > 0 && <SupplyConfigurationTable TableData={data} />
          )} */}
        </>
      )}


    </Layout>
  );
};

export default SupplierConfiguration;
