import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@mui/material";
import RedSwitch from "utils/RedSwitch";
import brasilFlag from "assets/BrasilFlag.svg";
import mexicoFlag from "assets/MexicoFlag.svg";
import bangladeshFlag from "assets/BangladeshFlag.svg";
import pakistanFlag from "assets/PakistanFlag.svg";
import MultiSelectInput from "components/common/MultiSelectInput";
import VarietyRegistrationService from "services/VarietyRegistrationService";
import SpacingMultiSelect from 'components/common/SpacingMultiSelect';

export default function CountryFilter({ 
  cultureId, 
  onCountryChange, 
  onSpacingChange, 
  onCultureTypeChange,
  cultureTypeOptions,
  initialCountries,
  initialSpacings,
  initialCultureTypes
}) {
  const { t } = useTranslation();

  const [values, setValues] = useState(initialCountries || {
    statusBrasil: false,
    statusMexico: false,
    statusBangladesh: false,
    statusPakistan: false,
  });

  const [spacingSelections, setSpacingSelections] = useState(initialSpacings || {});
  const [cultureTypeSelections, setCultureTypeSelections] = useState(initialCultureTypes || {});
  const [spacingOptions, setSpacingOptions] = useState([]);
  const [isLoadingSpacing, setIsLoadingSpacing] = useState(false);
  const [isLoadingCultureTypes, setIsLoadingCultureTypes] = useState(false);

  // Debug logs
  useEffect(() => {
    console.log('CountryFilter Initial Values:', {
      countries: initialCountries,
      spacings: initialSpacings,
      cultureTypes: initialCultureTypes
    });
  }, [initialCountries, initialSpacings, initialCultureTypes]);

  // Update values when initial data changes
  useEffect(() => {
    if (initialCountries) {
      setValues(initialCountries);
    }
    if (initialSpacings) {
      setSpacingSelections(initialSpacings);
    }
    if (initialCultureTypes) {
      setCultureTypeSelections(initialCultureTypes);
    }
  }, [initialCountries, initialSpacings, initialCultureTypes]);

  // Fetch spacing options
  useEffect(() => {
    const fetchSpacingOptions = async () => {
      if (!cultureId) return;
      
      setIsLoadingSpacing(true);
      try {
        const response = await VarietyRegistrationService.getSpacingByCulture(cultureId);
        
        const formattedOptions = response.data.response.map(spacing => ({
          value: spacing.spacingRegistrationUid,
          label: `${spacing.lineSpacing} x ${spacing.plantSpacing}`,
          lineSpacing: spacing.lineSpacing,
          plantSpacing: spacing.plantSpacing,
          spacingRegistrationUid: spacing.spacingRegistrationUid
        }));
        
        setSpacingOptions(formattedOptions);
      } catch (error) {
        console.error('Failed to fetch spacing options:', error);
      } finally {
        setIsLoadingSpacing(false);
      }
    };

    fetchSpacingOptions();
  }, [cultureId]);

  // Handle spacing type changes
  const handleSpacingTypeChange = (countryName, selectedOptions) => {
    const countryKey = `status${countryName.split(' ')[0]}`;

    setSpacingSelections(prev => ({
      ...prev,
      [countryKey]: selectedOptions
    }));

    // Pass the formatted selections to parent
    if (onSpacingChange) {
      onSpacingChange(countryKey, selectedOptions);
    }
  };

  // Update the culture type change handler
  const handleCultureTypeChange = (countryName, selectedOptions) => {
    const countryKey = `status${countryName.split(' ')[0]}`;
    
    setCultureTypeSelections(prev => ({
      ...prev,
      [countryKey]: selectedOptions
    }));

    // Pass the formatted selections to parent
    if (onCultureTypeChange) {
      onCultureTypeChange(countryKey, selectedOptions);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // Call the onCountryChange prop to update the parent state
    if (onCountryChange) {
      onCountryChange(name, checked);
    }
  };

  // Array of country configurations
  const countries = [
    {
      flag: brasilFlag,
      name: "Brasil (pt_BR)",
      statusKey: "statusBrasil",
    },
    {
      flag: mexicoFlag,
      name: "Mexico (es_MX)",
      statusKey: "statusMexico",
    },
    {
      flag: bangladeshFlag,
      name: "Bangladesh (bd_BD)",
      statusKey: "statusBangladesh",
    },
    {
      flag: pakistanFlag,
      name: "Pakistan (en_PK)",
      statusKey: "statusPakistan",
    },
  ];

  // Debug log to verify options are received
  useEffect(() => {
    console.log('Culture type options in CountryFilter:', cultureTypeOptions);
  }, [cultureTypeOptions]);

  const handleCountryToggle = (countryCode, isChecked) => {
    console.log('Country toggle:', countryCode, isChecked); // Debug log
    if (onCountryChange) {
      onCountryChange(countryCode, isChecked);
    }
  };

  return (
    <>
      {countries.map((country, index) => (
        <React.Fragment key={country.name}>
          <Grid
            container
            spacing={2}
            sx={{
              background: "#EFEFEF",
              borderRadius: "10px",
              p: 2,
              maxWidth: "100%",
              margin: "auto",
              paddingBottom: "34px",
            }}
          >
            <Grid container spacing={2}>

              {/* This grid for space */}
              <Grid
                item
                xs={12}
                style={{ paddingTop: "0px", paddingLeft: "0px" }}
              >
                <Typography display="flex" sx={{ opacity: "0" }}>
                  {/* <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} /> */}
                  Space
                </Typography>
              </Grid>


              {/* Country Flag and Name */}
              <Grid
                item
                xs={2.3}
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={country.flag}
                  alt={`${country.name} flag`}
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#5A646E",
                    fontFamily: "Montserrat",
                    fontSize: "2px",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                >
                  {country.name}
                </Typography>
              </Grid>

              {/* Culture Type */}
              <Grid item xs={5}>
                <MultiSelectInput
                  label={t('VarietyRegistration.countryFilter.cultureType')}
                  options={cultureTypeOptions || []}
                  value={cultureTypeSelections[country.statusKey] || []}
                  onChange={(selected) => handleCultureTypeChange(country.name, selected)}
                  disabled={!cultureId}
                  placeholder={t('VarietyRegistration.selectOption')}
                />
              </Grid>

              {/* Spacing Type */}
              <Grid item xs={2.5}>
                <SpacingMultiSelect
                  label={t('VarietyRegistration.countryFilter.spacingType')}
                  options={spacingOptions}
                  value={spacingSelections[country.statusKey] || []}
                  onChange={(selected) => handleSpacingTypeChange(country.name, selected)}
                  disabled={isLoadingSpacing || !cultureId}
                />
              </Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      color: "#0E2B63",
                      "&.Mui-focused": { color: "#0E2B63" },
                      "&:hover": { color: "#0E2B63" },
                    }}
                  >
                    {t('VarietyRegistration.countryFilter.status')}
                  </FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 3, ml: 3 }}>
                      {t('VarietyRegistration.countryFilter.inactive')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={values[country.statusKey]}
                          onChange={handleChange}
                          name={country.statusKey}
                        />
                      }
                      label={
                        <Typography sx={{ ml: 2 }}>
                          {t('VarietyRegistration.countryFilter.active')}
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Add white space between country sections */}
          {index < countries.length - 1 && <Box sx={{ m: "2rem" }} />}
        </React.Fragment>
      ))}
    </>
  );
}
