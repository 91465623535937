import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  Chip,
  Select,
  InputLabel,
  OutlinedInput,
  Autocomplete,
  setRef,
  TextField
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RedSwitch from "utils/RedSwitch";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import ApprovalDashboardService from "services/ApprovalDashboardService";
import CancelIcon from "@mui/icons-material/Cancel";

export default function ApprovalDashboard() {
  const { t } = useTranslation();
  const [module, setModule] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [moduleOptions, setModuleOptions] = useState([]);
  const [requestor, setRequestor] = useState(null);
  const [requestorId, setRequestorId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(5);

  const requestorOptions = [
    { id: 1, name: "ColaDaMora" },
    { id: 2, name: "Alfada RombusCola" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [requestDate, setRequestDate] = useState(null);
  const handleClearDate = () => {
    setRequestDate(null);
  };

  const statusOptions = [
    "Approved",
    "Rejected",
    "Partially Approved",
    "Partially Rejected",
    "Pending Approval",
  ];

  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

 
  useEffect(() => {
    // Check if all filters are empty/cleared
    const areFiltersEmpty =
      !module && !requestDate && selectedStatuses.length === 0;

    if (areFiltersEmpty) {
      fetchAllData(currentPage, itemsPerPage); // Fetch all data when no filters are active
    } else {
      fetchFilteredData(currentPage, itemsPerPage); // Fetch filtered data when at least one filter is active
    }
  }, [module, requestDate, selectedStatuses]);



  const fetchAllData = async (pageNumber,pageSize) => {
    setLoading(true);
    try {
      const response = await ApprovalDashboardService.getAllData({
        pageNumber,
        pageSize,
      });

      setTableData(response.data.response.workFlowApprovalDashboard || []);
      setCurrentPage(pageNumber);
      setItemsPerPage(pageSize);

      setTotalItems(response.data.response.totalItems);

      console.log("tableData:", tableData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
console.log('Page',currentPage,totalItems,itemsPerPage);
  const fetchFilteredData = async (pageNumber,pageSize) => {
    setLoading(true);
    try {
      const params = {
        pageNumber,
        pageSize,
      };

      // Add filters only if they have values
      if (module) {
        params.functionalityName = module;
      }

      if (requestDate) {
        // Format date to match API requirements (assuming YYYY-MM-DD)
        params.requestDate = requestDate;
      }

      if (selectedStatuses.length > 0) {
        params.status = selectedStatuses;
      }

      const response = await ApprovalDashboardService.searchApprovalDashboard(
        params
      );
      console.log(response, "response:::");

      setTableData(response.data.response.workFlowApprovalDashboard || []);
      setCurrentPage(pageNumber);
      setItemsPerPage(pageSize);
      setTotalItems(response.data.response.totalItems);

      console.log("searchtableData", tableData);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setTableData([]); // Set no result if 404 error
      } else {
        console.error("Error fetching filtered data:", error);
      }
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatuses(typeof value === "string" ? value.split(",") : value);
  };
  // const handleDateChange = (e) => {
  //   setRequestDate(e.target.value);
  // };

  const handleDelete = (statusToDelete) => {
    setSelectedStatuses((current) =>
      current.filter((status) => status !== statusToDelete)
    );
  };

  useEffect(() => {
    fetchModuleData();
  }, []);

  const fetchModuleData = async () => {
    try {
      let response = await ApprovalDashboardService.geModuleAPI();

      setModuleOptions(
        response.data.response.map((item) => ({
          label: item.functionalityName.toString(),
          value: item.functionalityName.toString(),
          moduleId: item.fId, // Assuming there's an id field
        }))
      );
      console.log("moduleOptions", moduleOptions);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };
  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (!module && !requestDate && !selectedStatuses) {
      fetchAllData(pageNumber - 1, itemsPerPage);
    } else {
      fetchFilteredData(
        pageNumber - 1,
        itemsPerPage,
        module,
        requestDate,
        selectedStatuses
      );
    }
  };

  return (
    <Layout
      title={t("Approval Dashboard")}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "20px",
          marginBottom: "12px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography sx={{ fontWeight: 600 }} variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("TechnicalRecomendation.mandatoryFilters")}
          </Typography>
        </Grid>

        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth size="small">
            <Autocomplete
              value={module}
              onChange={(event, newValue) => {
                if (newValue) {
                  setModule(newValue.value);
                  setModuleId(newValue.moduleId); // This will trigger useEffect
                } else {
                  setModule("");
                  setModuleId(""); // This will trigger useEffect
                }
              }}
              options={moduleOptions}
              disableClearable={false} // Allow clearing the value
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select an Option"
                  label={t("System Module")}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} style={{ paddingLeft: "20px" }}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                requestorOptions.find((option) => option.id === requestorId) ||
                null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setRequestor(newValue.name);
                  setRequestorId(newValue.id);
                } else {
                  setRequestor(null);
                  setRequestorId("");
                }
              }}
              options={requestorOptions}
              getOptionLabel={(option) => option.name || ""}
              disableClearable={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Requestor")}
                  variant="outlined"
                  placeholder="Select an Option"
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <input
              type="date"
              value={requestDate}
              onChange={handleDateChange}
              variant="outlined"
              style={{
                padding: "14px",
                fontSize: "16px",
                border: "1px solid navy blue",
                borderRadius: "4px",
                width: "100%",
              }}
            />
          </div> */}
        <Grid item xs={3} sx={{ width: "100%", mt: -1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Request Date"
                value={requestDate}
                onChange={(newValue) => setRequestDate(newValue)}
                sx={{width:'100%'}}
                slotProps={{
                  field: { 
                    clearable: true,
                  },
                 
                }}
               
              />
            </DemoContainer>
          </LocalizationProvider>
         
        </Grid>

        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>{t("Show all")}</Box>
            <RedSwitch color="primary" />
            <Box>{t("Assigned to me")}</Box>
          </Box>
        </Grid>

        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                multiple
                value={selectedStatuses}
                onChange={handleChange}
                input={<OutlinedInput label="Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Table Data={tableData} />
    </Layout>
  );
}
