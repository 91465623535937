// GetSoilAnalysis.jsx
import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Button, TextField, Typography, Grid, Modal, InputAdornment } from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SoilAnalysisTable from "./SoilAnalysisTable";
import SoilAnalysisService from "services/SoilAnalysisService";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from 'react-i18next';
import SearchIcon from "@mui/icons-material/Search";

const GetSoilAnalysis = () => {
  const navigate = useNavigate();
  const [soilData, setSoilData] = useState([]);
  const [filSoilData, setFilSoilData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchParams, setSearchParams] = useState({ farmer: "" });
  const [open, setOpen] = useState(false);
  const [searchDescriptionParams, setSearchDescriptionParams] = useState({
    description: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  console.log("filSoilData", filSoilData);

  const handleSearchParamsChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    setSearchKeyword(value);
    if (value) {
      callSearchAPI(
        0,
        itemsPerPage,
        value,
        searchDescriptionParams.description
      );
    } else {
      callSoilAPI(0, itemsPerPage);
    }
  };

  const handleSearchDescriptionParamsChange = (event) => {
    const { name, value } = event.target;
    setSearchDescriptionParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    setSearchKeyword(value);
    if (value) {
      callSearchAPI(0, itemsPerPage, searchParams.farmer, value);
    } else {
      callSoilAPI(0, itemsPerPage);
    }
  };

  const callSoilAPI = (pageNumber, size) => {
    setLoading(true);
    SoilAnalysisService.getSoilAnalysis({ pageNumber, size })
      .then((res) => {
        const soilResults = res?.data?.response?.soilAnalysis || [];
        console.log(soilResults);
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setSoilData(soilResults);
        setFilSoilData(soilResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (soilResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilData([]);
        setFilSoilData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchAPI = (
    pageNumber,
    size,
    farmerInput,
    soilAnalysisDescription,
    saStatus
  ) => {
    setLoading(true);
    let query = {
      pageNumber,
      size,
      farmerInput,
      soilAnalysisDescription,
      saStatus,
    };

    SoilAnalysisService.searchSoilAnalysis(query)
      .then((res) => {
        const soilResults = res?.data?.response?.soilAnalysis || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setSoilData(soilResults);
        setFilSoilData(soilResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (soilResults.length === 0 && searchKeyword.length > 0) {
          setMessage("No records found.");
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilData([]);
        setFilSoilData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadTemplate = () => {
    SoilAnalysisService.downloadTemplate()
      .then(response => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { 
          type: 'text/csv'
        });
        
        // Create a link element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'soil_analysis_template.csv');
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        setMessage(error?.response?.data?.message || "Error downloading template");
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      await handleFileUpload(file);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    try {
      setLoading(true);
      await SoilAnalysisService.importSoilAnalysis(
        file,
        '5d68bc83-3635-4a2b-a0df-1199b0b75c6e', // countryId
        '3fa85f64-5717-4562-b3fc-2c963f66afa6', // languageId
        'Mayur', // createdByUser
        'Mayur'  // modifiedByUser
      );

      setMessage("File uploaded successfully!");
      setOpenAlert(true);
      setSeverity("success");
      handleCloseModal();
      // Refresh the soil analysis data
      callSoilAPI(currentPage, itemsPerPage);
    } catch (error) {
      setMessage(error?.response?.data?.message || "Error uploading file");
      setOpenAlert(true);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callSoilAPI(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callSoilAPI(pageNumber - 1, itemsPerPage);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      await handleFileUpload(file);
    } else {
      setMessage("Please upload a CSV file");
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t('Soil Analysis List')}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t('filtersLable')}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="farmer"
                  label={t('SoilAnalysisRegistration.formLabels.searchFarmer')}
                  placeholder={t('SoilAnalysisRegistration.formLabels.searchCodeNameFiscal')}
                  value={searchParams.farmer}
                  onChange={handleSearchParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ 
                          color: 'action.active', 
                          mr: 1, 
                          fontSize: 35 
                        }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  name="description"
                  label={t('SoilAnalysisRegistration.formLabels.soilAnalysisDescription')}
                  value={searchDescriptionParams.description}
                  onChange={handleSearchDescriptionParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign={"right"}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={{
                  borderRadius: 1,
                  minWidth: 120,
                  minHeight: 44,
                  background: "#004F9F",
                  marginRight: "10px",
                }}
                variant="contained"
                onClick={handleOpenModal}
              >
                {t('SoilAnalysisRegistration.buttons.import')}
              </Button>

              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "460px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "420px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t('SoilAnalysisRegistration.modals.import')}
                    </Typography>
                  </Box>

                  {/* Centering the Icon, Typography, and Button */}
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1, // Space between elements
                    }}
                  >
                    {/* Wrap the icon and text in a Box with a border */}
                    <Box
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      sx={{
                        border: "4px solid #ACB4BA",
                        borderRadius: "5px",
                        p: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <DescriptionOutlinedIcon
                        sx={{ fontSize: 60, color: "grey" }}
                      />

                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 1, fontWeight: 400, textAlign: "center" }}
                      >
                        {t('SoilAnalysisRegistration.modals.dragDrop')}
                      </Typography>

                      {/* Add light gray background for "OR" and "BROWSE FILE" */}
                      <Box
                        sx={{
                          background: "#E9EBEE",
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          p: 1.5,
                          mt: 1, // Margin top for spacing
                        }}
                      >
                        <Typography
                          id="modal-modal-description"
                          sx={{ fontWeight: 400, textAlign: "center" }}
                        >
                          {t('SoilAnalysisRegistration.modals.or')}
                        </Typography>

                        <Button
                          onClick={handleBrowseClick}
                          sx={{
                            background: "#0E2B63",
                            width: "150px",
                            color: "white",
                            mt: 1,
                            "&:hover": { background: "#0E2B63" },
                          }}
                        >
                          {t('SoilAnalysisRegistration.modals.browseFile')}
                        </Button>
                        <input
                          type="file"
                          hidden
                          ref={fileInputRef}
                          onChange={handleFileSelect}
                          accept=".csv"
                        />
                      </Box>
                    </Box>

                    <Button
                      onClick={downloadTemplate}
                      sx={{
                        background: "#004F9F",
                        width: "200px",
                        color: "#ffffff",
                        mt: 1,
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t('SoilAnalysisRegistration.modals.downloadTemplate')}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "30px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t('close')}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              <Button
                sx={{
                  borderRadius: 1,
                  minWidth: 95,
                  minHeight: 44,
                  background: "#004F9F",
                }}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => navigate("/addSoilAnalysis")}
              >
                {t('addNew')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SoilAnalysisTable soilData={filSoilData} />
    </Layout>
  );
};

export default GetSoilAnalysis;
