// src\services\LocalityConfigurationService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "localityFieldTechnicianConfig";

const getAPI = async (query) => {
    const { 
        pageNumber = 0, 
        size = 50, 
        keyword = '',
        postalcode, 
        stateCode, 
        cityName,
        onlyLocalities = false
    } = query;
    
    // If we're only fetching localities, we'll use different parameters
    const params = onlyLocalities ? {
        pageNumber: 0,
        pageSize: size,
    } : {
        pageNumber,
        pageSize: size,
        locality: keyword || undefined,
        localityPostalCode: postalcode || undefined,
        stateCode: stateCode || undefined,
        cityName: cityName || undefined,
    };

    const response = await httpGrow.get(`/${basename}/search`, { params });
    return response;
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}`, data);
};

const getCityAPI = async () => {
    const response = await httpGrow.get(`/city`);
    return response;
};

const getRegionAPI = async () => {
    const response = await httpGrow.get(`/region`);
    return response;
};

const LocalityConfigurationService = {
    getAPI,
    updateAPI,
    getCityAPI,
    getRegionAPI,
};

export default LocalityConfigurationService;
