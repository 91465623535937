import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Lable from "utils/lables.json";
import { useNavigate } from "react-router-dom";
import { use } from 'i18next';
import ConfirmationModal from './ConfirmationModal'; // Import the ConfirmationModal component

// const FiltersGrid = ({
//   searchKeyword,
//   handleSearchChange,
//   menu,
//   statusFilter,
//   handleStatusChange,
// }) => {
//   const navigate = useNavigate();

  const FiltersGrid = ({ onViewHistory, historyData, initialContractTarget, initialProductivityTarget, onTargetsChange }) => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [menu,setMenu] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal
    const [contractTarget, setContractTarget] = useState(initialContractTarget || "");
    const [productivityTarget, setProductivityTarget] = useState(initialProductivityTarget || "");
    const [hectaresTarget, setHectaresTarget] = useState("");

    //todo
    const getAPI = undefined;


    const resetFilters = () => {
      // setSearchKeyword("");
      // setStatusFilter("");
      setCurrentPage(0);
      getAPI(0, itemsPerPage);
    };


    const handleStatusChange = (status) => {
      setStatusFilter(status);
      setCurrentPage(0);
      if (status === "") {
        setStatusFilter("");
        resetFilters();
      } else {
        const isActive =
          status === "Active" ? true : status === "Inactive" ? false : null;
        getAPI(0, itemsPerPage, searchKeyword, isActive);
      }
    };

    const handleSearchChange = (event) => {
      setSearchKeyword(event.target.value);
    };

    // New function to handle opening the modal
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };

    // New function to handle closing the modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    // Mock history data (replace with actual data when available)
    const mockHistoryData = [
      {
        hectares: 250,
        productivity: '3,874.00',
        contract: '58,633',
        dateTime: '26/03/2024 - 14h52',
        username: 'Antônio Silva',
      },
      {
        hectares: 250,
        productivity: '3,874.00',
        contract: '58,633',
        dateTime: '26/03/2024 - 14h52',
        username: 'Antônio Silva',
      },
    ];

    // Update values when initial values change from API
    React.useEffect(() => {
      if (initialContractTarget !== undefined) {
        setContractTarget(initialContractTarget);
      }
      if (initialProductivityTarget !== undefined) {
        setProductivityTarget(initialProductivityTarget);
      }

      // Calculate initial hectares if both values are present
      if (initialContractTarget && initialProductivityTarget) {
        const newHectares = (parseFloat(initialContractTarget) * 1000) / parseFloat(initialProductivityTarget);
        setHectaresTarget(newHectares.toFixed(2));
      }
    }, [initialContractTarget, initialProductivityTarget]);

    // Calculate hectares and notify parent of changes
    const handleTargetChange = (field, value) => {
      const numValue = parseFloat(value) || 0;
      
      if (field === 'contractTarget') {
        setContractTarget(value);
        // Calculate hectares if productivity exists
        if (productivityTarget) {
          const newHectares = (numValue * 1000) / parseFloat(productivityTarget);
          setHectaresTarget(isFinite(newHectares) ? newHectares.toFixed(2) : "0.00");
        }
      } else if (field === 'productivityTarget') {
        setProductivityTarget(value);
        // Calculate hectares if contract exists
        if (contractTarget) {
          const newHectares = (parseFloat(contractTarget) * 1000) / numValue;
          setHectaresTarget(isFinite(newHectares) ? newHectares.toFixed(2) : "0.00");
        }
      }

      // Notify parent component of all changes with the new global targets
      onTargetsChange({
        contractTarget: field === 'contractTarget' ? numValue : parseFloat(contractTarget) || 0,
        productivityTarget: field === 'productivityTarget' ? numValue : parseFloat(productivityTarget) || 0,
        hectaresTarget: parseFloat(hectaresTarget) || 0,
        updateAllRows: true // Add this flag to indicate global update
      });
    };

    return (
      <>
        <Grid
          container
          spacing={2}
          rowSpacing={0.1}
          sx={{ 
            background: "#EFEFEF",
            borderRadius: "5px",
            p: 2,
            maxWidth: "100%",
            margin: "auto",
            paddingBottom: "34px",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography display="flex">
              <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
              {/* {Lable.filtersLable} */}
              Global Targets
            </Typography>
          </Grid>

          <Grid item xs={3} display="flex" style={{ paddingLeft: "0px" }}>
            <TextField
              fullWidth
              label="Contract Target (TON)"
              value={contractTarget}
              onChange={(e) => handleTargetChange('contractTarget', e.target.value)}
              type="number"
            />
          </Grid>

          <Grid item xs={3} display="flex">
            <TextField
              fullWidth
              label="Productivity Target (Kg/Ha)"
              value={productivityTarget}
              onChange={(e) => handleTargetChange('productivityTarget', e.target.value)}
              type="number"
            />
          </Grid>

          <Grid item xs={3} display="flex">
            <TextField
              fullWidth
              label="Hectares Target"
              value={hectaresTarget}
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* <Grid item xs={3} display="flex" sx={{ mb: 1, minHeight: 44 }}>
            <FormControl sx={{ minWidth: "15rem" }}>
              <TextField
                select
                name="status"
                label="Status"
                InputLabelProps={{
                  shrink: true,
                }}
                value={statusFilter || "pl"}
                onChange={(event) => handleStatusChange(event.target.value)}
              >
                <MenuItem value="pl">Select an option</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </FormControl>
          </Grid> */}

          {/* <Grid item xs={4}></Grid> */}

          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
                mb: 1,
                mr: 2,
              }}
              variant="contained"
              onClick={handleOpenModal} // Changed to open modal instead of navigating
            >
              VIEW HISTORY
            </Button>
          </Grid>

        </Grid>

        {/* Add ConfirmationModal component */}
        <ConfirmationModal
          open={isModalOpen}
          onClose={handleCloseModal}
          historyData={historyData}
        />
      </>
    );
  };

export default FiltersGrid;
