// Table.jsx
import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./FarmerClusterTable.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import Blocked from "assets/Blocked.svg";
import Approved from "assets/Approved.svg";
import NotApplied from "assets/NotApplied.svg";
import PendingApproved from "assets/PendingApproved.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import Star from "assets/Star.svg";
import StarYellow from "assets/StarYellow.svg";
import ClusterIcon from "assets/ClusterIcon.svg";
import EditSuccess from "assets/EditSuccess.svg";
import {
  Box,
  Typography,
  Button,
  Modal,
  FormControl,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";

const FarmerClusterTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null);
  const [reason, setReason] = useState("");

  const [reasonId, setReasonId] = useState("");
  const reasonOptions = [
    { id: 1, name: "Moved Out" },
    { id: 2, name: "Lost to Competition" },
    { id: 3, name: "Passed Away" },
    { id: 4, name: "Retired" },
    { id: 5, name: "Will Not Plant Anymore" },
  ];
  const [editModeRows, setEditModeRows] = useState({});

  const handleEditClick = (row) => {
    setEditModeRows((prev) => ({
      ...prev,
      [row.code]: true, // Mark this row as edited
    }));
  };

  const data = [
    {
      code: 12345,
      name: "Luiz Pereira ",
      segmentation: "S1",
      leader: false,
      ESGstatus: "Approved",
    },
    {
      code: 67890,
      name: "Adelmo Luiz Pereira Da Silva",
      segmentation: "S2",
      leader: true,
      ESGstatus: "Approved",
    },
    {
      code: 10293,
      name: "Joao Augusto Carvalho",
      segmentation: "S3",
      leader: false,
      ESGstatus: "Approved",
    },
    {
      code: 19873,
      name: "Joao Pereira ",
      segmentation: "S4",
      leader: false,
      ESGstatus: "Approved",
    },
  ];
  const [tableData, setTableData] = useState(data);

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
    setReason("");
    setReasonId("");
  };
  const handleDeleteClick = (rowCode, isLeader) => {
    if (isLeader) {
      // If the record is a leader, do not open the modal
      return;
    }
    setDeleteId(rowCode); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };
  const handleDeleteConfirm = () => {
    setTableData((prevData) => prevData.filter((row) => row.code !== deleteId));
    setOpen(false); // Close the modal
    setReason("");
    setReasonId("");
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  // const handleEditClick = (row) => {
  // navigate("/addFarmerRegistration", {
  //   state: {
  //     rowData: row,
  //   },
  // });

  // };
  const handleLeaderClick = (code) => {
    setTableData((prevData) =>
      prevData.map((row) => ({
        ...row,
        leader: row.code === code,
      }))
    );
  };

  return (
    <div>
      <table className="farmercluster-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("code")}
            >
              {t("Farmer Code")}
              {getSortIcon("code")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("name")}
            >
              {t("Farmer Name")}
              {getSortIcon("name")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("segmentation")}
            >
              {t("Segmentation")}
              {getSortIcon("segmentation")}
            </th>

            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("leader")}
            >
              {t("Cluster Leader")}
              {getSortIcon("leader")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {t("ESG Status")}
            </th>

            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ cursor: "pointer" }}>
                <td>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      style={{
                        // backgroundColor: editModeRows[row.code]
                        //   ? "green"
                        //   : "red",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <img 
                        src={editModeRows[row.code] ? EditSuccess : ClusterIcon}
                        alt="Icon"
                      />
                    </div>
                    <div
                      style={{
                        //backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "15px",
                      }}
                    >
                      <Typography style={{fontWeight:'600'}}>{row.code}</Typography>
                    </div>
                  </div>
                </td>
                <td>{row.name}</td>
                <td>{row.segmentation}</td>
                <td>
                  <div
                    onClick={() => handleLeaderClick(row.code)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <img
                      src={row.leader ? StarYellow : Star}
                      alt={row.leader ? "Leader Star" : "Star"}
                      style={{ opacity: row.leader ? 1 : 0.7 }}
                    />
                    {row.leader && <span>{t("Leader")}</span>}
                  </div>
                </td>
                <td>
                  {row.ESGstatus === "Approved" && (
                    <Tooltip title={row.ESGstatus} placement="top" arrow>
                      <img
                        src={Approved}
                        alt="Approved"
                        width="90%"
                        height="30px"
                      />
                    </Tooltip>
                  )}
                  {row.ESGstatus === "ESG Blocked" && (
                    <Tooltip title={row.ESGstatus} placement="top" arrow>
                      <img
                        src={Blocked}
                        alt="Blocked"
                        width="90%"
                        height="30px"
                      />
                    </Tooltip>
                  )}
                  {row.ESGstatus === "Pending Approval" && (
                    <Tooltip title={row.ESGstatus} placement="top" arrow>
                      <img
                        src={PendingApproved}
                        alt="Pending Approval"
                        width="90%"
                        height="30px"
                      />
                    </Tooltip>
                  )}
                  {row.ESGstatus === "Pre Assess. Not Applied" && (
                    <Tooltip title={row.ESGstatus} placement="top" arrow>
                      <img
                        src={NotApplied}
                        alt="Not Applied"
                        width="90%"
                        height="30px"
                      />
                    </Tooltip>
                  )}
                </td>

                <td className="actions-cell">
                  <>
                    <Tooltip title="EDIT" placement="top" arrow>
                      <img
                        className="btn edit-btn"
                        onClick={() => handleEditClick(row)}
                        src={_ButtonEdit_}
                        alt="Edit"
                      />
                    </Tooltip>
                  </>
                  <Tooltip title="DELETE" placement="top" arrow>
                    <img
                      className="btn delete-btn"
                      onClick={() => handleDeleteClick(row.code, row.leader)} // Pass row code and leader status
                      src={_ButtonDelete_}
                      alt="Delete"
                    />
                  </Tooltip>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "220px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("Reason for withdrawal")}
            </Typography>
          </Box>
          <Box sx={{ p: 0, paddingLeft: 2, mb: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("Please select the reason for withdrawal")}
            </Typography>
          </Box>

          <Grid item xs={2} paddingLeft="15px" width="95%" marginBottom="20px">
            <FormControl fullWidth>
              <Autocomplete
                value={
                  reasonOptions.find((option) => option.id === reasonId) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setReason(newValue.name);
                    setReasonId(newValue.id);
                  } else {
                    setReason(null);
                    setReasonId("");
                  }
                }}
                options={reasonOptions}
                getOptionLabel={(option) => option.name || ""}
                disableClearable={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Reason")}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px", // Set custom height here
                        "& .MuiInputBase-input": {
                          padding: "14px", // Adjust padding to vertically align text
                        },
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("CANCEL")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("OK")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FarmerClusterTable;
