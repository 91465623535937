import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
// import "./CultureGroup.css";
import EditButton from "../../../../assets/editButton.svg";
import iconDelete from "../../../../assets/iconDelete.svg";
import iconEdit from "../../../../assets/iconEdit.svg";
import iconView from "../../../../assets/iconView.svg";
import moreActions from "../../../../assets/moreActions.svg";
import { useNavigate } from "react-router-dom";
import "./CropPhase.css";
import { SnackbarContext } from "context/snackBarContext";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CropPhaseTable = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [CropPhaseData, setCropPhaseData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const handleEdit = () => {
    handleClose();
  };

  const handleDelete = () => {
    // Add your delete logic here
    console.log("Delete action");
    handleClose();
  };

  const handleDuplicate = () => {
    // Add your duplicate logic here
    console.log("Duplicate action");
    handleClose();
  };

  console.log("🚀 ~ CropPhaseTable ~ CropPhaseData:", CropPhaseData);
  // console.log("🚀 ~ CultureTable ~ cultureData:", cultureData)
  // console.log("prps hai",props)
  // const {cultureGroupData} = props;

  useEffect(() => {
    setCropPhaseData(props.CropPhaseData);
  }, [props.CropPhaseData]);

  const [surityModalOpen, setSurityModalOpen] = React.useState(false);
  const [surityModalMessage, setSurityModalMessage] = React.useState("");
  const [surityModalHeader, setSurityModalHeader] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState({});
  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
    // console.log(data);
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return;
    setSelectedRow(data);
    if (action == "details") {
      // console.log("data",data)
      navigate("/addnewcultureGroup", {
        state: {
          description: data.cultureGroupName,
          status: data.status,
          edit: false,
          details: true,
        },
      });
    } else {
      // console.log("id",data);
      navigate("/addnewcultureGroup", {
        state: {
          description: data.cultureGroupName,
          status: data.status,
          edit: true,
          id: data.cultureGroupId,
        },
      });
    }
    setAnchorEl(null);
  };

  const handleEditClick = (item) => {};

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr>
            <th className="custom-th">Crop Phase</th>
            <th className="custom-th">Culture</th>
            <th className="custom-th">Status</th>
            <th className="custom-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {CropPhaseData?.map((item, index) => (
            <tr
              className="hierarchyColumn"
              key={index}
              style={{ cursor: "pointer" }}
            >
              <td
                style={{ textAlign: "center" }}
                onClick={() => {
                  navigate(`/viewCropPhaseRegistration/${item.cropPhaseId}`, {
                    state: { item, edit: false },
                  });
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.cropPhaseName}
                </Typography>
              </td>
              <td
                style={{ textAlign: "center" }}
                onClick={() => {
                  navigate(`/viewCropPhaseRegistration/${item.cropPhaseId}`, {
                    state: { item, edit: false },
                  });
                }}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span>
                    {item?.cultures
                      ? item.cultures.length > 3
                        ? item.cultures
                            // .slice(0, 3)
                            .map((culture) => culture?.cuCultureName)
                            .join(", ")
                        : item.cultures
                            .map((culture) => culture?.cuCultureName)
                            .join(", ")
                      : ""}
                  </span>
                </Box>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
                onClick={() => {
                  navigate(`/viewCropPhaseRegistration/${item.cropPhaseId}`, {
                    state: { item, edit: false },
                  });
                }}
              >
                {item.isActive}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button>
                    <img
                      style={{ maxHeight: "40px" }}
                      src={EditButton}
                      alt="Edit Button"
                      onClick={() => {
                        navigate(
                          `/editCropPhaseRegistration/${item.cropPhaseId}`,
                          { state: { item, edit: true } }
                        );
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    aria-label="more"
                    aria-controls={
                      menuIndex === index ? "long-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuIndex === index}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<EditIcon />}
                        onClick={() => {
                          navigate(
                            `/editCropPhaseRegistration/${item.cropPhaseId}`,
                            { state: { item, edit: true } }
                          );
                          handleClose();
                        }}
                      >
                        Edit
                      </Button>
                      {/* <ListItemText primary="Edit" /> */}
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#D4EDDA",
                          borderColor: "#28A745",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#28A745",
                          "&:hover": {
                            backgroundColor: "#28A745",
                            color: "#D4EDDA",
                            borderColor: "#28A745",
                          },
                        }}
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          navigate(
                            `/viewCropPhaseRegistration/${item.cropPhaseId}`,
                            {
                              state: { item, edit: false },
                            }
                          );
                          handleClose();
                        }}
                      >
                        View
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CropPhaseTable;
