import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import PreAssessmentFormBuilderTable from "./PAFB-Table";
import PAFBService from "services/PAFBService";
import debounce from "lodash/debounce";
import moment from "moment";

const PreAssessmentFormBuilderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  // Filter states
  const [searchKeyword, setSearchKeyword] = useState("");

  // Add new state for current date
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));

  // Using debounce to prevent excessive API calls
  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchFormData(0, itemsPerPage, value);
    }, 300),
    [itemsPerPage]
  );

  // Modify the useEffect for initial and search-based data fetching
  useEffect(() => {
    fetchFormData(currentPage, itemsPerPage, searchKeyword);
  }, [currentPage, itemsPerPage, searchKeyword]);

  // Optional: If you want to update the date in real-time
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(moment().format('YYYY-MM-DD'));
    }, 1000 * 60); // Update every minute, adjust as needed

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Modify fetchFormData to handle page reset more explicitly
  const fetchFormData = async (pageNumber = 0, size = itemsPerPage, keyword = '') => {
    try {
      setLoading(true);
      const response = await PAFBService.getAllFormApi({
        pageNumber: pageNumber,
        pageSize: size,
        formVersionNo: keyword
      });

      if (response.data.statusCode === 200) {
        const formsData = response.data.response.farmerContractPreAssessmentFormBuilderResponse;
        setFormData(formsData);
        setTotalItems(response.data.response.totalItems);

        // Show message if no data
        if (formsData.length === 0) {
          setMessage(keyword ? t('noDataAvailable') : t('noDataAvailable'));
          setSeverity("info");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(t("noRecordFound"));
      setFormData([]);
      setTotalItems(0);
    } finally {
      setLoading(false);
    }
  };

  // Modify search handler to reset page more explicitly
  const handleSearchParamsChange = (event) => {
    const { value } = event.target;
    setSearchKeyword(value);
    setCurrentPage(0); // Explicitly reset to first page
    
    if (value) {
      debouncedSearch(value);
    } else {
      // Directly call fetchFormData when input is cleared
      fetchFormData(0, itemsPerPage, '');
    }
  };

  // Modify pagination change handler
  const handlePageChange = (pageNumber, newItemsPerPage) => {
    // Adjust pageNumber to be 0-indexed
    const adjustedPageNumber = pageNumber - 1; 
    
    // Update both current page and items per page
    setCurrentPage(adjustedPageNumber);
    setItemsPerPage(newItemsPerPage);
  };

  const handleRefreshData = () => {
    fetchFormData(currentPage, itemsPerPage, searchKeyword);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={handlePageChange}
      currentPage={currentPage} 
      title={t("PreAssessmentFormBuilder.title")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  label={t("PreAssessmentFormBuilder.formVersion")}
                  value={searchKeyword}
                  onChange={handleSearchParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/preAssessmentFormBuilderDetails")}
            >
              {t("Create New Form")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {totalItems > 0 ? (
        <PreAssessmentFormBuilderTable
          formData={formData}
          loading={loading}
          onDelete={handleRefreshData}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}
        >
          <Typography variant="h6" color="textSecondary">
            {t('noDataAvailable')}
          </Typography>
        </Box>
      )}

      {/* Empty Space To prevent table and pagination overlap */}
      <Box sx={{mt:"50px",color:"white"}}>
        Space
      </Box>
    </Layout>
  );
};

export default PreAssessmentFormBuilderList;
