export const spacingField = [
  {
    name: "tobaccoType",
    label: "Tobacco Type",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "unitOfMeasure",
    label: "Unit Of Measure",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Centimeter", label: "Centimeter" },
      { value: "Inches", label: "Inches" },
      { value: "Meters", label: "Meters" },
    ],
    required: true,
  },
  {
    name: "lineSpacing",
    label: "Line Spacing",
    type: "text",
    required: true,
  },
  {
    name: "plantSpacing",
    label: "Plant Spacing",
    type: "text",
    required: true,
  },
  {
    name: "noOfPlants",
    label: "Number of Plants",
    type: "number",
    unit: "K Plants/Ha",
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];

export const editSpacingField = [
  {
    name: "tobaccoType",
    label: "Tobacco Type",
    type: "select",
    options: [],
    required: true,
    disabled: true,
  },
  {
    name: "unitOfMeasure",
    label: "Unit Of Measure",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Centimeter", label: "Centimeter" },
      { value: "Inches", label: "Inches" },
      { value: "Meters", label: "Meters" },
    ],
    required: true,
  },
  {
    name: "lineSpacing",
    label: "Line Spacing",
    type: "text",
    required: true,
  },
  {
    name: "plantSpacing",
    label: "Plant Spacing",
    type: "text",
    required: true,
  },
  {
    name: "noOfPlants",
    label: "Number of Plants",
    type: "number",
    unit: "K Plants/Ha",
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];
