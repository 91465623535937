import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalInformation: {
    farmerCode: "",
    cpf: "",
    farmerFullName: "",
    dateOfBirth: "",
    contactNumber: "",
    email: "",
    nationalId: "",
    issuingAuthority: "",
    emissionDt: "",
    gender: "",
    nationalityCountry: "",
    nationality: "",
    region: "",
    city: "",
    locality: "",
    zipCode: "",
    residence: "",
    dripIrrigation: "",
    farmerCountry: "",
    regionOfBirth: "",
    maritalStatus: "",
    spouseName: "",
    fatherName: "",
    motherName: "",
    minorsUnder18: "",
    adultsOver18: "",
    latitude: "",
    longitude: "",
    altitude: "",
    externalCode: "",
  },
};

const generalInformation = createSlice({
  name: "farmer",
  initialState,
  reducers: {
    updateGeneralInformation(state, action) {
      state.generalInformation = {
        ...state.generalInformation,
        ...action.payload,
      };
    },
    clearGeneralInformation(state) {
      state.generalInformation = initialState.generalInformation;
    },
  },
});

export const { updateGeneralInformation, clearGeneralInformation } =
  generalInformation.actions;
export default generalInformation.reducer;
