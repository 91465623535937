import PriceParameterizationService from 'services/PriceParameterizationService';
import {create} from 'zustand'

export const usePriceParameterizationStore=create((set)=>({
    priceParameterizationData:[],

    getPriceParameterizationData:async (prizeInvestmentId)=>{
        try {

           const response=await  PriceParameterizationService.GetPriceParameterizationList({
            pageNumber:0,
            size:10
           })

           console.log("response from zustand--",response?.data?.response?.priceParametrizationDetails
        )

        const res=response?.data?.response?.priceParametrizationDetails

        const result=res.find(item=>item.prizeInvestmentId===prizeInvestmentId)


        set({priceParameterizationData:result?.prizeParaList})
            
        } catch (error) {

            console.log("Error---",error)
            
        }
        
    }
}))