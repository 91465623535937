import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import moreActions from "assets/moreActions.svg";
import "./DataTable.css";
import { useNavigate } from "react-router-dom";

const DataTable = (props) => {
  const navigate = useNavigate();
  const CustomButton = () => (
    <Button variant="outlined" className="moreButton">
      <div>
        <img src={moreActions} alt="moreActions" />
      </div>{" "}
      <div style={{ height: "14px" }}>More</div>
    </Button>
  );
  const handleRowClick = (
    params, // GridRowParams
    event // MuiEvent<React.MouseEvent<HTMLElement>>
  ) => {
    event.preventDefault();
    navigate(params.row?.batOneID, { state: { ...params.row, mode: "Edit" } });
  };

  return (
    <div style={{ width: "100%", paddingTop: "2rem" }}>
      <div style={{ width: "100%", cursor: "pointer" }}>
        <DataGrid
          disableColumnSorting
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          getRowId={props.getRowId}
          components={{
            MoreActionsIcon: CustomButton,
          }}
          autoHeight
          disableRowSelectionOnClick
          onRowClick={(p, e) => handleRowClick(p, e)}
          rows={props.rows}
          columns={props.columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          style={{ borderStyle: "none" }}
          pageSizeOptions={[5, 10]}
          hideFooterPagination={true}
          sx={{
            // "& .MuiDataGrid-cell:hover": {
            //   color: "primary.main",
            // },
            "& .MuiDataGrid-row": {
              backgroundColor: "#ffffff",
              position: "relative",
              // marginTop: "10px",
              // marginBottom: "10px",
              borderRadius: "10px",
              // height: "4.688rem",
              // boxShadow: '2px 2px 10px 0px rgba(172, 180, 186, 0.3)',
              width: "99.8%",
              // maxHeight: "unset !important",
              alignItems: "center",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "unset",
              color: "rgba(59, 69, 79, 1)",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "24.38px",
              letterSpacing: "0em",
              textAlign: "left",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#F5F5F7",
              justifyContent: "center",
              paddingBottom: "1rem",
              borderTop: "none",
              margin: "0px -26px",
            },
            "&  .MuiDataGrid-columnHeaders": {
              border: "none",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 600,
              color: " rgba(19, 22, 25, 1)",
              // color: "#737D86",
              lineHeight: "24.38px",
              pointerEvents: "none",
            },
            "& .MuiDataGrid-virtualScroller": {},
            "& .MuiDataGrid-root": {},
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              display: "none",
            },
          }}
        ></DataGrid>
      </div>
    </div>
  );
};

export default DataTable;
