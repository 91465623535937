import { httpGrow } from "../http-common";


const getIcon = () => {
    return httpGrow.get(`/storage/file-urls?folderName=farmerIcons`);
  };

const FarmerClusterService = {
    getIcon
  };
  
export default FarmerClusterService;