import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
  MenuItem,
  Grid,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import GeneticService from "services/GeneticService";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import { useTranslation } from "react-i18next";
import { geneticSchema } from "utils/validations";

export default function AddGeneticRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    gtGeneticTypeId,
    gtGeneticAbbreviation,
    gtGeneticDescription,
    cultureId,
    isActive,
    countryId,
    languageId,
    isDelete,
  } = location?.state?.row || {};
  const { edit } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    gtGeneticTypeId: gtGeneticTypeId,
    gtGeneticAbbreviation: gtGeneticAbbreviation || "",
    gtGeneticDescription: gtGeneticDescription || "", // Assuming this is the tray type description
    cultureId: cultureId,
    isActive: isActive === true ? true : false, // Default to false if not true
    countryId: countryId || "", // Set default empty string or any fallback value
    languageId: languageId || "", // Set default empty string or any fallback value
    isDelete: isDelete === true ? true : false, // Default to false if not true
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [culture, setCulture] = useState([]);

  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const getCultureList = async () => {
    try {
      const response = await GeneticService.getCulture();
      console.log("tys", response.data.response);
      const d = response.data.response;
      setCulture(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };
  useEffect(() => {
    getCultureList();
  }, []);

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/geneticTypeRegistration");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("WERTYUI", name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    console.log("submit");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    try {
      await geneticSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        gtGeneticAbbreviation: values?.gtGeneticAbbreviation,
        gtGeneticDescription: values?.gtGeneticDescription,
        cultureId: values?.cultureId,
        isActive: values?.isActive,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };

      try {
        setLoading(true);
        const response = await GeneticService.createGenetic({ ...payload });
        setLoading(false);

        if (response.status === 201) {
          setSeverity("success");
          setMessage("Record saved successfuly!");
          setOpenAlert(true);
          navigate("/geneticTypeRegistration");
        }
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.submissionFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  const handleUpdate = async (event) => {
    console.log("calledUpdat");
    event.preventDefault();
    let schemaValidationErrors = {};

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        gtGeneticTypeId: values?.gtGeneticTypeId,
        gtGeneticAbbreviation: values?.gtGeneticAbbreviation,
        gtGeneticDescription: values?.gtGeneticDescription,
        cultureId: values?.cultureId,
        isActive: values?.isActive,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };
      console.log("Update Payload:", payload);
      try {
        setLoading(true);
        const response = await GeneticService.updateGenetic({ ...payload });
        setLoading(false);
        if (response.status === 201) {
          setSeverity("success");
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          navigate("/geneticTypeRegistration");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout
      title={t("geneticTypeRegistration.addPageTitle")}
      background="#ffffff"
    >
      <Box
        sx={{
          maxHeight: 115,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ backgroundColor: "#EFEFEF" }}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{Lable.fromLable}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //justifyContent: "start",
              height: "120px",
              marginTop: "-5px",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={2.3}>
                <FormControl fullWidth>
                  <TextField
                    name="gtGeneticAbbreviation"
                    label={t("geneticTypeRegistration.abbreviation")}
                    InputLabelProps={{ shrink: true }}
                    value={values?.gtGeneticAbbreviation}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    //required
                    error={!!errors.gtGeneticAbbreviation} // Show error state
                    helperText={errors.gtGeneticAbbreviation || " "} // Show error message
                    sx={{
                      "& .MuiFormHelperText-root": {
                        whiteSpace:"nowrap",
                        //fontSize: "12px !important",                       
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4.5}>
                <FormControl fullWidth>
                  <TextField
                    name="gtGeneticDescription"
                    label={t("geneticTypeRegistration.description")}
                    InputLabelProps={{ shrink: true }}
                    value={values?.gtGeneticDescription}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    //required
                    error={!!errors.gtGeneticDescription} // Show error state
                    helperText={errors.gtGeneticDescription || " "} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <FormControl fullWidth>
                  <TextField
                    name="cultureId"
                    label={t("geneticTypeRegistration.culture")}
                    value={values?.cultureId || ""}
                    onChange={handleChange}
                    disabled={edit == false}
                    InputLabelProps={{ shrink: true }}
                    select
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if (!selected) {
                          return <span style={{ color: 'black' }}>Select an option</span>;
                        }
                        return culture.find(item => item.cuCultureId === selected)?.cuCultureName;
                      },
                      IconComponent: ArrowDropDownIcon,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiSelect-select": {
                        color: !values?.cultureId ? 'gray' : 'inherit',
                      }
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                    error={!!errors.cultureId}
                    helperText={errors.cultureId || " "}
                  >
                    {culture.map((item, index) => (
                      <MenuItem key={index} value={item.cuCultureId}>
                        {item.cuCultureName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={1.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "20px",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      sx={{
                        fontFamily: "Montserrat, sans-serif", // Font
                        fontWeight: 500, // Weight
                        fontSize: "18px", // Size
                        lineHeight: "20px", // Line height
                        color: "#3B454F !important", // Fixed color
                        mb: 2,
                        ml: 1,
                        "&.Mui-focused": { color: "#0E2B63" },
                        "&:hover": { color: "#0E2B63" },
                      }}
                    >
                      {t("status")}
                    </FormLabel>
                    <Box sx={{ display: "flex", alignItems: "center", ml: 5 }}>
                      <Typography sx={{ mr: 2 }}>{t("inactive")}</Typography>
                      <FormControlLabel
                        control={
                          <RedSwitch
                            //sx={{ marginRight: "11px" }}
                            checked={values?.isActive}
                            onChange={handleChange}
                            name="isActive"
                            color="primary"
                            disabled={edit == false}
                          />
                        }
                        label={
                          <Typography sx={{ ml: 0.7 }}>
                            {t("active")}
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* <hr style={{ marginTop: "200px" }} /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? "Back" : "Cancel"}
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
