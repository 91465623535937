// Table.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { SnackbarContext } from "context/snackBarContext";
import FarmerSegmentationService from "services/FarmerSegmentationService";


const Table = ({ tableData,onDeleteSuccess}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [filterTableData, setFilterTableData] = useState([]);

  useEffect(() => {
    // Format tableData to include concatenated culture names
    const formattedData = tableData.map((item) => ({
      ...item,
      cultureNames: item.farmerTobaccoCultureTypeLists
        .map((culture) => culture.cultureName)
        .join(", "), // Join all culture names into a single string
    }));
    setFilterTableData(formattedData);
  }, [tableData]);

  console.log(filterTableData,'filterTableData');

  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  
    const sortedData = [...filterTableData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilterTableData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleEditClick = (row) => {
    navigate("/addGroup", { 
      state: { 
        rowData: row  // Pass the entire row object instead of just the ID
      } 
    });
  };
  
  const handleDelete = async () => {
    try {
      const response = await FarmerSegmentationService.deleteAPI(deleteId);
      if (response.status === 200) {
        setMessage(t("RECORD DELETED SUCCESSFULLY!"));
        setSeverity("success");
        setOpenAlert(true);
        handleClose(); // Close the modal
        
        // Call parent component's handler to refetch data
        await onDeleteSuccess();
        
        // Update local table data
        const updatedData = filterTableData.filter(
          (item) => item.ftgpFarmerTobaccoPriorityId !== deleteId
        );
        setFilterTableData(updatedData);
      }
    } catch (error) {
      setMessage(t("FAILED TO DELETE RECORD!"));
      setSeverity("error");
      setOpenAlert(true);
    }
  };


    


  return (
    <div className="table-container">
      <table className="farmerSegmentation-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("groupName")}
            >
              {t("Group Name")} {getSortIcon("groupName")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("cultureNames")}
            >
              {t("Culture Type")} {getSortIcon("cultureNames")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("cropYear")}
            >
              {t("Crop Season")} {getSortIcon("cropYear")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("status")}
            >
              {t("Status")} {getSortIcon("status")}
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody style={{ height: "100%", display: "table-row-group" }}>
  {filterTableData.length > 0 ? (
    filterTableData.map((row, index) => (
      <React.Fragment key={index}>
        <tr style={{ cursor: "pointer" }}>
          <td>{row.groupName}</td>
          <td>{row.cultureNames}</td>
          <td>{row.cropYear}</td>
          <td>{row.status}</td>
          <td className="actions-cell">
            <Tooltip title="EDIT" arrow placement="top">
              <img
                className="btn edit-btn"
                src={_ButtonEdit_}
                alt="Edit"
                onClick={() => handleEditClick(row)}
              />
            </Tooltip>
            <Tooltip title="DELETE" arrow placement="top">
              <img
                className="btn delete-btn"
                src={_ButtonDelete_}
                alt="Delete"
                onClick={() => handleOpen(row.ftgpFarmerTobaccoPriorityId)}
              />
            </Tooltip>
          </td>
        </tr>
      </React.Fragment>
    ))
  ) : (
    <tr>
      <td colSpan={5} style={{ textAlign: "center", paddingLeft: "500px", height: "100px", verticalAlign: "middle" }}>
       <h1>No Record Found</h1>
      </td>
    </tr>
  )}
</tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteModal.heading")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("deleteModal.sure")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {" "}
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
    
  );
};

export default Table;
