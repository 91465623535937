// src\services\PaymentPolicyLimitsDistributionService.jsx
import { httpGrow } from "../http-common";

const getPaymentPolicyDistributionAPI = async (query) => {
  const { cropYearUUID, importHierarchyId } = query;
  const response = await httpGrow.get(`/paymentPolicyLimitsDistribution`, {
    params: {
      cropYearUUID,
      importHierarchyId,
    },
  });
  return response;
};

const putPaymentPolicyDistributionAPI = async (data) => {
  const response = await httpGrow.put(
    `/paymentPolicyLimitsDistribution/save`,
    data
  );
  return response;
};

const deletePolicyAPI = async (cropYearUUID, policyID) => {
  const response = await httpGrow.delete(
    `/paymentPolicyLimitsDistribution/delete/${cropYearUUID}/${policyID}`
  );
  return response;
};

const getCropSeasonAPI = async () => {
  const response = await httpGrow.get("/cropYears");
  return response;
};

const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get("/importHierarchyVersion/tree");
  return response;
};

const getPolicyAPI = async () => {
  const response = await httpGrow.get("/paymentPolicy");
  return response;
};

const getActivePolicyAPI = async () => {
  const response = await httpGrow.get("/paymentPolicy/search?status=true");
  return response;
};

const PaymentPolicyLimitsDistributionService = {
  getPaymentPolicyDistributionAPI,
  putPaymentPolicyDistributionAPI,
  deletePolicyAPI,
  getCropSeasonAPI,
  getHierarchyNodeAPI,
  getPolicyAPI,
  getActivePolicyAPI,
};

export default PaymentPolicyLimitsDistributionService;
