// src\services\CultureTypeRegistrationService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "culture";
const geneticTypeBasename = "geneticType";

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, cultureName = '' } = query;
    
    const response = await httpGrow.get(`/${basename}/search`, {
        params: {
            pageNumber,
            size,
            cultureName
        }
    });
    return response;
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}/update`, data);
};

const deleteAPI = (id) => {
    return httpGrow.delete(`/${basename}/${id}`);
};

const getCultureTypeByCultureId = async (cultureTypeId) => {
    const response = await httpGrow.get(`/culture-type/${cultureTypeId}`);
    return response;
};

const getGeneticTypeAPI = async (query) => {
    const { pageNumber = 0, size = 50 } = query;
    
    const response = await httpGrow.get(`/${geneticTypeBasename}/list`, {
        params: {
            pageNumber,
            size
        }
    });
    return response;
};

const getCuringTypeAPI = async () => {
    const pageNumber = 0;
    const size = 50;
    const culture = '0a000214-903a-1f3b-8190-3a5fa0f10000';
    const status = true;
    
    const response = await httpGrow.get('/curing-type/list', {
        // Hardcoded params for specific tobacco culture and active status
        params: {
            pageNumber,
            size,
            culture,
            status
        }
    });
    return response;
};

const getCultureFull = async (query) => {
    const { pageNumber = 0, pageSize = 999 } = query;
    
    const response = await httpGrow.get('/culture', {
        params: {
            pageNumber,
            pageSize
        }
    });
    return response;
};

const postCultureTypeAPI = async (cultureTypeData) => {
    const response = await httpGrow.post('/culture-type', cultureTypeData);
    return response;
};

const createCultureCountrydata = (data) => {
    return httpGrow.put(`culture-type/countryLink`, data);
  };

const CultureTypeRegistrationService = {
    createCultureCountrydata,
    getAPI,
    updateAPI,
    deleteAPI,
    getCultureTypeByCultureId,
    getGeneticTypeAPI,
    getCuringTypeAPI,
    getCultureFull,
    postCultureTypeAPI
};

export default CultureTypeRegistrationService;
