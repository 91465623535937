import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  Typography,
  Modal,
  Grid,
  Autocomplete,
  Divider,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import _Minus_ from "assets/_Minus_.svg";
import _Plus_ from "assets/_Plus_.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import SegmentationKpiService from "services/SegmentationKpiService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function AddSegmentationRegistration() {
  const navigate = useNavigate();
  const location = useLocation();
  const { segmentationKpiId, name, description, segmentationOriginList } =
    location?.state?.row || {};
  const { edit } = location?.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    name: name || "",
    description: description || "",
    segmentationOriginList: segmentationOriginList || [
      {
        segmentationKpisRoutinesRulesId: "",
        origin: "",
      },
    ],
  });
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [origins, setOrigins] = useState([]);
  const [rulesRoutines, setRulesRoutines] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { t } = useTranslation();

  const CallSegmentationKpiById = (segmentationKpiId) => {
    SegmentationKpiService.getSegmentationKpiById(segmentationKpiId)
      .then((res) => {
        const data = res?.data?.response || {};
        data.segmentationOriginList = data.segmentationOriginList.map(
          (ele) => ({
            ...ele,
            ruleroutine: rulesRoutines.find(
              (val) =>
                ele.segmentationKpisRoutinesRulesId ===
                val.segmentationKpisRoutinesRulesId
            )?.name,
            description: rulesRoutines.find(
              (val) =>
                ele.segmentationKpisRoutinesRulesId ===
                val.segmentationKpisRoutinesRulesId
            )?.description,
          })
        );
        setValues(data);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setValues({});
      });
  };

  const handleAddNew = () => {
    setValues((prevValues) => ({
      ...prevValues,
      segmentationOriginList: [
        ...(prevValues.segmentationOriginList || []),
        {
          segmentationKpisRoutinesRulesId: "",
          origin: "",
        },
      ],
    }));
  };

  const handleDeleteOrigin = () => {
    const index = deleteIndex;
    setValues((prevValues) => {
      const updatedOrigins = prevValues.segmentationOriginList.filter(
        (_, i) => i !== index
      );

      return {
        ...prevValues,
        segmentationOriginList: updatedOrigins,
      };
    });
    setDeleteOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpen = (deleteID) => {
    setDeleteIndex(deleteID);
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    navigate("/segmentationRegistration");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleOriginChange = (event, newValue, index, fieldName) => {
    setValues((prevValues) => ({
      ...prevValues,
      segmentationOriginList: prevValues.segmentationOriginList.map((item, i) =>
        i === index
          ? {
              ...item,
              ...(fieldName === "origin" && {
                origin: newValue?.pickListDescription,
              }),
              ...(fieldName === "ruleroutine" && {
                ruleroutine: newValue?.name || "",
                description: newValue?.description || "",
                segmentationKpisRoutinesRulesId:
                  newValue?.segmentationKpisRoutinesRulesId || "",
              }),
            }
          : item
      ),
    }));
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    let schemaValidationErrors = {};

    if (!values.name.trim()) {
      schemaValidationErrors.name = t("This field is required");
    } else if (values.name.trim().length > 50) {
      schemaValidationErrors.name = t(
        "The Character limit cannot exceed more than 50 in Name."
      );
    }

    if (!values.description.trim()) {
      schemaValidationErrors.description = t("This field is required");
    } else if (values.description.trim().length > 500) {
      schemaValidationErrors.description = t(
        "The Character limit cannot exceed more than 500 Description."
      );
    }

    if (
      !values.segmentationOriginList.length ||
      values.segmentationOriginList.some(
        (item) => !item.segmentationKpisRoutinesRulesId || !item.origin.trim()
      )
    ) {
      schemaValidationErrors.segmentationOriginList = t(
        "At least one valid origin is required"
      );
    }

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      ...values,
      countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
      languageId: null,
      createdByUser: "ADMIN",
      modifiedByUser: "ADMIN",
      isDelete: false,
      segmentationOriginList: values.segmentationOriginList.map(
        ({ ruleroutine, description, ...rest }) => ({
          ...rest,
        })
      ),
    };

    try {
      const response = await SegmentationKpiService.createSegmentationKpi({
        ...payload,
      });
      if (response.status === 200) {
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
        navigate("/segmentationRegistration");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else {
        console.error("Failed to update planting");
        setSeverity("error");
        setMessage(t("snackbarMessage.updateFailed"));
        setOpenAlert(true);
      }
    }
  };

  const CallOriginsApi = () => {
    SegmentationKpiService.getOriginsData()
      .then((res) => {
        const List = res?.data?.response?.SKPIC || [];
        setOrigins(List);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setOrigins([]);
      });
  };

  const CallRulesRoutinesApi = () => {
    SegmentationKpiService.getRulesRoutines()
      .then((res) => {
        const List = res?.data?.response?.segmentationKpisRoutinesRules || [];
        setRulesRoutines(List);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setRulesRoutines([]);
      });
  };

  useEffect(() => {
    CallOriginsApi();
    CallRulesRoutinesApi();
  }, []);

  useEffect(() => {
    segmentationKpiId && CallSegmentationKpiById(segmentationKpiId);
  }, [rulesRoutines]);

  return (
    <Layout title={t("segmentationKPI.addPageTitle")} background="#ffffff">
      <Box
        sx={{
          minHeight: "100vh",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: "max-content",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontWeight: "600", color: "#666666" }}>
                {t("form")}
              </Typography>
            </Box>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label={t("segmentationKPI.name")}
                    InputLabelProps={{ shrink: true }}
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.name} // Show error state
                    helperText={errors.name || " "} // Show error message
                    disabled={edit === false}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...(edit === false && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name="description"
                    label={t("description")}
                    InputLabelProps={{ shrink: true }}
                    value={values.description}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description || " "}
                    disabled={edit === false}
                    multiline
                    rows={8}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...(edit === false && {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #0000003B",
                          },
                        }),
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        height: "216px",
                        padding: "8px",
                        lineHeight: "1.5",
                        justifyContent: "flex-start",
                        alignItems: "start",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ paddingBottom: "100px" }}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px 2px",
                  background: "#FFFFFF",
                  marginTop: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#5A646E",
                    marginLeft: "10px",
                  }}
                >
                  {t("segmentationKPI.originEnabled")}
                </Typography>
                {edit !== false && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      onClick={handleAddNew}
                      sx={{
                        minWidth: 95,
                        borderRadius: 1,
                        background: "#004F9F",
                        height: { xs: "40px", md: "44px" },
                        marginLeft: { xs: 0, md: "auto" },
                        marginRight: "10px",
                      }}
                    >
                      {t("TransportationRegistration.buttons.addNew")}
                    </Button>
                  </div>
                )}
              </Box>

              <Box
                id="divider"
                sx={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px 20px 0px",
                  gap: "20px",
                  background: "#FFFFFF",
                  borderBottom: "5px solid #004F9F",
                }}
              ></Box>
            </Box>
            {values.segmentationOriginList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  minHeight: "10%",
                  //marginTop: 5,
                  flexGrow: 1,
                  paddingBottom: "0.5rem",
                  width: "100%",
                  "& .MuiTextField-root": { m: 1 },
                  "& .MuiFormLabel-root": {
                    color: "rgba(16, 42, 98, 1)",
                    "& .MuiFormLabel-asterisk": {
                      color: "rgba(239, 0, 0, 1)",
                      fontSize: "18px",
                    },
                  },
                }}
              >
                <form autoComplete="off" noValidate>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      bgcolor: "#EFEFEF",
                      height: "156px",
                      marginTop: "20px",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4.2}
                        sx={{ marginBottom: "65px" }}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            value={
                              origins.find(
                                (option) =>
                                  option.pickListDescription === item?.origin
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              handleOriginChange(
                                event,
                                newValue,
                                index,
                                "origin"
                              );
                            }}
                            disabled={edit === false}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                ...(edit === false && {
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #0000003B",
                                  },
                                }),
                              },
                            }}
                            options={origins}
                            getOptionLabel={(option) =>
                              option.pickListDescription || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.pickListId === value?.pickListId
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("segmentationKPI.origin")}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.7}
                        sx={{ marginBottom: "65px" }}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            value={
                              rulesRoutines.find(
                                (option) =>
                                  option.name === item?.ruleroutine &&
                                  option.description === item?.description
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              handleOriginChange(
                                event,
                                newValue,
                                index,
                                "ruleroutine"
                              );
                            }}
                            disabled={edit === false}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                ...(edit === false && {
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #0000003B",
                                  },
                                }),
                              },
                            }}
                            options={rulesRoutines}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.segmentationKpisRoutinesRulesId ===
                              value?.segmentationKpisRoutinesRulesId
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("segmentationKPI.routine/rule")}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4.2}>
                        <FormControl fullWidth>
                          <TextField
                            name="description"
                            label={t("segmentationKPI.routine/rule-desc")}
                            InputLabelProps={{ shrink: true }}
                            value={item?.description}
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #0000003B", // Default gray border
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #0000003B", // Hover border (same as default)
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #0000003B", // Focus border (same as default)
                                  },
                                bgcolor: "#E9EBEE", // Background color
                                alignItems: "flex-start",
                              },
                              "& .MuiInputLabel-root": {
                                lineHeight: "1.2em",
                              },
                            }}
                            InputProps={{
                              readOnly: true,
                              style: { height: "116px" },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {values.segmentationOriginList.length > 1 && (
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        style={{ cursor: "pointer", marginRight: 5 }}
                        onClick={() => handleOpen(index)}
                      />
                    )}
                  </Box>

                  <Divider
                    sx={{
                      position: "fixed",
                      bottom: "82px",
                      width: "97%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "fixed",
                      width: "96%",
                      bottom: 6,
                      padding: "0.5rem",
                      background: "white",
                      zIndex: 999,
                    }}
                  >
                    {/* Cancel Button - Left bottom corner */}
                    <Button
                      style={{
                        backgroundColor: "#737D86",
                        padding: "6px 16px",
                        borderRadius: "4px",
                        marginBottom: "16px",
                        height: "44px",
                        marginLeft: "10px",
                      }}
                      variant="contained"
                      onClick={
                        edit === false ? handleCancelConfirm : handleOpenModal
                      }
                      startIcon={<img src={arrowLeft} alt="Arrow" />}
                    >
                      {edit === false ? t("back") : t("cancel")}
                    </Button>

                    <Modal
                      open={open}
                      onClose={handleCloseModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "7px",
                          width: "585px",
                          margin: "auto",
                          mt: 25,
                          height: "200px",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#102A62",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#ffffff",
                              fontSize: "20px",
                              fontWeight: 500,
                            }}
                          >
                            {t("cancelTitle")}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, fontWeight: 400 }}
                          >
                            {t("cancelMessage")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                            mb: 1,
                          }}
                        >
                          <Button
                            onClick={handleCloseModal}
                            sx={{
                              background: "#737D86",
                              width: "100px",
                              color: "#ffffff",
                              "&:hover": { background: "#737D86" },
                            }}
                          >
                            {t("no")}
                          </Button>
                          <Button
                            onClick={handleCancelConfirm}
                            sx={{
                              background: "#004F9F",
                              width: "100px",
                              color: "#ffffff",
                              "&:hover": { background: "#004F9F" },
                            }}
                          >
                            {t("yes")}
                          </Button>
                        </Box>
                      </Box>
                    </Modal>

                    {/* Save Button - Right bottom corner */}
                    {edit != false && (
                      <Button
                        sx={{
                          padding: "6px 16px",
                          borderRadius: "4px",
                          marginBottom: "16px",
                          backgroundColor: "#EF7D00",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#EF7D00",
                          },
                          marginRight: "10px",
                        }}
                        startIcon={<img src={Save} alt="Right Arrow" />}
                        variant="contained"
                        onClick={handleUpdate}
                      >
                        {t("save")}
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            ))}
          </Box>
        </form>
      </Box>
      <Modal open={deleteOpen} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("deleteConfirmation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t("deleteConfirmationMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDeleteOrigin}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
}
