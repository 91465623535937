import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  SvgIcon,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ReactComponent as FiltersIcon } from "assets/Filters.svg";
import Save from "assets/SaveNew.svg";
import MoreFilters from "./MoreFilters";
import CentralAgriculturalSupplyManagementService from "services/CentralAgriculturalSupplyManagementService";
import SearchIcon from "assets/SearchIcon.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";

const CentralAgriculturalSupplyManagement = () => {
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [operationType, setOperationType] = useState("unlock");
  const [supplySource, setSupplySource] = useState("agricultural");
  const [cropSeason, setCropSeason] = useState("");
  const [releaseModality, setReleaseModality] = useState([]);
  const [geneticType, setGeneticType] = useState([]);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [selectedHierarchicalNodeId, setSelectedHierarchicalNodeId] =
    useState("");
  const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
  const [childNodes, setChildNodes] = useState([]);
  const [hierarchyLoading, setHierarchyLoading] = useState(false);
  const [selectedHierarchicalNodes, setSelectedHierarchicalNodes] = useState(
    []
  );
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [cropSeasonLoading, setCropSeasonLoading] = useState(false);
  const [releaseModalityOptions, setReleaseModalityOptions] = useState([]);
  const [releaseModalityLoading, setReleaseModalityLoading] = useState(false);
  const [releaseModalityData, setReleaseModalityData] = useState([]);
  const [agriculturalSupplyOptions, setAgriculturalSupplyOptions] = useState(
    []
  );
  const [agriculturalSupplyLoading, setAgriculturalSupplyLoading] =
    useState(false);
  const [selectedAgriculturalSupply, setSelectedAgriculturalSupply] =
    useState("");
  const [destinationCropSeason, setDestinationCropSeason] = useState("");
  const [dataType, setDataType] = useState("usageInfo");
  const [selectedAgriculturalSupplies, setSelectedAgriculturalSupplies] =
    useState([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  // Fetch genetic type options on component mount
  useEffect(() => {
    const fetchGeneticTypes = async () => {
      try {
        const response =
          await CentralAgriculturalSupplyManagementService.getGeneticType();

        // Log the full response for debugging
        console.log("Genetic Types API Response:", response);

        // Check if response.data and response.data.response exist and is an array
        const geneticTypes = response?.data?.response || [];

        // Log the genetic types array
        console.log("Genetic Types Array:", geneticTypes);

        // Transform the API response to match the expected format
        const formattedOptions = geneticTypes.map((type) => ({
          value: type.gtGeneticTypeId, // Use the unique ID as value
          label: `${type.gtGeneticDescription}`,
        }));

        // Log the formatted options
        console.log("Formatted Genetic Type Options:", formattedOptions);

        setGeneticTypeOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching genetic types:", error);
        setOpenAlert(true);
        setSeverity("error");
        setMessage(
          t("centralAgriculturalSupplyManagement.geneticTypeFetchError")
        );

        // Set empty options to prevent further errors
        setGeneticTypeOptions([]);
      }
    };

    fetchGeneticTypes();
  }, []);

  // Fetch hierarchical nodes on component mount
  useEffect(() => {
    const fetchHierarchyNodes = async () => {
      try {
        setHierarchyLoading(true);
        const response =
          await CentralAgriculturalSupplyManagementService.getHierarchyNodeAPI();

        // Flatten hierarchy tree to match the new response structure
        const flattenHierarchy = (
          node,
          result = [],
          level = 0,
          parentTag = ""
        ) => {
          // Add the current node to the result
          result.push({
            label: node.locationName,
            value: node.id,
            level,
            tag: node.levelName || parentTag,
            parentId: node.parentId,
          });

          // Recursively flatten child hierarchies
          if (node.childHierarchies && node.childHierarchies.length) {
            node.childHierarchies.forEach((child) =>
              flattenHierarchy(child, result, level + 1, node.levelName)
            );
          }

          return result;
        };

        // Log the full response for debugging
        console.log("Hierarchy Nodes API Response:", response);

        // Flatten the entire hierarchy starting from the root
        const formattedNodes = flattenHierarchy(response.data.response);

        // Log the formatted nodes
        console.log("Formatted Hierarchy Nodes:", formattedNodes);

        setHierarchyOptions(formattedNodes);
      } catch (error) {
        console.error("Error fetching hierarchical nodes:", error);
        setOpenAlert(true);
        setSeverity("error");
        setMessage(
          t("centralAgriculturalSupplyManagement.hierarchyNodeFetchError")
        );

        // Set empty nodes to prevent further errors
        setHierarchyOptions([]);
      } finally {
        setHierarchyLoading(false);
      }
    };

    fetchHierarchyNodes();
  }, []);

  // Fetch crop season options on component mount
  useEffect(() => {
    const fetchCropSeasons = async () => {
      try {
        setCropSeasonLoading(true);
        const response =
          await CentralAgriculturalSupplyManagementService.getCropSeason();

        // Log the full response for debugging
        console.log("Crop Season API Response:", response);

        // Check if response.data and response.data.response exist and is an array
        const cropSeasons = response?.data?.response || [];

        // Transform the API response to match the expected format
        const formattedOptions = cropSeasons.map((season) => ({
          value: season.cropSeasonId, // Use cropSeasonId as value
          label: season.cropYear, // Use cropSeason as label
        }));

        // Log the formatted options
        console.log("Formatted Crop Season Options:", formattedOptions);

        setCropSeasonOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching crop seasons:", error);
        setOpenAlert(true);
        setSeverity("error");
        setMessage(
          t("centralAgriculturalSupplyManagement.cropSeasonFetchError")
        );

        // Set empty options to prevent further errors
        setCropSeasonOptions([]);
      } finally {
        setCropSeasonLoading(false);
      }
    };

    fetchCropSeasons();
  }, []);

  // Fetch release modality options on component mount
  useEffect(() => {
    const fetchReleaseModalityOptions = async () => {
      try {
        setReleaseModalityLoading(true);
        const response =
          await CentralAgriculturalSupplyManagementService.picklistAPI({
            pickListCodes: ["CASMTPRM", "CASMASRM"],
          });

        if (response?.status === 200) {
          // Store the full data for potential future use
          const thirdPartyData = response?.data?.response?.CASMTPRM || [];
          const agriculturalData = response?.data?.response?.CASMASRM || [];

          setReleaseModalityData({
            "third-party": thirdPartyData,
            agricultural: agriculturalData,
          });

          // Prepare options for third-party supply
          const thirdPartyOptions = thirdPartyData.map((item) => ({
            label: item.pickListDescription.toString(),
            value: item.pickListItemCode.toString(),
          }));

          // Prepare options for agricultural supply
          const agriculturalOptions = agriculturalData.map((item) => ({
            label: item.pickListDescription.toString(),
            value: item.pickListItemCode.toString(),
          }));

          // Set options based on supply source
          setReleaseModalityOptions(
            supplySource === "third-party"
              ? thirdPartyOptions
              : agriculturalOptions
          );
        } else {
          throw new Error("Failed to fetch release modality options");
        }
      } catch (error) {
        console.error("Error fetching release modality options:", error);
        setOpenAlert(true);
        setSeverity("error");
        setMessage(
          t("centralAgriculturalSupplyManagement.releaseModalityFetchError")
        );

        // Set empty options to prevent further errors
        setReleaseModalityOptions([]);
      } finally {
        setReleaseModalityLoading(false);
      }
    };

    // Fetch options when component mounts
    fetchReleaseModalityOptions();
  }, []);

  // Update release modality options when supply source changes
  useEffect(() => {
    if (
      releaseModalityData["third-party"] &&
      releaseModalityData["agricultural"]
    ) {
      const options =
        supplySource === "third-party"
          ? releaseModalityData["third-party"].map((item) => ({
              label: item.pickListDescription.toString(),
              value: item.pickListItemCode.toString(),
            }))
          : releaseModalityData["agricultural"].map((item) => ({
              label: item.pickListDescription.toString(),
              value: item.pickListItemCode.toString(),
            }));

      setReleaseModalityOptions(options);
    }
  }, [supplySource, releaseModalityData]);

  // Add this useEffect after the existing release modality useEffect
  useEffect(() => {
    // Reset release modality when supply source changes
    setReleaseModality([]);
  }, [supplySource]);

  // Fetch agricultural supply options when crop season or supply source changes
  useEffect(() => {
    const fetchAgriculturalSupply = async () => {
      // Only fetch if crop season and supply source are selected AND operation type is not clone
      if (cropSeason && supplySource && operationType !== "clone") {
        try {
          setAgriculturalSupplyLoading(true);
          const response =
            await CentralAgriculturalSupplyManagementService.getAgriculturalSupply(
              {
                cropSeasonId: cropSeason,
                isThirdPartySupply:
                  supplySource === "third-party" ? true : false,
              }
            );

          console.log("Agricultural Supply API Response:", response);

          // Transform the API response to match the expected format
          const formattedOptions =
            response?.data?.response?.map((supply) => ({
              value: supply.agriculturalSupplyId, // Use agriculturalSupplyId as value
              label: supply.agriculturalSupplyName, // Use agriculturalSupplyName as label
            })) || [];

          console.log(
            "Formatted Agricultural Supply Options:",
            formattedOptions
          );

          setAgriculturalSupplyOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching agricultural supply:", error);
          setOpenAlert(true);
          setSeverity("error");
          setMessage(t(error.response?.data?.message));

          // Set empty options to prevent further errors
          setAgriculturalSupplyOptions([]);
        } finally {
          setAgriculturalSupplyLoading(false);
        }
      } else {
        // Reset agricultural supply options when operation type is clone or required fields are missing
        setAgriculturalSupplyOptions([]);
      }
    };

    fetchAgriculturalSupply();
  }, [cropSeason, supplySource, operationType]);

  const handleOperationTypeChange = (event) => {
    const newOperationType = event.target.value;
    setOperationType(newOperationType);

    // Reset all form fields
    setCropSeason("");
    setDestinationCropSeason("");
    setReleaseModality([]);
    setGeneticType([]);
    setSelectedAgriculturalSupply("");
    setSelectedAgriculturalSupplies([]);
    setHierarchicalNode("");
    setSelectedHierarchicalNodes([]);
    setSelectedHierarchicalNodeId("");
    setIsTopHierarchyNode(false);
    setChildNodes([]);

    // Reset data type for clone operation
    if (newOperationType === "clone") {
      setDataType("usageInfo");
    }

    // Reset supply source to default
    setSupplySource("agricultural");
  };

  const handleSupplySourceChange = (event) => {
    setSupplySource(event.target.value);
  };

  const handleCropSeasonChange = (event, isDestination = false) => {
    const selectedCropSeason = event.target.value;

    if (operationType === "clone") {
      // For clone operation, handle source and destination separately
      if (isDestination) {
        setDestinationCropSeason(selectedCropSeason);
      } else {
        setCropSeason(selectedCropSeason);
      }
    } else {
      // For unlock and lock operations, only set source crop season
      setCropSeason(selectedCropSeason);
    }

    // Reset dependent fields when crop season changes
    setReleaseModality([]);
    setGeneticType([]);
    setSelectedAgriculturalSupply("");
    setSelectedHierarchicalNodes([]);
  };

  const handleReleaseModalityChange = (selectedOptions) => {
    setReleaseModality(selectedOptions);
  };

  const handleGeneticTypeChange = (selectedOptions) => {
    setGeneticType(selectedOptions);
  };

  const handleOpenMoreFilters = () => {
    setIsMoreFiltersOpen(true);
  };

  const handleCloseMoreFilters = () => {
    setIsMoreFiltersOpen(false);
  };

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setSelectedHierarchicalNodeId(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);

      // Filter child nodes at the next level
      setChildNodes(
        hierarchyOptions.filter((node) => node.parentId === newValue.value)
      );

      console.log("Selected node:", newValue);
      console.log("Node level:", newValue.level);
      console.log("Child Nodes:", childNodes);
    } else {
      // Reset all related states when value is cleared
      setHierarchicalNode("");
      setSelectedHierarchicalNodeId("");
      setIsTopHierarchyNode(false);
      setChildNodes([]);
    }
  };

  const handleAddHierarchicalNode = () => {
    if (hierarchicalNode) {
      const selectedNode = hierarchyOptions.find(
        (option) => option.value === hierarchicalNode
      );

      // Check if the node is already selected
      const isAlreadySelected = selectedHierarchicalNodes.some(
        (node) => node.value === selectedNode.value
      );

      if (!isAlreadySelected) {
        setSelectedHierarchicalNodes((prev) => [...prev, selectedNode]);
      }
    }
  };

  const handleRemoveHierarchicalNode = (nodeToRemove) => {
    setSelectedHierarchicalNodes((prev) =>
      prev.filter((node) => node.value !== nodeToRemove.value)
    );
  };

  const handleAddAgriculturalSupply = () => {
    if (selectedAgriculturalSupply) {
      // Find the full supply object from options
      const selectedSupplyObj = agriculturalSupplyOptions.find(
        (option) => option.value === selectedAgriculturalSupply
      );

      // Check if the supply is already selected
      const isAlreadySelected = selectedAgriculturalSupplies.some(
        (supply) => supply.value === selectedAgriculturalSupply
      );

      if (!isAlreadySelected && selectedSupplyObj) {
        setSelectedAgriculturalSupplies((prev) => [...prev, selectedSupplyObj]);
        // Reset the selected agricultural supply
        setSelectedAgriculturalSupply("");
      }
    }
  };

  const handleRemoveAgriculturalSupply = (supplyToRemove) => {
    setSelectedAgriculturalSupplies((prev) =>
      prev.filter((supply) => supply.value !== supplyToRemove.value)
    );
  };

  const handleCloseWarningModal = () => {
    setIsWarningModalOpen(false);
  };

  const handleCloneData = async () => {
    // Validate required fields
    if (!cropSeason || !destinationCropSeason) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(
        t("centralAgriculturalSupplyManagement.missingRequiredFields")
      );
      return;
    }

    // Ensure source and destination crop seasons are different
    if (cropSeason === destinationCropSeason) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(t("Source and destination crop seasons must be different"));
      return;
    }

    try {
      // First, check if data exists
      const checkDataPayload = {
        cropSeasonId: destinationCropSeason,
        copyUsageInfo: dataType === "usageInfo",
        isThirdPartySupply: supplySource === "third-party",
      };

      const checkDataResponse =
        await CentralAgriculturalSupplyManagementService.checkdata(
          checkDataPayload
        );

      // If checkdata returns true, show warning modal
      if (checkDataResponse.data.response === true) {
        setIsWarningModalOpen(true);
        return;
      }

      // If checkdata returns false, proceed with clone
      await performCloneData();
    } catch (error) {
      console.error("Error checking data:", error);
      setOpenAlert(true);
      setSeverity("error");
      setMessage(
        error.response?.data?.message ||
          t("centralAgriculturalSupplyManagement.cloneError")
      );
    }
  };

  const performCloneData = async () => {
    try {
      // Prepare the payload for clone data API
      const payload = {
        fromCropSeasonId: cropSeason, // Source crop season
        toCropSeasonId: destinationCropSeason, // Destination crop season
        copyUsageInfo: dataType === "usageInfo", // Explicitly convert to boolean
        isThirdPartSupply: supplySource === "third-party", // Explicitly convert to boolean
        createdByUser: "ADMIN", // You might want to replace this with actual user
        modifiedByUser: "ADMIN",
      };

      // Call clone data API
      const response =
        await CentralAgriculturalSupplyManagementService.cloneData(payload);

      // Handle response based on API message
      if (response?.status === 201 || response?.status === 200) {
        setOpenAlert(true);
        setSeverity("success");
        setMessage("Operation completed successfully.");
      } else {
        // Use API error message if available
        const errorMessage =
          response.data?.message ||
          t("centralAgriculturalSupplyManagement.cloneError");

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error cloning data:", error);

      // Determine error message
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        t("centralAgriculturalSupplyManagement.cloneError");

      setOpenAlert(true);
      setSeverity("error");
      setMessage(errorMessage);
    }
  };

  const handleMoreFiltersItemsSelect = (selectedItems) => {
    // If the selected items list is empty, clear all selections
    if (selectedItems.length === 0) {
      setSelectedAgriculturalSupplies([]);
      return;
    }

    // Create a new array to store updated selections
    const newSelectedSupplies = [...selectedAgriculturalSupplies];

    // Remove items that are no longer selected
    const updatedSelectedSupplies = newSelectedSupplies.filter(
      (existingSupply) =>
        selectedItems.some(
          (selectedItem) => selectedItem.value === existingSupply.value
        )
    );

    // Add any new items that are not already in the list
    selectedItems.forEach((item) => {
      const isAlreadySelected = updatedSelectedSupplies.some(
        (supply) => supply.value === item.value
      );

      if (!isAlreadySelected) {
        updatedSelectedSupplies.push(item);
      }
    });

    // Update the selected agricultural supplies
    setSelectedAgriculturalSupplies(updatedSelectedSupplies);
  };

  const handleLockUnlockData = async () => {
    // Validate required fields
    if (!cropSeason) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(
        t("centralAgriculturalSupplyManagement.missingRequiredFields")
      );
      return;
    }

    try {
      // Determine hierarchical nodes to send
      const hierarchicalNodeIds =
        selectedHierarchicalNodes.length > 0
          ? selectedHierarchicalNodes.map((node) => node.value)
          : hierarchyOptions.map((node) => node.value);

      // Prepare the payload for lock/unlock API
      const payload = {
        cropSeasonId: cropSeason,
        operationType: operationType === "lock" ? "Lock" : "Unlock",
        isThirdPartySupply: supplySource === "third-party",
        releaseModalities: releaseModality.map((rm) => rm.value),
        geneticTypes: geneticType.map((gt) => gt.value),
        agriculturalSupplyIds: selectedAgriculturalSupplies.map(
          (supply) => supply.value
        ),
        hierarchicalNodeIds: hierarchicalNodeIds,
        createdByUser: "ADMIN", // You might want to replace this with actual user
        modifiedByUser: "ADMIN",
      };

      // Call lock/unlock data API
      const response =
        await CentralAgriculturalSupplyManagementService.lockUnlockData(
          payload
        );

      // Handle response based on API message
      if (response?.status === 201 || response?.status === 200) {
        setOpenAlert(true);
        setSeverity("success");
        setMessage("Operation completed successfully");

        // Optional: Reset form or perform additional actions
        resetForm();
      } else {
        // Use API error message if available
        const errorMessage =
          response.data?.message ||
          t(`centralAgriculturalSupplyManagement.${operationType}Error`);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(`Error during ${operationType}:`, error);

      // Determine error message
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        t(`centralAgriculturalSupplyManagement.${operationType}Error`);

      setOpenAlert(true);
      setSeverity("error");
      setMessage(errorMessage);
    }
  };

  // Add a reset form method to clear selections after successful operation
  const resetForm = () => {
    setCropSeason("");
    setReleaseModality([]);
    setGeneticType([]);
    setSelectedAgriculturalSupplies([]);
    setSelectedHierarchicalNodes([]);
    setSelectedAgriculturalSupply("");
    setHierarchicalNode("");
  };

  // Update the save button's onClick handler
  const handleSaveAction = () => {
    if (operationType === "clone") {
      handleCloneData();
    } else {
      handleLockUnlockData();
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      title={t("centralAgriculturalSupplyManagement.title")}
      background="#ffffff"
      Reg
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>
            {t("centralAgriculturalSupplyManagement.configuration")}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 25 }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "20px",
              textAlign: "left",
              textDecorationSkipInk: "none",
              textUnderlinePosition: "from-font",
              marginRight: "5px",
            }}
          >
            {t("centralAgriculturalSupplyManagement.operationType")}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "20px",
              textAlign: "left",
              textDecorationSkipInk: "none",
              textUnderlinePosition: "from-font",
              marginRight: "100px",
            }}
          >
            {t("centralAgriculturalSupplyManagement.supplySource")}
          </Typography>

          {operationType === "clone" && (
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "20px",
                textAlign: "left",
                textDecorationSkipInk: "none",
                textUnderlinePosition: "from-font",
              }}
            >
              {t("centralAgriculturalSupplyManagement.dataType")}
            </Typography>
          )}
        </Box>

        <FormControl component="fieldset" sx={{ marginRight: 2 }}>
          <RadioGroup
            row
            aria-label="operation-type"
            name="operation-type"
            value={operationType}
            onChange={handleOperationTypeChange}
            sx={{
              "& .MuiFormControlLabel-root": {
                marginRight: 0.5, // Reduce margin between radio buttons
              },
            }}
          >
            <FormControlLabel
              value="unlock"
              control={<Radio />}
              label={t("centralAgriculturalSupplyManagement.unlock")}
            />
            <FormControlLabel
              value="lock"
              control={<Radio />}
              label={t("centralAgriculturalSupplyManagement.lock")}
            />
            <FormControlLabel
              value="clone"
              control={<Radio />}
              label={t("centralAgriculturalSupplyManagement.clone")}
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" sx={{ marginRight: 2 }}>
          <RadioGroup
            row
            aria-label="supply-source"
            name="supply-source"
            value={supplySource}
            onChange={handleSupplySourceChange}
            sx={{
              "& .MuiFormControlLabel-root": {
                marginRight: 0.5, // Reduce margin between radio buttons
              },
            }}
          >
            <FormControlLabel
              value="agricultural"
              control={<Radio />}
              label={t(
                "centralAgriculturalSupplyManagement.agriculturalSupply"
              )}
            />
            <FormControlLabel
              value="third-party"
              control={<Radio />}
              label={t("centralAgriculturalSupplyManagement.thirdPartySupply")}
            />
          </RadioGroup>
        </FormControl>

        {operationType === "clone" && (
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="data-type"
              name="data-type"
              value={dataType}
              onChange={(event) => setDataType(event.target.value)}
              sx={{
                "& .MuiFormControlLabel-root": {
                  marginRight: 0.5,
                },
              }}
            >
              <FormControlLabel
                value="usageInfo"
                control={<Radio />}
                label={t("centralAgriculturalSupplyManagement.usageInfo")}
              />
              <FormControlLabel
                value="lockUnlockInfo"
                control={<Radio />}
                label={t("centralAgriculturalSupplyManagement.lockUnlockInfo")}
              />
            </RadioGroup>
          </FormControl>
        )}
      </Box>

      {operationType === "clone" ? (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Grid
            container
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: 2,
              paddingTop: "5px",
              minHeight: "130px",
              width: "50%",
              flex: 1,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("centralAgriculturalSupplyManagement.cloneDataFrom")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    required
                    fullWidth
                    label={t("centralAgriculturalSupplyManagement.cropSeason")}
                    value={cropSeason}
                    onChange={(event) => handleCropSeasonChange(event, false)}
                    variant="outlined"
                    placeholder={t("selectoption")}
                    InputProps={{
                      notched: true,
                      endAdornment: cropSeason && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear crop season"
                            onClick={() => {
                              setCropSeason("");
                              // Reset dependent fields
                              setReleaseModality([]);
                              setGeneticType([]);
                              setSelectedAgriculturalSupply("");
                              setSelectedAgriculturalSupplies([]);
                              setSelectedHierarchicalNodes([]);
                            }}
                            edge="end"
                            sx={{ marginRight: "20px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if (!selected) {
                          return t("selectoption");
                        }
                        const selectedOption = cropSeasonOptions.find(
                          (option) => option.value === selected
                        );
                        return selectedOption ? selectedOption.label : selected;
                      },
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Set a maximum height for the dropdown
                            overflowY: "auto", // Enable vertical scrolling
                          },
                        },
                      },
                    }}
                    disabled={cropSeasonLoading}
                  >
                    {cropSeasonOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: 2,
              paddingTop: "5px",
              minHeight: "130px",
              width: "50%",
              flex: 1,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("centralAgriculturalSupplyManagement.cloneDataTo")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    required
                    fullWidth
                    label={t("centralAgriculturalSupplyManagement.cropSeason")}
                    value={destinationCropSeason}
                    onChange={(event) => handleCropSeasonChange(event, true)}
                    variant="outlined"
                    placeholder={t("selectoption")}
                    InputProps={{
                      notched: true,
                      endAdornment: destinationCropSeason && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear crop season"
                            onClick={() => {
                              setDestinationCropSeason("");
                              // Reset dependent fields
                              setReleaseModality([]);
                              setGeneticType([]);
                              setSelectedAgriculturalSupply("");
                              setSelectedAgriculturalSupplies([]);
                              setSelectedHierarchicalNodes([]);
                            }}
                            edge="end"
                            sx={{ marginRight: "20px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if (!selected) {
                          return t("selectoption");
                        }
                        const selectedOption = cropSeasonOptions.find(
                          (option) => option.value === selected
                        );
                        return selectedOption ? selectedOption.label : selected;
                      },
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Set a maximum height for the dropdown
                            overflowY: "auto", // Enable vertical scrolling
                          },
                        },
                      },
                    }}
                    disabled={cropSeasonLoading}
                  >
                    {cropSeasonOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <Grid
            container
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: 2,
              minHeight: "130px",
              height: "auto",
              "& .MuiGrid-item": {
                paddingTop: "0 !important", // Override default padding
              },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("centralAgriculturalSupplyManagement.cropInfo")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center", // Vertically center align
                  "& > .MuiGrid-item": {
                    display: "flex",
                    alignItems: "center", // Ensure grid items are centered
                  },
                }}
              >
                <Grid
                  item
                  sx={{
                    "& .MuiFormControl-root": {
                      height: "56px", // Consistent height for form control
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    },
                    "& .MuiInputBase-root": {
                      height: "56px", // Ensure input base has same height
                    },
                  }}
                  xs={12}
                  sm={supplySource === "third-party" ? 6 : 4}
                >
                  <TextField
                    select
                    required
                    fullWidth
                    label={t("centralAgriculturalSupplyManagement.cropSeason")}
                    value={cropSeason}
                    onChange={handleCropSeasonChange}
                    variant="outlined"
                    placeholder={t("selectoption")}
                    InputProps={{
                      notched: true,
                      endAdornment: cropSeason && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear crop season"
                            onClick={() => {
                              setCropSeason("");
                              // Reset dependent fields
                              setReleaseModality([]);
                              setGeneticType([]);
                              setSelectedAgriculturalSupply("");
                              setSelectedAgriculturalSupplies([]);
                              setSelectedHierarchicalNodes([]);
                            }}
                            edge="end"
                            sx={{ marginRight: "20px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if (!selected) {
                          return t("selectoption");
                        }
                        const selectedOption = cropSeasonOptions.find(
                          (option) => option.value === selected
                        );
                        return selectedOption ? selectedOption.label : selected;
                      },
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Set a maximum height for the dropdown
                            overflowY: "auto", // Enable vertical scrolling
                          },
                        },
                      },
                    }}
                  >
                    {cropSeasonOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={supplySource === "third-party" ? 6 : 4}
                  sx={{
                    "& .MuiFormControl-root": {
                      minHeight: "56px", // Minimum height
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    },
                    "& .MuiInputBase-root": {
                      minHeight: "56px", // Minimum height
                      height: "auto", // Allow dynamic height
                    },
                    "& .MuiAutocomplete-root": {
                      "& .MuiOutlinedInput-root": {
                        flexWrap: "wrap", // Allow multiple lines
                        height: "auto", // Dynamic height
                      },
                    },
                  }}
                >
                  <Autocomplete
                    multiple
                    required
                    fullWidth
                    id="release-modality-select"
                    options={releaseModalityOptions}
                    getOptionLabel={(option) => option.label}
                    value={releaseModality}
                    onChange={(event, newValue) =>
                      handleReleaseModalityChange(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <>
                            {t(
                              "centralAgriculturalSupplyManagement.releaseModality"
                            )}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </>
                        }
                        placeholder={
                          releaseModality.length === 0
                            ? t(
                                "centralAgriculturalSupplyManagement.selectMore"
                              )
                            : ""
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          notched: true,
                        }}
                      />
                    )}
                    loading={releaseModalityLoading}
                    disabled={!supplySource}
                  />
                </Grid>

                {supplySource !== "third-party" && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      "& .MuiFormControl-root": {
                        minHeight: "56px", // Minimum height
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      },
                      "& .MuiInputBase-root": {
                        minHeight: "56px", // Minimum height
                        height: "auto", // Allow dynamic height
                      },
                      "& .MuiAutocomplete-root": {
                        "& .MuiOutlinedInput-root": {
                          flexWrap: "wrap", // Allow multiple lines
                          height: "auto", // Dynamic height
                        },
                      },
                    }}
                  >
                    <Autocomplete
                      multiple
                      required
                      fullWidth
                      id="genetic-type-select"
                      options={geneticTypeOptions}
                      getOptionLabel={(option) => option.label}
                      value={geneticType}
                      onChange={(event, newValue) =>
                        handleGeneticTypeChange(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              {t(
                                "centralAgriculturalSupplyManagement.geneticType"
                              )}
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </>
                          }
                          placeholder={
                            geneticType.length === 0 ? t("selectoption") : ""
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            ...params.InputProps,
                            notched: true,
                          }}
                        />
                      )}
                      loading={geneticTypeOptions.length === 0}
                      disabled={supplySource === "third-party"}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: 2,
              paddingTop: "5px",
              height: "auto",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("centralAgriculturalSupplyManagement.selectSupplies")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    "& .MuiAutocomplete-root": {
                      height: "46px",
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiInputBase-root": {
                      height: "46px !important",
                    },
                    "& .MuiAutocomplete-input": {
                      height: "30px !important",
                      padding: "0 14px !important",
                    },
                  }}
                >
                  <Autocomplete
                    id="agricultural-supply-select"
                    options={agriculturalSupplyOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                      agriculturalSupplyOptions.find(
                        (option) => option.value === selectedAgriculturalSupply
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedAgriculturalSupply(
                        newValue ? newValue.value : ""
                      );
                    }}
                    disabled={!cropSeason || !supplySource}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          "centralAgriculturalSupplyManagement.agriculturalSupply"
                        )}
                        placeholder={t(
                          "centralAgriculturalSupplyManagement.typeAndSelectandOption"
                        )}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            transform: "translate(14px, -9px) scale(0.75)",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            height: "46px !important",
                            padding: "0 14px !important",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={SearchIcon}
                                alt="search"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#737D86",
                                  marginRight: "5px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "30px !important",
                            padding: "0 !important",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            opacity: 1,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={<AddCircleIcon />}
                    sx={{
                      height: "46px",
                      minHeight: "46px",
                      maxHeight: "46px",
                      background: "#004F9F",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleAddAgriculturalSupply}
                    disabled={!selectedAgriculturalSupply}
                  >
                    {t("centralAgriculturalSupplyManagement.addToSelected")}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={
                      <SvgIcon
                        component={FiltersIcon}
                        inheritViewBox
                        sx={{ width: 28, height: 28 }}
                      />
                    }
                    sx={{
                      height: "46px",
                      minHeight: "46px",
                      maxHeight: "46px",
                      background: "#004F9F",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleOpenMoreFilters}
                  >
                    {t("centralAgriculturalSupplyManagement.moreFilters")}
                  </Button>
                </Grid>
              </Grid>

              {selectedAgriculturalSupplies.length > 0 && (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 2px",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#5A646E",
                        marginLeft: "10px",
                      }}
                    >
                      Selected Agricultural Supplies
                    </Typography>
                  </Box>
                  <Box
                    id="divider"
                    sx={{
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px 20px 0px",
                      gap: "20px",
                      background: "#FFFFFF",
                      borderBottom: "5px solid #004F9F",
                    }}
                  ></Box>
                </Box>
              )}
            </Grid>

            {selectedAgriculturalSupplies.length > 0 && (
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Box
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {selectedAgriculturalSupplies.map((supply, index) => (
                    <Box
                      key={supply.value}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px 16px",
                        backgroundColor: "#FFFFFF",
                        borderBottom:
                          index < selectedAgriculturalSupplies.length - 1
                            ? "1px solid #E0E0E0"
                            : "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#000000",
                        }}
                      >
                        {supply.label}
                      </Typography>
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        onClick={() => handleRemoveAgriculturalSupply(supply)}
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: 2,
              paddingTop: "5px",
              height: "auto",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t(
                  "centralAgriculturalSupplyManagement.selecthierarchicalNodes"
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={10}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        hierarchyOptions.find(
                          (option) => option.value === hierarchicalNode
                        ) || null
                      }
                      onChange={handleHierarchyChange}
                      options={hierarchyOptions}
                      loading={hierarchyLoading}
                      clearOnBlur
                      clearIcon={<CloseIcon />}
                      PopperProps={{
                        placement: "bottom-start",
                        style: {
                          zIndex: 9999,
                        },
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <div>
                            <div style={{ fontSize: "16px" }}>
                              {option.label}
                            </div>
                            <div style={{ fontSize: "10px", color: "#5A646E" }}>
                              {option.tag}
                            </div>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={t(
                            "centralAgriculturalSupplyManagement.hierarchicalNode"
                          )}
                          fullWidth
                          placeholder={t("ALL")}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              opacity: 1,
                            },
                            "& .MuiOutlinedInput-root": {
                              padding: "5px",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={SearchIcon}
                                  alt="search"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#737D86",
                                    marginRight: "8px",
                                    marginLeft: "7px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    startIcon={<AddCircleIcon />}
                    sx={{ height: "46px", background: "#004F9F" }}
                    onClick={handleAddHierarchicalNode}
                  >
                    {t("centralAgriculturalSupplyManagement.addToSelected")}
                  </Button>
                </Grid>
              </Grid>

              {selectedHierarchicalNodes.length > 0 && (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 2px",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#5A646E",
                        marginLeft: "10px",
                      }}
                    >
                      Selected Hierarchical Nodes
                    </Typography>
                  </Box>
                  <Box
                    id="divider"
                    sx={{
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px 20px 0px",
                      gap: "20px",
                      background: "#FFFFFF",
                      borderBottom: "5px solid #004F9F",
                    }}
                  ></Box>
                </Box>
              )}
            </Grid>

            {selectedHierarchicalNodes.length > 0 && (
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Box
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {selectedHierarchicalNodes.map((node, index) => (
                    <Box
                      key={node.value}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px 16px",
                        backgroundColor: "#FFFFFF",
                        borderBottom:
                          index < selectedHierarchicalNodes.length - 1
                            ? "1px solid #E0E0E0"
                            : "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#000000",
                        }}
                      >
                        {`${node.label} (${node.tag})`}
                      </Typography>
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        onClick={() => handleRemoveHierarchicalNode(node)}
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Box sx={{ height: "220px" }}></Box>

      {/* Bottom Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "fixed",
          width: "100%",
          left: 0,
          bottom: "19px",
          background: "white",
          zIndex: 999,
          padding: "0.5rem",
          borderTop: "1px solid var(--Gray-1, #E9EBEE)",
        }}
      >
        {/* Save Button - Right bottom corner */}
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
            marginRight: "30px",
          }}
          disabled={
            (operationType === "clone" &&
              (!cropSeason || !destinationCropSeason)) ||
            (operationType !== "clone" &&
              (!cropSeason ||
                (selectedAgriculturalSupplies.length === 0 &&
                  selectedHierarchicalNodes.length === 0)))
          }
          startIcon={<img src={Save} alt="Right Arrow" />}
          variant="contained"
          onClick={handleSaveAction}
        >
          {t("save")}
        </Button>
      </Box>

      <MoreFilters
        open={isMoreFiltersOpen}
        onClose={handleCloseMoreFilters}
        cropSeasonId={cropSeason}
        isThirdPartySupply={supplySource === "third-party"}
        onItemsSelect={handleMoreFiltersItemsSelect}
      />

      <Modal
        open={isWarningModalOpen}
        onClose={handleCloseWarningModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "220px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("Confirm Operation")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t(
                "You are about to clone settings between Crop Seasons. All data existing in destination Crop Season will be overwritten. Do you want to continue?"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleCloseWarningModal}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={() => {
                handleCloseWarningModal();
                performCloneData();
              }}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default CentralAgriculturalSupplyManagement;
