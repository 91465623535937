// Table.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./FarmerContractTable.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import { SnackbarContext } from "context/snackBarContext";
import Arrow from "assets/UpDownArrow.svg";
import Details from "assets/Details.svg";
import Contracted from "assets/Contracted.svg";
import Blocked from "assets/Blocked.svg";
import Approved from "assets/Approved.svg";
import Inactive from "assets/Inactive.svg";
import NotApplied from "assets/NotApplied.svg";
import PendingApproved from "assets/PendingApproved.svg";
import PlayIcon from "assets/PlayIcon.svg";


const NewContractTable = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [tableData, setTableData] = useState(data);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const handleMoreDetailsClick = () => {
    setShowMoreDetails(!showMoreDetails); 
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };



  const handleEditClick = (row) => {
    navigate("/addGroup", {
      state: {
        rowData: row,
      },
    });
  };

  return (
    <div className="table-container">
      <table className="contract-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("code")}
            >
              {t("Farmer Code")}
              {getSortIcon("code")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("name")}
            >
              {t("Farmer Name")}
              {getSortIcon("name")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("segmentation")}
            >
              {t("Segmentation")}
              {getSortIcon("segmentation")}
            </th>
            
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("city")}
            >
              {t("City")}
              {getSortIcon("city")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => handleSort("locality")}
            >
              {t("Locality")}
              {getSortIcon("locality")}
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
             
            >
              {t("ESG Status")}
           
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
              }}
          
            >
              {t("Commercial Status")}
            
            </th>
           
           
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                {t("Actions")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ cursor: "pointer" }}>
                <td>
                  <Tooltip
                    title={showMoreDetails[index] ? "Less Details" : "More Details"}
                    arrow
                    placement="top"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                      }}
                      onClick={()=>handleMoreDetailsClick(index)}
                    >
                      <img src={Details} alt="Details" />
                      <img
                        src={Arrow}
                        style={{
                          transform: !showMoreDetails && "rotate(180deg)",
                        }}
                        height="10px"
                        alt="Details"
                      />
                    </div>
                  </Tooltip>
                  {row.code}
                </td>
                <td>{row.name}</td>
                <td>{row.segmentation}</td>
                <td>{row.city}</td>
                <td>{row.locality}</td>
                <td>
                {row.ESGstatus === "Approved" && (
                    <img src={Approved} alt="Approved" width="90%" height="30px" />
                  )}
                  {row.ESGstatus === "ESG Blocked" && (
                    <img src={Blocked} alt="Blocked" width="90%" height="30px"  />
                  )}
                   {row.ESGstatus === "Pending Approval" && (
                    <img src={PendingApproved} alt="PendingApproved" width="90%" height="30px" />
                  )}
                  {row.ESGstatus === "Pre Assess. Not Applied" && (
                    <img src={NotApplied} alt="NotApplied" width="90%" height="30px"  />
                  )}
                 
                </td>
                <td>
                {row.commercialStatus === "Contracted" && (
                    <img src={Contracted} alt="Contracted" width="95%" height="50px" />
                  )}
                  {row.commercialStatus === "Inactive" && (
                    <img src={Inactive} alt="Inactive" width="95%" height="50px"  />
                  )}
                  {row.commercialStatus !== "Contracted" && row.commercialStatus !== "Inactive" && (
                    row.commercialStatus
                  )}
                    
                </td>    
                <td className="actions-cell">
                  <Tooltip title="SELECT" arrow placement="top">
                    <img
                      className="btn edit-btn"
                      src={PlayIcon}
                      alt="Select"
                      onClick={() => handleEditClick(row)}
                    />
                  </Tooltip>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {showMoreDetails && <div className="more-details-box">abc</div>}
    </div>
  );
};

export default NewContractTable;
