// AddSoilAnalysisApplicationPhase.jsx
import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import SoilAnalysisApplicationPhaseService from "services/SoilAnalysisApplicationPhaseService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Save from "assets/SaveNew.svg";
import { id } from "date-fns/locale";

export default function AddSoilAnalysisApplicationPhase() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  // setting the default value of the User Story
  const { applicationPhase, status, applicationId } =
    location?.state?.row || {};
  const { edit } = location.state || {};
  const [values, setValues] = useState({
    itemId: applicationId || "",
    discription: applicationPhase || "",
    status: status === true,
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Function to handle changes in the form fields
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Function to handle form submission for both edit and add
  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedDesc = values.discription.trim();
    if (!trimmedDesc || !/^[a-zA-Z0-9 ]+$/.test(trimmedDesc)) {
      setMessage("Invalid application phase");
      setSeverity("error");
      setOpenAlert(true);
      return;
    }

    // Set the payload based on the user story
    const payload = {
      applicationPhase: trimmedDesc,
      status: values.status,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
      isDelete: false,
      countryCode: "bra",
      ...(edit && { applicationId: values.itemId }),
    };

    // Call the API for create or update
    try {
      setLoading(true);
      const service = edit
        ? SoilAnalysisApplicationPhaseService.updateAPI
        : SoilAnalysisApplicationPhaseService.createAPI;
      const response = await service(payload);
      setLoading(false);

      // Handle the response if successful
      if (response.status === 200) {
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        navigate("/soilAnalysisApplicationPhase");
      }
    }

    // Handle the error responses
    catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(`The ${values.discription} entered already exists. Please enter a unique Phase Name.`);
        setOpenAlert(true);
      } else {
        setLoading(false);
        setSeverity("error");
        setMessage(error.response?.data?.message || "Operation failed");
        setOpenAlert(true);
      }
    };
  };

  return (
    <Layout
      title={"Soil Analysis - Application Phase List"}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "10vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },

        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>From</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
            }}
          >
            <TextField
              name="discription"
              label="Application Phase"
              value={values.discription}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
              }}
              InputProps={{ style: { height: "50px" } }}
            />
            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  Status
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        disabled={edit === false}
                      />
                    }
                    label={<Typography sx={{ ml: 2 }}>Active</Typography>}
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={() => setOpen(true)}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? "Back" : "Cancel"}
            </Button>
            {edit !== false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed",
                  right: "1rem",
                  bottom: "1.5rem",
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": { backgroundColor: "#EF7D00" },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={handleSubmit}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                Save
              </Button>
            )}
          </Box>
        </form>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Cancel Operation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              Are you sure you want to cancel this operation? All your data will
              be lost.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={() => navigate("/soilAnalysisApplicationPhase")}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
}
