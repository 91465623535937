import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Typography,
  Modal,
  MenuItem,
  Grid,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import _Minus_ from "assets/_Minus_.svg";
import _Plus_ from "assets/_Plus_.svg";
import { ArrowDropDownIcon, ClearIcon } from "@mui/x-date-pickers";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import { useTranslation } from "react-i18next";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import iconSearch from "assets/iconSearchs.svg";
import SpacingTargetService from "services/SpacingTargetService";
import InvestmentGroupLimitsService from "services/InvestmentGroupLimitsService";
import UnlockForSaleManagementService from "services/UnlockForSaleManagementService";
import { unlockForSaleManagementSchema } from "utils/validations";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  "& .MuiTreeItem-content": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent", // Remove hover background
    },
    "&.Mui-selected": {
      backgroundColor: "transparent", // Remove selection background
    },
    "&.Mui-focused": {
      backgroundColor: "transparent", // Remove focus background
      outline: "none", // Remove focus outline
    },
  },
  "& .MuiTreeItem-label": {
    "&:hover": {
      backgroundColor: "transparent", // Remove label hover effect
    },
  },
}));

export default function SaleManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const { supplierDetails } = location.state || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    cropYearId: "",
    geneticTypeId: "",
    releaseModality: "",
    ihID: {
      label: "All",
      level: 0,
      parentId: "parentNode",
      tag: "Country",
      value: "0a2a8e4d-92d8-1893-8192-dd2a1c19000a",
    },
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState([]);
  const [open, setOpen] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [regions, setRegions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const { t } = useTranslation();
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [agriculturalSupply, setAgriculturalSupply] = useState([]);

  const [treeData, setTreeData] = useState([]);
  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse, geneticResponse] =
        await Promise.all([
          SpacingTargetService.getCropSeasonAPI(),
          InvestmentGroupLimitsService.getHierarchyNodeAPI(),
          SpacingTargetService.getGeneticType(),
        ]);

      setCropSeasonOptions(
        cropSeasonResponse?.data?.response?.cropSeason?.map((item) => ({
          label: item.cropYear.toString(),
          value: item.cropYear.toString(),
          cropSeasonId: item.cropSeasonId, // Assuming there's an id field
        }))
      );

      setGeneticTypeOptions(
        geneticResponse?.data?.response?.map((item) => ({
          label: item.gtGeneticDescription.toString(),
          value: item.gtGeneticDescription.toString(),
          geneticTypeId: item.gtGeneticTypeId, // Assuming there's an id field
        }))
      );

      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
          label: node.locationName,
          value: node.id,
          level,
          tag: node.levelName,
          parentId: node.parentId || "parentNode",
        });
        node.childHierarchies.forEach((child) =>
          flattenHierarchy(child, result, level + 1)
        );
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/agriculturalSuppliesRegistrationList");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => {
      // If the changed field is bankRegistrationId
      if (name === "bankRegistrationId") {
        const selectedBank = banks.find(
          (bank) => bank.bankRegistrationId === value
        );
        // Fetch branches for the selected bank
        fetchBranchDetails(value); // Call the function to fetch branches
        return {
          ...prevValues,
          [name]: value,
          bankCode: selectedBank ? selectedBank.bankCode : "",
          bankBranchRegistrationId: "", // Reset branch selection when bank changes
        };
      }

      // For all other fields, update normally
      return {
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Add function to fetch branch details
  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response =
        await TransporterRegistrationService.getBankBranchDetails(
          bankRegistrationId
        );
      setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all dropdown data in parallel
        const [
          nationalityResponse,
          maritalStatusResponse,
          regionsResponse,
          banksResponse,
        ] = await Promise.all([
          TransporterRegistrationService.getNationality(),
          TransporterRegistrationService.getmaritalStatus(),
          TransporterRegistrationService.getRegions(),
          TransporterRegistrationService.getBankDetails(),
        ]);

        setNationalities(nationalityResponse.data.response || []);
        setMaritalStatuses(maritalStatusResponse.data.response || []);
        setRegions(regionsResponse.data.response || []);
        setBanks(banksResponse.data.response || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessage("Failed to fetch data");
        setSeverity("error");
        setOpenAlert(true);
      }
    };
    fetchInitialData();
    fetchData();
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const renderTree = (node) => {
    if (!node) return null;

    return (
      <StyledTreeItem
        key={node.id}
        nodeId={node.id}
        label={<TreeItemContent nodes={node} />}
      >
        {Array.isArray(node.childHierarchies) &&
          node.childHierarchies.map((child) => renderTree(child))}
      </StyledTreeItem>
    );
  };

  const toggleAvailability = (id, newValue, node) => {
    // If the node's id matches, update the node and all its children
    if (node.id === id) {
      return {
        ...node,
        isAvailable: newValue,
        childHierarchies: node.childHierarchies.map(
          (child) => toggleAvailability(id, newValue, child) // Recursively update child nodes
        ),
      };
    }

    // If the node's id doesn't match, return the node as is
    return {
      ...node,
      childHierarchies: node.childHierarchies.map(
        (child) => toggleAvailability(id, newValue, child) // Continue checking child nodes
      ),
    };
  };

  const handleCheckboxChange = (event, targetId) => {
    const updatedChecked = event.target.checked;

    // Update the tree with the new availability status
    const updatedTreeData = toggleAvailability(
      targetId,
      updatedChecked,
      treeData
    );

    // Update the state with the modified tree data
    setTreeData(updatedTreeData);
  };

  // Create a separate component to handle the checkbox state
  const TreeItemContent = ({ nodes }) => {
    const [isChecked, setIsChecked] = useState(nodes.isAvailable);
    // const handleCheckboxChange = (event) => {
    //   console.log("change ho rha hai");
    //   setIsChecked(event.target.checked);
    // };

    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={(e) => e.stopPropagation()}
      >
        <span style={{ marginRight: 7 }}>{nodes.label}</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "417px",
            height: "100px",
            gap: "10px",
            marginTop: "7px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "#F8F8F8",
              height: "100px",
              width: "417px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "10px",
                backgroundColor: "#F8F8F8",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                }}
              >
                <div id="abovetext">
                  Hierarchical Node
                  <div
                    style={{
                      fontSize: "12px",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {nodes?.locationName}
                  </div>
                </div>
                <div>
                  <Checkbox
                    {...label}
                    checked={isChecked}
                    onChange={(e) => handleCheckboxChange(e, nodes.id)}
                    sx={{
                      color: "#5A646E", // Unchecked color
                      "&.Mui-checked": {
                        color: "#004F9F", // Checked color
                      },
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <div id="belowtext">
                  Hierarchical Level
                  <div
                    style={{
                      fontSize: "12px",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {nodes?.levelName}
                  </div>
                </div>
                <div>
                  Available
                  <div style={{ fontSize: "12px", color: "gray" }}>
                    {nodes.levelName}
                  </div>
                </div>
              </div>
            </div>

            <button
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright",
                backgroundColor: isChecked ? "#004F9F" : "#ACB4BA", // Change color when checked
                color: "white",
                border: "1px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                height: "100px",
                width: "10px",
                cursor: "pointer",
              }}
            ></button>
          </div>
        </div>
      </div>
    );
  };

  const getAgriSupply = async (asrSupplyId) => {
    try {
      const response = await UnlockForSaleManagementService.getAgriSupply(
        supplierDetails.asrSupplyId
      );
      setAgriculturalSupply(response.data.response);
      setLoading(false);
    } catch (err) {
      //setError("Failed to fetch data");
      setLoading(false);
    }
  };

  const getHierarchicalTree = async () => {
    const payload = {
      cropYearId: values?.cropYearId.cropSeasonId,
      geneticTypeId: values?.geneticTypeId.geneticTypeId,
      releaseModality: values?.releaseModality,
      ihID: values?.ihID.value,
    };
    try {
      setLoading(true);
      const response = await UnlockForSaleManagementService.getTree({
        ...payload,
      });
      setLoading(false);
      setTreeData(response.data.response);
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Transform values for validation
      const transformedValues = {
        ...values,
        cropYearId: values.cropYearId?.value || values.cropYearId, // Extract value if object
        geneticTypeId: values.geneticTypeId?.value || values.geneticTypeId,
      };
      const flattenTreeData = (node) => {
        let result = [
          {
            ihId: node.id,
            isAvailable: node.isAvailable,
          },
        ];

        if (node.childHierarchies && node.childHierarchies.length > 0) {
          node.childHierarchies.forEach((child) => {
            result = result.concat(flattenTreeData(child));
          });
        }

        return result;
      };
      const agriSuppliesUnlockList = flattenTreeData(treeData);
      await unlockForSaleManagementSchema.validate(transformedValues, {
        abortEarly: false,
      });
      setErrors({}); // Clear previous errors if validation passes

      // Proceed with your existing logic...
      // Prepare payload
      const payload = {
        grCropSeason: values.cropYearId.cropSeasonId,
        agriculturalSupplies: supplierDetails.asrSupplyId,
        geneticType: values?.geneticTypeId.geneticTypeId,
        releaseModality: values?.releaseModality,
        agriSuppliesUnlockList: agriSuppliesUnlockList,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string",
      };

      const response = await UnlockForSaleManagementService.updateTree(payload);
      setLoading(false);

      if (response.status === 200) {
        navigate("/agriculturalSuppliesRegistrationList");
        setSeverity("success");
        //setMessage(t("snackbarMessage.successAdd"));
        setMessage("Availability has been successfully updated");
        setOpenAlert(true);
      } else if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
      return true;
    } catch (err) {
      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        // Map Yup errors to state
        const newError = {};
        err.inner.forEach((error) => {
          newError[error.path] = error.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form."
        );
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    if (
      values.cropYearId &&
      values.geneticTypeId &&
      values.releaseModality &&
      values.ihID
    ) {
      getHierarchicalTree();
    }
  }, [
    values.cropYearId,
    values.geneticTypeId,
    values.releaseModality,
    values.ihID,
  ]);

  useEffect(() => {
    getAgriSupply();
  }, []);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [showExitWarningOverlayFeed, setShowExitWarningOverlayFeed] =
    useState(false);
  const [pendingNavigation, setPendingNavigation] = useState();
  const showModal = () => {
    setUnsavedChangesModal(true);
  };
  const cancelNavigation = () => {
    setUnsavedChangesModal(false);
    navigate(pendingNavigation);
  };

  const confirmNavigation = async () => {
    const isSaveSuccessful = await handleSubmit();
    if (isSaveSuccessful) {
      setUnsavedChangesModal(false);
      navigate(pendingNavigation);
    } else {
      setUnsavedChangesModal(false);
    }
  };
  useEffect(() => {
    const handlePopState = (event) => {
      const shouldNavigate = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!shouldNavigate) {
        window.history.pushState(
          null,
          "",
          window.location.pathname + window.location.search
        );
      } else {
        window.history.go(-1);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "You have unsaved changes. Are you sure you want to leave?";
    };

    if (showExitWarningOverlayFeed === true) {
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.history.pushState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showExitWarningOverlayFeed]);
  useEffect(() => {}, [values]);

  return (
    <Layout
      title={t("agriculturalSupplyUnlock.title")}
      background="#ffffff"
      showExitWarningOverlayFeed={showExitWarningOverlayFeed}
      setShowExitWarningOverlayFeed={showModal}
      setPendingNavigation={setPendingNavigation}
    >
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              //  height: hasTransporterDataErrors() ? "500px" : "440px",
              //   padding: "10px",
              //   borderRadius: "5px",
              //  transition: "height 200ms ease-in-out",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontWeight: 600, color: "#666666" }}>
                {t("agriculturalSupplyUnlock.agriculturalSupply")}
              </Typography>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("agriculturalSupplyUnlock.code")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.asrCode}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE !important",
                        "& fieldset": {
                          borderColor: "#BDBDBD", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#BDBDBD !important", // Override hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#BDBDBD !important", // Override focus border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("agriculturalSupplyUnlock.sapCode")}
                    value={agriculturalSupply?.sapCode}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={5.8}>
                <FormControl fullWidth>
                  <TextField
                    name="transporterName"
                    label={t("agriculturalSupplyUnlock.name")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.asrSupplyName}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.transporterName} // Show error state
                    helperText={errors.transporterName || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.transporterName ? "20px" : "0px",
                        opacity: errors.transporterName ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.transporterName ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="fiscalID"
                    label={t("agriculturalSupplyUnlock.unitOfMeasure")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.asrUnitOfMeasurement}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.fiscalID} // Show error state
                    helperText={errors.fiscalID || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.fiscalID ? "20px" : "0px",
                        opacity: errors.fiscalID ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.fiscalID ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="nationalityId"
                    label={t("agriculturalSupplyUnlock.supplyType")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.supplyTypeName}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.nationalityId}
                    helperText={errors.nationalityId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.nationalityId ? "20px" : "0px",
                        opacity: errors.nationalityId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.nationalityId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {nationalities.map((nationality) => (
                      <MenuItem
                        key={nationality.nationalityId}
                        value={nationality.nationalityId}
                      >
                        {nationality.nationality}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="maritalStatusId"
                    label={t("agriculturalSupplyUnlock.typeOfUse")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.typeOfUse}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.maritalStatusId}
                    helperText={errors.maritalStatusId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.maritalStatusId ? "20px" : "0px",
                        opacity: errors.maritalStatusId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.maritalStatusId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {maritalStatuses.map((status) => (
                      <MenuItem
                        key={status.maritalStatusId}
                        value={status.maritalStatusId}
                      >
                        {status.maritalStatus}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="regionId"
                    label={t("agriculturalSupplyUnlock.agriculturalInputGroup")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.agriculturalInputGroup}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    required
                    error={!!errors.regionId}
                    helperText={errors.regionId || ""}
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.regionId ? "20px" : "0px",
                        opacity: errors.regionId ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.regionId ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {/* {regions.map((region) => (
                      <MenuItem key={region.regionId} value={region.regionId}>
                        {region.stateCode}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="stateRegistrationNumber"
                    label={t("agriculturalSupplyUnlock.seedType")}
                    InputLabelProps={{ shrink: true }}
                    placeholder="-"
                    //value={agriculturalSupply?.sapCode}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.stateRegistrationNumber} // Show error state
                    helperText={errors.stateRegistrationNumber || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.stateRegistrationNumber ? "20px" : "0px",
                        opacity: errors.stateRegistrationNumber ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.stateRegistrationNumber
                        ? "20px"
                        : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {dataType.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={11.82}>
                <FormControl fullWidth>
                  <TextField
                    name="Route Details"
                    label={t("agriculturalSupplyUnlock.lockInfo")}
                    //InputLabelProps={{ shrink: true }}
                    //value={agriculturalSupply?.sapCode}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit", // Keeps border color consistent on hover
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0)", // Default border color
                      },
                      "& .MuiInputLabel-root": {
                        fontWeight: "600",
                        color: "#5A646E",
                        transform: "translate(10px, 10px) scale(1)", // Adjust vertical position
                      },
                      "& .MuiInputLabel-shrink": {
                        transform: "translate(0, -5px) scale(0.75)", // Adjust position when label shrinks
                      },
                    }}
                    InputProps={{
                      style: { height: "40px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStation"
                    label={t("agriculturalSupplyUnlock.blockedForBilling")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.blockedforBilling}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.buyingStation} // Show error state
                    helperText={errors.buyingStation || ""} // Show error message
                    // select
                    // SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStation ? "20px" : "0px",
                        opacity: errors.buyingStation ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStation ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    <MenuItem value="Rio Negro">Rio Negro</MenuItem>
                    <MenuItem value="Papanduva">Papanduva</MenuItem>
                    <MenuItem value="Santa Cruz do Sul">
                      Santa Cruz do Sul
                    </MenuItem>
                    <MenuItem value="Imbituva">Imbituva</MenuItem>
                    <MenuItem value="Unidade Rio Negro">
                      Unidade Rio Negro
                    </MenuItem>
                    <MenuItem value="Ararangua">Ararangua</MenuItem>
                    <MenuItem value="Pouso Redondo">Pouso Redondo</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStationRoute"
                    label={t("agriculturalSupplyUnlock.unlockedForOffSeason")}
                    InputLabelProps={{ shrink: true }}
                    value={agriculturalSupply?.unlockedforOffSeason}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    error={!!errors.buyingStationRoute} // Show error state
                    helperText={errors.buyingStationRoute || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        bgcolor: "#E9EBEE",
                      },
                      "& fieldset": {
                        borderColor: "#BDBDBD", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#BDBDBD !important", // Override hover border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStationRoute ? "20px" : "0px",
                        opacity: errors.buyingStationRoute ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStationRoute ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Bank Information */}

          <div>
            <Box
              sx={{
                bgcolor: "#EFEFEF",
                height: "106px",
                //   height: hasBankInfoErrors() ? "130px" : "100px",
                //   padding: "10px",
                //   borderRadius: "5px",
                //   transition: "height 200ms ease-in-out",
                mt: "10px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ArrowDropDownIcon sx={{ marginRight: 1 }} />
                <Typography sx={{ fontWeight: 600, color: "#666666" }}>
                  {t("agriculturalSupplyUnlock.mandatoryFilters")}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2.95}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={values.cropYearId}
                      disableClearable={!values.cropYearId} // Disable clearable when no value is selected
                      clearOnEscape
                      clearIcon={values.cropYearId ? <ClearIcon /> : null} // Show icon only if a value is selected
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setShowExitWarningOverlayFeed(true);
                          setValues((prevValues) => ({
                            ...prevValues,
                            cropYearId: newValue, // Assuming newValue has a property 'cropYearId'
                          }));
                        } else {
                          setValues((prevValues) => ({
                            ...prevValues,
                            cropYearId: "", // Reset cropYearId if newValue is null or undefined
                          }));
                        }
                      }}
                      options={cropSeasonOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="cropYearId"
                          label={t("investmentGroupLimits.csSearchField")}
                          fullWidth
                          placeholder="Select an Option"
                          error={!!errors.cropYearId}
                          helperText={errors.cropYearId || " "}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              opacity: 1,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { height: "50px" },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.95}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={values.geneticTypeId}
                      disableClearable={!values.geneticTypeId} // Disable clearable when no value is selected
                      clearOnEscape
                      clearIcon={values.geneticTypeId ? <ClearIcon /> : null} // Show icon only if a value is selected
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setShowExitWarningOverlayFeed(true);
                          setValues((prevValues) => ({
                            ...prevValues,
                            geneticTypeId: newValue, // Assuming newValue has a property 'cropYearId'
                          }));
                        } else {
                          setValues((prevValues) => ({
                            ...prevValues,
                            geneticTypeId: "", // Reset cropYearId if newValue is null or undefined
                          }));
                        }
                      }}
                      options={geneticTypeOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="geneticTypeId"
                          label={t("spacingTarget.geneticType")}
                          fullWidth
                          placeholder="Select an Option"
                          error={!!errors.geneticTypeId}
                          helperText={errors.geneticTypeId || " "}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              opacity: 1,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { height: "50px" },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2.95}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      value={values.releaseModality}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setShowExitWarningOverlayFeed(true);
                          setValues((prevValues) => ({
                            ...prevValues,
                            releaseModality: newValue.value, // Assuming newValue has a property 'cropYearId'
                          }));
                        } else {
                          setValues((prevValues) => ({
                            ...prevValues,
                            releaseModality: "", // Reset cropYearId if newValue is null or undefined
                          }));
                        }
                      }}
                      options={[
                        { value: "Normal", label: "Normal" },
                        {
                          value: "Additional without an area increase",
                          label: "Additional without an area increase",
                        },
                        { value: "Replacement", label: "Replacement" },
                      ]}
                      renderInput={(params) => (
                        <TextField
                          name="releaseModality"
                          {...params}
                          label={t("agriculturalSupplyUnlock.releaseModality")}
                          placeholder="Select an Option"
                          error={!!errors.releaseModality}
                          helperText={errors.releaseModality || ""}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              opacity: 1,
                            },
                            "& .MuiOutlinedInput-root": {
                              padding: "7px",
                              minHeight: "50px",
                            },
                            "& .MuiInputLabel-root": {
                              lineHeight: "1.2em",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { height: "50px" },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.95}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={values.ihID}
                      // disableClearable={!values.ihID} // Disable clearable when no value is selected
                      // clearOnEscape
                      // clearIcon={values.ihID ? <ClearIcon /> : null} // Show icon only if a value is selected
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setShowExitWarningOverlayFeed(true);
                          setValues((prevValues) => ({
                            ...prevValues,
                            ihID: newValue, // Assuming newValue has a property 'cropYearId'
                          }));
                        } else {
                          setValues((prevValues) => ({
                            ...prevValues,
                            ihID: "", // Reset cropYearId if newValue is null or undefined
                          }));
                        }
                      }}
                      options={hierarchyOptions}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <div>
                            <div style={{ fontSize: "16px" }}>
                              {option.label}
                            </div>
                            <div style={{ fontSize: "10px", color: "#5A646E" }}>
                              {option.tag}
                            </div>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          name="hierarchicalNode"
                          {...params}
                          label={t("investmentGroupLimits.hnSearchField")}
                          fullWidth
                          placeholder="All"
                          error={!!errors.ihID}
                          helperText={errors.ihID || " "}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              opacity: 1,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { height: "50px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={iconSearch}
                                  alt="search"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#737D86",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>
          {values.cropYearId &&
            values.geneticTypeId &&
            values.releaseModality &&
            values.ihID &&
            treeData && (
              <>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: "5px solid #004F9F",
                    mt: "15px",
                    px: 1, // Add horizontal padding to match other components
                    width: "auto", // Allow container to respect padding
                    mx: 1, // Add horizontal margin to match other components
                  }}
                >
                  <Grid>
                    <Typography sx={{ color: "#5A646E", fontWeight: "600" }}>
                      {t("agriculturalSupplyUnlock.availiblityInfo")}
                    </Typography>
                  </Grid>
                </Grid>

                <TreeView
                  sx={{
                    "& .MuiTreeItem-root": {
                      backgroundColor: "transparent !important",
                    },
                    "& .MuiTreeItem-content": {
                      backgroundColor: "transparent !important",
                      "&:hover": {
                        backgroundColor: "transparent !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "transparent !important",
                      },
                      "&.Mui-focused": {
                        backgroundColor: "transparent !important",
                        outline: "none",
                      },
                    },
                    "& .MuiTreeItem-label": {
                      "&:hover": {
                        backgroundColor: "transparent !important",
                      },
                    },
                  }}
                  defaultCollapseIcon={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ height: "16px", width: "16px" }}
                        src={_Minus_}
                        alt="Minus"
                      />
                    </div>
                  }
                  defaultExpandIcon={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ height: "16px", width: "16px" }}
                        src={_Plus_}
                        alt="Plus"
                      />
                    </div>
                  }
                >
                  {renderTree(treeData)}
                </TreeView>
              </>
            )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              left: 0, // Ensure it starts from the left edge
              bottom: "19px", // Place at the bottom of the screen
              background: "white",
              zIndex: 999,
              padding: "0.5rem",
              borderTop: "1px solid var(--Gray-1, #E9EBEE)",
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                marginLeft: "30px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? t("back") : t("cancel")}
            </Button>

            {/* Modal confirmation */}

            <Modal
              open={unsavedChangesModal}
              onClose={() => cancelNavigation()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("Unsaved Changes Confirmation")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("unsavedChanges2")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={cancelNavigation}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={confirmNavigation}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("save")}
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                  marginRight: "30px",
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={handleSubmit}
                variant="contained"
              >
                {loading ? <CircularProgress size={20} /> : t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
