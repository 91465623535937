import React, { useState, useEffect,useContext } from "react";
import Layout from "components/structure/Layout";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Modal,
} from "@mui/material";
import Divider from "components/common/GrowingDivider";
import Save from "assets/SaveNew.svg";
import arrowLeft from "assets/arrowLeft.svg";
import Cluster from "assets/Cluster.svg";
import Lands from "assets/Lands.svg";
import Minors from "assets/Minors.svg";
import Adults from "assets/Adults.svg";
import Contracts from "assets/Contracts.svg";
import Curing from "assets/Curing.svg";
import Supplies from "assets/Supplies.svg";
import Finance from "assets/Finance.svg";
import Investments from "assets/Investments.svg";
import ThirdParty from "assets/ThirdParty.svg";
import Plot from "assets/Plot.svg";
import Technology from "assets/Technology.svg";
import Funding from "assets/Funding.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FarmerClusterTable from "./FarmerClusterTable";
import FarmerClusterService from "services/FarmerClusterService";
import { SnackbarContext } from "context/snackBarContext";

export default function FarmerCluster() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // Modal state
  //const [IconData, setIconData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleAddNewClick = () => {
    navigate("/addFarmerRegistration");
  };
  const handleSave=()=>{
      setMessage(t("Record Saved Successfully"));
      setSeverity("success");
      setOpenAlert(true);
  }

  // useEffect(() => {
  //   fetchIcon();
  // }, []);

  // const fetchIcon = async () => {
  //   try {
  //     const response = await FarmerClusterService.getIcon();
  //     setIconData(response.data.response); // Store the response data in the state
  //     console.log(response.data.response, "IconData");
  //   } catch (error) {
  //     console.error("Error fetching Icon data:", error);
  //   }
  // };
  // console.log(IconData, "IconData2");

  // const menuItems = [
  //   { text: "Cluster", name: "Cluster" },
  //   { text: "Lands", name: "Lands" },
  //   { text: "Minors", name: "Minors" },
  //   { text: "Adults", name: "Adults" },
  //   { text: "Contracts", name: "Contracts" },
  //   { text: "Curing Units", name: "Curing" },
  //   { text: "Supplies Order", name: "Supplies" },
  //   { text: "Self Finance", name: "Finance" },
  //   { text: "Funding", name: "Funding" },
  //   { text: "Investments", name: "Investments" },
  //   { text: "3rd Party Supply", name: "ThirdParty" },
  //   { text: "Plots", name: "Plot" },
  //   { text: "Technology Planning", name: "Technology" },
  // ];
  const menuItems = [
    { text: "Cluster", name: Cluster },
    { text: "Lands", name: Lands },
    { text: "Minors", name: Minors },
    { text: "Adults", name: Adults },
    { text: "Contracts", name: Contracts },
    { text: "Curing Units", name: Curing },
    { text: "Supplies Order", name: Supplies },
    { text: "Self Finance", name: Finance },
    { text: "Funding", name: Funding },
    { text: "Investments", name: Investments },
    { text: "3rd Party Supply", name: ThirdParty },
    { text: "Plots", name: Plot },
    { text: "Technology Planning", name: Technology },
  ];
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(true);
  };
  // const updatedMenuItems = menuItems.map((item) => {
  //   const icon = IconData.find((icon) => icon.name === item.name);
  //   return {
  //     ...item,
  //     url: icon ? icon.url : null, // Add the URL if there's a match, otherwise null
  //   };
  // });
  // console.log(updatedMenuItems, "updatedMenuItems");

  return (
    <Layout title={t("New Contract – Details (Cluster Tab)")}>
      <Box sx={{ display: "flex", gap: 3, height:'auto' }}>
        {/* Left Sidebar */}
        <Box
          sx={{
            width: 240,
            bgcolor: "#EFEFEF",
            borderRadius: "15px",
            overflow: "auto",
          }}
        >
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                key={index}
                button
                sx={{
                  py: 1,
                  "&:hover": {
                    bgcolor: "#e3f2fd",
                  },
                  "& .MuiListItemIcon-root": {
                    minWidth: 40,
                    color: "#1976d2",
                  },
                }}
              >
               <ListItemIcon>
                    <img src={item.name}/>
                    </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontSize: "0.875rem",
                      color: "#424242",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2, // Add some margin for spacing
              width: "100%", // Ensure the Box takes up full width
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Align items to the left and right
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {t("Farmers")}
              </Typography>
              <Button
                variant="contained"
                onClick={handleAddNewClick}
                sx={{
                  minWidth: 175,
                  borderRadius: 1,
                  background: "#737D86",
                  "&:hover": {
                    backgroundColor: "#737D90", // Hover background color
                  },
                  height: { xs: "40px", md: "44px" },
                }}
              >
                {t("ADD NEW")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  minWidth: 175,
                  borderRadius: 1,
                  background: "#004F9F",
                  height: { xs: "40px", md: "44px" },
                }}
              >
                {t("ADD EXISTING")}
              </Button>
            </Box>

            <Divider sx={{ width: "100%", my: 0 }} />
          </Box>

          {/* Full Width Divider */}

          <FarmerClusterTable />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "4px",
          borderTop: "1px solid #E0E0E0",
          backgroundColor: "#F5F5F7",
          marginTop: "2rem",
          marginLeft: "-25px",
          marginRight: "-25px",
        }}
      >
        {/* Cancel Button */}
        <Button
          sx={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            marginLeft: "1rem",
            marginBottom: "24px",
            "&:hover": {
              backgroundColor: "#2C343B",
            },
          }}
          variant="contained"
          startIcon={<img src={arrowLeft} alt="Arrow" />}
          onClick={handleCancel}
        >
          {t("CANCEL")}
        </Button>
        {/* Previous Button */}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            sx={{
              backgroundColor: "#E0E0E0",
              height: "40px",
              borderRadius: "4px",
              textTransform: "uppercase",
              color: "#424242",
              minWidth: "108px",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#BDBDBD",
              },
            }}
            variant="contained"
          >
            {t("PREVIOUS")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#004F9F",
              height: "40px",
              minWidth: "108px",
              borderRadius: "4px",
              textTransform: "uppercase",
              color: "white",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#003D7A",
              },
            }}
            variant="contained"
          >
            {t("NEXT")}
          </Button>
        </Box>

        {/* Save Button */}
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            marginRight: "1rem",
            marginBottom: "24px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          onClick={handleSave}
          startIcon={<img src={Save} alt="save" />}
          variant="contained"
          type="submit"
          style={{ display: "flex" }}
        >
          {t("SAVE")}
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t("Cancel Contracting")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 1, fontWeight: 400 }}
            >
              {t(
                "Are you sure you want to cancel this contract? All data is saved in draft to continue later."
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleCloseModal}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={handleCloseModal}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
}
