import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { cultureGroupSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import _Save_Button_ from "assets/_Save_Button_.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import Save from "assets/SaveNew.svg";

export default function AddPlantRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkMS", location);
  const { plantTypeDescription, status, details, ptPlantingId } =
    location?.state?.row || {};
  const { edit } = location.state || {};
  console.log("location.state:", location.state);

  console.log("dhoniGG", plantTypeDescription, edit, ptPlantingId);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    plantTypeDescription: plantTypeDescription || "", // Assuming description is the plant type description
    status: status === true ? true : false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/plantingTypeRegistration"); // Navigate after confirming
  };

  const handleChange = (event) => {
    console.log("type");
    const { name, value, type, checked } = event.target;
    console.log(name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let schemaValidationErrors = {};

    // Validation: required, no leading/trailing spaces, no special characters
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/; // Allow only letters, numbers, and spaces

    // Trim the input to check for leading/trailing spaces
    const plantTypeDescTrimmed = values?.plantTypeDescription
    const plantTypeDescStartTrimmed = values?.plantTypeDescription?.trimStart();

    if (!plantTypeDescTrimmed) {
      schemaValidationErrors.plantTypeDescription =
        "Planting Type is required.";
    }
    //  else if (!specialCharPattern.test(plantTypeDescTrimmed)) {
    //   schemaValidationErrors.plantTypeDescription =
    //     "No special characters allowed.";
    // }
    else if (plantTypeDescTrimmed?.trim() !== values?.plantTypeDescription) {
      schemaValidationErrors.plantTypeDescription =
        "Planting Type must not contain leading or trailing spaces";
    }
    else if (plantTypeDescTrimmed.length > 40) {
      schemaValidationErrors.plantTypeDescription =
        "Maximum 40 characters allowed.";
    }
    // } else if (values?.plantTypeDescription.startsWith(" ")) {
    //   schemaValidationErrors.plantTypeDescription =
    //     "No leading spaces allowed.";
    // }
    // If there are validation errors, update the errors state and exit
    if (Object.keys(schemaValidationErrors).length > 0) {
      // setSeverity("error");
      // setMessage("Unable to save Planting Type. Please check your inputs.");
      // setOpenAlert(true);
      setErrors(schemaValidationErrors);
      return;
    }

    // Proceed with the form submission if no validation errors
    const payload = {
      plantTypeDescription: plantTypeDescTrimmed, // Use trimmed value
      status: values?.status,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string", // Change this to actual user data
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
    };

    try {
      setLoading(true);
      const response = await PlantingService.createPlanting({ ...payload });
      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        // setMessage("RECORD SAVED SUCCESSFULLY!");
        setMessage("Added successfully");
        setOpenAlert(true);
        navigate("/plantingTypeRegistration");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    // try {
    //   await cultureGroupSchema.validate(values, { abortEarly: false });
    // } catch (err) {
    //   err?.inner?.forEach((error) => {
    //     schemaValidationErrors[error.path] = error.message;
    //   });
    //   setErrors(schemaValidationErrors);
    // }
    const plantTypeDescTrimmed = values?.plantTypeDescription

    if (!plantTypeDescTrimmed) {
      schemaValidationErrors.plantTypeDescription =
        "Planting Type is required.";
    }
    else if (plantTypeDescTrimmed?.trim() !== values?.plantTypeDescription) {
      console.log("before->", values?.plantTypeDescription, "after->", plantTypeDescTrimmed?.trim())
      schemaValidationErrors.plantTypeDescription =
        "Planting Type must not contain leading or trailing spaces";
    }
    else if (plantTypeDescTrimmed.length > 40) {
      schemaValidationErrors.plantTypeDescription =
        "Maximum 40 characters allowed.";
    }
    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        plantTypeDescription: values?.plantTypeDescription, // Assuming values from form
        status: values?.status, // Assuming status from form
        modifiedDateTimeUTC: new Date(), // Update modified date
        modifiedByUser: "string", // Update modified user info
        ptPlantingId, // Keep the plantingId from existing data
      };
      console.log("Update Payload:", payload);
      try {
        setLoading(true);
        const response = await PlantingService.updatePlanting({ ...payload });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage("Updated successfully");
          setOpenAlert(true);
          navigate("/plantingTypeRegistration");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={"Planting Type Registration "} background="#ffffff">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{Lable.fromLable}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
            }}
          >
            <TextField
              name="plantTypeDescription"
              label="Planting Type"
              value={values?.plantTypeDescription}
              onChange={handleChange}
              error={!!errors.plantTypeDescription}
              helperText={errors.plantTypeDescription}
              variant="outlined"
              required
              disabled={edit == false}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-disabled": {
                    "& fieldset": {
                      borderColor: "gray",
                      borderWidth: "1px",
                    },
                    "&:hover fieldset": {
                      borderColor: "gray",
                      borderWidth: "1px",
                    },
                  },
                },
                "& .MuiFormHelperText-root": {
                  position: "absolute",
                  bottom: "-30px",
                },
              }}
              InputProps={{
                style: { height: "50px" },
              }}
            />

            {/* Switch and Status Part */}
            {/* <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  Status
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        color="primary"
                        disabled={details}
                      />
                    }
                    label={<Typography sx={{ ml: 0 }}>Active</Typography>}
                    labelPlacement="end" // Change label placement to "end" to display it after the switch
                  />
                </Box>
              </FormControl>
            </Box> */}
            <Box
              sx={{
                ml: "15px",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#5A646E",
                    mb: 2,
                    ml: 0.5,
                    // mr:17,
                    // bgcolor:"red",
                    "&.Mui-focused": { color: "#5A646E" },
                    "&:hover": { color: "#5A646E" },
                    fontWeight: "500",

                  }}
                >
                  Status
                  <span style={{ color: "red" }}> *</span>
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 0.5,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={values.status}
                      onChange={handleChange}
                      name="status"
                      color="primary"
                      disabled={details || edit === false}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500", color: "#737D86" }}>
                      {values.status === true ? "Active" : "Inactive"}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
              disabled={loading} // Keep disabled if needed, but no loading spinner shown
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {/* disabled={edit==false} */}
              {edit == false ? "Back" : "Cancel"}
            </Button>

            {loading && <CircularProgress size={20} />}

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    Are you sure you want to cancel this operation? All your
                    data will be lost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Save Button - Right bottom corner */}
            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
