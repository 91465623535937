import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  ListItemText,
} from '@mui/material';

const SpacingMultiSelect = ({ 
  label, 
  options, 
  value = [], 
  onChange, 
  disabled = false 
}) => {
  const handleChange = (event) => {
    const selectedValues = event.target.value;
    console.log('Selected Options:', selectedValues); 

    if (Array.isArray(selectedValues)) {
      const lastSelected = selectedValues[selectedValues.length - 1];
      const isAlreadySelected = value.some(v => v.value === (lastSelected?.value || lastSelected));

      let newValue;
      if (isAlreadySelected) {
        newValue = value.filter(v => v.value !== (lastSelected?.value || lastSelected));
      } else {
        const optionToAdd = options.find(opt => opt.value === (lastSelected?.value || lastSelected));
        if (optionToAdd) {
          newValue = [...value, optionToAdd];
        } else {
          newValue = [...value, lastSelected];
        }
      }

      // Format the selected options before passing them to the parent
      const formattedOptions = newValue.map(option => ({
        value: option.value,
        label: option.label,
        lineSpacing: option.lineSpacing,
        plantSpacing: option.plantSpacing,
        spacingRegistrationUid: option.spacingRegistrationUid
      }));

      onChange(formattedOptions);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel 
        id={`${label}-label`}
        shrink
        sx={{
          '&.Mui-focused': {
            color: 'gray',
          },
          padding: '0 8px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((val) => (
              <Chip
                key={val.value}
                label={val.label}
                size="small"
                onDelete={(e) => {
                  e.stopPropagation();
                  const newValue = value.filter(v => v.value !== val.value);
                  onChange(newValue);
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem 
            key={option.value}
            value={option}
            sx={{
              borderLeft: value?.some(v => v.value === option.value) ? '4px solid #004F9F' : 'none',
              '&.Mui-selected': {
                borderLeft: '4px solid #004F9F',
              },
              '&.Mui-selected:hover': {
                borderLeft: '4px solid #004F9F',
              }
            }}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SpacingMultiSelect; 