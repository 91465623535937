import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import { FormHelperText, Grid, ListItemText } from "@mui/material";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import CheckboxTick from "assets/CheckboxTick.svg";
import CheckboxUntick from "assets/CheckboxUntick.svg";
import CustomButton from "utils/CustomButton";
import ProfileService from "services/ProfileService";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  profileCode: Yup.string()
    .required("Profile Code is required")
    .max(50, "Profile Code must be less than 50 characters")
    .matches(
      /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      "Profile Code is required and must not contain special characters or extra spaces"
    ),
  profileName: Yup.string()
    .required("Profile Name is required")
    .max(50, "Profile Name must be less than 50 characters")
    .matches(
      /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      "Profile Name is required and must not contain special characters or extra spaces"
    ),
  profileAttributes: Yup.string().required("Attribute is required"),
  functionality: Yup.array()
    .min(1, "At least one item is required")
    .required("Functionality is required"),
});

const AddProfiles = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { user } = AuthData();
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const { previousData, edit, id } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [formData, setFormData] = useState({
    profileCode: previousData?.profileCode || "",
    profileName: previousData?.profileName || "",
    profileAttributes: previousData?.attributes || "",
    isActive: previousData?.active == 'Active' ? true:false || false,
    functionality: selected,
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const val = name === "isActive" ? checked : value;
    setFormData({
      ...formData,
      [name]: val,
    });
  };


  useEffect(() => {
    if (location && location.pathname == "/addProfiles") {
      let p_name = formData.profileName;
      let p_attr = formData.profileAttributes
      const result = mergeFirstCharacters(p_name) + mergeFirstCharacters(p_attr);
      const uniqueNumber = generateUniqueNumber();
      let numberStr = uniqueNumber.toString();
      if (numberStr.charAt(0) == '-') {
        numberStr = numberStr.replace(numberStr[0], '');
      }
      setFormData({
        ...formData,
        ['profileCode']: `${result.toUpperCase()}${numberStr}`,
      });
    }

  }, [formData.profileName, formData.profileAttributes])

  const mergeFirstCharacters = (str) => {
    const words = str.split(" ");
    const mergedCharacters = words.map(word => word.charAt(0)).join("");
    return mergedCharacters;
  };
  
  function generateUniqueNumber() {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 1000000);
    const uniqueIdentifier = navigator.userAgent;
    const uniqueNumber = hashCode(timestamp + randomNumber + uniqueIdentifier);
    return uniqueNumber;
  }

  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return hash;
  }


  useEffect(() => {
    const fetchFunctionalityOptions = async () => {
      try {
        const response = await ProfileService.getFuntionalityOptions();

        if (edit === true) {
          setOptions([
            ...previousData.functionality,
            ...response.data.response.filter(
              (option) =>
                !previousData.functionality.some(
                  (existingOption) =>
                    existingOption.functionalityName ===
                    option.functionalityName
                )
            ),
          ]);

          // Initialize selected countries with countryArr
          const selectedOptions = previousData.functionality.filter((option) =>
            response.data.response.some(
              (selectedOption) =>
                selectedOption.functionalityName === option.functionalityName
            )
          );
          setSelected(selectedOptions);
          setFormData({
            ...formData,
            functionality: selectedOptions,
          });
        } else {
          setOptions(response.data.response);
        }
      } catch (error) {
        console.error("Error fetching functionality options:", error);
      }
    };

    fetchFunctionalityOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      const payload = {
        profileCode: formData.profileCode,
        profileName: formData.profileName,
        isActive: formData.isActive,
        profileAttribute: formData.profileAttributes,
        functionality: formData.functionality,
        createdByUser: user?.userName,
        modifiedByUser: user?.userName,
      };

      const response = await ProfileService.createProfileRegistration(payload);

      if (response.status === 201) {
        navigate("/manageProfiles");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Failed to submit form");
        setOpenAlert(true);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        let newError = {};
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        setMessage(error?.response?.data?.message || error?.message)
        setOpenAlert(true)
        setSeverity("error")
        setLoading(false)
      }
    }
    setLoading(false);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      const payload = {
        profileCode: formData.profileCode,
        profileName: formData.profileName,
        isActive: formData.isActive,
        profileAttribute: formData.profileAttributes,
        modifiedByUser: user.userName,
        profileId: id,
        functionality: formData.functionality,
      };

      const response = await ProfileService.updateProfileRegistration(payload);

      if (response.status === 200) {
        navigate("/manageProfiles");
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
      } else {
        console.error("Failed to update form");
        setSeverity("error");
        setMessage("Failed to update form");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        let newError = {};
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", error);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
    setLoading(false);
  };

  const handleChangeCountry = (event) => {
    const { name, value } = event.target;
    const options = event.target.value;
    setSelected(options);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Layout title={t("manageProfiles.pageTitle")}>
      <div style={{paddingBottom:"4rem"}}>
      <Heading>{edit === true ? "Edit" : "Add"}  {t("manageProfiles.profiles")}</Heading>
      <PaperContainer>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
              {t("manageProfiles.profileCode")}
              </InputLabel>
              <Box>
                <TextField
                  sx={{ width: { xs: "100%", md: "14rem" } }}
                  id="profileCode"
                  aria-describedby="my-helper-text"
                  label={formData.profileCode ? "" : "Profile Code"}
                  value={formData.profileCode}
                  name="profileCode"
                  onChange={handleChange}
                  disabled={true}
                />
                {errors.profileCode && (
                  <FormHelperText error={true}>
                    {errors.profileCode}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={4.5}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
              {t("manageProfiles.attributes")}
              </InputLabel>
              <FormControl
                sx={{ width: { xs: "100%", md: 222 }, minHeight: 50 }}
              >
                <TextField
                  select
                  value={formData.profileAttributes}
                  name="profileAttributes"
                  label={t("manageProfiles.attributes")}
                  onChange={handleChange} // Update the event handler
                  displayEmpty
                  inputProps={{ "aria-label": "Attributes" }}
                  renderValue={(selected) =>
                    selected ? selected : <em>Attributes</em>
                  }
                >
                  <MenuItem value="">
                    <em>Attributes</em>
                  </MenuItem>
                  <MenuItem value={"Global"}>Global</MenuItem>
                  <MenuItem value={"Local"}>Local</MenuItem>
                </TextField>
                <FormHelperText error={true}>
                  {errors.profileAttributes}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              md={2.2}
              xs={12}
              sx={{
                display: { md: "flex", xs: "none" },
                alignItems: "center",
                flexDirection: "end",
              }}
            >
              <InputLabel
                sx={{ margin: "0 4.1rem 0  2rem" }}
                id="mutiple-select-label"
              >
                {t("active")}
              </InputLabel>
              <Checkbox
                onChange={handleChange}
                name="isActive"
                required
                checked={formData.isActive}
                icon={<img src={CheckboxUntick} />}
                checkedIcon={<img src={CheckboxTick} />}
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
              {t("manageProfiles.profileName")}
              </InputLabel>
              <Box>
                <TextField
                  sx={{ width: { xs: "100%", md: "14rem" } }}
                  id="my-input"
                  aria-describedby="my-helper-text"
                  label={formData.profileName ? "" : "Profile Name"}
                  value={formData.profileName}
                  name="profileName"
                  onChange={handleChange}
                  disabled={edit}
                />
                <FormHelperText error={true}>
                  {errors.profileName}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
              {t("manageProfiles.functionality")}
              </InputLabel>
              <FormControl
                sx={{ width: { xs: "100%", md: 222 }, minHeight: 50 }}
              >
                <InputLabel
                  id="mutiple-select-label"
                  sx={{
                    "&.MuiInputLabel-shrink": {
                      color: "#0E2B63",
                    },
                    color: "#0E2B63",
                  }}
                >
                  {t("manageProfiles.functionality")}
                </InputLabel>

                <Select
                  labelId="mutiple-select-label"
                  multiple
                  variant="outlined"
                  name="functionality"
                  value={selected}
                  label="Functionality"
                  onChange={handleChangeCountry}
                  renderValue={(selected) =>
                    selected
                      .map((select) => select.functionalityName)
                      .join(", ")
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.functionalityName} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selected.indexOf(option) > -1}
                          icon={<img src={CheckboxUntick} />}
                          checkedIcon={<img src={CheckboxTick} />}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option.functionalityName} />{" "}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={Boolean(errors.functionality)}>
                  {errors.functionality}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: { md: "none", xs: "flex" },
                alignItems: "center",
                flexDirection: "end",
              }}
            >
              <InputLabel
                sx={{ margin: "0 4.1rem 0  2rem" }}
                id="mutiple-select-label"
              >
                 {t("active")}
              </InputLabel>
              <Checkbox
                onChange={handleChange}
                name="isActive"
                required
                checked={formData.isActive}
                icon={<img src={CheckboxUntick} />}
                checkedIcon={<img src={CheckboxTick} />}
              />
            </Grid>
          </Grid>
        </form>
        <ButtonContainer>
          <CustomButton
            label= {t("cancel")}
            variant="cancel"
            onClick={() => navigate("/manageProfiles")}
          />

          <CustomButton
            loading={loading}
            label={edit === true ? t("update") : t("submit")}
            onClick={edit === true ? handleEdit : handleSubmit}
          />
        </ButtonContainer>
      </PaperContainer>
      </div>

    </Layout>
  );
};

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

export default AddProfiles;
