import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Save from "assets/SaveNew.svg";
import Layout from "components/structure/Layout";
import { useTranslation } from "react-i18next";
import RedSwitch from "utils/RedSwitch";

// Styled Components (similar to InvestmentGroupTable)
const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto",
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0px 4px",
  minWidth: "100%",
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  height: "20px",
  minHeight: "20px",
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  verticalAlign: "middle",
});

const InputDataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  height: "20px",
  minHeight: "20px",
  fontWeight: 400,
  fontSize: "15px",
  color: "#000000",
  padding: "13px 20px",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  verticalAlign: "middle",
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  height: "20px",
  minHeight: "20px",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
  verticalAlign: "middle",
});

const TSF = () => {
  const { t } = useTranslation();
  const columns = ["Distributed", "Consumed", "Balance"];
  const headers = ["Payment Self Finance for 2023"];
  const headers1 = ["Target for 2024"];

  // State to track which columns are selected
  const [selectedColumns, setSelectedColumns] = useState(
    columns.reduce((acc, column) => {
      acc[column] = false;
      return acc;
    }, {})
  );

  // State to manage input values
  const [targetInputs, setTargetInputs] = useState({
    'Node 1': '0',
    'Node 2': '0',
    'Node 3': '0',
    'Node 4': '0',
  });

  // New state for the switch
  const [isContracted, setIsContracted] = useState(false);

  // Handler to toggle column selection
  const handleColumnToggle = (column) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // Handler for input changes
  const handleTargetInputChange = (node, value) => {
    // Remove non-numeric characters and limit to numbers with up to 2 decimal places
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    // Ensure only valid numeric input
    setTargetInputs(prev => ({
      ...prev,
      [node]: numericValue
    }));
  };

  // Handler for switch toggle
  const handleSwitchToggle = () => {
    setIsContracted(!isContracted);
  };

  return (
    <Layout 
      title="Targets of Self Finance"
      background="#ffffff"
    >
      {/* Filters Section */}
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>

        <Grid container spacing={2}>
          {/* Crop Season Input Field */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label={t("targetsOfSelfFinance.cropSeason")}
              variant="outlined"
            />
          </Grid>

          {/* Hierarchical Node Input Field */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label={t("targetsOfSelfFinance.hierarchicalNode")}
              variant="outlined"
            />
          </Grid>
          {!isContracted && (
            <>
            {/* Crop input cost per ha input field */}
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label={t("targetsOfSelfFinance.cropInputCostPerHa")}
                  variant="outlined"
                />
              </Grid>
              {/* Previous crop year comparison input field */}
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label={t("targetsOfSelfFinance.previousCropYearComparison")}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          {isContracted && (
            <Grid item xs={12} sm={6}></Grid>
          )}
        </Grid>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 2px",
            background: "#FFFFFF",
            marginTop: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#5A646E",
              marginLeft: "10px",
            }}
          >
            Subordinates Hierarchies & Distribution Targets
          </Typography>
        </Box>
        <Box
          id="divider"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 20px 0px",
            gap: "20px",
            background: "#FFFFFF",
            borderBottom: "5px solid #004F9F",
          }}
        ></Box>
      </Box>

      {/* Column and Header Selection */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={3.5}>
          <Box
            sx={{
              backgroundColor: "#EFEFEF",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            <Box sx={{ display: "flex", marginLeft: "40px" }}>
              <Typography fontWeight="600" color="#666666">
                View Target For:
              </Typography>
            </Box>
            <Box
              sx={{
                ml: 4,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl
                component="fieldset"
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#0E2B63 !important",
                  },
                  mt: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      mr: 3,
                      ml: 3,
                      color: "#0E2B63",
                      "&.MuiTypography-root": {
                        color: "#0E2B63 !important",
                      },
                    }}
                  >
                    Estimated
                  </Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        name="isActive"
                        color="primary"
                        checked={isContracted}
                        onChange={handleSwitchToggle}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          ml: 0,
                          color: "#0E2B63",
                          "&.MuiTypography-root": {
                            color: "#0E2B63 !important",
                          },
                        }}
                      >
                        Contracted
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Table Section */}
      <Grid container spacing={2} sx={{ mb: 10, mt: 1, overflowX: 'auto' }}>
        {/* Grid Item for Hierarchical Node Column */}
        <Grid item xs={3.5} sx={{ minWidth: '250px', mt:"100px" }}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <SecondaryHeaderCell>Hierarchical Node</SecondaryHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Dynamically render rows based on the current view */}
                {(isContracted 
                  ? ["Node 1", "Node 2", "Node 3"] 
                  : ["Node 1", "Node 2", "Node 3", "Node 4"]
                ).map((node) => (
                  <TableRow key={node}>
                    <DataCell>{node}</DataCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TotalCell sx={{ 
                    // height: '50px', // Fixed height for all total rows
                    padding: '13px 20px',
                  }}>
                    {t("total")}
                  </TotalCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', overflowX: 'auto', marginLeft: "20px" }}>
          <Grid container spacing={2} sx={{ flexWrap: 'nowrap', display: 'flex' }}>
            {/* Conditionally render Self Finance for 2024 when switch is off (Estimated) */}
            {!isContracted && (
              <Grid item sx={{ minWidth: '800px', paddingLeft: "100px" }}>
                <StyledTableContainer component={Paper}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <HeaderCell colSpan={6}>
                          Self Finance for 2024
                        </HeaderCell>
                      </TableRow>
                      <TableRow>
                        <SecondaryHeaderCell>
                          Total Invoiced (R$)
                        </SecondaryHeaderCell>
                        <SecondaryHeaderCell>By Tobacco (R$)</SecondaryHeaderCell>
                        <SecondaryHeaderCell>By Bank (R$)</SecondaryHeaderCell>
                        <SecondaryHeaderCell>
                          Paid Upfront (R$)
                        </SecondaryHeaderCell>
                        <SecondaryHeaderCell>Percentage (%)</SecondaryHeaderCell>
                        <SecondaryHeaderCell>Target (%)</SecondaryHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {["Node 1", "Node 2", "Node 3", "Node 4"].map((node) => (
                        <TableRow key={node}>
                          <DataCell>0</DataCell>
                          <DataCell>0</DataCell>
                          <DataCell>0</DataCell>
                          <DataCell>0</DataCell>
                          <DataCell>0</DataCell>
                          <InputDataCell>
                            <TextField
                              value={targetInputs[node] || '0'}
                              onChange={(e) => handleTargetInputChange(node, e.target.value)}
                              type="number"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  height: '20px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  '& input': {
                                    textAlign: 'center',
                                    height: '100%',
                                    padding: 0,
                                  }
                                }
                              }}
                              fullWidth
                            />
                          </InputDataCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TotalCell>0</TotalCell>
                        <TotalCell>0</TotalCell>
                        <TotalCell>0</TotalCell>
                        <TotalCell>0</TotalCell>
                        <TotalCell>0</TotalCell>
                        <TotalCell>0</TotalCell>
                      </TableRow>
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </Grid>
            )}

            {/* Conditionally render Payment Self Finance for 2023 when switch is on (Contracted) */}
            {isContracted && (
              <Grid item sx={{ minWidth: '800px'}}>
                <StyledTableContainer component={Paper}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <HeaderCell key={header} colSpan={5}>
                            {header}
                          </HeaderCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        {headers.map((header) => (
                          <React.Fragment key={header}>
                            <SecondaryHeaderCell>
                              Total Invoiced (R$)
                            </SecondaryHeaderCell>
                            <SecondaryHeaderCell>By Tobacco (R$)</SecondaryHeaderCell>
                            <SecondaryHeaderCell>By Bank (R$)</SecondaryHeaderCell>
                            <SecondaryHeaderCell>
                              Paid Upfront (R$)
                            </SecondaryHeaderCell>
                            <SecondaryHeaderCell>Percentage (%)</SecondaryHeaderCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {["Node 1", "Node 2", "Node 3"].map((node) => (
                        <TableRow key={node}>
                          {headers.map((header) => (
                            <>
                              <DataCell>0</DataCell>
                              <DataCell>0</DataCell>
                              <DataCell>0</DataCell>
                              <DataCell>0</DataCell>
                              <DataCell>0</DataCell>
                            </>
                          ))}
                        </TableRow>
                      ))}
                      <TableRow>
                        {headers.map((header) => (
                          <>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                          </>
                        ))}
                      </TableRow>
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </Grid>
            )}

            {/* Conditionally render Target for 2024 when switch is on (Contracted) */}
            {isContracted && (
              <Grid item sx={{ minWidth: '500px' }}>
                <StyledTableContainer component={Paper}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        {headers1.map((header) => (
                          <HeaderCell key={header} colSpan={3}>
                            {header}
                          </HeaderCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        {headers1.map((header) => (
                          <React.Fragment key={header}>
                            <SecondaryHeaderCell>
                              Billing Prediction (R$)
                            </SecondaryHeaderCell>
                            <SecondaryHeaderCell>Target (R$)</SecondaryHeaderCell>
                            <SecondaryHeaderCell>Percentage (%)</SecondaryHeaderCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {["Node 1", "Node 2", "Node 3"].map((node) => (
                        <TableRow key={node}>
                          {headers1.map((header) => (
                            <>
                              <DataCell>0</DataCell>
                              <DataCell>0</DataCell>
                              <InputDataCell>
                                <TextField
                                  value={targetInputs[node]}
                                  onChange={(e) => handleTargetInputChange(node, e.target.value)}
                                  type="number"
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                      height: '20px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                      '& input': {
                                        textAlign: 'center',
                                        height: '100%',
                                        padding: 0,
                                      }
                                    }
                                  }}
                                  fullWidth
                                />
                              </InputDataCell>
                            </>
                          ))}
                        </TableRow>
                      ))}
                      <TableRow>
                        {headers1.map((header) => (
                          <>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                            <TotalCell>0</TotalCell>
                          </>
                        ))}
                      </TableRow>
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </Grid>
            )}
            
            {/* MY TARGET always renders */}
            <Grid item sx={{ minWidth: '300px' }}>
              <StyledTableContainer component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <HeaderCell>MY TARGET</HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <DataCell>68,4 / 75%</DataCell>
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Save Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            marginBottom: 4,
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Save" />}
          variant="contained"
        >
          {t("save")}
        </Button>
      </Box>
    </Layout>
  );
};

export default TSF;
