import React, { useState, useEffect } from 'react';
import { TextField, Button, Switch, FormControlLabel, Typography, Box, Grid } from '@mui/material';
import { ChromePicker } from 'react-color';
import { useLocation, useNavigate } from 'react-router-dom';
import MyEditor from '../Editor/MyEditor';

const EditArticle = ({ onUpdateArticle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const article = location.state?.article;
  const articles = location.state?.articles;
  const [articleName, setArticleName] = useState(article?.name || '');
  const [itemColor, setItemColor] = useState(article?.itemColor || '#ffffff');
  const [itemTextColor, setItemTextColor] = useState(article?.itemTextColor || '#000000');
  const [availableOnWeb, setAvailableOnWeb] = useState(article?.availableOnWeb || false);
  const [availableOnMobile, setAvailableOnMobile] = useState(article?.availableOnMobile || false);
  const [icon, setIcon] = useState(article?.icon || null);
  const [attachments, setAttachments] = useState(article?.attachments || null);
  const [editorValue, setEditorValue] = useState(article?.editorValue || null);

  const handleUpdateArticle = () => {
    const updatedArticle = {
      ...article,
      name: articleName,
      itemColor,
      itemTextColor,
      availableOnWeb,
      availableOnMobile,
      icon,
      attachments,
      editorValue
    };
    console.log(updatedArticle)
   
    const updatedArticles = articles.map((art) =>
        art.id === updatedArticle.id ? updatedArticle : art
      );
  
      navigate('/pocArticle', { state: updatedArticles }); 
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }}>
        Edit Article
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <MyEditor value={editorValue} handleEditorChange = {(value)=>setEditorValue(value)}/>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              maxWidth: 600,
              margin: '0 auto',
              padding: 2,
              backgroundColor: '#f9f9f9',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <TextField
              label="Article Name"
              value={articleName}
              onChange={(e) => setArticleName(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ marginBottom: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <Box sx={{ flex: 1, marginRight: 1 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Item Color
                </Typography>
                <ChromePicker color={itemColor} onChangeComplete={(color) => setItemColor(color.hex)} />
              </Box>
              <Box sx={{ flex: 1, marginLeft: 1 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Item Text Color
                </Typography>
                <ChromePicker color={itemTextColor} onChangeComplete={(color) => setItemTextColor(color.hex)} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <FormControlLabel
                control={<Switch checked={availableOnWeb} onChange={(e) => setAvailableOnWeb(e.target.checked)} />}
                label="Available on Web"
              />
              <FormControlLabel
                control={<Switch checked={availableOnMobile} onChange={(e) => setAvailableOnMobile(e.target.checked)} />}
                label="Available on Mobile"
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <Button variant="contained" component="label">
                Upload Icon
                <input type="file" hidden onChange={(e) => setIcon(e.target.files[0])} />
              </Button>
              <Button variant="contained" component="label">
                Upload Attachments
                <input type="file" hidden multiple onChange={(e) => setAttachments(e.target.files)} />
              </Button>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleUpdateArticle}>
                Update Article
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditArticle;