// SelectInput.jsx
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import searchIcon from '../../assets/SearchIcon.svg';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchDropInput({
  label,
  options = [],
  value,
  onChange,
  placeholder = 'Select or type an option',
  variant = "outlined",
  disabled = false,
  sx,
  name,
  error,
  helperText,
  isOptionEqualToValue
}) {
  return (
    <FormControl 
      variant={variant} 
      fullWidth 
      sx={{ ...sx }}
      error={error}
      disabled={disabled}
    >
      <Autocomplete
        freeSolo
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        options={options}
        getOptionLabel={(option) => {
          // Handle both string values and option objects
          if (typeof option === 'string') return option;
          return option?.label || '';
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={variant}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'grey.500' }} />
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem 
            {...props}
            sx={{
              borderLeft: value?.value === option.value ? '4px solid #004F9F' : 'none',
              '&.Mui-selected': {
                borderLeft: '4px solid #004F9F',
              },
              '&.Mui-selected:hover': {
                borderLeft: '4px solid #004F9F',
              }
            }}
          >
            {option.label}
          </MenuItem>
        )}
      />
    </FormControl>
  );
}