import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { tobaccoCompititorsSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import { ADD_SUCCESS_MSG, UPDATE_SUCCESS_MSG } from "utils/AgriErrorMsg";

export default function AddTobaccoCompetitorsRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    tobaccoCompanyName,
    tccCountryCode,
    region,
    status,
    countryCode,
    languageId,
    isDelete,
    tobaccoCompanyId,
  } = location?.state?.row || {};
  const { edit } = location.state || {};

  console.log("dhoni", tobaccoCompanyName, tccCountryCode, region);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    tobaccoCompanyId: tobaccoCompanyId,
    tobaccoCompanyName: tobaccoCompanyName || "", // Assuming this is the tray type description
    tccCountryCode: tccCountryCode || "", // Default to 0 if not provided
    region: region, // Default to 0 if not provided
    status: status === true ? true : false, // Default to false if not true
    countryCode: countryCode || "", // Set default empty string or any fallback value
    languageId: languageId || "", // Set default empty string or any fallback value
    isDelete: isDelete === true ? true : false, // Default to false if not true
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/tobaccoCompetitorsRegistration");
  };

  const handleChange = (event) => {
    // console.log("working");
    const { name, value, type, checked } = event.target;
    // console.log("WERTYUI",name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    console.log("submit");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    try {
      await tobaccoCompititorsSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    // if (Object.keys(schemaValidationErrors).length > 0) {
    //   setValidationErrors(schemaValidationErrors);
    //   return;
    // }

    // setValidationErrors({});
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        //tobaccoCompanyId : values?.tobaccoCompanyId,
        tobaccoCompanyName: values?.tobaccoCompanyName, // Use trimmed value
        tccCountryCode: values?.tccCountryCode,
        region: values?.region,
        status: values?.status,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };

      console.log("payload hai", payload);

      try {
        setLoading(true);
        const response = await TobaccoService.createTobacco({ ...payload });
        setLoading(false);

        if (response.status === 200) {
          setSeverity("success");
          setMessage(
            values.ttTrayTypeId ? UPDATE_SUCCESS_MSG : ADD_SUCCESS_MSG
          );
          setOpenAlert(true);
          navigate("/tobaccoCompetitorsRegistration");
        }
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage("Submission failed");
          setOpenAlert(true);
        }
      }
    }
  };

  const handleUpdate = async (event) => {
    console.log("calledUpdat");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    try {
      await tobaccoCompititorsSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        tobaccoCompanyId: values?.tobaccoCompanyId,
        tobaccoCompanyName: values?.tobaccoCompanyName, // Use trimmed value
        tccCountryCode: values?.tccCountryCode,
        region: values?.region,
        status: values?.status,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };
      console.log("Update Payload:", payload);
      try {
        setLoading(true);
        const response = await TobaccoService.updateTobacco({ ...payload });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage(UPDATE_SUCCESS_MSG);
          setOpenAlert(true);
          navigate("/tobaccoCompetitorsRegistration");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    }
  };
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await TobaccoService.getRegions();
        const formattedOptions = response.data.response.map((item) => ({
          regionId: item.regionId, // Adjust based on your response structure
          stateCode: item.stateCode, // Adjust based on your response structure
        }));
        setRegions(formattedOptions);
      } catch (error) {
        console.error("Error fetching regions:", error);
      } finally {
        // setLoadingOptions(false);
      }
    };
    fetchRegions();
  }, []);

  return (
    <Layout
      title={"Competing Tobacco Companies Registration"}
      //      title={"Tobacco Companies Competitors Registration"}
      background="#ffffff"
    >
      <Box
        sx={{
          maxHeight: 115,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ backgroundColor: "#EFEFEF" }}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{Lable.fromLable}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //justifyContent:"space-between",
              height: "120px",
              marginTop: "-5px",
            }}
          >
            <FormControl sx={{}}>
              <TextField
                name="tobaccoCompanyName"
                label="Company Name"
                value={values?.tobaccoCompanyName}
                onChange={handleChange}
                disabled={edit == false}
                fullWidth
                required
                error={!!errors.tobaccoCompanyName} // Show error state
                helperText={errors.tobaccoCompanyName || " "} // Show error message
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    minWidth: "25rem",
                    "&.MuiOutlinedInput-root.Mui-disabled": {
                      "& fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                    "&.Mui-disabled": {
                      color: "#102a62",
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    minHeight: "1.5em",
                  },
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
              />
            </FormControl>

            <FormControl
              sx={{ ml: "15px" }}
            //error={!!validationErrors.ttNoOfCells}
            >
              <TextField
                name="tccCountryCode"
                label="Country"
                value={values?.tccCountryCode || "PL"}
                onChange={handleChange}
                disabled={edit === false}
                select
                required
                SelectProps={{ IconComponent: ArrowDropDownIcon }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                    width: "17rem",
                    "&.Mui-disabled": {
                      // "& fieldset": {
                      //   borderColor: "gray",
                      //   borderWidth: "1px",
                      // },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                    "&.Mui-disabled": {
                      color: "#102a62",
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    minHeight: "1.5em", // Reserve space for the helper text
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
                error={!!errors.tccCountryCode} // Display error state if validation fails
                helperText={errors.tccCountryCode || " "} // Show the validation error message
              >
                <MenuItem value="PL" disabled sx={{ display: "none" }}>
                  Select an option
                </MenuItem>
                <MenuItem value="Brazil">Brazil</MenuItem>
              </TextField>
            </FormControl>

            <FormControl sx={{}}>
              <TextField
                name="region"
                label="Region"
                value={values?.region || "PL"}
                onChange={handleChange}
                fullWidth
                required
                disabled={edit == false}
                select
                SelectProps={{ IconComponent: ArrowDropDownIcon }}
                error={!!errors.region} // Show error if the field is invalid
                helperText={errors.region || " "} // Show the error message
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                    width: "17rem",
                    "&.Mui-disabled": {
                      "& fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                    "&.Mui-disabled": {
                      color: "#102a62",
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    minHeight: "1.5em", // Reserve space for the helper text
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
              >
                <MenuItem value="PL" disabled sx={{ display: "none" }}>
                  Select an option
                </MenuItem>
                <MenuItem value="RS">RS</MenuItem>
                <MenuItem value="RJ">RJ</MenuItem>
                <MenuItem value="RR">RR</MenuItem>
              </TextField>
            </FormControl>

            <Box
              sx={{
                ml: "15px",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                mb: "2em"
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    fontWeight: "500",
                    color: "#5A646E",
                    mb: 1.5,
                    ml: 0.5,
                    // mr:17,
                    // bgcolor:"red",
                    "&.Mui-focused": { color: "#5A646E" },
                    "&:hover": { color: "#5A646E" },
                    fontWeight: "500",
                  }}
                >
                  Status
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 0.5,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={values?.status}
                      onChange={handleChange}
                      name="status"
                      color="primary"
                      disabled={edit == false}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500", color: "#737D86" }}>
                      {values.status === true ? "Active" : "Inactive"}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
          </Box>
          {/* <hr style={{ marginTop: "200px" }} /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {/* {edit == false ? "Back" : "Cancel"} */}
              Cancel
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    Are you sure you want to cancel this operation? All your
                    data will be lost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
