import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Button, Card, CardContent, Typography, Box, IconButton, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const ArticleList = () => {
  const location = useLocation();
  const [articles, setArticles] = useState(location?.state || []);
  // const [articles, setArticles] = useState( []);
  const navigate = useNavigate();
console.log('aaa' ,articles)
  const handleAddArticle = (newArticle) => {
    setArticles([...articles, newArticle]);
  };

  const handleEditArticle = (article) => {
    navigate('/editArticle', { state: { article, articles } });
  };

  const handleUpdateArticle = (updatedArticle) => {
    setArticles(articles.map(article => (article.id === updatedArticle.id ? updatedArticle : article)));
  };

  const handleDeleteArticle = (articleId) => {
    setArticles(articles.filter(article => article.id !== articleId));
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }}>
        Article List
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/pocArticleAdd', { state: articles })}
        sx={{ marginBottom: 2 }}
      >
        Add Article
      </Button>
      <Grid container spacing={2}>
        {articles.map((article) => (
          <Grid item xs={12} sm={6} md={3} key={article?.id}>
            <Card
              sx={{
                backgroundColor: article?.itemColor,
                color: article?.itemTextColor,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                height: '100%',
              }}
              onClick={() => handleEditArticle(article)}
            >
              <CardContent>
                <Typography variant="h6">{article?.name}</Typography>
              </CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
                <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteArticle(article.id); }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ArticleList;