// GetCuringDevice.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CuringDeviceTable from "./CuringDeviceTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringDeviceService from "services/CuringDeviceService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const GetCuringDevice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [curingData, setCuringData] = useState([]);
  const [filCuringData, setfilCuringData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchParams, setSearchParams] = useState({ description: "" });
  const [isActiveStatus, setIsActiveStatus] = useState(null);

  const handleSearchParamsChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    setSearchKeyword(value);
    if (value) {
      callSearchCuringAPI(0, itemsPerPage, value, isActiveStatus);
    } else {
      callCuringAPI(0, itemsPerPage);
    }
  };

  const handleClearStatus = () => {
    setStatusFilter('');
    setCurrentPage(0);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword);
  }

  const handleStatusChange = (status) => {
    setStatusFilter(status);
    setCurrentPage(0);

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;
    setIsActiveStatus(isActive);
    callSearchCuringAPI(0, itemsPerPage, searchKeyword, isActive);
  };

  const callCuringAPI = (pageNumber, size) => {
    setLoading(true);
    CuringDeviceService.getCuring({ pageNumber, size })
      .then((res) => {
        const curingResults = res?.data?.response?.curingDevices || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCuringData(curingResults);
        setfilCuringData(curingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (curingResults.length === 0 && searchKeyword.length > 0) {
          setMessage(t('CuringDeviceRegistration.messages.noRecordsFound'));
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCuringData([]);
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullCuringMenuAPI = () => {
    setLoading(true);
    CuringDeviceService.getCuring({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const curingFullResults = res?.data?.response?.curingDevices || [];
        const curingmenu = curingFullResults.map((e) => e.cdCuringDeviceName);
        setMenu(curingmenu);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCuringData([]);
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchCuringAPI = (pageNumber, size, keyword, status) => {
    setLoading(true);
    let query = { pageNumber, size, keyword };

    if (status === true || status === false) {
      query.status = status;
    }

    CuringDeviceService.searchCuring(query)
      .then((res) => {
        const curingResults = res?.data?.response?.curingDevices || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setCuringData(curingResults);
        setfilCuringData(curingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (curingResults.length === 0 && searchKeyword.length > 0) {
          setMessage(t('CuringDeviceRegistration.messages.noRecordsFound'));
          setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setCuringData([]);
        setfilCuringData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callCuringAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullCuringMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchCuringAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callCuringAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t('Curing Device Type')}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "100px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t('filtersLable')}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  label={t('CuringDeviceRegistration.curingDeviceTable.curingDevice')}
                  value={searchParams.description}
                  onChange={handleSearchParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label={t('status')}
                    value={statusFilter}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: statusFilter && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="">
                      <span>{t('CuringDeviceRegistration.filters.selectOption')}</span>
                    </MenuItem>
                    <MenuItem value="Active">{t('active')}</MenuItem>
                    <MenuItem value="Inactive">{t('inactive')}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addCuringDevice")}
            >
              {t('addNew')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CuringDeviceTable
        curingData={filCuringData}
      />
    </Layout>
  );
};

export default GetCuringDevice;