import { Autocomplete, Box, Button, FormControl, FormLabel, Grid, Modal, TextField, Typography } from '@mui/material';
import Layout from 'components/structure/Layout';
import { SnackbarContext } from 'context/snackBarContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FarmersContractPreAssessmentService from 'services/FarmersContractPreAssessmentService';
import RedSwitch from 'utils/RedSwitch';
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import uploadIcon from "../../../../../src/assets/uploadImporticon.svg";
import SoilAnalysisService from 'services/SoilAnalysisService';
import label from "../growingLabel.json";
import PreAssessmentRMFarmerStatusTable from './PreAssessmentRMFarmerStatusTable';



const PreAssessmentRMFarmerStatus = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {
    cropSeason,
    crsId,
  } = location?.state || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [selectedCropSeasonId, setSelectedCropSeasonId] = useState(crsId);
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [values, setValues] = useState({});
  console.log("values", values)

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [uploadModal, setUploadModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  console.log("dataRes", data)
  const [isNew, setIsNew] = useState(false)

  console.log("cropSeasonOPT", selectedCropSeasonId, cropSeasonOptions)

  useEffect(() => {
    fetchCropSeasonData();
    fetchData();
  }, [crsId]);

  useEffect(() => {
    fetchData();
  }, [selectedCropSeasonId]);

  const fetchCropSeasonData = async () => {
    try {
      const [cropSeasonResponse] = await Promise.all([
        FarmersContractPreAssessmentService.getCropSeasonAPI(),
      ]);

      setCropSeasonOptions(cropSeasonResponse.data.response.cropSeason.map(item => ({
        label: item.cropYear.toString(),
        value: item.cropYear.toString(),
        cropSeasonId: item.cropSeasonId,// Assuming there's an id field      
      })));

    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await FarmersContractPreAssessmentService.getPreAssessmentRMFarmerList(selectedCropSeasonId);
      console.log("get Response 0", response.data.response)
      const formattedResponse = {
        createdDateTimeUTC: response.data.response.createdDateTimeUTC || "",
        createdByUser: response.data.response.createdByUser || "user",
        modifiedDateTimeUTC: response.data.response.modifiedDateTimeUTC || "",
        modifiedByUser: response.data.response.modifiedByUser || "user",
        fpaFarmerPreAssmntId: response.data.response.fpaFarmerPreAssmntId,
        crsId: response.data.response.crsId,
        fpaIsEnableCropSeason: response.data.response.fpaIsEnableCropSeason,
        cropSeason: response.data.response.cropSeason,
      };
      console.log("get Response 1", formattedResponse)
      setValues(formattedResponse)

      setData(response.data.response.farmerPreAssesmentDetails)
    } catch (error) {
      console.error("No data:", error);
      if (error.status == 404) {
        try {
          const newResponse = await FarmersContractPreAssessmentService.getInitialAllFarmerList();
          const formatNewResponse = newResponse.data.response.map((item) => ({
            createdDateTimeUTC: item.createdDateTimeUTC || "",
            createdByUser: item.createdByUser || "",
            modifiedDateTimeUTC: item.modifiedDateTimeUTC || "",
            modifiedByUser: item.modifiedByUser || "",
            fpadFarmerPreAsseDetailsId: item.fpadFarmerPreAsseDetailsId || "",
            farmerId: item.farmerId,
            farmerFullName: item.farmerFullName,
            farmerCode: item.farmerCode,
            fpadIsBlocked: item.fpadIsBlocked || true,
            fpadBlockReason: item.fpadBlockReason || "",
            cpf: item.fiscalIdCpf || "",
            ihLocationCode: item.farmerHierarchyLinkDto
              ? `${item.farmerHierarchyLinkDto.ihLocationCode || ""} ${item.farmerHierarchyLinkDto.ihLocationName || ""}`
              : "",
            ihLocationName: item.farmerHierarchyLinkDto?.ihLocationName || "",
            status: item.status
          }))
          const defaultResponse = {
            createdDateTimeUTC: "",
            createdByUser: "user",
            modifiedDateTimeUTC: "",
            modifiedByUser: "user",
            fpaFarmerPreAssmntId: "",
            crsId: selectedCropSeasonId,
            fpaIsEnableCropSeason: false,
            cropSeason: cropSeason,
          }
          console.log("formatNewResponse", formatNewResponse)
          setValues(defaultResponse)
          setData(formatNewResponse)
          setIsNew(true)
        } catch (error) {
          console.error("Error fetching PreAssessmentdetails data:", error);
        }
      }
    }
  };

  //handlechanges
  const handleCSStatus = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      fpaIsEnableCropSeason: event.target.checked
    }))
  }


  //File code
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      await handleFileUpload(file);
    } else {
      setMessage("Please upload a CSV file");
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      await handleFileUpload(file);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    try {
      setLoading(true);
      await FarmersContractPreAssessmentService.importPresAssessment(
        file,
        '5d68bc83-3635-4a2b-a0df-1199b0b75c6e', // countryId
        '3fa85f64-5717-4562-b3fc-2c963f66afa6', // languageId
        'Janarddan', // createdByUser
        'Janarddan',  // modifiedByUser
        selectedCropSeasonId,
        values.fpaIsEnableCropSeason
      );
      fetchData()
      setMessage("File uploaded successfully!");
      setOpenAlert(true);
      setSeverity("success");
      setUploadModal(false);
      // Refresh the soil analysis data
      //   callSoilAPI(currentPage, itemsPerPage);
    } catch (error) {
      setMessage(error?.response?.data?.message || "Error uploading file");
      setOpenAlert(true);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplate = () => {
    FarmersContractPreAssessmentService.downloadTemplate()
      .then(response => {
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: 'text/csv'
        });

        // Create a link element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pre_assessment_release_module_template.csv');
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        setMessage(error?.response?.data?.message || "Error downloading template");
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  return (
    <Layout
      title={t('farmersPreAssessment.secondPageTitle')}
      background="#ffffff"
    >
      <Grid
        container
        id="mainfilter"
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
          marginBottom: "12px",
        }}
      >

        <Grid item xs={4} md={4} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              disabled={!edit}
              value={cropSeasonOptions.find(option => option.cropSeasonId === selectedCropSeasonId) || null}
              disableClearable={true}
              onChange={(event, newValue) => {
                if (newValue) {
                  setValues((prevValues) => ({
                    ...prevValues,
                    cropSeason: newValue.value,
                    crsId: newValue.cropSeasonId,
                  }));
                  setSelectedCropSeasonId(newValue.cropSeasonId)
                }
              }}
              options={cropSeasonOptions}
              renderInput={(params) => (
                <TextField {...params}
                  label={t('investmentGroupLimits.csSearchField')}
                  fullWidth
                  placeholder='Select an Option'
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                  }}
                // InputProps={{
                //     style: { height: "50px" }, // Directly setting the height
                //   }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl component="fieldset">
            <FormLabel
              sx={{
                fontWeight: "500",
                color: "#5A646E",
                mb: 1.5,
                ml: 0.5,
                "&.Mui-focused": { color: "#5A646E" },
                "&:hover": { color: "#5A646E" },
              }}
            >
              {t("farmersPreAssessment.enableCropSeason")}
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
                ml: 0.5,
              }}
            >
              {/* "No" label on the left */}
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#5A646E",
                }}
              >
                {t("farmersPreAssessment.disabled")}
              </Typography>

              {/* Switch */}
              <RedSwitch
                sx={{
                  marginRight: "11px",
                }}
                checked={values?.fpaIsEnableCropSeason}
                onChange={(event) => handleCSStatus(event)}
                name="enableCS"
                color="primary"
                disabled={!edit}
              />

              {/* "Yes" label on the right */}
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#5A646E",
                }}
              >
                {t("farmersPreAssessment.enabled")}
              </Typography>
            </Box>
          </FormControl>
        </Grid>
        <Grid item md={3.5} />
        <Grid item md={2.5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <Button
              variant="contained"
              sx={{
                mr: 2,
                height: "50px",
                background: "#004F9F"
              }}
              onClick={() => setUploadModal(true)}
              disabled={!edit}
              startIcon={<img src={uploadIcon} alt="icon" />}
            >
              <Typography textTransform={'uppercase'} fontWeight={500}>
                {t("agriSupplyReg.upload")}/{t("farmersPreAssessment.import")}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "460px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "420px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              {t('farmersPreAssessment.import')}
            </Typography>
          </Box>

          {/* Centering the Icon, Typography, and Button */}
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1, // Space between elements
            }}
          >
            {/* Wrap the icon and text in a Box with a border */}
            <Box
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              sx={{
                border: "4px solid #ACB4BA",
                borderRadius: "5px",
                p: 2,
                paddingLeft: 4,
                paddingRight: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <DescriptionOutlinedIcon
                sx={{ fontSize: 60, color: "grey" }}
              />

              <Typography
                id="modal-modal-description"
                sx={{ mt: 1, fontWeight: 400, textAlign: "center" }}
              >
                {t('SoilAnalysisRegistration.modals.dragDrop')}
              </Typography>

              {/* Add light gray background for "OR" and "BROWSE FILE" */}
              <Box
                sx={{
                  background: "#E9EBEE",
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1.5,
                  mt: 1, // Margin top for spacing
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ fontWeight: 400, textAlign: "center" }}
                >
                  {t('SoilAnalysisRegistration.modals.or')}
                </Typography>

                <Button
                  onClick={handleBrowseClick}
                  sx={{
                    background: "#0E2B63",
                    width: "150px",
                    color: "white",
                    mt: 1,
                    "&:hover": { background: "#0E2B63" },
                  }}
                >
                  {t('SoilAnalysisRegistration.modals.browseFile')}
                </Button>
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept=".csv"
                />
              </Box>
            </Box>

            <Button
              onClick={downloadTemplate}
              sx={{
                background: "#004F9F",
                width: "200px",
                color: "#ffffff",
                mt: 1,
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t('SoilAnalysisRegistration.modals.downloadTemplate')}
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => setUploadModal(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t('close')}
            </Button>
          </Box>
        </Box>
      </Modal>
      {data?.length > 0 ? (
        <PreAssessmentRMFarmerStatusTable data={data} edit={edit} values={values} isNew={isNew} />
      ) : (
        <div>{label.noDataMessage}</div>
      )}
    </Layout>

  )
}

export default PreAssessmentRMFarmerStatus