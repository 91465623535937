import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import CuringDeviceService from "services/CuringDeviceService";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

export default function AddCuringDevice() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    cdCuringDeviceName,
    isActive,
    details,
    cdCuringDeviceid,
    countryId,
    languageId,
  } = location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    cdCuringDeviceName: cdCuringDeviceName || "",
    isActive: isActive === true ? true : false,
    countryId: countryId || "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
    languageId: languageId || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/curingDevice");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const curingDeviceNameTrimmed = values?.cdCuringDeviceName?.trim();

    if (!curingDeviceNameTrimmed) {
      schemaValidationErrors.cdCuringDeviceName = t(
        "CuringDeviceRegistration.formErrors.deviceNameRequired"
      );
    } else if (!specialCharPattern.test(curingDeviceNameTrimmed)) {
      schemaValidationErrors.cdCuringDeviceName = t(
        "CuringDeviceRegistration.formErrors.noSpecialChars"
      );
    } else if (curingDeviceNameTrimmed !== values?.cdCuringDeviceName) {
      schemaValidationErrors.cdCuringDeviceName = t(
        "CuringDeviceRegistration.formErrors.noLeadingTrailingSpaces"
      );
    }

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      cdCuringDeviceName: curingDeviceNameTrimmed,
      isActive: values?.isActive,
      isDelete: false,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
      countryId: values.countryId,
      languageId: values.languageId,
    };

    try {
      setLoading(true);
      const response = await CuringDeviceService.createCuringDevice({
        ...payload,
      });
      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        setMessage(t("RECORD SAVED SUCCESSFULLY!"));
        setOpenAlert(true);
        navigate("/curingDevice");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(
          t("CuringDeviceRegistration.messages.deviceExists", {
            name: values.cdCuringDeviceName,
          })
        );
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("CuringDeviceRegistration.messages.submissionFailed"));
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        cdCuringDeviceName: values?.cdCuringDeviceName,
        isActive: values?.isActive,
        isDelete: false,
        countryId: "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
        languageId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        modifiedDateTimeUTC: new Date(),
        modifiedByUser: "string",
        cdCuringDeviceid,
      };
      try {
        setLoading(true);
        const response = await CuringDeviceService.updateCuringDevice({
          ...payload,
        });
        setLoading(false);
        if (response.status === 201) {
          setSeverity("success");
          setMessage(t("RECORD SAVED SUCCESSFULLY!"));
          setOpenAlert(true);
          navigate("/curingDevice");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else if (error.response && error.response.status === 500) {
          setSeverity("error");
          setMessage(
            t("CuringDeviceRegistration.messages.deviceExists", {
              name: values.cdCuringDeviceName,
            })
          );
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage(t("CuringDeviceRegistration.messages.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout
      title={t("CuringDeviceRegistration.curingDeviceTable.registration")}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t("form")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
              borderRadius: "5px",
            }}
          >
            <TextField
              name="cdCuringDeviceName"
              label={t(
                "CuringDeviceRegistration.curingDeviceTable.curingDevice"
              )}
              value={values?.cdCuringDeviceName}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                  marginLeft: "10px",
                },
              }}
              InputProps={{
                style: { height: "50px", marginLeft: "10px" },
              }}
            />
            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl 
                component="fieldset" 
                sx={{
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#0E2B63 !important'
                  }
                }}
              >
                <FormLabel 
                  sx={{ 
                    color: "#0E2B63", 
                    mb: 2, 
                    ml: 0.5,
                    '&.Mui-focused': {
                      color: '#0E2B63 !important'
                    }
                  }}
                >
                  {t("status")}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography 
                    sx={{ 
                      mr: 3, 
                      ml: 3, 
                      color: '#0E2B63', 
                      '&.MuiTypography-root': { 
                        color: '#0E2B63 !important' 
                      } 
                    }}
                  >
                    {t("inactive")}
                  </Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        sx={{ marginRight: "11px" }}
                        checked={values.isActive}
                        onChange={handleChange}
                        name="isActive"
                        color="primary"
                        disabled={edit === false}
                      />
                    }
                    label={
                      <Typography 
                        sx={{ 
                          ml: 0, 
                          color: '#0E2B63',
                          '&.MuiTypography-root': { 
                            color: '#0E2B63 !important' 
                          } 
                        }}
                      >
                        {t("active")}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("back") : t("cancel")}
            </Button>

            {loading && <CircularProgress size={20} />}

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("CuringDeviceRegistration.modals.cancelOperation")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t(
                      "CuringDeviceRegistration.modals.cancelOperationMessage"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed",
                  right: "1rem",
                  bottom: "1.5rem",
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
