import React, { useContext, useState, useEffect } from "react";
import {
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import {
  StyledTableContainer,
  StyledTable,
  StyledTextField,
  HeaderCell,
  SecondaryHeaderCell,
  SubHeaderCell,
  DataCell,
  TotalCell,
} from "./StyledComponents";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import PaymentPolicyList from "./PaymentPolicyList";
import SaveButton from "./SaveButton";
import PaymentPolicyLimitsDistributionService from "services/PaymentPolicyLimitsDistributionService";

const PaymentPolicyLimitsDistributionTable = ({
  cropYearUUID,
  importHierarchyId,
  topLevel,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const columns = ["Distributed", "Consumed", "Balance"];
  const [totalPolicies, setTotalPolicies] = useState(policies);
  const [checkedPolicies, setCheckedPolicies] = useState([]); // Store only checked policies

  const [showColumns, setShowColumns] = useState({
    distributed: true,
    consumed: true,
    balance: true,
  });
  const [minColumnModel, setMinColumnModel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleColumnChange = (key, isChecked) => {
    const uncheckedCount = Object.values(showColumns).filter(
      (value) => !value
    ).length;

    if (!isChecked && uncheckedCount >= 2) {
      setMinColumnModel(true);
      return;
    }
    setShowColumns((prev) => ({
      ...prev,
      [key]: isChecked,
    }));
    setMinColumnModel(false);
  };

  useEffect(() => {
    const destructureData = () => {
      const filteredArray = tableData.flatMap((group) =>
        group.locations
          .filter(() => checkedPolicies.includes(group.policyDescription)) // Filter by checkedPolicies
          .map((location) => ({
            policyId: group.policyID,
            policyDescription: group.policyDescription,
            importHierarchyId: location.importHierarchyId,
            paymentPolicyLimitsDistId: location.paymentPolicyLimitsDistId,
            createdDateTimeUTC: location.createdDateTimeUTC,
            createdByUser: location.createdByUser,
            modifiedDateTimeUTC: new Date().toISOString(),
            cropYearId: cropYearUUID,
            modifiedByUser: "UI",
            ihLocationName: location.ihLocationName,
            target: location.target,
            distributed: location.distributed,
            consumed: location.consumed,
            balance: location.balance,
          }))
      );

      setTransformedData(filteredArray);
    };

    if (tableData.length > 0) {
      destructureData();
    }
  }, [tableData]);

  useEffect(() => {
    const allHeaders = tableData.map((item) => item.policyDescription);
    setCheckedPolicies(allHeaders);
  }, [tableData]);

  // Function to handle checkbox toggle
  const handleCheckboxChange = (header) => {
    setCheckedPolicies((prevChecked) => {
      if (prevChecked.includes(header)) {
        // If already checked, remove it (but prevent unchecking the last one)
        return prevChecked.length > 1
          ? prevChecked.filter((item) => item !== header)
          : prevChecked;
      } else {
        // Otherwise, add it
        return [...prevChecked, header];
      }
    });
  };

  const fetchPolicies = async () => {
    try {
      const response =
        await PaymentPolicyLimitsDistributionService.getPolicyAPI();
      if (response?.data?.statusCode === 200) {
        // Extract PPDescription and paymentPolicyId
        const policiesList = response.data.response.paymentPolicy.map(
          (policy) => policy.PPDescription
        );
        setPolicies(policiesList); // Set the processed list into the state
      } else {
        console.error("Failed to fetch policies:", response.data.message);
      }
    } catch (error) {
      console.error("Error while fetching active policies:", error);
    }
  };

  const fetchActivePolicies = async () => {
    try {
      const response =
        await PaymentPolicyLimitsDistributionService.getActivePolicyAPI();
      if (response?.data?.statusCode === 200) {
        // Extract PPDescription and paymentPolicyId
        const policiesList = response.data.response.paymentPolicy.map(
          (policy) => policy.PPDescription
        );
        setTotalPolicies(policiesList); // Set the processed list into the state
      } else {
        console.error("Failed to fetch policies:", response.data.message);
      }
    } catch (error) {
      console.error("Error while fetching active policies:", error);
    }
  };

  const handleSaveRecords = async (data) => {
    try {
      const response =
        await PaymentPolicyLimitsDistributionService.putPaymentPolicyDistributionAPI(
          data
        );
      if (response.status === 201) {
        setMessage("Records Updated Successfully");
        setSeverity("success");
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("Error updating record:", error);
      setMessage("Error updating record");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const fetchPaymentPolicyLimitsDistributionData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response =
        await PaymentPolicyLimitsDistributionService.getPaymentPolicyDistributionAPI(
          {
            cropYearUUID,
            importHierarchyId,
          }
        );

      if (response?.data?.statusCode === 200) {
        // Create a mapping of location name to policy IDs
        const locationPolicyMap = response.data.response.reduce((map, item) => {
          const key = item.ihLocationName;
          if (!map[key]) {
            map[key] = new Set();
          }
          map[key].add(item.policyId);
          return map;
        }, {});

        // Group the data by policy description and create a unique set of import hierarchy locations
        const processedTableData = response.data.response.reduce(
          (acc, item) => {
            const policyDesc = item.policyDescription;

            // Find or create the policy group
            let policyGroup = acc.find(
              (group) => group.policyDescription === policyDesc
            );

            if (!policyGroup) {
              // If policy group doesn't exist, create it
              policyGroup = {
                policyDescription: policyDesc,
                policyID: item.policyId,
                locations: [],
              };
              acc.push(policyGroup);
            }

            // Check if this import hierarchy ID already exists in the policy group
            const existingLocationIndex = policyGroup.locations.findIndex(
              (loc) => loc.importHierarchyId === item.importHierarchyId
            );

            if (existingLocationIndex === -1) {
              // Add the location only if it doesn't already exist
              const locationEntry = {
                paymentPolicyLimitsDistId: item.paymentPolicyLimitsDistId,
                importHierarchyId: item.importHierarchyId,
                ihLocationName: item.ihLocationName,
                target: item.target,
                distributed: item.distributed,
                consumed: item.consumed,
                balance: item.balance,
                importHierarchyId: item.importHierarchyId,
                createdDateTimeUTC: item.createdDateTimeUTC,
                createdByUser: item.createdByUser,
                // Add the policy IDs for this location
                policyIds: Array.from(
                  locationPolicyMap[item.ihLocationName] || []
                ),
              };

              policyGroup.locations.push(locationEntry);
            }

            return acc;
          },
          []
        );

        // Sort locations to ensure consistent order
        processedTableData.forEach((group) => {
          group.locations.sort(
            (a, b) => a.importHierarchyId - b.importHierarchyId
          );
        });

        setTableData(processedTableData);
      } else {
        throw new Error(response?.data?.message || "Unexpected error occurred");
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data");
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivePolicies();
    fetchPolicies();
  }, []);

  // Fetch data when cropYearUUID or importHierarchyId changes
  useEffect(() => {
    if (cropYearUUID || importHierarchyId) {
      fetchPaymentPolicyLimitsDistributionData();
    }
  }, [cropYearUUID, importHierarchyId]);

  const handleTargetChange = (inputValue, policyDescription, locationName) => {
    setTableData((prevTableData) =>
      prevTableData.map((policyGroup) => {
        if (policyGroup.policyDescription === policyDescription) {
          return {
            ...policyGroup,
            locations: policyGroup.locations.map((location) => {
              if (location.ihLocationName === locationName) {
                const newTarget =
                  inputValue === "" ? "" : parseFloat(inputValue) || 0;
                const consumed = location.consumed || 0;

                // Calculate new balance based on target and consumed
                const newBalance =
                  typeof newTarget === "number"
                    ? newTarget - consumed
                    : location.balance;

                return {
                  ...location,
                  target: newTarget,
                  balance: newBalance,
                };
              }
              return location;
            }),
          };
        }
        return policyGroup;
      })
    );
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        {/* First Grid Item */}
        <Grid item xs={3}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("paymentPolicyLimits.showColumns")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap", // Prevent wrapping
                whiteSpace: "nowrap",
                overflowX: "auto", // Enable horizontal scrolling
                pl: 0.3,
                "@media (max-width: 600px)": {
                  flexDirection: "column", // Stacks items vertically on small screens
                  gap: "4px", // Adjust gap for smaller screens if needed
                },
              }}
            >
              {columns.map((column) => {
                const isDisabled =
                  Object.values(showColumns).filter((value) => value).length <
                    2 && showColumns[column.toLowerCase()];

                return (
                  <Box
                    key={column}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isDisabled}
                          checked={showColumns[column.toLowerCase()]}
                          onChange={(e) =>
                            handleColumnChange(
                              column.toLowerCase(),
                              e.target.checked
                            )
                          }
                          sx={{
                            "&.Mui-checked": {
                              color: "#004F9F", // Optional: Text color when checked
                            },
                          }}
                        />
                      }
                      label={column}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                          whiteSpace: "nowrap", // Prevent text from breaking
                          overflow: "hidden", // Hide overflowing text
                          textOverflow: "ellipsis", // Add ellipsis for long text
                          display: "block", // Ensure proper alignment
                        },
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={9}>
          <Box
            sx={{
              backgroundColor: "#EFEFEF",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("paymentPolicyLimits.showHeaders")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              {/* Header checkboxes */}
              <Box
                sx={{
                  display: "flex",
                  ml: 1,
                  width: "1300px",
                  overflowX: "auto", // Enables horizontal scrolling
                  whiteSpace: "nowrap", // Prevents wrapping of text into multiple lines
                  "&::-webkit-scrollbar": {
                    width: "12px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "6px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                {totalPolicies.map((header, index) => (
                  <Box
                    key={header}
                    sx={{
                      display: "inline-block",
                      marginRight: "8px", // Adjust spacing if needed
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedPolicies.includes(header)} // Use the updated checkedPolicies state
                          onChange={() => handleCheckboxChange(header)}
                          disabled={
                            checkedPolicies.length === 1 &&
                            checkedPolicies.includes(header) // Disable if it's the last checked
                          }
                          sx={{
                            "&.Mui-checked": {
                              color: "#004F9F",
                            },
                          }}
                        />
                      }
                      label={header}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                          whiteSpace: "nowrap", // Prevents label text from wrapping
                          overflow: "hidden", // Ensures no unwanted overflow
                          textOverflow: "ellipsis", // Optional: Truncate overflowing text
                        },
                      }}
                    />
                  </Box>
                ))}
              </Box>

              {/* Manage Items button */}
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 2,
                    backgroundColor: "#004F9F",
                    mt: -1,
                    fontSize: "12px",
                    width: "max-content",
                    display: topLevel ? "block" : "none",
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  {t("paymentPolicyLimits.manageItems")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, pb: "100px" }}>
        <Grid item xs={3}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "25px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SubHeaderCell
                    colSpan={4}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      padding: "26px",
                    }}
                  ></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SecondaryHeaderCell colSpan={4}>
                    {t("paymentPolicyLimits.hnSearchField")}
                  </SecondaryHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(
                  new Set(
                    tableData.flatMap((policyGroup) =>
                      policyGroup.locations.map((row) => row.ihLocationName)
                    )
                  )
                ).map((locationName) => {
                  // Detailed check for policies in this location
                  const policiesForLocation = tableData.filter((policyGroup) =>
                    policyGroup.locations.some(
                      (row) => row.ihLocationName === locationName
                    )
                  );

                  const hasCheckedPolicies = policiesForLocation.some(
                    (policyGroup) =>
                      checkedPolicies.includes(policyGroup.policyDescription)
                  );

                  return (
                    <TableRow key={locationName}>
                      <DataCell colSpan={4}>
                        {locationName}
                        {!hasCheckedPolicies && (
                          <span
                            style={{
                              color: "gray",
                              marginLeft: "10px",
                              fontStyle: "italic",
                            }}
                          >
                            (No Policies)
                          </span>
                        )}
                      </DataCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TotalCell colSpan={4}>
                    {t("paymentPolicyLimits.totals")}
                  </TotalCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": {
              width: "12px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <Box sx={{ display: "flex", gap: 2, minWidth: "fit-content" }}>
            {checkedPolicies.map((header) => (
              <Grid
                key={header}
                item
                xs={6}
                sx={{ display: "inline-block", minWidth: "600px" }}
              >
                <StyledTableContainer component={Paper}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <HeaderCell
                          colSpan={Math.max(
                            2,
                            1 +
                              Object.values(showColumns).filter(Boolean).length
                          )}
                        >
                          {header}
                        </HeaderCell>
                      </TableRow>
                      <TableRow>
                        <SubHeaderCell
                          colSpan={Math.max(
                            2,
                            1 +
                              Object.values(showColumns).filter(Boolean).length
                          )}
                          sx={{ textAlign: "center" }}
                        >
                          {t("paymentPolicyLimits.available/target")}{" "}
                          {(() => {
                            // Find the data for the current policy description
                            const policyData = tableData.find(
                              (item) => item.policyDescription === header
                            );

                            // Filter locations based on `checkedPolicies`
                            const locations =
                              policyData?.locations.filter((location) =>
                                checkedPolicies.includes(
                                  policyData.policyDescription
                                )
                              ) || [];

                            // Render the available/target calculation
                            return locations.length > 0 ? (
                              <span>
                                {"  "}
                                {locations.reduce(
                                  (sum, row) => sum + (row.target || 0),
                                  0
                                ) -
                                  locations.reduce(
                                    (sum, row) => sum + (row.distributed || 0),
                                    0
                                  )}{" "}
                                /{" "}
                                {locations.reduce(
                                  (sum, row) => sum + (row.target || 0),
                                  0
                                )}
                              </span>
                            ) : null;
                          })()}
                        </SubHeaderCell>
                      </TableRow>
                      <TableRow>
                        {["Target", "Distributed", "Consumed", "Balance"]
                          .filter((headerName) => {
                            const key = headerName.toLowerCase();
                            return key === "target" || showColumns[key];
                          })
                          .map((headerName) => (
                            <SecondaryHeaderCell
                              key={headerName}
                              style={{
                                borderLeft:
                                  headerName === "Target"
                                    ? "none"
                                    : "5px solid white",
                                borderRight: "5px solid white",
                              }}
                            >
                              {headerName}
                            </SecondaryHeaderCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        // Find the data for the current header
                        const policyData = tableData.find(
                          (item) => item.policyDescription === header
                        );
                        const locations = policyData?.locations || [];

                        // Get unique locations across all policy groups
                        const allLocations = Array.from(
                          new Set(
                            tableData.flatMap((policyGroup) =>
                              policyGroup.locations.map(
                                (row) => row.ihLocationName
                              )
                            )
                          )
                        );

                        return (
                          <>
                            {allLocations.map((locationName) => {
                              // Find the row for this location in the current policy
                              const row = locations.find(
                                (loc) => loc.ihLocationName === locationName
                              );

                              return (
                                <TableRow key={`${header}-${locationName}`}>
                                  {[
                                    "target",
                                    "distributed",
                                    "consumed",
                                    "balance",
                                  ]
                                    .filter(
                                      (key) =>
                                        key === "target" || showColumns[key]
                                    )
                                    .map((key, j) => (
                                      <DataCell key={`${locationName}-${key}`}>
                                        {key === "target" && row?.target? (
                                          <StyledTextField
                                            fullWidth
                                            variant="outlined"
                                            disabled={!row?.target}
                                            value={
                                              row
                                                ? row.target !== undefined
                                                  ? row.target
                                                  : ""
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleTargetChange(
                                                e.target.value,
                                                header, // Pass the policy description
                                                locationName // Pass the location name
                                              )
                                            }
                                            inputProps={{
                                              inputMode: "decimal",
                                              type: "text", // Allow text input
                                            }}
                                          />
                                        ) : row ? (
                                          row[key] !== undefined ? (
                                            row[key].toLocaleString()
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          "-"
                                        )}
                                      </DataCell>
                                    ))}
                                </TableRow>
                              );
                            })}

                            {/* Totals row */}
                            <TableRow>
                              {["target", "distributed", "consumed", "balance"]
                                .filter(
                                  (key) => key === "target" || showColumns[key]
                                )
                                .map((key) => (
                                  <TotalCell key={key}>
                                    {locations.length > 0
                                      ? locations
                                          .reduce(
                                            (sum, row) => sum + (row[key] || 0),
                                            0
                                          )
                                          .toLocaleString()
                                      : "-"}
                                  </TotalCell>
                                ))}
                            </TableRow>
                          </>
                        );
                      })()}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>

      {/* Save Button */}
      <SaveButton
        onSave={() => {
          handleSaveRecords(transformedData);
        }}
      />

      <PaymentPolicyList
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        headerData={policies}
        setTableCheckedPolicies={setCheckedPolicies}
        checkedPolicies={totalPolicies}
        setCheckedPolicies={setTotalPolicies}
        cropYearUUID={cropYearUUID}
      />
    </>
  );
};
export default PaymentPolicyLimitsDistributionTable;
