import React, { useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@mui/material";
import RedSwitch from "utils/RedSwitch";
import brasilFlag from "assets/BrasilFlag.svg";
import mexicoFlag from "assets/MexicoFlag.svg";
import bangladeshFlag from "assets/BangladeshFlag.svg";
import pakistanFlag from "assets/PakistanFlag.svg";
// import MultiSelectInput from "components/common/MultiSelectInput";

export default function CountryFilter() {
  const [values, setValues] = useState({
    statusBrasil: false,
    statusMexico: false,
    statusBangladesh: false,
    statusPakistan: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Array of country configurations
  const countries = [
    {
      flag: bangladeshFlag,
      name: "Bangladesh (bd_BD)",
      statusKey: "statusBangladesh",
    },
    {
      flag: brasilFlag,
      name: "Brasil (pt_BR)",
      statusKey: "statusBrasil",
    },
    {
      flag: mexicoFlag,
      name: "Mexico (es_MX)",
      statusKey: "statusMexico",
    },
    {
      flag: pakistanFlag,
      name: "Pakistan (en_PK)",
      statusKey: "statusPakistan",
    },
  ];

  return (
    <>
      <Box sx={{marginTop: 5}}></Box>
      {countries.map((country, index) => (
        <React.Fragment key={country.name}>
          <Grid
            container
            spacing={1}
            sx={{
              background: "white",
              borderRadius: "10px",
              p: 2,
              maxWidth: "100%",
              margin: "auto",
              paddingBottom: "10px",
            }}
          >
            <Grid container spacing={1}>

              {/* This grid for space */}
              <Grid
                item
                xs={12}
                style={{ paddingTop: "0px", paddingLeft: "0px" }}
              >
                <Typography display="flex" sx={{ opacity: "0" }}>
                  {/* <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} /> */}
                  Space
                </Typography>
              </Grid>


              {/* Country Flag and Name */}
              <Grid
                item
                xs={2.3}
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={country.flag}
                  alt={`${country.name} flag`}
                  style={{
                    width: "30px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#5A646E",
                    fontFamily: "Montserrat",
                    fontSize: "2px",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                >
                  {country.name}
                </Typography>
              </Grid>

              {/* Culture Type
              <Grid item xs={5}>
                <MultiSelectInput
                  label="Culture Type"
                  name={`cultureType${country.name}`}
                  placeholder="Select an option"
                  variant="outlined"
                  sx={{ width: "100%", height: "46px" }}
                />
              </Grid> */}

              {/* Spacing Type */}
              {/* <Grid item xs={2.5}>
                <MultiSelectInput
                  label="Spacing Type"
                  name={`spacingType${country.name}`}
                  placeholder="Select an option"
                  variant="outlined"
                  sx={{ width: "100%", height: "46px" }}
                />
              </Grid> */}

              {/* Empty grid for spacing */}
              <Grid item xs={7.7}></Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControl component="fieldset">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={values[country.statusKey]}
                          onChange={handleChange}
                          name={country.statusKey}
                        />
                      }
                      label={<Typography sx={{ ml: 2 }}>Active</Typography>}
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Add white space between country sections */}
          {index < countries.length - 1 && <Box sx={{ m: "2rem" }} />}
        </React.Fragment>
      ))}
    </>
  );
}
